import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Input, Select } from 'antd';
import { ArrowDropDown } from '@material-ui/icons';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyGetCampaignListQuery } from 'core/campaign/CampaignService';
import { setCampaignData } from 'core/campaign/CampaignSlice';
import classNames from 'classnames';

type SetupPart = {
  isValid: boolean;
  segmentsName: string;
  selectedFilterStatus: number;
  setSegmentsName: (value: string) => void;
  setSelectedFilterStatus: (value: number) => void;
};

const SetupPart = ({
  isValid,
  segmentsName,
  selectedFilterStatus,
  setSegmentsName,
  setSelectedFilterStatus,
}: SetupPart) => {
  const dispatch = useAppDispatch();
  const [getCampaignList] = useLazyGetCampaignListQuery();

  const { list } = useAppSelector(state => state.campaignReducer);
  const { campaigns } = list;

  useEffect(() => {
    getInitialData();
  }, []);

  const handleSegmentsNameChange = (e: any) => {
    setSegmentsName(e.target.value.trimStart());
  };

  const handleSort = (value: number) => {
    setSelectedFilterStatus(value);
  };

  const getInitialData = async () => {
    const queryString = 'count=true&sort[id]=desc';
    try {
      const res = await getCampaignList({ queryString }).unwrap();
      dispatch(setCampaignData({ list: res }));
    } catch (error) {
      console.error('Error fetching campaign list:', error);
    } finally {
      /* setState({ ...state, isLoading: false }); */
    }
  };

  const checkField = (value: any) => {
    if (isValid) return;
    if (value) {
      const valid = value.length > 0 || value;
      return !valid;
    } else {
      return true;
    }
  };

  return (
    <>
      <div className="" id="campaign_form_wrapper">
        <div className="basic_info_form">
          <div className="tabs_input">
            <Form.Item>
              <p className="title_deals">
                <FormattedMessage id="Internal Name" />
              </p>
              <Input
                placeholder="Nombre Interno"
                value={segmentsName}
                onChange={handleSegmentsNameChange}
                maxLength={200}
                className={checkField(segmentsName) ? 'has-error' : ''}
              />
              {checkField(segmentsName) && (
                <span className="error_msg">
                  <FormattedMessage id="Field is required" />
                </span>
              )}
            </Form.Item>
            <Form.Item>
              <p className="title_deals">
                <FormattedMessage id="Associated Campaign" />
              </p>
              <Select
                className={`select_modal ${checkField(selectedFilterStatus) ? 'has-error' : ''}`}
                suffixIcon={<ArrowDropDown />}
                onChange={handleSort}
                id="campaign_status_filter"
                value={selectedFilterStatus}
                dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
              >
                {campaigns &&
                  campaigns.map((item: any) => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
              {checkField(selectedFilterStatus) && (
                <span className="error_msg">
                  <FormattedMessage id="Field is required" />
                </span>
              )}
              <p className={classNames('title_deals', 'title_weight')}>
                <FormattedMessage id="Base Weight" />
              </p>
              <p className="description_weight">
                <FormattedMessage id="Base Weight Description" />
              </p>
            </Form.Item>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetupPart;
