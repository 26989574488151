import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { compose } from 'react-recompose';
import PropTypes from 'prop-types';
import { Img } from 'react-image';
import { Button, Input, Spin } from 'antd';
import Icon from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { FormattedMessage, useIntl } from 'react-intl';
import { MailOutline, LockOutlined, ArrowForward } from '@material-ui/icons';
import logoImg from 'assets/img/logo.svg';
import { getCountry } from 'utilities/common';
import { useLoginMutation, useLogoutMutation } from 'core/auth/LoginService';
import { setAdminData } from 'core/auth/LoginSlice';
import { useAppSelector, useAppDispatch } from 'utilities/redux';
import { authenticationErrorCodes } from '../../utilities/constants';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const Login = ({ form }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [login] = useLoginMutation();
  const [logout] = useLogoutMutation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const intl = useIntl();

  const { admin } = useAppSelector(state => state.loginReducer);

  useEffect(() => {
    if (admin.accessToken) {
      history.push('/dashboard/overview');
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        setIsLoading(true);
        try {
          const res = await login({
            email: values.email,
            password: values.password,
          }).unwrap();
          console.log(res);
          dispatch(
            setAdminData({
              admin: res,
            }),
          );
          setIsLoading(false);
          history.push('/dashboard/overview');
        } catch (error) {
          if (authenticationErrorCodes.includes(error.data.code)) {
            setErrMsg('Authentication error');
          } else {
            setErrMsg('Cannot connect to internet');
          }
          setIsLoading(false);
        }
      }
    });
  };

  const globalSSO = async () => {
    setIsLoading(true);
    await logout({}).unwrap();
    document.cookie = 'connect.sid=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    window.location.href = `${process.env.REACT_APP_SERVER}/v0/auth/mcd-global`;
    setIsLoading(false);
  };

  const { getFieldDecorator } = form;
  return (
    <div className="login_layout">
      <Spin indicator={antIcon} spinning={isLoading}>
        <div className="left_wrapper">
          <div className="logo-wrapper">
            <Img src={logoImg} alt="logo" />
          </div>
          <div className="login_wrapper">
            <p className="title">
              <FormattedMessage id="Administration system" />
            </p>
            <p className="description">
              {getCountry()} / <FormattedMessage id="Backend" />
            </p>
            <div onClick={globalSSO} className="sso_btn">
              <FormattedMessage id="Enter with Global SSO" />
              <ArrowForward />
            </div>
            <div className="option">O entrar por correo electrónico y contraseña</div>
            <Form onSubmit={handleSubmit} className="login-form">
              <Form.Item>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      message: intl.formatMessage({
                        id: 'The input is not valid email!',
                      }),
                    },
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'Please input your email!',
                      }),
                    },
                  ],
                })(<Input prefix={<MailOutline />} placeholder="Correo electrónico" />)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'Please input your Password!',
                      }),
                    },
                  ],
                })(<Input type="password" prefix={<LockOutlined />} placeholder="Contraseña" />)}
              </Form.Item>
              {errMsg && (
                <p className="error_msg">
                  <FormattedMessage id={`${errMsg}`} />
                </p>
              )}
              <Form.Item className="login-form-btn">
                <Button htmlType="submit">
                  <FormattedMessage id="Login" />
                  <ArrowForward />
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="footer">
            <p className="copy_right">
              <FormattedMessage id="McDonald’s © {year}" values={{ year: new Date().getFullYear() }} />
            </p>
          </div>
        </div>
      </Spin>
    </div>
  );
};

Login.defaultProps = {
  form: {},
};

export default compose(Form.create({ name: 'login_form' }))(Login);
