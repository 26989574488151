import React from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import ModalDlg from 'components/Basic/ModalDlg';
import { NavLink } from 'react-router-dom';

type AlertModalOfferType = {
  isAlertModal: boolean;
  onCancel: () => void;
  onClose: () => void;
};

const AlertModalOffer: React.FC<AlertModalOfferType> = ({ isAlertModal = false, onCancel, onClose }) => {
  return (
    <ModalDlg className="save_as_draft_modal" visible={isAlertModal} width={434} handleCancel={onCancel} isCenter>
      <div className="header">
        <h3>
          <FormattedMessage id="You can't create a new offer" />
        </h3>
        <p className="description">
          <FormattedMessage id="Please, at first fill in the configuration" />
        </p>
      </div>
      <div className="footer">
        <div className="btn_wrapper">
          <p onClick={onClose}>
            <FormattedMessage id="Cancel" />
          </p>
          <NavLink to="/dashboard/deals/configuration">
            <Button className="save_button_primary" onClick={onClose}>
              <FormattedMessage id="Go to Configuration" />
            </Button>
          </NavLink>
        </div>
      </div>
    </ModalDlg>
  );
};

export default AlertModalOffer;
