import { sliceDateTimeToDayjsUtcString } from 'utilities/transformDateTime';
import { generateExtendedData } from './generateExtendedData';

export const generateDealPayload = (state: any) => {
  const isRepeatable: boolean = state.redemptionConfiguration.isRepeatable;

  return {
    name: state.name.trim(),
    campaignId: state.campaign?.id,
    redemptionConfiguration: {
      type: state.redemptionConfiguration.type,
      expiration: state.redemptionConfiguration.expiration,
      limitType: state.redemptionConfiguration.limitType,
      limit: state.redemptionConfiguration.limitType === 'limited' ? state.redemptionConfiguration.limit : 0,
      isRepeatable: state.redemptionConfiguration.isRepeatable,
      repeatDaysCount: isRepeatable ? state.redemptionConfiguration.repeatDaysCount : 0,
      repeatStartTime: isRepeatable
        ? sliceDateTimeToDayjsUtcString(state.redemptionConfiguration.repeatStartTime || state.schedule?.dailyStartTime)
        : null,
      repeatCount: isRepeatable ? state.redemptionConfiguration.repeatCount : 0,
    },
    baseWeight: state.baseWeight,
    extendedData: {
      type: state.selectedTemplates,
      data: generateExtendedData(state),
    },
    images: state.images,
    title: state.title,
    description: state.description,
    terms: state.terms,
    tabIds: state.tabs,
    categoryId: state.category?.id,
    schedule: {
      startDate: sliceDateTimeToDayjsUtcString(state.schedule?.startDate),
      startTime: sliceDateTimeToDayjsUtcString(state.schedule?.startTime),
      endDate: sliceDateTimeToDayjsUtcString(state.schedule?.endDate),
      endTime: sliceDateTimeToDayjsUtcString(state.schedule?.endTime),
      dailyStartTime: sliceDateTimeToDayjsUtcString(state.schedule?.dailyStartTime),
      dailyEndTime: sliceDateTimeToDayjsUtcString(state.schedule?.dailyEndTime),
      isMonday: state.schedule?.isMonday,
      isTuesday: state.schedule?.isTuesday,
      isWednesday: state.schedule?.isWednesday,
      isThursday: state.schedule?.isThursday,
      isFriday: state.schedule?.isFriday,
      isSaturday: state.schedule?.isSaturday,
      isSunday: state.schedule?.isSunday,
    },
    segmentId: state.segment?.id,
    contentTags: state.lowerList.map((item: any) => ({ type: item.id })),
    locationType: state.locationType,
    isHidden: state.isHidden,
    isSecret: state.isHidden && state.isSecret,
    isShortUrl: state.isHidden && state.isShortUrl,
    isActive: state.isActive,
    ...(state.locationType !== 'everywhere' ? { restaurantIds: state.restaurantIds } : {}),
  };
};
