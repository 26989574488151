import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import OperatorAgentList from 'containers/Dashboard/OperatorAgents/OperatorAgentList';
import OperatorAgentEdit from 'containers/Dashboard/OperatorAgents/OperatorAgentEdit';

class OperatorAgents extends PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path="/dashboard/operators" component={OperatorAgentList} />
        <Route exact path={['/dashboard/operators/new', '/dashboard/operators/:id']} component={OperatorAgentEdit} />
      </Switch>
    );
  }
}

export default OperatorAgents;
