import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import moment from 'moment-timezone';
import { Img } from 'react-image';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Button, Input, Switch, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import { ArrowDropDown } from '@material-ui/icons';
import toast from 'components/Basic/Toast';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import { getPhoneCode } from 'utilities/common';
import Loading from 'components/Basic/Loading';
import { Divider } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import {
  useLazyGetSettingsListQuery,
  useCreateSettingsMutation,
  useUpdateSettingsMutation,
} from 'core/settings/SettingsService';
import { setSettingsData } from 'core/settings/SettingsSlice';

const { Option } = Select;

const Banner = ({ form }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [maxiumValues, setMaxiumValues] = useState(new Array(41).fill().map((_, index) => index));
  const monthValues = [3, 6, 9];

  const [state, setState] = useState({
    bannerInfo: {},
    fileWebElement: null,
    fileAppElement: null,
    fileWebSrc: null,
    fileAppSrc: null,
    dimentionsWeb: {
      type: '',
      width: 0,
      height: 0,
    },
    dimentionsApp: {
      type: '',
      width: 0,
      height: 0,
    },
    isLoading: false,
    s3ImageUpdatedAt: '',
  });

  const { list } = useAppSelector(state => state.settingsReducer);
  const [pointsEarningState, setPointsEarningState] = useState('');

  const [getSettingsList] = useLazyGetSettingsListQuery();
  const [createSettings] = useCreateSettingsMutation();
  const [updateSettings] = useUpdateSettingsMutation();

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {
    try {
      const res = await getSettingsList({}).unwrap();
      dispatch(setSettingsData({ list: res.rows }));
      if (res.rows.length !== 0) {
        setState(prev => ({ ...prev, bannerInfo: res.rows[0] }));
      }
      setPointsEarningState(res.rows[0].pointsExpired ? 'Off' : 'On');
      //!!for current work should send expired like false if switch true and send true if switch false
    } catch (e) {
      console.log(e);
    }
  };

  const [inputRef, setInputRef] = useState(null);
  const [inputSecondRef, setInputSecondRef] = useState(null);

  useEffect(() => {
    if (inputRef) {
      setState(prev => ({ ...prev, fileWebElement: inputRef }));
    }
    if (inputSecondRef) {
      setState(prev => ({ ...prev, fileAppElement: inputSecondRef }));
    }
  }, [inputRef, inputSecondRef]);

  const openChooseFile = type => {
    if (type === 'web') {
      state.fileWebElement.click();
    } else {
      state.fileAppElement.click();
    }
  };

  const handleFileChange = (event, type) => {
    const { bannerInfo, dimentionsWeb, dimentionsApp } = state;
    if (event.target.files.length !== 0) {
      if (event.target.files[0].size > 1024 * 1024) {
        toast.error({
          title: intl.formatMessage({
            id: 'Image size should be less than 1MB',
          }),
        });
      } else {
        if (type === 'web') {
          setState(prev => ({
            ...prev,
            bannerInfo: {
              ...bannerInfo,
              pwaLandingImage: event.target.files[0],
            },
          }));
        } else {
          setState(prev => ({
            ...prev,
            bannerInfo: {
              ...bannerInfo,
              mobileLandingImage: event.target.files[0],
            },
          }));
        }
        const file = event.target.files[0];

        const reader = new FileReader();
        reader.onload = () => {
          const img = new Image();
          img.onload = () => {
            if (type === 'web') {
              setState(prev => ({
                ...prev,
                dimentionsWeb: {
                  ...dimentionsWeb,
                  width: img.width,
                  height: img.height,
                  type: file.type,
                },
              }));
            } else {
              setState(prev => ({
                ...prev,
                dimentionsApp: {
                  ...dimentionsApp,
                  width: img.width,
                  height: img.height,
                  type: file.type,
                },
              }));
            }
          };
          img.src = reader.result;
          if (type === 'web') {
            setState(prev => ({ ...prev, fileWebSrc: reader.result }));
          } else {
            setState(prev => ({ ...prev, fileAppSrc: reader.result }));
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSaveBanner = async () => {
    const { s3ImageUpdatedAt } = state;

    const values = form.getFieldsValue([
      'ios_version',
      'android_version',
      'forceUpdate',
      'terms',
      'coupon',
      'about',
      'faq',
      'privacy',
      'facebook',
      'instagram',
      'twitter',
      'supportWhatsapp',
      'weeklyOrderLimit',
      'monthlyCardLimit',
      'monthlyCardDeletionLimit',
      'monthValues',
      'totalCardLimit',
      'opennodeParams',
      'currentLocationEnabled',
      'pointsExpired',
      'pointsEarning',
    ]);

    const { bannerInfo } = state;

    setState(prev => ({ ...prev, isLoading: true }));

    if (list.length === 0) {
      const value = {
        pwaLandingImage: bannerInfo.pwaLandingImage,
        mobileLandingImage: bannerInfo.mobileLandingImage,
        iOsVersion: values.ios_version,
        androidVersion: values.android_version,
        forceUpdate: values.forceUpdate ? 'Y' : 'N',
        terms: values.terms || '',
        coupon: values.coupon || '',
        about: values.about || '',
        faq: values.faq || '',
        privacy: values.privacy || '',
        facebook: values.facebook || '',
        instagram: values.instagram || '',
        twitter: values.twitter || '',
        supportWhatsapp: `${values.supportWhatsapp || ''}`,
        currentLocationEnabled: values.currentLocationEnabled,
        s3ImageUpdatedAt:
          s3ImageUpdatedAt ||
          (bannerInfo.s3ImageUpdatedAt
            ? moment(bannerInfo.s3ImageUpdatedAt).tz('America/Guatemala').format('MM/DD/YYYY')
            : ''),
        monthlyCardDeletionLimit: values.monthlyCardDeletionLimit,
        totalCardLimit: values.totalCardLimit,
        weeklyOrderLimit: values.weeklyOrderLimit,
        monthlyCardLimit: values.monthlyCardLimit,
        opennodeParams: values.opennodeParams || '',
        forceCustomerProfileUpdatePeriod: parseFloat(values.monthValues),
        pointsExpired: pointsEarningState === 'Off' ? true : false,
        pointsEarning: values.pointsEarning,
      };

      const formData = new FormData();
      for (const key in value) {
        formData.append(key, value[key]);
      }

      try {
        await createSettings({ formData }).unwrap();
        getSettings();
        setState(prev => ({ ...prev, isLoading: false }));
        toast.success({
          title: intl.formatMessage({ id: 'Banner is created' }),
        });
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
        toast.error({
          title: intl.formatMessage({
            id: 'All fields are required',
          }),
        });
      }
    } else {
      const value = {
        id: list[0].id,
        pwaLandingImage: bannerInfo.pwaLandingImage,
        mobileLandingImage: bannerInfo.mobileLandingImage,
        iOsVersion: values.ios_version,
        androidVersion: values.android_version,
        forceUpdate: values.forceUpdate ? 'Y' : 'N',
        terms: values.terms || '',
        coupon: values.coupon || '',
        about: values.about || '',
        faq: values.faq || '',
        privacy: values.privacy || '',
        facebook: values.facebook || '',
        instagram: values.instagram || '',
        twitter: values.twitter || '',
        currentLocationEnabled: values.currentLocationEnabled,
        supportWhatsapp: `${values.supportWhatsapp || ''}`,
        s3ImageUpdatedAt:
          s3ImageUpdatedAt ||
          (bannerInfo.s3ImageUpdatedAt
            ? moment(bannerInfo.s3ImageUpdatedAt).tz('America/Guatemala').format('MM/DD/YYYY')
            : ''),
        weeklyOrderLimit: values.weeklyOrderLimit,
        monthlyCardLimit: values.monthlyCardLimit,
        monthlyCardDeletionLimit: values.monthlyCardDeletionLimit,
        totalCardLimit: values.totalCardLimit,
        opennodeParams: values.opennodeParams || '',
        forceCustomerProfileUpdatePeriod: parseFloat(values.monthValues),
        pointsExpired: pointsEarningState === 'Off' ? true : false,
        pointsEarning: values.pointsEarning,
      };

      const formData = new FormData();
      for (const key in value) {
        formData.append(key, value[key]);
      }

      try {
        await updateSettings({ id: value.id, formData }).unwrap();
        getSettings();
        setState(prev => ({ ...prev, isLoading: false }));
        toast.success({
          title: intl.formatMessage({ id: 'Banner is updated' }),
        });
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
        toast.error({
          title: intl.formatMessage({
            id: 'Updating banner is failure',
          }),
        });
      }
    }
  };

  const handleUpdateImageDate = () => {
    setState(prev => ({
      ...prev,
      s3ImageUpdatedAt: moment(new Date()).tz('America/Guatemala').format('MM/DD/YYYY'),
    }));
  };

  const { getFieldDecorator } = form;
  const { bannerInfo, dimentionsWeb, dimentionsApp, fileWebSrc, fileAppSrc, s3ImageUpdatedAt } = state;
  const initialLang = process.env.REACT_APP_LANG || 'en';

  return (
    <div className="banner_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader type="banner" onSaveBanner={handleSaveBanner} />
      <Form>
        <div className="banner_setting_wrapper">
          <div className="application_scheme_wrapper">
            <p className="title">
              <FormattedMessage id="Application schemes" />
            </p>
            <p className="description">
              <FormattedMessage id="Dynamic configuration schemes for the mobile application and PWA (Progressive Web App) for McDonald’s customers." />
            </p>
          </div>
          <div className="application_detail_wrapper">
            <p className="title">
              <FormattedMessage id="Details of the application" />
            </p>
            <div className="banner_upload_wrapper">
              <div className="web_banner_wrapper">
                <div className="photo_info">
                  <p className="title">
                    <FormattedMessage id="Startup Photography (PWA)" />
                  </p>
                  <p className="description">
                    Archivo {dimentionsWeb.type} de {dimentionsWeb.width}px por {dimentionsWeb.height}px
                  </p>
                  <Button className="select_photo_btn" onClick={() => openChooseFile('web')}>
                    <FormattedMessage id="Select File" />
                  </Button>
                </div>
                <input
                  type="file"
                  className="file_input"
                  accept="image/*, .svg"
                  ref={setInputRef}
                  onChange={e => handleFileChange(e, 'web')}
                />
                <div className="photo_area">
                  {(bannerInfo.pwaLandingImage || fileWebSrc) && (
                    <Img
                      src={fileWebSrc || `${process.env.REACT_APP_SERVER}/${bannerInfo.pwaLandingImage}`}
                      alt="promotion"
                      onClick={() => openChooseFile('web')}
                    />
                  )}
                </div>
              </div>
              <div className="mobile_banner_wrapper">
                <div className="photo_info">
                  <p className="title">
                    <FormattedMessage id="Startup photography (Mobile app)" />
                  </p>
                  <p className="description">
                    Archivo {dimentionsApp.type} de {dimentionsApp.width}px por {dimentionsApp.height}px
                  </p>
                  <Button className="select_photo_btn" onClick={() => openChooseFile('app')}>
                    <FormattedMessage id="Select File" />
                  </Button>
                </div>
                <input
                  type="file"
                  className="file_input"
                  accept="image/*, .svg"
                  ref={setInputSecondRef}
                  onChange={e => handleFileChange(e, 'app')}
                />
                <div className="photo_area">
                  {(bannerInfo.mobileLandingImage || fileAppSrc) && (
                    <Img
                      src={fileAppSrc || `${process.env.REACT_APP_SERVER}/${bannerInfo.mobileLandingImage}`}
                      alt="promotion"
                      onClick={() => openChooseFile('app')}
                    />
                  )}
                </div>
              </div>
            </div>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="IOS published version number" />
              </p>
              {getFieldDecorator('ios_version', {
                rules: [],
                initialValue: bannerInfo.iOsVersion || '',
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Android published version number" />
              </p>
              {getFieldDecorator('android_version', {
                rules: [],
                initialValue: bannerInfo.androidVersion || '',
              })(<Input placeholder="" />)}
            </Form.Item>
            <div className="aditional_detail">
              <p>
                <FormattedMessage id="Force update to users" />
              </p>
              <Form.Item>
                {getFieldDecorator('forceUpdate', {
                  rules: [],
                  initialValue: bannerInfo.forceUpdate === 'Y',
                })(
                  <Switch
                    checked={bannerInfo.forceUpdate === 'Y'}
                    onChange={checked => {
                      setState(prev => ({
                        ...prev,
                        bannerInfo: {
                          ...bannerInfo,
                          forceUpdate: checked ? 'Y' : 'N',
                        },
                      }));
                    }}
                  />,
                )}
              </Form.Item>
            </div>
            <div className="aditional_detail">
              <p>
                <FormattedMessage id="My current location option" />
              </p>
              <Form.Item>
                {getFieldDecorator('currentLocationEnabled', {
                  rules: [],
                  initialValue: bannerInfo.currentLocationEnabled,
                })(
                  <Switch
                    checked={bannerInfo.currentLocationEnabled}
                    onChange={checked => {
                      setState(prev => ({
                        ...prev,
                        bannerInfo: {
                          ...bannerInfo,
                          currentLocationEnabled: checked,
                        },
                      }));
                    }}
                  />,
                )}
              </Form.Item>
            </div>
            <div className="update-image-date-wrapper">
              <Form.Item>
                <p className="title">
                  <FormattedMessage id="Catalog update date" />
                </p>
                <div className="update-image-date">
                  <span>
                    {s3ImageUpdatedAt ||
                      (bannerInfo.s3ImageUpdatedAt
                        ? moment(bannerInfo.s3ImageUpdatedAt).tz('America/Guatemala').format('MM/DD/YYYY')
                        : '')}
                  </span>
                  <Button onClick={handleUpdateImageDate}>
                    <FormattedMessage id="Set today's date" />
                  </Button>
                </div>
              </Form.Item>
            </div>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Maximum CC transactions per week" />
              </p>
              {getFieldDecorator('weeklyOrderLimit', {
                rules: [],
                initialValue: bannerInfo.weeklyOrderLimit,
              })(
                <Select suffixIcon={<ArrowDropDown />} dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}>
                  {maxiumValues.map(v => (
                    <Option value={v} key={v}>
                      <p>{v}</p>
                    </Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Maximum CC token creation per month" />
              </p>
              {getFieldDecorator('monthlyCardLimit', {
                rules: [],
                initialValue: bannerInfo.monthlyCardLimit,
              })(
                <Select suffixIcon={<ArrowDropDown />} dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}>
                  {maxiumValues.map(v => (
                    <Option value={v} key={v}>
                      <p>{v}</p>
                    </Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Divider orientation={'horizontal'} />
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Maximum credit card limit" />
              </p>
              {getFieldDecorator('totalCardLimit', {
                rules: [],
                initialValue: bannerInfo.totalCardLimit,
              })(
                <Select suffixIcon={<ArrowDropDown />} dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}>
                  {maxiumValues.map(v => (
                    <Option value={v} key={v}>
                      <p>{v}</p>
                    </Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Maximum credit card monthly deletion" />
              </p>
              {getFieldDecorator('monthlyCardDeletionLimit', {
                rules: [],
                initialValue: bannerInfo.monthlyCardDeletionLimit,
              })(
                <Select suffixIcon={<ArrowDropDown />} dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}>
                  {maxiumValues.map(v => (
                    <Option value={v} key={v}>
                      <p>{v}</p>
                    </Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="User profile update frequency" />
              </p>
              {getFieldDecorator('monthValues', {
                rules: [],
                initialValue:
                  `${bannerInfo.forceCustomerProfileUpdatePeriod} ${initialLang === 'en' ? 'month' : 'meses'}` || '',
              })(
                <Select suffixIcon={<ArrowDropDown />}>
                  {monthValues.map(v => (
                    <Option value={v} key={v}>
                      <p>
                        {v} <FormattedMessage id="Month" />
                      </p>
                    </Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="BTC Dynamic Parameter" />
              </p>
              {getFieldDecorator('opennodeParams', {
                rules: [],
                initialValue: bannerInfo.opennodeParams || '',
              })(<Input placeholder="" />)}
            </Form.Item>
            <div className="aditional_detail">
              <p>
                <FormattedMessage id="Points awarding" />
              </p>
              <Form.Item>
                {getFieldDecorator('pointsEarning', {
                  rules: [],
                  initialValue: bannerInfo.pointsEarning,
                })(
                  <Switch
                    checked={bannerInfo.pointsEarning}
                    onChange={checked => {
                      setState(prev => ({
                        ...prev,
                        bannerInfo: {
                          ...bannerInfo,
                          pointsEarning: checked,
                        },
                      }));
                    }}
                  />,
                )}
              </Form.Item>
            </div>
            <div className="aditional_detail" style={{ marginBottom: '20px' }}>
              <p>
                <FormattedMessage id="Points redemption" />
              </p>
              <Form.Item>
                {getFieldDecorator('pointsExpired', {
                  rules: [],
                  initialValue: pointsEarningState,
                })(
                  <Switch
                    checked={pointsEarningState === 'On'}
                    onChange={checked => {
                      setPointsEarningState(checked ? 'On' : 'Off');
                    }}
                  />,
                )}
              </Form.Item>
            </div>
          </div>
          <div className="social_links_wrapper">
            <p className="title">
              <FormattedMessage id="Links and URLs" />
            </p>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Terms and conditions URL" />
              </p>
              {getFieldDecorator('terms', {
                rules: [],
                initialValue: bannerInfo.terms || '',
              })(<Input placeholder="https://" />)}
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Get discount coupons" />
              </p>
              {getFieldDecorator('coupon', {
                rules: [],
                initialValue: bannerInfo.coupon || '',
              })(<Input placeholder="https://" />)}
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="About McDonald's" />
              </p>
              {getFieldDecorator('about', {
                rules: [],
                initialValue: bannerInfo.about || '',
              })(<Input placeholder="https://" />)}
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Frequent questions" />
              </p>
              {getFieldDecorator('faq', {
                rules: [],
                initialValue: bannerInfo.faq || '',
              })(<Input placeholder="https://" />)}
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Privacy policy" />
              </p>
              {getFieldDecorator('privacy', {
                rules: [],
                initialValue: bannerInfo.privacy || '',
              })(<Input placeholder="https://" />)}
            </Form.Item>
            <Form.Item>
              <p className="title">Facebook</p>
              {getFieldDecorator('facebook', {
                rules: [],
                initialValue: bannerInfo.facebook || '',
              })(<Input placeholder="https://" />)}
            </Form.Item>
            <Form.Item>
              <p className="title">Instagram</p>
              {getFieldDecorator('instagram', {
                rules: [],
                initialValue: bannerInfo.instagram || '',
              })(<Input placeholder="https://" />)}
            </Form.Item>
            <Form.Item>
              <p className="title">Twitter</p>
              {getFieldDecorator('twitter', {
                rules: [],
                initialValue: bannerInfo.twitter || '',
              })(<Input placeholder="https://" />)}
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Support by WhatsApp" />
              </p>
              {getFieldDecorator('supportWhatsapp', {
                rules: [],
                initialValue: `${bannerInfo.supportWhatsapp || ''}`,
              })(<Input placeholder={`+${getPhoneCode()}`} />)}
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

Banner.defaultProps = {
  form: {},
  list: [],
};

export default compose(injectIntl, Form.create({ name: 'banner_form' }))(Banner);
