import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import ModalDlg from 'components/Basic/ModalDlg';
import { ArrowForward } from '@material-ui/icons';

function CatalogueSyncModal({ title, description, buttonText, isOpenModal, onCloseModal }) {
  return (
    <ModalDlg className="catalogue_sync_modal" visible={isOpenModal} width={434} handleCancel={onCloseModal} isCenter>
      <div className="modal_header">
        <div>
          <h3>{title}</h3>
          <p className="description">{description}</p>
        </div>
      </div>
      <div className="modal_footer">
        <Button onClick={onCloseModal}>
          <span>{buttonText}</span>
          <ArrowForward />
        </Button>
      </div>
    </ModalDlg>
  );
}

CatalogueSyncModal.defaultProps = {
  isOpenModal: false,
  title: '',
  description: '',
  buttonText: '',
};

export default CatalogueSyncModal;
