import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import ModalDlg from 'components/Basic/ModalDlg';

type Props = {
  isOpenModal: boolean;
  onCloseModal: () => void;
  onConfirm: () => void;
};

const ConfirmCancelOrder: React.FC<Props> = ({ isOpenModal, onCloseModal, onConfirm }) => {
  return (
    <ModalDlg className="save_as_draft_modal" visible={isOpenModal} width={434} handleCancel={onCloseModal} isCenter>
      <div className="header">
        <h3>
          <FormattedMessage id="Do you want to cancel the order?" />
        </h3>
        <p className="description">
          <FormattedMessage id="This action cannot be undone.  Continue canceling the order?" />
        </p>
      </div>
      <div className="footer">
        <div className="btn_wrapper">
          <p onClick={onCloseModal}>
            <FormattedMessage id="Cancel" />
          </p>
          <Button className="save_button" onClick={onConfirm}>
            <FormattedMessage id="Confirm" />
          </Button>
        </div>
      </div>
    </ModalDlg>
  );
};

ConfirmCancelOrder.defaultProps = {
  isOpenModal: false,
};

export default ConfirmCancelOrder;
