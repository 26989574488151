import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { compose } from 'react-recompose';
import moment from 'moment-timezone';
import { Img } from 'react-image';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import ModalDlg from 'components/Basic/ModalDlg';
import { ArrowForward, Edit, Delete } from '@material-ui/icons';
import { getDefaultSize } from 'utilities/common';
import placeHolderImg from 'assets/img/placeholder.svg';

function PromotionViewModal({ intl, products, sizes, promotionInfo, isOpenModal, onCloseModal, onEdit, onDelete }) {
  const [selectedProduct, setSelectedProduct] = useState({});

  useEffect(() => {
    if (promotionInfo && products.length !== 0 && promotionInfo.value) {
      const product = products.filter(p => p.id == promotionInfo.value)[0];
      setSelectedProduct(product);
    }
  }, [promotionInfo, products]);

  if (!promotionInfo) {
    return null;
  }

  const getProductCode = data => {
    let defaultCode = 0;
    if (data && sizes) {
      defaultCode = data.productCode;
      if (data.type !== '1') {
        const productVariants = sizes.filter(
          ({ classCode, item, state }) => state === 'A' && classCode === data.classCode && item === data.item,
        );
        if (productVariants.length > 0) {
          defaultCode = getDefaultSize(productVariants).productCode;
        }
      }
    }
    return { productCode: defaultCode };
  };

  const getProduct = v => {
    const product = products.filter(p => p.id == v)[0];
    return product || {};
  };

  const getProductInfo = data => {
    let defaultCode = 0;
    if (data && sizes) {
      defaultCode = data.productCode;
      if (data.type !== '1') {
        const productVariants = sizes.filter(
          ({ classCode, item, state }) => state === 'A' && classCode === data.classCode && item === data.item,
        );
        if (productVariants.length > 0) {
          defaultCode = getDefaultSize(productVariants).productCode;
        }
      }
    }
    return { productCode: defaultCode };
  };

  return (
    <ModalDlg className="promotion_view_modal" visible={isOpenModal} width={574} handleCancel={onCloseModal} isCenter>
      <div className="modal_header">
        <div>
          <h3>{promotionInfo.name || ''}</h3>
          <p className="last_import_time">
            {`Inicio: ${moment(promotionInfo.startAt).tz('America/Guatemala').format('LL')} - Final: 
              ${moment(promotionInfo.endAt).tz('America/Guatemala').format('LL')}`}
          </p>
        </div>
      </div>
      <div className="modal_content">
        <div className="promotion_photo">
          {promotionInfo.picture && <Img src={`${process.env.REACT_APP_SERVER}/${promotionInfo.picture}-sm`} />}
        </div>
        {promotionInfo.type === 'P' && (
          <div className="detail">
            <p className="type">{`Tipo: ${promotionInfo.type}`}</p>
            <div className="promotion_info">
              {selectedProduct && selectedProduct.productCode !== undefined && (
                <Img
                  src={
                    selectedProduct.productCode !== undefined
                      ? `${process.env.REACT_APP_S3}/${selectedProduct.channel === 'APP' ? 'customer' : 'ots'}/${
                          selectedProduct.productCode > 0
                            ? selectedProduct.productCode
                            : getProductInfo(selectedProduct).productCode
                        }.jpg`
                      : placeHolderImg
                  }
                />
              )}
              <p
                className="description"
                dangerouslySetInnerHTML={{
                  __html: `${getProductCode(getProduct(promotionInfo.value)).productCode} - ${
                    getProduct(promotionInfo.value).name
                  }`,
                }}
              />
            </div>
          </div>
        )}
        {promotionInfo.type === 'O' && (
          <div className="detail">
            <p className="type">{`Tipo: ${promotionInfo.type}`}</p>
            <div className="promotion_info">
              <p
                className="description"
                dangerouslySetInnerHTML={{
                  __html: `${promotionInfo.value}`,
                }}
              />
            </div>
          </div>
        )}
        {promotionInfo.type === 'U' && (
          <div className="detail">
            <p className="type">
              {`Tipo: ${
                promotionInfo.type === 'U'
                  ? intl.formatMessage({ id: 'Web View URL' })
                  : intl.formatMessage({ id: 'Product page' })
              }`}
            </p>
            <div className="promotion_info">
              <p className="description">{promotionInfo.value}</p>
            </div>
            <a href={promotionInfo.value}>View link</a>
          </div>
        )}
      </div>
      <div className="modal_footer">
        <div className="aditional_detail">
          <p>
            <FormattedMessage id="Unique views" />: {promotionInfo.visits || 0}
          </p>
        </div>
        <div className="btn_wrapper">
          <Button className="delete_btn" onClick={onDelete}>
            <Delete />
          </Button>
          <Button className="edit_btn" onClick={onEdit}>
            <Edit />
          </Button>
          <Button className="close_btn" onClick={onCloseModal}>
            <FormattedMessage id="Close" />
            <ArrowForward />
          </Button>
        </div>
      </div>
    </ModalDlg>
  );
}

PromotionViewModal.defaultProps = {
  isOpenModal: false,
  promotionInfo: null,
  products: [],
  sizes: [],
};

export default compose(injectIntl)(PromotionViewModal);
