import React, { PureComponent } from 'react';
import { NavLink, Route, Switch, Redirect } from 'react-router-dom';
import AnalyticalPoints from './AnalyticalPoints';
import AnalyticalPaymentGateway from './AnalyticalPaymentGateway';
import AnalyticalUsers from './AnalyticalUsers';
import AnalyticalOrders from './AnalyticalOrders';
import { FormattedMessage } from 'react-intl';
import AnalyticalDownload from './AnalyticalDownload';
import AnalyticalCalls from './AnalyticalCalls';
import AnalyticalPromo from './AnalyticalPromo';
class Analytical extends PureComponent {
  render() {
    if (window.location.pathname === '/dashboard/analytical') {
      return <Redirect to={'/dashboard/analytical/payment'}></Redirect>;
    }
    return (
      <div className="analytical_layout">
        <div className={'analytical_sidebar'}>
          <div className={'analytical_sidebar_title'}>
            <p>
              <FormattedMessage id={'Analytics'}></FormattedMessage>
            </p>
            <span>
              <FormattedMessage
                id={'Report and Analytical Scheme of Processes Related to the McDonald’s Express operation'}
              />
            </span>
          </div>
          <hr />
          <nav>
            <NavLink to={'/dashboard/analytical/payment'} activeClassName={'active'}>
              <FormattedMessage id={'Payment gateway'} />
            </NavLink>
          </nav>
          <nav>
            <NavLink to={'/dashboard/analytical/points'} activeClassName={'active'}>
              <FormattedMessage id={'McDonald’s points'} />
            </NavLink>
          </nav>
          <nav>
            <NavLink to={'/dashboard/analytical/users'} activeClassName={'active'}>
              <FormattedMessage id={'Users analytic'} />
            </NavLink>
          </nav>
          <nav>
            <NavLink to={'/dashboard/analytical/orders'} activeClassName={'active'}>
              <FormattedMessage id={'Orders'} />
            </NavLink>
          </nav>
          <nav>
            <NavLink to={'/dashboard/analytical/calls'} activeClassName={'active'}>
              <FormattedMessage id={'Customer calls'} />
            </NavLink>
          </nav>
          <nav>
            <NavLink to={'/dashboard/analytical/promo'} activeClassName={'active'}>
              <FormattedMessage id={'Promotional product'} />
            </NavLink>
          </nav>
          <nav>
            <NavLink to={'/dashboard/analytical/download'} activeClassName={'active'}>
              <FormattedMessage id={'Download'} />
            </NavLink>
          </nav>
        </div>
        <div className={'analytical_layout_content'}>
          <div className={'analytical_layout_content_box'}>
            <Switch>
              <Route exact path={'/dashboard/analytical/payment'} component={AnalyticalPaymentGateway} />
              <Route exact path={'/dashboard/analytical/points'} component={AnalyticalPoints} />
              <Route exact path={'/dashboard/analytical/users'} component={AnalyticalUsers} />
              <Route exact path={'/dashboard/analytical/orders'} component={AnalyticalOrders} />
              <Route exact path={'/dashboard/analytical/calls'} component={AnalyticalCalls} />
              <Route exact path={'/dashboard/analytical/download'} component={AnalyticalDownload} />
              <Route exact path={'/dashboard/analytical/promo'} component={AnalyticalPromo} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default Analytical;
