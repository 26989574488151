import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, CouponData } from './types';

const initialState: initState = {
  list: {
    rows: [
      {
        id: 0,
        code: '',
        name: '',
        type: '',
        condition: '',
        rewards: [
          {
            product: 0,
            quantity: 0,
          },
        ],
        totalCount: 0,
        userLimit: 0,
        usedCount: 0,
        startAt: '',
        endAt: '',
        description: '',
        state: '',
        createdAt: '',
        updatedAt: '',
      },
    ],
    totalCount: 0,
  },
};

const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    setCouponData(state: initState, action: PayloadAction<{ list: CouponData }>) {
      state.list = action.payload.list;
    },
  },
});

export const { setCouponData } = couponSlice.actions;

export default couponSlice.reducer;
