import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, VersionData } from './types';

const initialState: initState = {
  version: '',
};

const versionSlice = createSlice({
  name: 'version',
  initialState,
  reducers: {
    setVersionData(state: initState, action: PayloadAction<{ version: VersionData }>) {
      state.version = action.payload.version;
    },
  },
});

export const { setVersionData } = versionSlice.actions;

export default versionSlice.reducer;
