import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import OrderAnalysisModal from 'components/Dashboard/Distribution/RestaurantAnalysis/OrderAnalysisModal';
import AmbassadorListModal from 'components/Dashboard/Distribution/RestaurantAnalysis/AmbassadorListModal';

function OrderAnalysis({ data, intl }) {
  const [isAnalysisModal, setIsAnalysisModal] = useState(false);
  const [isAmbassadorModal, setIsAmbassadorModal] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [orderState, setOrderState] = useState('');

  useEffect(() => {
    setChartData([
      {
        name: 'En Ruta',
        value: data.onRoute ? data.onRoute.count : 0,
        color: '#F946B7',
      },
      {
        name: 'En producción',
        value: data.inProduction ? data.inProduction.count : 0,
        color: '#50E2C2',
      },
      {
        name: 'Resolviendo',
        value: data.solving ? data.solving.count : 0,
        color: '#D35858',
      },
      {
        name: 'Pendientes',
        value: data.slopes ? data.slopes.count : 0,
        color: '#F3B900',
      },
      {
        name: 'Entregadas',
        value: data.delivered ? data.delivered.count : 0,
        color: '#8DD73C',
      },
    ]);
  }, [data]);

  const renderCustomizedLabel = props => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const mx = cx + outerRadius * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <text style={{ fontSize: 12 }} x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={fill}>
        {`${payload.name}: ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className="order_analysis_wrapper">
      <div className="statistic_wrapper">
        <p className="title">
          <FormattedMessage id="Showing data from the last hour in progress" />
        </p>
        <div
          className="card_item received_card"
          onClick={() => {
            setOrderState('RV');
            setIsAnalysisModal(true);
          }}
        >
          <p className="count_number">{data.received || 0}</p>
          <div className="card_description_wrapper">
            <p className="card_title">
              <FormattedMessage id="Orders received" />
            </p>
            <p className="card_description">
              <FormattedMessage id="Not yet sent to produce" />
            </p>
          </div>
        </div>
        <div className="card_row">
          <div
            className="card_item route_card"
            onClick={() => {
              setOrderState('OW');
              setIsAnalysisModal(true);
            }}
          >
            <p className="count_number">{data.onRoute ? data.onRoute.count : 0}</p>
            <div className="card_description_wrapper">
              <p className="card_title">
                <FormattedMessage id="On Route" />
              </p>
              <p className="card_description">
                {`${data.onRoute ? parseInt(data.onRoute.averageTime) : 0} minutos de tiempo promedio`}
              </p>
            </div>
          </div>
          <div
            className="card_item production_card"
            onClick={() => {
              setOrderState('IP');
              setIsAnalysisModal(true);
            }}
          >
            <p className="count_number">{data.inProduction ? data.inProduction.count : 0}</p>
            <div className="card_description_wrapper">
              <p className="card_title">
                <FormattedMessage id="In production" />
              </p>
              <p className="card_description">
                {`${data.inProduction ? parseInt(data.inProduction.averageTime) : 0} minutos de tiempo promedio`}
              </p>
            </div>
          </div>
        </div>
        <div className="card_row">
          <div
            className="card_item solving_card"
            onClick={() => {
              setOrderState('RS');
              setIsAnalysisModal(true);
            }}
          >
            <p className="count_number">{data.solving ? data.solving.count : 0}</p>
            <div className="card_description_wrapper">
              <p className="card_title">
                <FormattedMessage id="Solving" />
              </p>
              <p className="card_description">
                {`${data.solving ? parseInt(data.solving.averageTime) : 0} minutos de tiempo promedio`}
              </p>
            </div>
          </div>
          <div
            className="card_item sloping_card"
            onClick={() => {
              setOrderState('CL');
              setIsAnalysisModal(true);
            }}
          >
            <p className="count_number">{data.slopes ? data.slopes.count : 0}</p>
            <div className="card_description_wrapper">
              <p className="card_title">
                <FormattedMessage id="Slopes" />
              </p>
              <p className="card_description">
                {`${data.slopes ? parseInt(data.slopes.averageTime) : 0} minutos de tiempo promedio`}
              </p>
            </div>
          </div>
        </div>
        <div className="card_row">
          <div
            className="card_item delivered_card"
            onClick={() => {
              setOrderState('DD');
              setIsAnalysisModal(true);
            }}
          >
            <p className="count_number">{data.delivered ? data.delivered.count : 0}</p>
            <div className="card_description_wrapper">
              <p className="card_title">
                <FormattedMessage id="Delivered" />
              </p>
              <p className="card_description">
                {`${data.delivered ? parseInt(data.delivered.averageTime) : 0} minutos de tiempo promedio`}
              </p>
            </div>
          </div>
          <div
            className="card_item ambassadors_card"
            onClick={() => {
              setIsAmbassadorModal(true);
            }}
          >
            <p className="count_number">{(data.onlineAmbassadors || []).length}</p>
            <div className="card_description_wrapper">
              <p className="card_title">
                <FormattedMessage id="Ambassadors" />
              </p>
              <p className="card_description">
                {intl.formatMessage(
                  {
                    id: `{Count} assigned to the restaurant`,
                  },
                  {
                    Count: (data.ambassadors || []).length,
                  },
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="analysis_chart_wrapper">
        <div style={{ width: '100%', height: '100%' }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={chartData.filter(c => c.value !== 0)}
                cx="50%"
                cy="50%"
                outerRadius="40%"
                dataKey="value"
                label={renderCustomizedLabel}
              >
                {chartData
                  .filter(c => c.value !== 0)
                  .map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
      <OrderAnalysisModal
        visible={isAnalysisModal}
        orderState={orderState}
        onCloseModal={() => setIsAnalysisModal(false)}
      />
      <AmbassadorListModal
        visible={isAmbassadorModal}
        ambassadors={data.ambassadors || []}
        onCloseModal={() => setIsAmbassadorModal(false)}
      />
    </div>
  );
}

OrderAnalysis.defaultProps = {
  data: {},
};

export default compose(injectIntl)(OrderAnalysis);
