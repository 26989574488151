import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, MyAddressData } from './types';

const initialState: initState = {
  list: [
    {
      id: 0,
      customer: 0,
      name: '',
      address: '',
      type: '',
      long: 0,
      lat: 0,
      description: '',
      belongsToDeliveryArea: false,
      department: 0,
      municipality: 0,
      zone: 0,
      restaurant: 0,
      postalCode: '',
      addressCity: '',
      addressState: '',
      addressCountry: '',
      isSarChangeRestaurant: false,
      createdAt: '',
      updatedAt: '',
    },
  ],
};

const addressSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setMyAddressData(state: initState, action: PayloadAction<{ list: MyAddressData }>) {
      state.list = action.payload.list;
    },
  },
});

export const { setMyAddressData } = addressSlice.actions;

export default addressSlice.reducer;
