import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, AmbassadorData, SelectedAmbassadorData, SyncedData, SyncedScheduleData } from './types';

const initialState: initState = {
  list: {
    limit: 10,
    offset: 0,
    rows: [
      {
        id: 0,
        type: '',
        firstName: '',
        eid: '',
        enableNotification: false,
        restaurants: [],
        workState: '',
        detailedState: '',
        state: '',
        createdAt: '',
        updatedAt: '',
      },
    ],
    totalCount: 0,
  },
  selectedAmbassador: {
    id: 0,
    type: '',
    firstName: '',
    eid: '',
    enableNotification: false,
    workState: '',
    detailedState: '',
    state: '',
    createdAt: '',
    updatedAt: '',
  },
  synced: {
    employee: {
      failure: 0,
      success: 0,
    },
    floatingDriver: {
      failure: 0,
      success: 0,
    },
  },
  syncedSchedule: {
    failure: 0,
    success: 0,
  },
};

const ambassadorSlice = createSlice({
  name: 'ambassador',
  initialState,
  reducers: {
    setAmbassadorData(state: initState, action: PayloadAction<{ list: AmbassadorData }>) {
      state.list = action.payload.list;
    },
    setSelectedAmbassadorData(state: initState, action: PayloadAction<{ selectedAmbassador: SelectedAmbassadorData }>) {
      state.selectedAmbassador = action.payload.selectedAmbassador;
    },
    setSyncedAmbassadorData(state: initState, action: PayloadAction<{ synced: SyncedData }>) {
      state.synced = action.payload.synced;
    },
    setSyncedScheduleAmbassadorData(state: initState, action: PayloadAction<{ syncedSchedule: SyncedScheduleData }>) {
      state.syncedSchedule = action.payload.syncedSchedule;
    },
  },
});

export const {
  setAmbassadorData,
  setSelectedAmbassadorData,
  setSyncedAmbassadorData,
  setSyncedScheduleAmbassadorData,
} = ambassadorSlice.actions;

export default ambassadorSlice.reducer;
