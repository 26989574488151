import React, { useState, useEffect } from 'react';
import { Tooltip, Button, Pagination } from 'antd';
import { Delete, Edit, ArrowDownwardOutlined, ArrowUpwardOutlined } from '@material-ui/icons';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import CategoriesViewModal from 'components/Dashboard/Categories/CategoriesViewModal';
import DeleteConfirmModal from 'components/Basic/DeleteConfirmModal';
import toast from 'components/Basic/Toast';
import { useAppDispatch } from 'utilities/redux';
import { useLazyGetCategoriesListQuery, useDeleteCategoriesMutation } from 'core/categories/CategoriesService';
import { setCategoriesListData } from 'core/categories/CategoriesSlice';

type CategoryItem = {
  id: number;
  name: string;
  updatedAt: string;
  createdAt: string;
};

type CategoriesTableType = {
  categoriesData: Array<CategoryItem>;
  onChangePage: (current: number, size: number) => void;
  total: number;
  setCurrent: (current: number) => void;
  current: number;
  setPageSize: (size: number) => void;
  pageSize: number;
  offerModal: boolean;
  /* setOption, */
};

const CategoriesTable: React.FC<CategoriesTableType> = ({
  categoriesData,
  onChangePage,
  total,
  setCurrent,
  current,
  setPageSize,
  pageSize,
  offerModal = false,
  /* setOption, */
}) => {
  const [isShowDetailModal, setShowDetailModal] = useState(false);
  const [categoriesInfo, setCategoriesInfo] = useState<CategoryItem | null>(null);
  const [isRemoveModal, setIsRemoveModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [getCategoriesList] = useLazyGetCategoriesListQuery();
  const [deleteCategories] = useDeleteCategoriesMutation();

  useEffect(() => {
    onChangePage(1, 10);
  }, []);

  const handleChangePage = (page: number, size: number) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage(page, size);
  };

  const handleShowSizeChange = (c: number, s: number) => {
    handleChangePage(1, s);
  };

  const handleDeleteButtonClick = async () => {
    setIsLoading(true);
    setIsRemoveModal(false);
    try {
      await deleteCategories({ id: categoriesInfo!.id }).unwrap();
      const queryString = buildQueryString({});
      const categoriesList = await getCategoriesList({ queryString }).unwrap();
      dispatch(setCategoriesListData({ list: categoriesList }));

      toast.success({
        title: intl.formatMessage({
          id: 'Category is deleted successfully!',
        }),
      });
    } catch (error) {
      toast.error({
        title: intl.formatMessage({
          id: 'Category is deleted failure!',
        }),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="categories_table_layout" style={offerModal ? { width: '100%' } : {}}>
      <div className="table_header">
        <div className="id">
          <FormattedMessage id="ID" />
        </div>
        <div className="name">
          <FormattedMessage id="Category" />
        </div>
        <div className="actions">
          <FormattedMessage id="Actions" />
        </div>
      </div>
      <div>
        <div className="table_content">
          {Array.isArray(categoriesData) &&
            categoriesData.map((item, index) => (
              <div className={`table_item type_state_true`} key={index}>
                <div className="id">
                  <p>{item.id}</p>
                </div>
                <div className="name">
                  <p>{item.name}</p>
                </div>
                <div className="actions">
                  <Tooltip placement="top" title={intl.formatMessage({ id: 'Edit category' })}>
                    <Button
                      className="edit_btn"
                      onClick={() => {
                        setCategoriesInfo(item);
                        setShowDetailModal(true);
                      }}
                    >
                      <Edit />
                    </Button>
                  </Tooltip>
                  <Tooltip placement="top" title={intl.formatMessage({ id: 'Delete category' })}>
                    <Button
                      className="edit_btn"
                      onClick={() => {
                        setCategoriesInfo(item);
                        setIsRemoveModal(true);
                      }}
                    >
                      <Delete />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            ))}
        </div>
        <div className="pagination_wrapper">
          <Pagination
            showSizeChanger
            defaultCurrent={1}
            defaultPageSize={10}
            current={current}
            pageSize={pageSize}
            pageSizeOptions={['5', '10', '20', '30']}
            total={total}
            onChange={handleChangePage}
            onShowSizeChange={handleShowSizeChange}
          />
          <div className="total">Resultados: {total}</div>
        </div>
      </div>
      <CategoriesViewModal
        isOpenModal={isShowDetailModal}
        onCloseModal={() => setShowDetailModal(false)}
        categoriesInfo={categoriesInfo}
        setPageSize={setPageSize}
        /* setOption={setOption} */
      />
      <DeleteConfirmModal
        isOpenModal={isRemoveModal}
        title={intl.formatMessage({ id: 'Do you want to remove the category?' })}
        subtitle={intl.formatMessage({
          id: 'This action cannot be undone. Continue deleting the category?',
        })}
        onCancel={() => setIsRemoveModal(false)}
        onOk={() => handleDeleteButtonClick()}
      />
    </div>
  );
};

export default CategoriesTable;

const buildQueryString = ({
  search = '',
  page = 1,
  size = 10,
  count = true,
  sort_by_name = '',
  sort_by_id = 'desc',
}) => {
  const params = [`search=${encodeURIComponent(search)}`, `page=${page}`, `size=${size}`, `count=${count}`];

  if (sort_by_id) {
    params.push(`sort[id]=${sort_by_id}`);
  }

  if (sort_by_name) {
    params.push(`sort[name]=${sort_by_name}`);
  }

  return params.join('&');
};
