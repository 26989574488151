import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, DatePicker, Input, Select } from 'antd';
import { Search, ArrowDropDown, Refresh, DateRange } from '@material-ui/icons';

type OffersDraftFilterType = {
  sorts: any;
  selectedTab: any;
  selectedCategory: any;
  selectedCampaign: any;
  searchText: string;
  tabs: Object[];
  categories: Object[];
  campaigns: Object[];
  handleSearch: (event: any) => void;
  onSort: (value: any) => void;
  onApplyFilter: () => void;
  setSelectedTab: (value: any) => void;
  setSelectedCategory: (value: any) => void;
  setSelectedCampaign: (value: any) => void;
  setStartDate: (value: any) => void;
  setEndDate: (value: any) => void;
};

const OffersDraftFilter: FC<OffersDraftFilterType> = ({
  sorts,
  selectedTab,
  selectedCategory,
  selectedCampaign,
  searchText,
  tabs,
  categories,
  campaigns,
  handleSearch,
  onSort,
  onApplyFilter,
  setSelectedTab,
  setSelectedCategory,
  setSelectedCampaign,
  setStartDate,
  setEndDate,
}: OffersDraftFilterType) => {
  const intl = useIntl();

  return (
    <div id="offers_draft_filter" className="offers_draft_filter">
      <div className="availabilities_containers">
        <Select
          className="select"
          suffixIcon={<ArrowDropDown />}
          placeholder={intl.formatMessage({ id: 'Tab' })}
          value={selectedTab}
          onChange={setSelectedTab}
          dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
        >
          <Select.Option value="" key="allTabs" style={{ fontFamily: 'Lato', color: '#242c40' }}>
            {intl.formatMessage({ id: 'All tabs' })}
          </Select.Option>
          {tabs &&
            tabs.map((item: any) => (
              <Select.Option value={item.name} key={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
        <Select
          className="select"
          suffixIcon={<ArrowDropDown />}
          placeholder={intl.formatMessage({ id: 'Category' })}
          value={selectedCategory}
          onChange={setSelectedCategory}
          dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
        >
          <Select.Option value="" key="allCategories">
            {intl.formatMessage({ id: 'All categories' })}
          </Select.Option>
          {categories &&
            categories.map((item: any) => (
              <Select.Option value={item.name} key={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
        <Select
          className="select"
          suffixIcon={<ArrowDropDown />}
          placeholder={intl.formatMessage({ id: 'Campaign' })}
          value={selectedCampaign}
          onChange={setSelectedCampaign}
          dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
        >
          <Select.Option value="" key="allCategories">
            {intl.formatMessage({ id: 'All campaigns' })}
          </Select.Option>
          {campaigns &&
            campaigns.map((item: any) => (
              <Select.Option value={item.name} key={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
        <DatePicker
          className="datepicker"
          placeholder="Fecha de inicio"
          onChange={(_, dateString: string) => setStartDate(dateString)}
          suffixIcon={<DateRange />}
        />
        <DatePicker
          className="datepicker"
          placeholder="Fecha límite"
          onChange={(_, dateString: string) => setEndDate(dateString)}
          suffixIcon={<DateRange />}
        />
        <Select
          className="select"
          suffixIcon={<ArrowDropDown />}
          placeholder={intl.formatMessage({ id: 'Sort by' })}
          onChange={onSort}
          dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
        >
          {sorts &&
            sorts.map((item: any) => (
              <Select.Option value={item.value} key={item.label} style={{ fontFamily: 'Lato', color: '#242c40' }}>
                {item.label}
              </Select.Option>
            ))}
        </Select>
        <Button id="apply_button" className="apply_button" onClick={onApplyFilter}>
          <FormattedMessage id="Apply filters" />
          <Refresh />
        </Button>
      </div>
      <div className="search_container">
        <Input
          suffix={<Search />}
          placeholder={intl.formatMessage({ id: 'Search' })}
          value={searchText}
          onChange={(e: any) => handleSearch(e)}
          onKeyDown={(e: any) => handleSearch(e)}
        />
      </div>
    </div>
  );
};

export default OffersDraftFilter;
