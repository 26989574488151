import React, { useRef, useState } from 'react';
import { ArrowDropDown, DateRange } from '@material-ui/icons';
import { Button, DatePicker, Input, Select } from 'antd';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { compose } from 'react-recompose';
import PropTypes from 'prop-types';
import toast from '../../../components/Basic/Toast';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyExportPointReportQuery } from 'core/report/ReportService';
import { setPointReportData } from 'core/report/ReportSlice';

const { Option } = Select;

const AnalyticalPoints = ({ intl }) => {
  const [type, setType] = useState(0);

  const typeState = [
    { value: 0, label: intl.formatMessage({ id: 'Only user' }) },
    { value: 1, label: intl.formatMessage({ id: 'All users' }) },
  ];

  const { pointReport } = useAppSelector(state => state.reportReducer);

  const dispatch = useAppDispatch();
  const [exportPointReporter] = useLazyExportPointReportQuery();

  const [isCreate, setIsCreate] = useState(false);
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();

  const userID = useRef('');

  const handleChangeStartDate = (date, dateString) => {
    setDateStart(dateString);
  };

  const handleChangeEndDate = (date, dateString) => {
    setDateEnd(dateString);
  };

  const handleExport = async () => {
    if (dateStart && dateEnd) {
      try {
        const res = await exportPointReporter({
          fromDate: dateStart,
          toDate: dateEnd,
          userID: type ? '' : userID.current.input.value,
        }).unwrap();

        dispatch(setPointReportData({ pointReport: res }));
        setIsCreate(true);
      } catch (e) {
        console.log(e);
      }
    } else {
      toast.error({ title: intl.formatMessage({ id: 'Fields date is empty' }) });
    }
  };

  const handleClipboardFilename = () => {
    navigator.clipboard.writeText(pointReport.filename);
    toast.success({ title: intl.formatMessage({ id: 'File name has been copied' }) });
  };

  return (
    <div className={'analytical_layout_content_box_inside'}>
      <div className={'analytical_layout_content_box_inside_top'}>
        <span>
          <FormattedMessage id={'User Point Report'} />
        </span>
        <p>
          <FormattedMessage id={'Export a CSV file the history of a specific user or a lot based on selected dates'} />
        </p>
      </div>
      <div className={'analytical_layout_content_box_inside_bottom'}>
        <div>
          <FormattedMessage id={'Export type'} />
          <Select
            className="sort_by_custom"
            suffixIcon={<ArrowDropDown />}
            defaultValue={type}
            onChange={e => setType(e)}
            dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
          >
            {typeState.map(item => (
              <Option value={item.value} key={item.label}>
                {item.label}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <FormattedMessage id="Start date" />
          <DatePicker
            className="start_date_custom"
            placeholder={intl.formatMessage({ id: 'Start date' })}
            onChange={handleChangeStartDate}
            suffixIcon={<DateRange />}
          />
        </div>
        <div>
          <FormattedMessage id="Finish date" />
          <DatePicker
            className="start_date_custom"
            placeholder={intl.formatMessage({ id: 'Finish date' })}
            onChange={handleChangeEndDate}
            suffixIcon={<DateRange />}
          />
        </div>
        {!type && (
          <div>
            <FormattedMessage id={'User ID'} />
            <Input className={'input_id'} placeholder="123456" ref={userID} />
          </div>
        )}
        <Button className="export_scv_button" onClick={handleExport}>
          <FormattedMessage id={'Export to CSV'} />
        </Button>
        {isCreate ? (
          <div className={'analytical_alert_success_create'}>
            <span>
              <FormattedMessage id="Please wait when file will be format and go to Download page. Save file name :" />
              <button onClick={handleClipboardFilename}>{pointReport.filename}</button>
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

AnalyticalPoints.propsTypes = {
  intl: typeof useIntl,
  exportPointReporter: PropTypes.func.isRequired,
};

export default compose(injectIntl)(AnalyticalPoints);
