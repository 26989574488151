import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'antd';
import ModalDlg from 'components/Basic/ModalDlg';
import { Edit, ArrowForward } from '@material-ui/icons';

function UserViewModal({ userInfo, isOpenModal, onCloseModal }) {
  if (!userInfo) return null;
  const intl = useIntl();
  return (
    <ModalDlg className="user_detail_modal" visible={isOpenModal} width={574} handleCancel={onCloseModal} isCenter>
      <div className="modal_header">
        <div>
          <h3>{`${userInfo.firstName} ${userInfo.lastName}`}</h3>
          <p className="description">{userInfo.lastOrderDate || '-'}</p>
        </div>
        <div className="status">
          <p className="userid">{userInfo.id}</p>
          {userInfo.isDeleted && <span className="deleted">{intl.formatMessage({ id: 'Deleted' })}</span>}
        </div>
      </div>
      <div className="modal_content">
        <div className="user_analytics">
          <div>
            <p className="quantity">{userInfo.totalOrders || 0}</p>
            <p className="status_name">
              <FormattedMessage id="Orders placed" />
            </p>
          </div>
          <div>
            <p className="quantity">{Math.floor(userInfo.point || 0)}</p>
            <p className="status_name">
              <FormattedMessage id="McDonald’s Points" />
            </p>
          </div>
          <div>
            <p className="quantity">{userInfo.addresses || 0}</p>
            <p className="status_name">
              <FormattedMessage id="Delivery addresses" />
            </p>
          </div>
        </div>
      </div>
      <div className="modal_footer">
        <div className="aditional_detail">
          <p className="email">{userInfo.email}</p>
          <p className="phone">{userInfo.phone}</p>
        </div>
        <div className="btn_wrapper">
          <NavLink to={`/dashboard/users/${userInfo.id}`}>
            <Button className="edit_btn">
              <Edit />
            </Button>
          </NavLink>
          <Button className="close_btn" onClick={onCloseModal}>
            <FormattedMessage id="Close" />
            <ArrowForward />
          </Button>
        </div>
      </div>
    </ModalDlg>
  );
}

UserViewModal.defaultProps = {
  isOpenModal: false,
  userInfo: null,
};

export default UserViewModal;
