import { api } from '..';
import { CouponData } from './types';

export const couponApi = api.enhanceEndpoints({ addTagTypes: ['Coupon'] }).injectEndpoints({
  endpoints: build => ({
    getCouponList: build.query<
      CouponData,
      { offset: number; limit: number; or: boolean; filter: string; sort: string }
    >({
      query: ({ offset, limit, or, filter, sort }) => ({
        url: `v0/advertising/coupons?offset=${offset || 0}&limit=${limit || 5}${or ? `&or=${or}` : ''}${filter || ''}${
          sort ? `&sort=${sort}` : '&sort=createdAt desc'
        }`,
        method: 'GET',
      }),
    }),
    getCoupon: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `v0/advertising/coupons/${id}`,
        method: 'GET',
      }),
    }),
    createCoupon: build.mutation<any, any>({
      query: body => ({
        url: `v0/advertising/coupons`,
        method: 'POST',
        body: body.values,
      }),
    }),
    updateCoupon: build.mutation<any, any>({
      query: body => ({
        url: `v0/advertising/coupons/${body.id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteCoupon: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `v0/advertising/coupons/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetCouponListQuery,
  useLazyGetCouponQuery,
  useCreateCouponMutation,
  useUpdateCouponMutation,
  useDeleteCouponMutation,
} = couponApi;
