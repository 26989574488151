import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import moment from 'moment-timezone';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Tooltip, Button, Pagination } from 'antd';
import { Apps, Edit } from '@material-ui/icons';
import { getCurrency } from 'utilities/common';

function DeliveryZoneTable({ intl, deliveryZoneData, rid, total, onChangePage }) {
  const dayEnNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const zoneStatus = {
    opened: 'Abierto',
    closed: 'Cerrado',
    notApply: 'No aplica',
    tempClose: 'Cerrado temporal',
  };
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    onChangePage(0, 10);
  }, []);

  const handleChangePage = (page, size) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage((page - 1) * size, size);
  };

  const handleShowSizeChange = (c, s) => {
    handleChangePage(1, s);
  };
  const days = moment(new Date()).day();
  const getZoneStatusBaseonTime = item => {
    const currentTime = moment(new Date(), 'HH:mm').tz('America/Guatemala');
    if (!item[`${dayEnNames[days]}OpenTime`]) {
      return 'notApply';
    }
    const beforeTime = moment(item[`${dayEnNames[days]}OpenTime`], 'HH:mm').tz('America/Guatemala');
    const afterTime = moment(item[`${dayEnNames[days]}CloseTime`], 'HH:mm').tz('America/Guatemala');
    if (currentTime.isBetween(beforeTime, afterTime)) {
      if (item[`${dayEnNames[days]}Schedule`] === 'O') {
        return 'opened';
      }
      if (item[`${dayEnNames[days]}Schedule`] === 'C') {
        return 'closed';
      }
      return 'tempClose';
    }
    return 'notApply';
  };

  return (
    <div className="deliveryzone_table_layout">
      <div className="table_header">
        <div className="name">
          <Apps />
          <FormattedMessage id="Name" />
        </div>
        <div className="delivery_details">
          <FormattedMessage id="Delivery Detail" />
        </div>
        <div className="assigned_addresses">
          <FormattedMessage id="Assigned Addresses" />
        </div>
        <div className="orders_now">
          <FormattedMessage id="Orders Now" />
        </div>
        <div className="attention_state">
          <FormattedMessage id="Attention State" />
        </div>
        <div className="actions">
          <FormattedMessage id="Actions" />
        </div>
      </div>
      <div className="table_content">
        {deliveryZoneData &&
          deliveryZoneData.map((item, index) => (
            <div className={`table_item type_state_${item.state === 'A'}`} key={index}>
              <div className="name">
                <p className="code">{item.id}</p>
                <p className="address">{item.name}</p>
                <p className="includeStatus">{item.type === 'I' ? 'Incluyente' : 'Excluyente'}</p>
              </div>
              <div className="delivery_details">
                <p className="purchase">
                  {`Mínimo de compra: ${getCurrency().symbol}${item.minimumPurchase / 100 || 0}`}
                </p>
                <p className="time">{`Tiempo promedio de entrega: ${item.deliveryTime || 0} minutos`}</p>
              </div>
              <div className="assigned_addresses">
                <p className="count">{item.addresses || 0}</p>
                <p className="customers">{`de ${item.customers || 0} clientes`}</p>
              </div>
              <div className="orders_now">
                <p className="process">{`${item.processingOrders || 0} procesando`}</p>
                <p className="route">{`${item.onTheWayOrders || 0} en ruta`}</p>
                <p className="door">{`${item.atTheDoorOrders || 0} en puerta`}</p>
              </div>
              <div className="attention_state">
                <p className="open_time">
                  {`De ${item[`${dayEnNames[days]}OpenTime`] || 0} a 
                  ${item[`${dayEnNames[days]}CloseTime`] || 0} horas`}
                </p>
                <div className={`open_status ${getZoneStatusBaseonTime(item) || ''}`}>
                  <p>{zoneStatus[getZoneStatusBaseonTime(item)] || ''}</p>
                </div>
              </div>
              <div className="actions">
                <Tooltip placement="top" title={intl.formatMessage({ id: 'Edit' })}>
                  <NavLink to={`/dashboard/restaurants/${rid}/deliveryzone/${item.id}`}>
                    <Button className="edit_btn">
                      <Edit />
                    </Button>
                  </NavLink>
                </Tooltip>
              </div>
            </div>
          ))}
      </div>
      <div className="pagination_wrapper">
        <Pagination
          showSizeChanger
          defaultCurrent={1}
          defaultPageSize={10}
          current={current}
          pageSize={pageSize}
          pageSizeOptions={['5', '10', '20', '30']}
          total={total}
          onChange={handleChangePage}
          onShowSizeChange={handleShowSizeChange}
        />
        <div className="total">Resultados: {total}</div>
      </div>
    </div>
  );
}

DeliveryZoneTable.defaultProps = {
  deliveryZoneData: [],
  total: 0,
};

export default compose(injectIntl)(DeliveryZoneTable);
