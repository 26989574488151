import { api } from '..';
import { TabsListData } from './types';

export const tabsApi: any = api.enhanceEndpoints({ addTagTypes: ['Tabs'] }).injectEndpoints({
  endpoints: build => ({
    getTabsList: build.query<TabsListData, any>({
      query: ({ search }) => ({
        url: `v1/deals/tabs?search=${search}`,
        method: 'GET',
        cache: 'no-cache',
      }),
    }),
    createTabs: build.mutation<any, any>({
      query: body => ({
        url: `v1/deals/tabs`,
        method: 'POST',
        body,
      }),
    }),
    updateTabs: build.mutation<any, any>({
      query: body => ({
        url: `v1/deals/tabs/${body.id}`,
        method: 'PUT',
        body,
        cache: 'no-cache',
      }),
    }),
    deleteTabs: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `v1/deals/tabs/${id}`,
        method: 'DELETE',
      }),
    }),
    changeTabs: build.mutation<any, any>({
      query: body => ({
        url: `v1/deals/tabs/positions`,
        method: 'PUT',
        body,
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useLazyGetTabsListQuery,
  useCreateTabsMutation,
  useUpdateTabsMutation,
  useDeleteTabsMutation,
  useChangeTabsMutation,
} = tabsApi;
