import React, { useState } from 'react';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import DeliveryZoneFilter from 'components/Dashboard/Restaurants/DeliveryZoneFilter';
import DeliveryZoneTable from 'components/Dashboard/Restaurants/DeliveryZoneTable';
import Loading from 'components/Basic/Loading';
import { useLazyGetDeliveryAreaListQuery } from 'core/restaurant/RestaurantService';

const DeliveryZoneList = ({ match }) => {
  const { rid } = match.params;

  const [state, setState] = useState({
    deliveryZoneData: [],
    rid: rid,
    sort: '',
    filterQuery: '',
    stateFilter: '',
    typeFilter: '',
    searchFilterQuery: '',
    isLoading: false,
  });

  const [getDeliveryAreaList] = useLazyGetDeliveryAreaListQuery();

  const handleChangePage = async (offset, limit, filterQuery, sort) => {
    setState(prev => ({ ...prev, offset: offset, limit: limit, isLoading: true }));
    await getDeliveryAreaList({
      id: rid,
      offset,
      limit,
      or: false,
      filter: filterQuery || state.filterQuery + state.searchFilterQuery,
      sort: sort || state.sort,
    })
      .unwrap()
      .then(res => {
        setState(prev => ({ ...prev, deliveryZoneData: res, isLoading: false }));
      })
      .catch(err => {
        console.log(err);
        setState(prev => ({ ...prev, isLoading: false }));
      });
  };

  const handleStateFilter = values => {
    if (values.length !== 0) {
      let filterQuery = '&filter=state in';
      values.map(v => {
        filterQuery += ` ${v}`;
        return filterQuery;
      });
      setState(prev => ({ ...prev, stateFilter: filterQuery }));
    } else {
      setState(prev => ({ ...prev, stateFilter: '' }));
    }
  };

  const handleTypeFilter = value => {
    if (value !== 'ALL') {
      setState(prev => ({ ...prev, typeFilter: `&filter=type eq ${value}` }));
    } else {
      setState(prev => ({ ...prev, typeFilter: '' }));
    }
  };

  const handleSort = value => {
    setState(prev => ({ ...prev, sort: value }));
  };

  const handleSearch = e => {
    setState(prev => ({ ...prev, searchText: e.target.value }));
  };

  const handleKeyDownSearch = async e => {
    let searchFilterQuery = '';

    if (e.keyCode === 13) {
      if (state.searchText) {
        if (!isNaN(state.searchText, 10) && state.searchText < 100000000) {
          searchFilterQuery += `&filter=or.id eq ${state.searchText}`;
        } else {
          searchFilterQuery += `&filter=or.name iLike %25${state.searchText}%25`;
        }
      } else {
        searchFilterQuery = '';
      }
      setState(prev => ({ ...prev, searchFilterQuery: searchFilterQuery, isLoading: true }));
      await getDeliveryAreaList({
        id: rid,
        offset: state.offset,
        limit: state.limit,
        or: false,
        filter: state.filterQuery + searchFilterQuery,
        sort: state.sort,
      })
        .unwrap()
        .then(res => {
          setState(prev => ({ ...prev, deliveryZoneData: res, isLoading: false }));
        })
        .catch(err => {
          console.log(err);
          setState(prev => ({ ...prev, isLoading: false }));
        });
    }
  };

  const handleApplyFilter = async () => {
    setState(prev => ({ ...prev, filterQuery: state.stateFilter + state.typeFilter, isLoading: true }));
    await getDeliveryAreaList({
      id: rid,
      offset: state.offset,
      limit: state.limit,
      or: false,
      filter: state.stateFilter + state.typeFilter + state.searchFilterQuery,
      sort: state.sort,
    })
      .unwrap()
      .then(res => {
        setState(prev => ({ ...prev, deliveryZoneData: res, isLoading: false }));
      })
      .catch(err => {
        console.log(err);
        setState(prev => ({ ...prev, isLoading: false }));
      });
  };

  return (
    <div className="delivery_zone_list_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader type="delivery_zone" rid={rid} />
      <div className="delivery_zone_filter_wrapper">
        <DeliveryZoneFilter
          searchText={state.searchText}
          onStateFilter={handleStateFilter}
          ontypeFilter={handleTypeFilter}
          onSort={handleSort}
          onSearch={handleSearch}
          onKeyDownSearch={handleKeyDownSearch}
          onApplyFilter={handleApplyFilter}
        />
        <DeliveryZoneTable
          deliveryZoneData={state.deliveryZoneData.rows}
          onChangePage={handleChangePage}
          total={state.deliveryZoneData.totalCount}
          rid={rid}
        />
      </div>
    </div>
  );
};

export default DeliveryZoneList;
