import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SarConfigForm from 'containers/Dashboard/SarConfig/SarConfigForm';

const SarConfig = () => (
  <Switch>
    <Route exact path={'/dashboard/sar'} component={SarConfigForm} />
  </Switch>
);

export default SarConfig;
