import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import moment from 'moment-timezone';
import { Tooltip, Button, Pagination } from 'antd';
import { RemoveRedEye, Edit } from '@material-ui/icons';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import CouponViewModal from 'components/Dashboard/Coupons/CouponViewModal';
import DeleteConfirmModal from 'components/Basic/DeleteConfirmModal';

function CouponTable({ intl, couponData, total, onDeleteCoupon, onEditCoupon, onChangePage }) {
  const [isShowDetailModal, setShowDetailModal] = useState(false);
  const [isRemoveModal, setIsRemoveModal] = useState(false);
  const [couponInfo, setCouponInfo] = useState(null);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    onChangePage(0, 10);
  }, []);

  const handleChangePage = (page, size) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage((page - 1) * size, size);
  };

  const handleShowSizeChange = (c, s) => {
    handleChangePage(c, s);
  };

  const handleRemoveCoupon = () => {
    setIsRemoveModal(false);
    onDeleteCoupon(couponInfo);
  };

  return (
    <div className="coupon_table_layout">
      <div className="table_header">
        <div className="code">
          <FormattedMessage id="Code" />
        </div>
        <div className="name">
          <FormattedMessage id="Coupon Name" />
        </div>
        <div className="type">
          <FormattedMessage id="Kind" />
        </div>
        <div className="start_date">
          <FormattedMessage id="Start date" />
        </div>
        <div className="finish_date">
          <FormattedMessage id="Finish date" />
        </div>
        <div className="actions">
          <FormattedMessage id="Actions" />
        </div>
      </div>
      <div className="table_content">
        {couponData &&
          couponData.map((item, index) => (
            <div className={`table_item type_state_${item.state}`} key={index}>
              <div className="code">
                <p>{item.code}</p>
              </div>
              <div className="name">
                <p>{item.name}</p>
              </div>
              <div className="type">
                <p>{item.type}</p>
                <p className="service_info">{item.serviceInfo}</p>
              </div>
              <div className="start_date">
                <p>{item.startAt ? `${moment(item.startAt).tz('America/Guatemala').format('LL')}` : ''}</p>
              </div>
              <div className="finish_date">
                <p>{item.endAt ? `${moment(item.endAt).tz('America/Guatemala').format('LL')}` : ''}</p>
              </div>
              <div className="actions">
                <Tooltip placement="top" title={intl.formatMessage({ id: 'Edit coupon' })}>
                  <Button
                    className="edit_btn"
                    onClick={() => {
                      onEditCoupon(item);
                    }}
                  >
                    <Edit />
                  </Button>
                </Tooltip>
                <Tooltip placement="top" title={intl.formatMessage({ id: 'See details' })}>
                  <Button
                    className="detail_btn"
                    onClick={() => {
                      setCouponInfo(item);
                      setShowDetailModal(true);
                    }}
                  >
                    <RemoveRedEye />
                  </Button>
                </Tooltip>
              </div>
            </div>
          ))}
      </div>
      <div className="pagination_wrapper">
        <Pagination
          showSizeChanger
          defaultCurrent={1}
          defaultPageSize={10}
          current={current}
          pageSize={pageSize}
          pageSizeOptions={['5', '10', '20', '30']}
          total={total}
          onChange={handleChangePage}
          onShowSizeChange={handleShowSizeChange}
        />
        <div className="total">Resultados: {total}</div>
      </div>
      <CouponViewModal
        isOpenModal={isShowDetailModal}
        couponInfo={couponInfo}
        onEdit={() => {
          setShowDetailModal(false);
          onEditCoupon(couponInfo);
        }}
        onDelete={() => {
          setShowDetailModal(false);
          setIsRemoveModal(true);
        }}
        onCloseModal={() => setShowDetailModal(false)}
      />
      <DeleteConfirmModal
        isOpenModal={isRemoveModal}
        title={intl.formatMessage({ id: 'Do you want to remove the screen?' })}
        subtitle={intl.formatMessage({
          id: 'This action cannot be undone. Continue deleting the screen?',
        })}
        onCancel={() => setIsRemoveModal(false)}
        onOk={handleRemoveCoupon}
      />
    </div>
  );
}

CouponTable.defaultProps = {
  couponData: [],
  total: '0',
};

export default compose(injectIntl)(CouponTable);
