import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DropMenu from 'components/Basic/DropMenu';

const dayNames = ['Domingos', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábados'];

const dayEnNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const orderSets = [
  { key: 'O', value: 'Abierto' },
  { key: 'C', value: 'Cerrado' },
  { key: 'T', value: 'Cerrado temporal' },
];

class DeliverySchedule extends PureComponent {
  constructor(props) {
    super(props);
    this.times = [];
    for (let i = 0; i < 24; i += 0.5) {
      this.times.push({
        key: `${parseInt(i, 10) < 10 ? `0${parseInt(i, 10)}` : parseInt(i, 10)}:${i % 1 != 0 ? '30' : '00'}`,
        value: `${parseInt(i, 10) < 10 ? `0${parseInt(i, 10)}` : parseInt(i, 10)}:${i % 1 != 0 ? '30' : '00'}`,
        num: i,
      });
    }
  }

  handleOrderSet = (key, field) => {
    this.props.onChange(field, orderSets[key].key);
  };

  handleOrderOpen = (key, field) => {
    this.props.onChange(field, this.times[key].key);
  };

  handleOrderClose = (key, field) => {
    this.props.onChange(field, this.times[key].key);
  };

  render() {
    const { deliveryInfo } = this.props;

    return (
      <div className="delivery_schedule_layout">
        {dayNames.map((day, index) => (
          <div className="delivery_time_schedule" key={day}>
            <div className="select_time_range">
              <div className="order_set">
                <p className="title">{day}</p>
                <DropMenu
                  items={orderSets}
                  onMenu={key => this.handleOrderSet(key, `${dayEnNames[index]}Schedule`)}
                  defaultValue={deliveryInfo[`${dayEnNames[index]}Schedule`] || 'C'}
                />
              </div>
              <div className="open_time">
                <p className="title">
                  <FormattedMessage id="Distribute from" />
                </p>
                <DropMenu
                  items={this.times}
                  onMenu={key => this.handleOrderOpen(key, `${dayEnNames[index]}OpenTime`)}
                  placeHolder="Selecciona una hora"
                  defaultValue={deliveryInfo[`${dayEnNames[index]}OpenTime`]}
                />
              </div>
              <div className="close_time">
                <p className="title">
                  <FormattedMessage id="Deal up" />
                </p>
                <DropMenu
                  items={this.times}
                  onMenu={key => this.handleOrderClose(key, `${dayEnNames[index]}CloseTime`)}
                  placeHolder="Selecciona una hora"
                  defaultValue={deliveryInfo[`${dayEnNames[index]}CloseTime`]}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

DeliverySchedule.defaultProps = {
  deliveryInfo: {},
};

export default DeliverySchedule;
