import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Select, Input, Button } from 'antd';
import { Refresh, Search, ArrowDropDown } from '@material-ui/icons';

const { Option } = Select;

function DeliveryZoneFilter({
  intl,
  searchText,
  onStateFilter,
  ontypeFilter,
  onSort,
  onSearch,
  onKeyDownSearch,
  onApplyFilter,
}) {
  const zoneStates = [
    { value: 'A', label: intl.formatMessage({ id: 'Active' }) },
    { value: 'I', label: intl.formatMessage({ id: 'Inactive' }) },
  ];
  const zoneTypes = [
    { value: 'ALL', label: intl.formatMessage({ id: 'All types' }) },
    { value: 'I', label: 'Inclusive' },
    { value: 'E', label: 'Excluding' },
  ];
  const zoneSorts = [
    { value: 'state desc', label: intl.formatMessage({ id: 'By state' }) },
    { value: 'id desc', label: intl.formatMessage({ id: 'By ID' }) },
    { value: 'type desc', label: intl.formatMessage({ id: 'By type' }) },
    {
      value: 'addresses desc',
      label: intl.formatMessage({ id: 'By number of assigned addresses' }),
    },
    {
      value: 'assignedOrders desc',
      label: intl.formatMessage({ id: 'By number of orders now' }),
    },
    {
      value: 'inProductionOrders desc',
      label: intl.formatMessage({ id: 'By state of opening' }),
    },
  ];
  const handleStateFilter = values => {
    onStateFilter(values);
  };

  const handleTypeFilter = value => {
    ontypeFilter(value);
  };

  const handleSort = value => {
    onSort(value);
  };

  const handleSearch = e => {
    onSearch(e);
  };

  return (
    <div className="delivery_zone_filter_layout">
      <Select
        className="active_states"
        suffixIcon={<ArrowDropDown />}
        mode="multiple"
        placeholder="estados activos"
        onChange={handleStateFilter}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {zoneStates.map(item => (
          <Option value={item.value} key={item.label}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Select
        className="all_types"
        suffixIcon={<ArrowDropDown />}
        placeholder="Todos los tipos"
        onChange={handleTypeFilter}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {zoneTypes.map(item => (
          <Option value={item.value} key={item.label}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Select
        className="sort_by"
        suffixIcon={<ArrowDropDown />}
        placeholder="Ordernar por"
        onChange={handleSort}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {zoneSorts.map(item => (
          <Option value={item.value} key={item.label}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Button className="apply_filters_btn" onClick={onApplyFilter}>
        <FormattedMessage id="Apply filters" />
        <Refresh />
      </Button>
      <Input
        suffix={<Search />}
        placeholder="Buscar zona de reparto"
        value={searchText}
        onChange={e => handleSearch(e)}
        onKeyDown={e => onKeyDownSearch(e)}
      />
    </div>
  );
}

export default compose(injectIntl)(DeliveryZoneFilter);
