import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import CatalogueList from 'containers/Dashboard/Catalogue/CatalogueList';
import CatalogueManage from 'containers/Dashboard/Catalogue/CatalogueManage';

class Catalogue extends PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path="/dashboard/catalogue" component={CatalogueList} />
        <Route exact path="/dashboard/catalogue/manage" component={CatalogueManage} />
      </Switch>
    );
  }
}

export default Catalogue;
