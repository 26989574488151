import { api } from '..';

export const sarApi = api.enhanceEndpoints({ addTagTypes: ['Sar'] }).injectEndpoints({
  endpoints: build => ({
    getSar: build.query<any, any>({
      query: () => ({
        url: `v0/sar/settings`,
        method: 'GET',
      }),
    }),
    updateSar: build.mutation<any, any>({
      query: body => ({
        url: `v0/sar/settings`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useLazyGetSarQuery, useUpdateSarMutation } = sarApi;
