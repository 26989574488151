import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Img } from 'react-image';
import { FormattedMessage } from 'react-intl';
import { getProductInfo } from 'utilities/common';
import placeHolderImg from 'assets/img/placeholder.svg';
import { useLazyGetPricesQuery, useLazyGetSizesQuery } from 'core/catalog/CatalogService';
import { setPricesData, setSizesData } from 'core/catalog/CatalogSlice';
import { useAppDispatch, useAppSelector } from 'utilities/redux';

function TopSaleList({ topProducts, topSales }) {
  const [topList, setTopList] = useState([]);
  const [getPrices] = useLazyGetPricesQuery();
  const [getSizes] = useLazyGetSizesQuery();

  const dispatch = useAppDispatch();

  const { prices, sizes } = useAppSelector(state => state.catalogReducer);

  useEffect(() => {
    getPrices({})
      .unwrap()
      .then(res => {
        dispatch(
          setPricesData({
            prices: res.rows,
          }),
        );
      });
    getSizes({})
      .unwrap()
      .then(res => {
        dispatch(
          setSizesData({
            sizes: res.rows,
          }),
        );
      });
  }, []);
  const getTotalSales = id => {
    return [...topSales].filter(sale => sale.item === id)[0]?.total;
  };

  const getProduct = data => {
    return getProductInfo(data, prices, sizes);
  };

  useEffect(() => {
    setTopList(
      [...topProducts].sort((a, b) => {
        if (getTotalSales(a.id) < getTotalSales(b.id)) {
          return 1;
        }
        return -1;
      }),
    );
  }, [topProducts, topSales]);

  return (
    <div className="top_sales_list">
      <div className="top_sales_title">
        <FormattedMessage id="Top 5 best selling today" />
      </div>
      <div className="top_five_sales_wrapper">
        {topList.map((food, index) => (
          <div className="food_item" key={index}>
            <div className="food_img">
              <Img
                src={
                  food.productCode !== undefined
                    ? `${process.env.REACT_APP_S3}/${food.channel === 'APP' ? 'customer' : 'ots'}/${
                        food.productCode > 0 ? food.productCode : getProduct(food).productCode
                      }.jpg`
                    : placeHolderImg
                }
                alt="food"
              />
            </div>
            <div className="food_info">
              <p className="name" dangerouslySetInnerHTML={{ __html: food.name }} />
              <p className="sold-count">{`${getTotalSales(food.id)} vendidos`}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

TopSaleList.defaultProps = {
  topProducts: [],
  topSales: [],
};

export default TopSaleList;
