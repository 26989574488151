import { ArrowForward, CategoryOutlined, FolderOutlined, PeopleAltOutlined } from '@material-ui/icons';
import { Button } from 'antd';
import AlertModalOffer from 'components/Basic/AlertModalOffers';
import Loading from 'components/Basic/Loading';
import toast from 'components/Basic/Toast';
import OffersFilter from 'components/Dashboard/Offers/OffersFilter';
import OffersTable from 'components/Dashboard/Offers/OffersTable';
import OffersViewModal from 'components/Dashboard/Offers/OffersViewModal';
import { useLazyGetCampaignListQuery } from 'core/campaign/CampaignService';
import { useLazyGetCategoriesListQuery } from 'core/categories/CategoriesService';
import { useLazyGetConfigurationQuery } from 'core/deals/DealsService';
import { setConfiguration } from 'core/deals/DealsSlice';
import { useLazyGetOffersListQuery } from 'core/offers/OffersService';
import { setOffertListData } from 'core/offers/OffersSlice';
import { useLazyGetTabsListQuery } from 'core/tabs/TabsService';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'utilities/redux';

const Offers: React.FC = () => {
  const intl = useIntl();
  const [state, setState] = useState<any>({
    isLoading: false,
    searchText: '',
    sortStatus: '',
    filterStatus: null,
    filterTab: null,
    filterCat: null,
    filterCompaign: null,
    sortName: '',
    sortId: 'desc',
    startDate: '',
    endDate: '',
    sortWeight: '',
    sortDate: '',
  });

  const [isAlertModal, setAlertModal] = useState<boolean>(false);
  const [isShowModal, setShowModal] = useState<boolean>(false);

  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const statuses = [
    { value: '', label: intl.formatMessage({ id: 'All' }) },
    { value: 'true', label: intl.formatMessage({ id: 'Active' }) },
    { value: 'false', label: intl.formatMessage({ id: 'Inactive' }) },
  ];

  const sorts = [
    { value: 'Idesc', label: intl.formatMessage({ id: 'ID (desc)' }) },
    { value: 'Iasc', label: intl.formatMessage({ id: 'ID (asc)' }) },
    { value: 'Nasc', label: intl.formatMessage({ id: 'Name (A-Z)' }) },
    { value: 'Ndesc', label: intl.formatMessage({ id: 'Name (Z-A)' }) },
    { value: 'Sdesc', label: intl.formatMessage({ id: 'Status (Active first)' }) },
    { value: 'Sasc', label: intl.formatMessage({ id: 'Status (Inactive first)' }) },
    // { value: 'Wdesc', label: intl.formatMessage({ id: 'Weight (desc)' }) },
    // { value: 'Wasc', label: intl.formatMessage({ id: 'Weight (asc)' }) },
  ];

  const dispatch = useAppDispatch();
  const [getOffersList] = useLazyGetOffersListQuery();
  const [getTabsList] = useLazyGetTabsListQuery();
  const [getCategoriesList] = useLazyGetCategoriesListQuery();
  const [getCampaignList] = useLazyGetCampaignListQuery();
  const [getConfiguration] = useLazyGetConfigurationQuery();

  const [tabs, setTabs] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [campaigns, setCampaings] = useState<any>([]);

  const { list } = useAppSelector(state => state.offersReducer);
  const { configuration } = useAppSelector(state => state.dealsReducer);

  const { offerId } = useParams<{ offerId: string }>();

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    setState({ ...state, isLoading: true });
    try {
      const queryString = 'search=&page=1&size=10&count=true&sort[id]=desc';
      const configuration = await getConfiguration({}).unwrap();
      const offersResponse = await getOffersList({ queryString }).unwrap();

      const tabsResponce = await getTabsList({ search: '' }).unwrap();
      const categoriesResponse = await getCategoriesList({ queryString: '' }).unwrap();
      const campaignResponse = await getCampaignList({ queryString: '' }).unwrap();
      dispatch(setConfiguration(configuration));
      dispatch(setOffertListData({ list: offersResponse }));
      setTabs(tabsResponce.tabs);
      setCategories(categoriesResponse.categories);
      setCampaings(campaignResponse.campaigns);
    } catch (error: any) {
      toast.error({ title: error.message });
    } finally {
      setState({ ...state, isLoading: false });
    }
  };

  const handleChangePage = async (page: number, size: number) => {
    setState({ ...state, isLoading: true });
    setPageSize(size);
    try {
      const queryString = buildQueryString({
        search: state.searchText.toLowerCase(),
        page,
        size,
        count: true,
        sort_by_status: state.sortStatus,
        sort_by_id: state.sortId,
        sort_by_name: state.sortName,
        filter: state.filterStatus,
        filter_tab: state.filterTab,
        filter_cat: state.filterCat,
        filter_campaign: state.filterCompaign,
        start_date: state.startDate,
        end_date: state.endDate,
        sort_by_weight: state.sortWeight,
        sort_by_date: state.sortDate,
      });

      const res = await getOffersList({ queryString }).unwrap();
      dispatch(setOffertListData({ list: res }));
    } catch (e) {
      console.log(e);
    } finally {
      setState({ ...state, isLoading: false });
    }
  };

  const handleSearch = async (e: any) => {
    const searchTextValue = e.target.value;
    setState({ ...state, searchText: searchTextValue });
    if (e.keyCode === 13) {
      setCurrent(1);
      try {
        const queryString = buildQueryString({
          search: e.target.value,
          page: 1,
          size: state.pageSize,
          count: true,
          sort_by_status: state.sortStatus,
          sort_by_id: state.sortId,
          sort_by_name: state.sortName,
          filter: state.filterStatus,
          filter_tab: state.filterTab,
          filter_cat: state.filterCat,
          filter_campaign: state.filterCompaign,
          start_date: state.startDate,
          end_date: state.endDate,
          sort_by_weight: state.sortWeight,
          sort_by_date: state.sortDate,
        });

        const res = await getOffersList({ queryString }).unwrap();
        dispatch(setOffertListData({ list: res }));
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleStatusFilter = (value: string) => {
    setState({ ...state, filterStatus: value });
  };

  const onCampaignFilter = (value: string) => {
    setState({ ...state, filterCompaign: value });
  };

  const onTabFilter = (value: string) => {
    setState({ ...state, filterTab: value });
  };

  const onCatFilter = (value: string) => {
    setState({ ...state, filterCat: value });
  };

  const onStartFilterChange = (value: string) => {
    setState({ ...state, startDate: value });
  };

  const onEndFilterChange = (value: string) => {
    setState({ ...state, endDate: value });
  };

  const handleSort = (value: string) => {
    let newState = { ...state };
    if (value[0] === 'I') {
      newState = {
        ...newState,
        sortId: value.substring(1),
        sortName: '',
        sortStatus: '',
        sortWeight: '',
        sortDate: '',
      };
    } else if (value[0] === 'N') {
      newState = {
        ...newState,
        sortName: value.substring(1),
        sortId: '',
        sortStatus: '',
        sortWeight: '',
        sortDate: '',
      };
    } else if (value[0] === 'S') {
      newState = {
        ...newState,
        sortStatus: value.substring(1),
        sortId: '',
        sortName: '',
        sortWeight: '',
        sortDate: '',
      };
    } else if (value[0] === 'W') {
      newState = {
        ...newState,
        sortWeight: value.substring(1),
        sortId: '',
        sortName: '',
        sortStatus: '',
        sortDate: '',
      };
    } else if (value[0] === 'D') {
      newState = {
        ...newState,
        sortDate: value.substring(1),
        sortId: '',
        sortName: '',
        sortStatus: '',
        sortWeight: '',
      };
    }
    setState(newState);
  };

  const onApplyFilter = async () => {
    setState({ ...state, isLoading: true });
    setCurrent(1);
    try {
      const queryString = buildQueryString({
        search: state.searchText.toLowerCase(),
        page: 1,
        size: state.pageSize,
        count: true,
        sort_by_status: state.sortStatus,
        sort_by_id: state.sortId,
        sort_by_name: state.sortName,
        filter: state.filterStatus,
        filter_tab: state.filterTab,
        filter_cat: state.filterCat,
        filter_campaign: state.filterCompaign,
        start_date: state.startDate,
        end_date: state.endDate,
        sort_by_weight: state.sortWeight,
        sort_by_date: state.sortDate,
      });

      const res = await getOffersList({ queryString }).unwrap();
      dispatch(setOffertListData({ list: res }));
    } catch (e) {
      console.log(e);
    } finally {
      setState({ ...state, isLoading: false });
    }
  };

  const checkAddNewOffer = () => {
    if ((configuration.codePrefix && configuration.id) || list.deals.length > 0) {
      setShowModal(true);
    } else {
      setAlertModal(true);
    }
  };

  return (
    <div className="offers_wrapper">
      <div className="btn_wrapper">
        <Button className="add_btn" onClick={checkAddNewOffer}>
          <FormattedMessage id="Add new offer" />
          <ArrowForward />
        </Button>
        <div className="right_btns">
          <NavLink to="/dashboard/deals/offers/tabs">
            <Button className="add_btn">
              <FormattedMessage id="Configure tabs" />
              <FolderOutlined />
            </Button>
          </NavLink>
          <NavLink to="/dashboard/deals/offers/categories">
            <Button className="add_btn">
              <FormattedMessage id="Configure categories" />
              <CategoryOutlined />
            </Button>
          </NavLink>
          <NavLink to="/dashboard/deals/offers/segments">
            <Button className="add_btn">
              <FormattedMessage id="Configure segments" />
              <PeopleAltOutlined />
            </Button>
          </NavLink>
        </div>
      </div>
      {list && list.deals && (
        <OffersFilter
          sorts={sorts}
          statuses={statuses}
          tabs={tabs}
          categories={categories}
          campaigns={campaigns}
          searchText={state.searchText}
          selectedStatus={state.filterStatus}
          selectedTab={state.filterTab}
          selectedCategory={state.filterCat}
          selectedCompaign={state.filterCompaign}
          selectedSortStatus={state.sortStatus}
          onSelectSort={handleSort}
          onSelectStatus={handleStatusFilter}
          onSelectCategory={onCatFilter}
          onSelectTab={onTabFilter}
          onSelectCampaign={onCampaignFilter}
          onChangeStartDate={onStartFilterChange}
          onChangeEndDate={onEndFilterChange}
          onChangeSearch={handleSearch}
          onApplyClick={onApplyFilter}
        />
      )}
      <OffersTable
        current={current}
        offerId={offerId}
        total={list && list.totalCount ? list.totalCount : 0}
        offersData={list && list.deals ? list.deals : null}
        setCurrent={setCurrent}
        onChangePage={(current, size) => handleChangePage(current, size)}
      />
      {list && list.deals && (
        <>
          <OffersViewModal
            isDraftPage={false}
            isShowModal={isShowModal}
            onShowModal={(value: boolean) => setShowModal(value)}
            categories={list && list.deals ? list.deals : []}
            current={current}
            pageSize={pageSize}
            offersInfo={null}
            draftRequest={() => {}}
            offersRequest={handleChangePage}
          />
        </>
      )}
      {state.isLoading && <Loading visible={state.isLoading} />}
      <AlertModalOffer
        isAlertModal={isAlertModal}
        onCancel={() => setAlertModal(false)}
        onClose={() => setAlertModal(false)}
      />
    </div>
  );
};

Offers.defaultProps = {
  list: {},
};

export default Offers;

const buildQueryString = ({
  search = '',
  page = 1,
  size = 10,
  count = true,
  sort_by_status = '',
  sort_by_id = 'desc',
  sort_by_name = '',
  filter = '',
  filter_tab = '',
  filter_cat = '',
  filter_campaign = '',
  start_date = '',
  end_date = '',
  sort_by_date = '',
  sort_by_weight = '',
}) => {
  const params = [`search=${encodeURIComponent(search)}`, `page=${page}`, `size=${size}`, `count=${count}`];

  if (sort_by_status) {
    params.push(`sort[status]=${sort_by_status}`);
  }

  if (sort_by_id) {
    params.push(`sort[id]=${sort_by_id}`);
  }

  if (sort_by_name) {
    params.push(`sort[name]=${sort_by_name}`);
  }

  if (sort_by_date) {
    params.push(`sort[date]=${sort_by_date}`);
  }

  if (filter) {
    params.push(`filter[status]=${filter}`);
  }

  if (filter_tab) {
    params.push(`filter[tab]=${filter_tab}`);
  }

  if (filter_cat) {
    params.push(`filter[category]=${filter_cat}`);
  }

  if (filter_campaign) {
    params.push(`filter[campaign]=${filter_campaign}`);
  }

  if (start_date) {
    params.push(`filter[startDate]=${start_date}`);
  }

  if (end_date) {
    params.push(`filter[endDate]=${end_date}`);
  }

  if (sort_by_weight) {
    params.push(`sort[weight]=${sort_by_weight}`);
  }

  return params.join('&');
};
