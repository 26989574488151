import React, { useState } from 'react';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import UserFilter from 'components/Dashboard/Users/UserFilter';
import UserTable from 'components/Dashboard/Users/UserTable';
import Loading from 'components/Basic/Loading';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyGetCustomerListQuery } from 'core/customer/CustomerService';
import { setListData } from 'core/customer/CustomerSlice';

const UserList = () => {
  const [state, setState] = useState({
    searchText: '',
    offset: 0,
    limit: 10,
    filterQuery: '',
    searchFilterQuery: '',
    sort: '',
    isLoading: false,
  });

  const dispatch = useAppDispatch();

  const [getCustomerList] = useLazyGetCustomerListQuery();

  const { list } = useAppSelector(state => state.customerReducer);

  const handleChangePage = async (offset, limit, filterQuery, sort) => {
    setState(prev => ({ ...prev, offset: offset, limit: limit, isLoading: true }));
    try {
      const res = await getCustomerList({
        offset,
        limit,
        or: false,
        filter: filterQuery || state.filterQuery + state.searchFilterQuery,
        sort: sort || state.sort,
      }).unwrap();
      dispatch(setListData({ list: res }));
      setState(prev => ({ ...prev, isLoading: false }));
    } catch (err) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const handleStateFilter = values => {
    if (values === 'D') {
      setState(prev => ({ ...prev, filterQuery: '&filter=isDeleted eq true' }));
    } else if (values === 'O') {
      setState(prev => ({ ...prev, filterQuery: '' }));
    } else {
      setState(prev => ({ ...prev, filterQuery: `&filter=isDeleted eq false&filter=state in ${values}` }));
    }
  };

  const handleSort = value => {
    setState(prev => ({ ...prev, sort: value }));
  };

  const handleSearch = e => {
    setState(prev => ({ ...prev, searchText: e.target.value }));
  };

  const handleKeyDownSearch = async e => {
    let searchFilterQuery = '';
    if (e.keyCode === 13) {
      const { offset, limit, filterQuery, sort, searchText } = state;
      if (searchText) {
        if (!isNaN(searchText, 10) && searchText < 100000000) {
          searchFilterQuery += `&filter=or.id eq ${searchText}`;
        }
        searchFilterQuery += `&filter=or.fullName iLike %25${searchText}%25&filter=or.email iLike %25${searchText}%25&filter=or.phone iLike %25${searchText}%25`;
      } else {
        searchFilterQuery = '';
      }
      setState(prev => ({ ...prev, searchFilterQuery: searchFilterQuery, isLoading: true }));
      try {
        const res = await getCustomerList({
          offset: 0,
          limit: 10,
          or: false,
          filter: filterQuery + searchFilterQuery,
          sort: sort,
        }).unwrap();
        dispatch(setListData({ list: res }));
        setState(prev => ({ ...prev, isLoading: false }));
      } catch (err) {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    }
  };

  const handleApplyFilter = async () => {
    const { filterQuery, sort, searchFilterQuery } = state;
    setState(prev => ({ ...prev, isLoading: true }));
    try {
      const res = await getCustomerList({
        offset: 0,
        limit: 10,
        or: false,
        filter: filterQuery + searchFilterQuery,
        sort: sort,
      }).unwrap();
      dispatch(setListData({ list: res }));
      setState(prev => ({ ...prev, isLoading: false }));
    } catch (err) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  return (
    <div className="user_list_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader type="users" />
      <div className="user_filter_wrapper">
        <UserFilter
          searchText={state.searchText}
          onStateFilter={handleStateFilter}
          onSort={handleSort}
          onSearch={handleSearch}
          onKeyDownSearch={handleKeyDownSearch}
          onApplyFilter={handleApplyFilter}
        />
        <UserTable userData={list.rows || []} onChangePage={handleChangePage} total={list.totalCount} />
      </div>
    </div>
  );
};

export default UserList;
