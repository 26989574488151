import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ArrowForward } from '@material-ui/icons';
import CampaignTable from 'components/Dashboard/Campaign/CampaignTable';
import Loading from 'components/Basic/Loading';
import CampaignViewModal from 'components/Dashboard/Campaign/CampaignViewModal';
import CampaignFilter from 'components/Dashboard/Campaign/CampaignFilter';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyGetCampaignListQuery } from 'core/campaign/CampaignService';
import { setCampaignData } from 'core/campaign/CampaignSlice';

const Campaign: React.FC = () => {
  const [state, setState] = useState({
    isLoading: false,
    isShowDetailModal: false,
    searchText: '',
    sortStatus: '',
    filterStatus: '',
    sortName: '',
    sortId: 'desc',
  });
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const dispatch = useAppDispatch();
  const [getCampaignList] = useLazyGetCampaignListQuery();

  const { list } = useAppSelector(state => state.campaignReducer);

  useEffect(() => {
    getInitialData();
    setState({ ...state, searchText: '' });
  }, []);

  useEffect(() => {
    setCurrent(1);
  }, [pageSize]);

  const getInitialData = async () => {
    setState({ ...state, isLoading: true });

    const queryString = buildQueryString({
      search: '',
      page: 1,
      size: pageSize,
      count: true,
      sort_by_id: state.sortId,
      filter: state.filterStatus,
    });

    try {
      const res = await getCampaignList({ queryString }).unwrap();
      dispatch(setCampaignData({ list: res }));
    } catch (error) {
      console.error('Error fetching campaign list:', error);
    } finally {
      setState({ ...state, isLoading: false });
    }
  };

  const handleChangePage = async (page: number, size: number, search = '') => {
    setState({ ...state, isLoading: true });
    setPageSize(size);

    const queryString = buildQueryString({
      search: state.searchText,
      page: page,
      size: size,
      count: true,
      sort_by_status: state.sortStatus,
      sort_by_id: state.sortId,
      sort_by_name: state.sortName,
      filter: state.filterStatus,
    });

    try {
      const res = await getCampaignList({ queryString }).unwrap();
      dispatch(setCampaignData({ list: res }));
      setState({ ...state, isLoading: false });
    } catch (e) {
      setState({ ...state, isLoading: false });
    }
  };

  const handleSearch = async (e: any) => {
    const searchTextValue = e.target.value.trimStart();
    setState({ ...state, searchText: searchTextValue });
    if (e.keyCode === 13) {
      setCurrent(1);

      const queryString = buildQueryString({
        search: e.target.value,
        page: 1,
        size: pageSize,
        count: true,
        sort_by_status: state.sortStatus,
        sort_by_id: state.sortId,
        sort_by_name: state.sortName,
        filter: state.filterStatus,
      });

      try {
        const res = await getCampaignList({ queryString }).unwrap();
        dispatch(setCampaignData({ list: res }));
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleAvailableFilter = (value: string) => {
    setState({ ...state, filterStatus: value });
  };

  const handleSort = (value: string) => {
    let newState = { ...state };
    if (value[0] === 'I') {
      newState = {
        ...newState,
        sortId: value.substring(1),
        sortName: '',
        sortStatus: '',
      };
    } else if (value[0] === 'N') {
      newState = {
        ...newState,
        sortName: value.substring(1),
        sortId: '',
        sortStatus: '',
      };
    } else if (value[0] === 'S') {
      newState = {
        ...newState,
        sortStatus: value.substring(1),
        sortId: '',
        sortName: '',
      };
    }
    setState(newState);
  };

  const onApplyFilter = async () => {
    setState({ ...state, isLoading: true });
    setCurrent(1);

    const queryString = buildQueryString({
      search: state.searchText,
      page: 1,
      size: pageSize,
      count: true,
      sort_by_status: state.sortStatus,
      sort_by_id: state.sortId,
      sort_by_name: state.sortName,
      filter: state.filterStatus,
    });

    try {
      const res = await getCampaignList({ queryString }).unwrap();
      dispatch(setCampaignData({ list: res }));
    } catch (e) {
      console.log(e);
    } finally {
      setState({ ...state, isLoading: false });
    }
  };

  return (
    <div className="campaign_wrapper" id="campaign_wrapper">
      <div className="btn_wrapper" id="new_campaign_btn_wrapper">
        <Button
          className="add_btn"
          onClick={() => setState({ ...state, isShowDetailModal: true })}
          id="new_campaign_btn"
        >
          <FormattedMessage id="Add new campaign" />
          <ArrowForward />
        </Button>
      </div>
      <CampaignFilter
        searchText={state.searchText}
        handleSearch={handleSearch}
        onAvailableFilter={handleAvailableFilter}
        onSort={handleSort}
        onApplyFilter={onApplyFilter}
      />
      <CampaignTable
        campaignData={list.campaigns || null}
        onChangePage={(current, size) => handleChangePage(current, size)}
        total={list.totalCount}
        setCurrent={setCurrent}
        current={current}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
      <CampaignViewModal
        isOpenModal={state.isShowDetailModal}
        onCloseModal={() => setState({ ...state, isShowDetailModal: false })}
        setPageSize={setPageSize}
        setCurrent={setCurrent}
        campaignInfo={null}
      />
      {state.isLoading && <Loading visible={state.isLoading} />}
    </div>
  );
};

Campaign.defaultProps = {
  list: {},
};

export default Campaign;

const buildQueryString = ({
  search = '',
  page = 1,
  size = 10,
  count = true,
  sort_by_id = 'desc',
  sort_by_status = '',
  sort_by_name = '',
  filter = '',
}) => {
  const params = [`search=${encodeURIComponent(search)}`, `page=${page}`, `size=${size}`, `count=${count}`];

  if (sort_by_status) {
    params.push(`sort[status]=${sort_by_status}`);
  }

  if (sort_by_id) {
    params.push(`sort[id]=${sort_by_id}`);
  }

  if (sort_by_name) {
    params.push(`sort[name]=${sort_by_name}`);
  }

  if (filter) {
    params.push(`filter[status]=${filter}`);
  }

  return params.join('&');
};
