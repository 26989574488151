import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Input, Button, Select } from 'antd';
import { Refresh, Search, ArrowDropDown } from '@material-ui/icons';
import { useLazyGetRestaurantListQuery } from 'core/restaurant/RestaurantService';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { setRestaurantList } from 'core/restaurant/RestaurantSlice';

const { Option } = Select;

function AmbassadorFilter({
  intl,
  searchText,
  onStateFilter,
  onTypeFilter,
  onRestaurantFilter,
  onSort,
  onSearch,
  onKeyDownSearch,
  onApplyFilter,
}) {
  const ambassadorStates = [
    { value: 'A', label: intl.formatMessage({ id: 'Active' }) },
    { value: 'I', label: intl.formatMessage({ id: 'Inactive' }) },
  ];

  const ambassadorTypes = [
    { value: 'ALL', label: intl.formatMessage({ id: 'All types' }) },
    { value: 'E', label: intl.formatMessage({ id: 'Ambassadors' }) },
    { value: 'F', label: intl.formatMessage({ id: 'Floating drivers' }) },
  ];

  const ambassadorSorts = [
    { value: 'state desc', label: intl.formatMessage({ id: 'By state' }) },
    { value: 'id desc', label: intl.formatMessage({ id: 'By ID' }) },
    { value: 'type desc', label: intl.formatMessage({ id: 'By type' }) },
    { value: 'lastAccessedAt desc', label: 'By date of last access' },
  ];

  const { list: restaurantList } = useAppSelector(state => state.restaurantReducer);

  const dispatch = useAppDispatch();
  const [getRestaurantList] = useLazyGetRestaurantListQuery();

  useEffect(async () => {
    try {
      const res = await getRestaurantList({}).unwrap();
      dispatch(setRestaurantList({ list: res }));
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleStateFilter = values => {
    onStateFilter(values);
  };

  const handleTypeFilter = value => {
    onTypeFilter(value);
  };

  const handleRestaurantFilter = values => {
    onRestaurantFilter(values);
  };

  const handleSort = value => {
    onSort(value);
  };

  const handleSearch = e => {
    onSearch(e);
  };

  return (
    <div className="ambassador_filter_layout">
      <Select
        className="active_states"
        suffixIcon={<ArrowDropDown />}
        mode="multiple"
        placeholder="estados activos"
        onChange={handleStateFilter}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {ambassadorStates.map(item => (
          <Option value={item.value} key={item.label} style={{ fontFamily: 'Lato', color: '#242c40' }}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Select
        className="type_filter"
        defaultValue="ALL"
        suffixIcon={<ArrowDropDown />}
        placeholder="Todos los tipos"
        onChange={handleTypeFilter}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {ambassadorTypes.map(item => (
          <Option value={item.value} key={item.label} style={{ fontFamily: 'Lato', color: '#242c40' }}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Select
        className="all_restaurants"
        suffixIcon={<ArrowDropDown />}
        mode="multiple"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={v => handleRestaurantFilter(v)}
        placeholder="Todos los restaurantes"
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {restaurantList.rows &&
          restaurantList.rows.map(s => (
            <Option value={s.id} key={s.id} style={{ fontFamily: 'Lato', color: '#242c40' }}>
              {s.description || ''}
            </Option>
          ))}
      </Select>
      <Select
        className="sort_by"
        suffixIcon={<ArrowDropDown />}
        placeholder="Ordernar por"
        onChange={handleSort}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {ambassadorSorts.map(item => (
          <Option value={item.value} key={item.label} style={{ fontFamily: 'Lato', color: '#242c40' }}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Button className="apply_filters_btn" onClick={onApplyFilter}>
        <FormattedMessage id="Apply filters" />
        <Refresh />
      </Button>
      <Input
        suffix={<Search />}
        placeholder="Buscar embajador"
        value={searchText}
        onChange={e => handleSearch(e)}
        onKeyDown={e => onKeyDownSearch(e)}
      />
    </div>
  );
}

AmbassadorFilter.defaultProps = {
  restaurantList: {},
};

export default compose(injectIntl)(AmbassadorFilter);
