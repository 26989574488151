import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { withRouter } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import MarkerClusterer from '@google/markerclusterer';
import Geocode from 'react-geocode';
import Marker from 'components/Basic/Marker';
import ProfileStatistic from 'components/Dashboard/OtsAgents/ProfileStatistic';
import { getLocations } from 'utilities/common';

class AmbassadorMap extends PureComponent {
  constructor(props) {
    super(props);
    this.map = null;
    this.maps = null;
    this.markerClusterer = null;
    this.state = {
      center: {
        lat: getLocations().lat,
        lng: getLocations().lng,
      },
      zoom: 10,
      isFullScreen: false,
    };
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API);
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src =
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js';
    script.async = true;
    document.body.appendChild(script);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ambassadorData !== this.props.ambassadorData || !this.props.ambassadorData.length) {
      this.setClusterOnMap();
    }
  }

  handleGoogleMapChange = value => {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    if (w === value.size.width && h === value.size.height) {
      this.setState({ isFullScreen: true });
    }
  };

  handleGoogleAPILoaded = ({ map, maps }) => {
    this.map = map;
    this.maps = maps;
    this.setClusterOnMap();
  };

  setClusterOnMap = () => {
    const { ambassadorData } = this.props;
    const clusterOptions = {
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      gridSize: 30,
      zoomOnClick: false,
      maxZoom: 10,
    };

    if (this.map && this.maps && ambassadorData.length !== 0) {
      const infoWindow = new this.maps.InfoWindow('');
      const markers = [];
      if (ambassadorData.length === 1 && ambassadorData[0].locationLat && ambassadorData[0].locationLong) {
        this.map.setCenter({
          lat: ambassadorData[0].locationLat,
          lng: ambassadorData[0].locationLong,
        });
      }
      for (let i = 0; i < ambassadorData.length; i += 1) {
        const data = ambassadorData[i];
        if (data.locationLat && data.locationLong) {
          const marker = new this.maps.Marker({
            position: { lat: data.locationLat, lng: data.locationLong },
            icon: '/marker1.png',
          });
          markers.push(marker);
          this.maps.event.addListener(marker, 'click', () => {
            Geocode.fromLatLng(marker.position.lat(), marker.position.lng()).then(
              response => {
                const address = response.results[0].formatted_address;
                infoWindow.setContent(`
                  <div className="ambassador_tooltip">
                    <p>${data.firstName || ''} ${data.lastName || ''} - ${data.eid || ''}</p>
                    <p>Última ubicación registrada:</p>
                    <p>${address}</p>
                  </div>
                `);
                infoWindow.open(this.map, marker);
              },
              error => {
                console.error(error);
              },
            );
          });
        }
      }
      if (this.markerClusterer) {
        this.markerClusterer.clearMarkers();
      }
      this.markerClusterer = new MarkerClusterer(this.map, markers, clusterOptions);
      const styles = this.markerClusterer.getStyles();
      for (let i = 0; i < styles.length; i += 1) {
        styles[i].textColor = 'white';
        styles[i].textSize = 18;
      }
    }
  };

  render() {
    const { center } = this.state;
    const { mapType, statisticInfo } = this.props;
    return (
      <div className="ambassador_map_layout">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_API,
          }}
          defaultCenter={{ lat: getLocations().lat, lng: getLocations().lng }}
          center={center}
          defaultZoom={10}
          zoom={this.state.zoom}
          onGoogleApiLoaded={this.handleGoogleAPILoaded}
          onChange={this.handleGoogleMapChange}
        >
          {this.state.isFullScreen && mapType === 'multi_ambassador' && (
            <ProfileStatistic statisticInfo={statisticInfo} />
          )}
          {/* {ambassadorGeo &&
            ambassadorGeo.map((geo, index) => (
              <Marker lat={geo.lat} lng={geo.long} key={index} />
            ))} */}
        </GoogleMapReact>
      </div>
    );
  }
}

AmbassadorMap.defaultProps = {
  ambassadorData: [],
  statisticInfo: [],
  mapType: 'single_ambassador',
};

export default compose(withRouter)(AmbassadorMap);
