import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, DatePicker, Checkbox, TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { isDateRangeInSelectedDays, isValidDateRange } from './tools/checkValidDates';

type DatesType = {
  isMonday: boolean;
  isTuesday: boolean;
  isWednesday: boolean;
  isThursday: boolean;
  isFriday: boolean;
  isSaturday: boolean;
  isSunday: boolean;
  startDate: Dayjs | null;
  startTime: Dayjs | null;
  endDate: Dayjs | null;
  endTime: Dayjs | null;
  dailyStartTime: Dayjs | null;
  dailyEndTime: Dayjs | null;
  isValid: boolean;
  validationDates: any;
  setIsMonday: (e: boolean) => void;
  setIsTuesday: (e: boolean) => void;
  setIsWednesday: (e: boolean) => void;
  setIsThursday: (e: boolean) => void;
  setIsFriday: (e: boolean) => void;
  setIsSaturday: (e: boolean) => void;
  setIsSunday: (e: boolean) => void;
  onStartDate: (e: any) => void;
  onStartTime: (value: Dayjs | null) => void;
  onEndDate: (e: any) => void;
  onEndTime: (value: Dayjs | null) => void;
  onDailyStartTime: (value: Dayjs | null) => void;
  onDailyEndTime: (value: Dayjs | null) => void;
};

const Dates: React.FC<DatesType> = ({
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isSaturday,
  isSunday,
  startDate,
  startTime,
  endDate,
  endTime,
  dailyStartTime,
  dailyEndTime,
  isValid,
  validationDates,
  setIsMonday,
  setIsTuesday,
  setIsWednesday,
  setIsThursday,
  setIsFriday,
  setIsSaturday,
  setIsSunday,
  onStartDate,
  onStartTime,
  onEndDate,
  onEndTime,
  onDailyStartTime,
  onDailyEndTime,
}) => {
  const [isAllDays, setAllDays] = useState(false);

  const handleDayChange = (dayId: string, newState: boolean) => {
    switch (dayId) {
      case 'All days':
        setAllDays(newState);
        daysOfWeek.forEach(day => day.setState(newState));
        break;
      case 'Monday':
        setIsMonday(newState);
        setAllDays(false);
        break;
      case 'Tuesday':
        setIsTuesday(newState);
        setAllDays(false);
        break;
      case 'Wednesday':
        setIsWednesday(newState);
        setAllDays(false);
        break;
      case 'Thursday':
        setIsThursday(newState);
        setAllDays(false);
        break;
      case 'Friday':
        setIsFriday(newState);
        setAllDays(false);
        break;
      case 'Saturday':
        setIsSaturday(newState);
        setAllDays(false);
        break;
      case 'Sunday':
        setIsSunday(newState);
        setAllDays(false);
        break;
      default:
        break;
    }
  };

  const onDateChange = (date: any, dateString: any, callback: any) => {
    if (date) {
      callback(dayjs(date).set('h', 0).set('m', 0).set('s', 0).set('ms', 0));
    } else {
      callback(null);
    }
  };

  const onTimeChange = (value: Dayjs | null, dateString: any, callback: (value: Dayjs | null) => void) => {
    if (value) {
      callback(dayjs(value).set('s', 0).set('ms', 0));
    } else {
      callback(null);
    }
  };

  const checkField = (value: any, isArray: boolean, dates: boolean) => {
    if (isValid) return;
    if (isArray) {
      const days = daysOfWeek?.filter((_, id) => id > 0);
      const weeks = isDateRangeInSelectedDays(
        startDate,
        endDate,
        days?.map((day: any) => day.state),
      );
      return weeks;
    }
    if (!isArray && dates) {
      if (!value) {
        return 'empty';
      } else {
        return isValidDateRange(startDate, endDate, startTime, endTime, dailyStartTime, dailyEndTime);
      }
    }
  };

  useEffect(() => {
    if (isMonday && isTuesday && isWednesday && isThursday && isFriday && isSaturday && isSunday) {
      handleDayChange('All days', true);
    }
  }, [isMonday, isTuesday, isWednesday, isThursday, isFriday, isSaturday, isSunday]);

  const daysOfWeek = [
    { id: 'All days', state: isAllDays, setState: setAllDays },
    { id: 'Monday', state: isMonday, setState: setIsMonday },
    { id: 'Tuesday', state: isTuesday, setState: setIsTuesday },
    { id: 'Wednesday', state: isWednesday, setState: setIsWednesday },
    { id: 'Thursday', state: isThursday, setState: setIsThursday },
    { id: 'Friday', state: isFriday, setState: setIsFriday },
    { id: 'Saturday', state: isSaturday, setState: setIsSaturday },
    { id: 'Sunday', state: isSunday, setState: setIsSunday },
  ];

  const validMessage = isValidDateRange(startDate, endDate, startTime, endTime, dailyStartTime, dailyEndTime);

  return (
    <>
      <div className="" id="campaign_form_wrapper">
        <div className="basic_info_form" style={{ paddingBottom: typeof validationDates === 'string' ? 14 : '' }}>
          <div className="tabs_input">
            <Form.Item>
              <p className="title_deals">
                <FormattedMessage id="Days" />
              </p>
              {daysOfWeek.map((day, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                  }}
                >
                  <Checkbox
                    indeterminate={day.state}
                    checked={day.state}
                    onChange={e => handleDayChange(day.id, e.target.checked)}
                  />
                  <p className="title_deals" style={{ marginLeft: '12px', marginBottom: '0', marginTop: '0' }}>
                    <FormattedMessage id={day.id} />
                  </p>
                </div>
              ))}
              {typeof checkField(daysOfWeek, true, false) === 'string' && (
                <span className="error_msg">
                  <FormattedMessage id={checkField(daysOfWeek, true, false) as string} />
                </span>
              )}
            </Form.Item>
          </div>
          <div className="two_line">
            <div>
              <p className="title">
                <FormattedMessage id="Start date" />
              </p>
              <DatePicker
                value={startDate ? dayjs(startDate, 'YYYY-MM-DD') : null}
                className={`start_date ${
                  typeof checkField(startDate, false, true) === 'string' ? 'has-error_area' : ''
                }`}
                placeholder="00/00/0000"
                onChange={(date, dateString) => onDateChange(date, dateString, onStartDate)}
              />
              {checkField(startDate, false, true) === 'empty' && (
                <span className="error_msg">
                  <FormattedMessage id="Field is required" />
                </span>
              )}
            </div>
            <div>
              <p className="title">
                <FormattedMessage id="Start time" />
              </p>
              <TimePicker
                className={`start_date ${
                  typeof checkField(startTime, false, true) === 'string' ? 'has-error_area' : ''
                }`}
                format="HH:mm"
                placeholder="00:00"
                value={startTime ? dayjs(startTime, 'HH:mm') : null}
                onChange={(date, dateString) => onTimeChange(date, dateString, onStartTime)}
                minuteStep={10}
              />
              {checkField(startTime, false, true) === 'empty' && (
                <span className="error_msg">
                  <FormattedMessage id="Field is required" />
                </span>
              )}
            </div>
          </div>
          <div className="two_line">
            <div>
              <p className="title">
                <FormattedMessage id="End date" />
              </p>
              <DatePicker
                value={endDate ? dayjs(endDate, 'YYYY-MM-DD') : null}
                className={`start_date ${typeof checkField(endDate, false, true) === 'string' ? 'has-error_area' : ''}`}
                placeholder="00/00/0000"
                onChange={(date, dateString) => onDateChange(date, dateString, onEndDate)}
              />
              {checkField(endDate, false, true) === 'empty' && (
                <span className="error_msg">
                  <FormattedMessage id="Field is required" />
                </span>
              )}
            </div>
            <div>
              <p className="title">
                <FormattedMessage id="End time" />
              </p>
              <TimePicker
                className={`start_date ${typeof checkField(endTime, false, true) === 'string' ? 'has-error_area' : ''}`}
                format="HH:mm"
                placeholder="00:00"
                value={endTime ? dayjs(endTime, 'HH:mm') : null}
                onChange={(date, dateString) => onTimeChange(date, dateString, onEndTime)}
                minuteStep={10}
              />
              {checkField(endTime, false, true) === 'empty' && (
                <span className="error_msg">
                  <FormattedMessage id="Field is required" />
                </span>
              )}
            </div>
          </div>
          <div className="two_line">
            <div>
              <p className="title">
                <FormattedMessage id="Daily Start Time" />
              </p>
              <TimePicker
                className={`start_date ${
                  typeof checkField(dailyStartTime, false, true) === 'string' ? 'has-error_area' : ''
                }`}
                format="HH:mm"
                placeholder="00:00"
                value={dailyStartTime ? dayjs(dailyStartTime, 'HH:mm') : null}
                onChange={(date, dateString) => onTimeChange(date, dateString, onDailyStartTime)}
                minuteStep={10}
              />
              {checkField(dailyStartTime, false, true) === 'empty' && (
                <span className="error_msg">
                  <FormattedMessage id="Field is required" />
                </span>
              )}
            </div>
            <div>
              <p className="title">
                <FormattedMessage id="Daily End Time" />
              </p>
              <TimePicker
                className={`start_date ${
                  typeof checkField(dailyEndTime, false, true) === 'string' ? 'has-error_area' : ''
                }`}
                format="HH:mm"
                placeholder="00:00"
                value={dailyEndTime ? dayjs(dailyEndTime, 'HH:mm') : null}
                onChange={(date, dateString) => onTimeChange(date, dateString, onDailyEndTime)}
                minuteStep={10}
              />
              {checkField(dailyEndTime, false, true) === 'empty' && (
                <span className="error_msg">
                  <FormattedMessage id="Field is required" />
                </span>
              )}
            </div>
          </div>
          {!isValid &&
          typeof validMessage === 'string' &&
          (validMessage?.includes('startEnd') || validMessage?.includes('daily')) ? (
            <span className="error_msg">
              <FormattedMessage id={validMessage} />
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

Dates.propTypes = {};

Dates.defaultProps = {};

export default Dates;
