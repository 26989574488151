import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import moment from 'moment-timezone';
import * as dayjs from 'dayjs';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Input, Switch, Select, DatePicker, TimePicker } from 'antd';
import { Form } from '@ant-design/compatible';
import { ArrowDropDown, DateRange } from '@material-ui/icons';
import toast from 'components/Basic/Toast';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import Loading from 'components/Basic/Loading';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyGetItemsQuery } from 'core/catalog/CatalogService';
import { setProductsData } from 'core/catalog/CatalogSlice';
import {
  useLazyGetChallengesQuery,
  useCreateChallengesMutation,
  useUpdateChallengesMutation,
  useLazyGetPunchesQuery,
  useCreatePunchesMutation,
  useUpdatePunchesMutation,
} from 'core/settings/SettingsService';

const { Option } = Select;

const Loyalty = ({ form }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const [maxiumValues, setMaxiumValues] = useState(new Array(40).fill().map((_, index) => index + 1));

  const [state, setState] = useState({
    challengeInfo: {},
    puncheInfo: {},
    formData: null,
  });

  const { products } = useAppSelector(state => state.catalogReducer);

  const [getItems] = useLazyGetItemsQuery();
  const [getChallenges] = useLazyGetChallengesQuery();
  const [createChallenges] = useCreateChallengesMutation();
  const [updateChallenges] = useUpdateChallengesMutation();
  const [getPunches] = useLazyGetPunchesQuery();
  const [createPunches] = useCreatePunchesMutation();
  const [updatePunches] = useUpdatePunchesMutation();

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    try {
      const products = await getItems({ filter: '&filter=channel eq APP' }).unwrap();
      dispatch(setProductsData({ products: products }));

      const challenges = await getChallenges({}).unwrap();
      if (challenges.rows && challenges.rows.length !== 0) {
        setState(prev => ({ ...prev, challengeInfo: challenges.rows[0] }));
      }

      const punches = await getPunches({}).unwrap();
      if (punches.rows && punches.rows.length !== 0) {
        setState(prev => ({ ...prev, puncheInfo: punches.rows[0] }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSaveSetting = async () => {
    const { challengeInfo } = state;
    const values = form.getFieldsValue([
      'description',
      'start_date',
      'start_time',
      'punchCount',
      'products',
      'end_date',
      'end_time',
      'isNewChallenge',
      'rewardType',
      'free_product',
      'discount_amount',
      'punchMinOrderAmount',
    ]);

    setState(prev => ({ ...prev, formData: values }));

    if (
      !values.description ||
      !values.start_date ||
      !values.start_time ||
      !values.end_date ||
      !values.end_time ||
      (values.rewardType === 'F' && !values.free_product) ||
      (values.rewardType === 'D' && !values.discount_amount) ||
      !values.punchMinOrderAmount
    ) {
      return false;
    }

    const challengeParams = {
      description: values.description,
      items: { products: values.products || [] },
      punchCount: values.punchCount,
      startAt: moment(
        `${moment(values.start_date).format('YYYY-MM-DD').toString()}
          ${moment(values.start_time).format('HH:mm').toString()}`,
      )
        .tz('America/Guatemala')
        .toString(),
      endAt: moment(
        `${moment(values.end_date).format('YYYY-MM-DD').toString()}
          ${moment(values.end_time).format('HH:mm').toString()}`,
      )
        .tz('America/Guatemala')
        .toString(),
    };

    const puncheParams = {
      punchNumber: 5,
      rewardType: values.rewardType,
      rewardParams: {},
      punchMinOrderAmount: values.punchMinOrderAmount,
    };

    if (values.rewardType === 'F') {
      puncheParams.rewardParams.prodId = values.free_product;
    } else {
      puncheParams.rewardParams.discountAmount = values.discount_amount;
    }

    if (values.isNewChallenge || !challengeInfo.id) {
      try {
        await createChallenges({ ...challengeParams }).unwrap();
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        await updateChallenges({
          id: challengeInfo.id,
          ...challengeParams,
        }).unwrap();
      } catch (e) {
        console.log(e);
      }
    }

    try {
      const challenges = await getChallenges({}).unwrap();
      if (challenges.rows && challenges.rows.length !== 0) {
        setState(prev => ({ ...prev, challengeInfo: challenges.rows[0] }));
      }
    } catch (e) {
      console.log(e);
    }

    try {
      const punches = await getPunches({}).unwrap();
      let tempPuncheInfo = {};

      if (punches.rows && punches.rows.length !== 0) {
        setState(prev => ({ ...prev, puncheInfo: punches.rows[0] }));
        tempPuncheInfo = {
          punchNumber: 5,
          rewardType: punches.rows[0].rewardType,
          rewardParams: punches.rows[0].rewardParams,
          punchMinOrderAmount: punches.rows[0].punchMinOrderAmount,
        };
      }

      if (
        (punches.rows && punches.rows.length === 0) ||
        JSON.stringify(tempPuncheInfo) !== JSON.stringify(puncheParams)
      ) {
        try {
          await createPunches({ ...puncheParams }).unwrap();
        } catch (e) {
          console.log(e);
        }
      }
    } catch (e) {
      console.log(e);
    }

    toast.success({
      title: intl.formatMessage({ id: 'Successfully updated!' }),
    });
  };

  const handleChangeProducts = productIds => {
    console.log('productIds', productIds);
  };

  const handleChangeFreeProduct = freeProductId => {
    console.log('freeProductId', freeProductId);
  };

  const { getFieldDecorator } = form;
  const { challengeInfo, puncheInfo, formData } = state;

  return (
    <div className="loyalty_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader type="loyalty" onSaveLoyalty={handleSaveSetting} />
      <Form>
        <div className="challenge-setting-wrapper">
          <div className="title">
            <FormattedMessage id="Current Challenge Settings" />
          </div>
          <div className="form-wrapper">
            <div className="left-wrapper">
              <Form.Item>
                <p className="label">
                  <FormattedMessage id="Enter the description of the challenge" />
                </p>
                {getFieldDecorator('description', {
                  rules: [],
                  initialValue: challengeInfo?.description || '',
                })(<Input placeholder="Ingresa la descripción del reto" />)}
                {formData && !formData?.description && (
                  <p className="error_msg">
                    <FormattedMessage id="This field is required!" />
                  </p>
                )}
              </Form.Item>
              <Form.Item>
                <p className="label">
                  <FormattedMessage id="Start date and time" />
                </p>
                <div className="start-date-time">
                  {getFieldDecorator('start_date', {
                    rules: [],
                    initialValue: challengeInfo?.startAt ? dayjs(challengeInfo.startAt) : '',
                  })(<DatePicker className="start_date" placeholder="Seleccionar fecha" suffixIcon={<DateRange />} />)}
                  {getFieldDecorator('start_time', {
                    rules: [],
                    initialValue: challengeInfo?.startAt ? dayjs(challengeInfo.startAt) : '',
                  })(
                    <TimePicker
                      className="start_time"
                      placeholder="Seleccionar hora"
                      // value={selectedTime ? moment(`${selectedTime}`, 'HH:mm').tz('America/Guatemala') : null}
                      format="HH:mm"
                      // minuteStep={15}
                      hideDisabledOptions
                    />,
                  )}
                </div>
                {formData && (!formData.start_date || !formData.start_time) && (
                  <p className="error_msg">
                    <FormattedMessage id="This field is required!" />
                  </p>
                )}
              </Form.Item>
              <Form.Item>
                <p className="label">
                  <FormattedMessage id="Number of additional punches" />
                </p>
                {getFieldDecorator('punchCount', {
                  rules: [],
                  initialValue: challengeInfo?.punchCount || 0,
                })(
                  <Select
                    className="punche_num"
                    suffixIcon={<ArrowDropDown />}
                    dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                  >
                    <Option value={0}>0 punches - sin reto actual</Option>
                    <Option value={1}>1 punch adicional (total de 2 al cumplir el reto)</Option>
                    <Option value={2}>2 punches adicionales (total de 3 al cumplir el reto)</Option>
                    <Option value={3}>3 punches adicionales (total de 4 al cumplir el reto)</Option>
                    <Option value={4}>4 punches adicionales (total de 5 al cumplir el reto)</Option>
                    <Option value={5}>5 punches adicionales (total de 6 al cumplir el reto)</Option>
                  </Select>,
                )}
              </Form.Item>
            </div>
            <div className="right-wrapper">
              <Form.Item>
                <p className="label">
                  <FormattedMessage id="Product required in purchase" />
                </p>
                {getFieldDecorator('products', {
                  rules: [],
                  initialValue: challengeInfo?.items ? challengeInfo.items.products || [] : [],
                })(
                  <Select
                    showSearch
                    placeholder="Seleccionar producto"
                    optionFilterProp="children"
                    mode="multiple"
                    onChange={v => handleChangeProducts(v)}
                    dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                    filterOption={(input, option) =>
                      option.props.children.props.dangerouslySetInnerHTML.__html
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    suffixIcon={<ArrowDropDown />}
                  >
                    {(products.rows || [])
                      .filter(p => p.state === 'A')
                      .map(item => (
                        <Option value={item.id} key={item.id}>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.name || '',
                            }}
                          />
                        </Option>
                      ))}
                  </Select>,
                )}
              </Form.Item>
              <Form.Item>
                <p className="label">
                  <FormattedMessage id="End date and time" />
                </p>
                <div className="end-date-time">
                  {getFieldDecorator('end_date', {
                    rules: [],
                    initialValue: challengeInfo?.endAt ? dayjs(challengeInfo.endAt) : '',
                  })(<DatePicker className="end_date" placeholder="Seleccionar fecha" suffixIcon={<DateRange />} />)}
                  {getFieldDecorator('end_time', {
                    rules: [],
                    initialValue: challengeInfo?.endAt ? dayjs(challengeInfo.endAt) : '',
                  })(
                    <TimePicker
                      className="end_time"
                      placeholder="Seleccionar hora"
                      // value={selectedTime ? moment(`${selectedTime}`, 'HH:mm').tz('America/Guatemala') : null}
                      format="HH:mm"
                      // minuteStep={15}
                      hideDisabledOptions
                    />,
                  )}
                </div>
                {formData && (!formData.end_date || !formData.end_time) && (
                  <p className="error_msg">
                    <FormattedMessage id="This field is required!" />
                  </p>
                )}
              </Form.Item>
              <Form.Item className="challenge_toggle-wrapper">
                <p className="label">
                  <FormattedMessage id="New challenge for everyone?" />
                </p>
                {getFieldDecorator('isNewChallenge', {
                  rules: [],
                  valuePropName: 'checked',
                  initialValue: !challengeInfo?.id,
                })(<Switch />)}
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="award-setting-wrapper">
          <div className="title">
            <FormattedMessage id="Current award settings" />
          </div>
          <div className="form-wrapper">
            <div className="left-wrapper">
              <Form.Item>
                <p className="label">
                  <FormattedMessage id="Type of award" />
                </p>
                {getFieldDecorator('rewardType', {
                  rules: [],
                  initialValue: puncheInfo?.rewardType || 'F',
                })(
                  <Select
                    className="award_type"
                    suffixIcon={<ArrowDropDown />}
                    dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                  >
                    <Option value="F">Producto gratis</Option>
                    <Option value="D">Descuento en pedido</Option>
                  </Select>,
                )}
              </Form.Item>
            </div>
            <div className="right-wrapper">
              {form.getFieldValue('rewardType') === 'F' && (
                <Form.Item>
                  <p className="label">
                    <FormattedMessage id="Free Product" />
                  </p>
                  {getFieldDecorator('free_product', {
                    rules: [],
                    initialValue: puncheInfo?.rewardParams ? puncheInfo.rewardParams.prodId || '' : '',
                  })(
                    <Select
                      showSearch
                      placeholder="Seleccionar producto"
                      optionFilterProp="children"
                      onChange={v => handleChangeFreeProduct(v)}
                      dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                      filterOption={(input, option) =>
                        option.props.children.props.dangerouslySetInnerHTML.__html
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      suffixIcon={<ArrowDropDown />}
                    >
                      {(products.rows || [])
                        .filter(p => p.state === 'A')
                        .map(item => (
                          <Option value={item.id} key={item.id}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.name || '',
                              }}
                            />
                          </Option>
                        ))}
                    </Select>,
                  )}
                  {formData && !formData.free_product && (
                    <p className="error_msg">
                      <FormattedMessage id="This field is required!" />
                    </p>
                  )}
                </Form.Item>
              )}
              {form.getFieldValue('rewardType') === 'D' && (
                <Form.Item>
                  <p className="label">
                    <FormattedMessage id="Enter the discount amount when ordering" />
                  </p>
                  {getFieldDecorator('discount_amount', {
                    rules: [],
                    initialValue: puncheInfo.rewardParams ? puncheInfo.rewardParams.discountAmount || '' : '',
                  })(<Input type="number" min={0} placeholder="" />)}
                  {formData && !formData.discount_amount && (
                    <p className="error_msg">
                      <FormattedMessage id="This field is required!" />
                    </p>
                  )}
                </Form.Item>
              )}
            </div>
          </div>
        </div>
        <div className="additional-configuration-wrapper">
          <div className="title">
            <FormattedMessage id="Additional configuration" />
          </div>
          <div className="form-wrapper">
            <div className="left-wrapper">
              <Form.Item>
                <p className="label">
                  <FormattedMessage id="Minimum purchase to get a punch" />
                </p>
                {getFieldDecorator('punchMinOrderAmount', {
                  rules: [],
                  initialValue: puncheInfo?.punchMinOrderAmount || '',
                })(<Input type="number" placeholder="" />)}
                {formData && !formData?.punchMinOrderAmount && (
                  <p className="error_msg">
                    <FormattedMessage id="This field is required!" />
                  </p>
                )}
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

Loyalty.defaultProps = {
  form: {},
  products: {},
};

export default compose(injectIntl, Form.create({ name: 'loyalty_form' }))(Loyalty);
