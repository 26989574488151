import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Input, DatePicker, Switch } from 'antd';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { BorderColor, DateRange } from '@material-ui/icons';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import PlacedOrders from 'components/Dashboard/Users/PlacedOrders';
import DeliveryAddresses from 'components/Dashboard/Users/DeliveryAddresses';
import OrderDetailModal from 'components/Dashboard/Orders/OrderDetailModal';
import DeliveryUpdateModal from 'components/Dashboard/Users/DeliveryUpdateModal';
import DropMenu from 'components/Basic/DropMenu';
import toast from 'components/Basic/Toast';
import Loading from 'components/Basic/Loading';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import {
  useLazyGetCustomerQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useLazyGetCustomerTagsQuery,
  useCreateCustomerTagsMutation,
  useUpdateCustomerTagsMutation,
  useDeleteCustomerTagsMutation,
} from 'core/customer/CustomerService';
import { useLazyGetOrderListQuery } from 'core/order/OrderService';
import { useLazyGetMyAddressQuery } from 'core/address/AddressService';
import { setOrdersData } from 'core/order/OrderSlice';
import { setMyAddressData } from 'core/address/AddressSlice';
import { setSelectedUserData } from 'core/customer/CustomerSlice';
import { validationErrorCodes, duplicationErrorCodes } from '../../../utilities/constants';
import passwordComplexity from 'joi-password-complexity';

const stateOptions = [
  { key: 'A', value: 'Active' },
  { key: 'I', value: 'Inactive' },
  { key: 'P', value: 'En seguimiento' },
];
const genderOptions = [
  { key: 'M', value: 'Male' },
  { key: 'F', value: 'Female' },
];

const complexityOptions = {
  min: 10,
  max: 128,
  lowerCase: 1,
  upperCase: 1,
  numeric: 1,
  symbol: 1,
};

const rulesOption = {
  'passwordComplexity.tooShort': complexityOptions.min,
  'passwordComplexity.lowercase': complexityOptions.lowerCase,
  'passwordComplexity.uppercase': complexityOptions.upperCase,
  'passwordComplexity.numeric': complexityOptions.numeric,
  'passwordComplexity.symbol': complexityOptions.symbol,
  'passwordComplexity.tooLong': complexityOptions.max,
};

dayjs.extend(utc);
dayjs.extend(timezone);

const UserEdit = ({ match }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const currentPhone = useRef();

  const { params } = match;

  const [state, setState] = useState({
    orderDetail: null,
    addressDetail: {},
    isOrdersOpenModal: false,
    isAddressOpenModal: false,
    id: params.id,
    gender: 'Género',
    userState: '',
    isVip: false,
    tags: [],
    customerInfo: {},
    isLoading: false,
  });
  const [errorState, setErrorState] = useState([]);
  const [invalidFields, setInvalidFields] = useState([]);

  const [getMyAddress] = useLazyGetMyAddressQuery();
  const [getCustomer] = useLazyGetCustomerQuery();
  const [createCustomer] = useCreateCustomerMutation();
  const [updateCustomer] = useUpdateCustomerMutation();
  const [getOrderList] = useLazyGetOrderListQuery();
  const [getCustomerTags] = useLazyGetCustomerTagsQuery();
  const [createCustomerTags] = useCreateCustomerTagsMutation();
  const [updateCustomerTags] = useUpdateCustomerTagsMutation();
  const [deleteCustomerTags] = useDeleteCustomerTagsMutation();

  const { list: addressList } = useAppSelector(state => state.addressReducer);
  const { rows: orderList } = useAppSelector(state => state.orderReducer.list);

  useEffect(async () => {
    dispatch(setMyAddressData({ list: [] }));

    try {
      const res = await getCustomerTags({}).unwrap();
      setState(prev => ({ ...prev, tags: res.tows || [] }));
    } catch (e) {
      console.log(e);
    }

    const { id } = state;
    if (id) {
      try {
        const customer = await getCustomer({ id }).unwrap();
        dispatch(setSelectedUserData({ selectedUser: customer }));

        const blacklist = customer.tags.find(item => item.tag === 2) || {};
        setState(prev => ({
          ...prev,
          customerInfo: { ...customer, inactive_reason: blacklist.note || '' },
          isVip: !!customer.tags.find(item => item.tag === 1),
        }));
        currentPhone.current = { ...customer, inactive_reason: blacklist.note || '' };

        const orderList = await getOrderList({
          offset: 0,
          limit: 10,
          filter: `&filter=customer eq ${id}`,
        }).unwrap();
        dispatch(setOrdersData({ list: orderList }));

        const myAddress = await getMyAddress({ id }).unwrap();
        dispatch(setMyAddressData({ list: myAddress.rows }));
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  const onSelectOrder = orderDetail => {
    setState(prev => ({ ...prev, orderDetail: orderDetail, isOrdersOpenModal: true }));
  };

  const onSelectAddress = addressDetail => {
    const url = `https://www.google.com/maps?z=12&t=m&q=loc:${addressDetail.lat}+${addressDetail.long}`;
    window.open(url, '_blank');
  };

  const handleChange = (field, value) => {
    const { customerInfo } = state;
    setState(prev => ({ ...prev, customerInfo: { ...customerInfo, [field]: value } }));
  };

  const handlePasswordChange = (field, value) => {
    const { customerInfo } = state;
    setState(prev => ({ ...prev, customerInfo: { ...customerInfo, [field]: value } }));
    if (value) {
      const validatePassword = passwordComplexity(complexityOptions).validate(value);
      const validateErrors = validatePassword?.error?.details?.map(item => item.type);
      if (validateErrors?.length) {
        setErrorState(validateErrors || []);
      } else {
        setErrorState([]);
        if (/(.)\1{2,}/.test(value)) {
          setErrorState(['Identical_characters']);
        }
      }
    } else {
      setErrorState([]);
    }
  };

  const handleFilter = (filterType, key) => {
    if (filterType === 'gender') {
      handleChange('gender', genderOptions[key].key);
      setState(prev => ({ ...prev, gender: genderOptions[key].value }));
    } else if (filterType === 'state') {
      handleChange('state', stateOptions[key].key);
      setState(prev => ({ ...prev, userState: stateOptions[key].value }));
    }
  };

  const handlePhoneChange = value => {
    const { customerInfo } = state;
    setState(prev => ({ ...prev, customerInfo: { ...customerInfo, phone: value } }));
  };

  const onStartDateChange = (date, dateString) => {
    const { customerInfo } = state;
    setState(prev => ({ ...prev, customerInfo: { ...customerInfo, birthday: dateString } }));
  };

  const validateFields = () => {
    const requiredKeys = ['firstName', 'lastName', 'email', 'phone', 'password', 'confirm_password'];
    const keyToLabelMap = {
      confirm_password: 'Confirm Password',
      email: 'Email',
      firstName: 'Name',
      lastName: 'Surnames',
      password: 'Password',
      phone: 'Phone',
    };
    const obj = customerInfo;
    let invalidFields = [];

    for (let key of requiredKeys) {
      if (!obj.hasOwnProperty(key) || (typeof obj[key] === 'string' && obj[key].length === 0)) {
        invalidFields.push(keyToLabelMap[key]);
      }
    }

    return invalidFields;
  };

  const handleCustomer = async () => {
    if (errorState && errorState?.length) {
      toast.error({
        title: intl.formatMessage({
          id: 'Invalid password!',
        }),
      });
      return;
    }
    const { customerInfo, id } = state;
    const phoneNumber = customerInfo.phone?.replace(/-/g, '').replace('(', '').replace(')', '').replace(/ /g, '');
    if (customerInfo.password && customerInfo.password !== customerInfo.confirm_password) {
      toast.error({
        title: intl.formatMessage({
          id: 'Password is not matched with confirm password!',
        }),
      });
    } else if (!id) {
      const valid = validateFields();
      setInvalidFields([]);
      if (valid?.length > 0) {
        setInvalidFields(valid);
        const text = valid.map(item => intl.formatMessage({ id: item })).join(', ');
        toast.error({
          title: intl.formatMessage(
            {
              id: 'Creating customer is failure. Please check fields: {Fields}',
            },
            { Fields: text },
          ),
        });
        return;
      }
      setState(prev => ({ ...prev, isLoading: true }));
      try {
        await createCustomer({ ...customerInfo, phone: '+' + phoneNumber }).unwrap();
        setState(prev => ({ ...prev, isLoading: false }));
        toast.success({
          title: intl.formatMessage({
            id: 'New customer is created successfully!',
          }),
        });
        history.push(`/dashboard/users`);
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
        if (validationErrorCodes.includes(e.data.code) || duplicationErrorCodes.includes(e.data.code)) {
          toast.error({
            title: intl.formatMessage({
              id: e.msg.data.details[0].message,
            }),
          });
        } else {
          toast.error({
            title: intl.formatMessage({
              id: 'Creating customer is failure!',
            }),
          });
        }
      }
    } else {
      setState(prev => ({ ...prev, isLoading: true }));
      try {
        await updateCustomer({
          id,
          ...customerInfo,
          phone: currentPhone.current.phone === phoneNumber ? phoneNumber : '+' + phoneNumber,
        }).unwrap();

        if (state.isVip) {
          if (!customerInfo.tags.find(item => item.tag === 1)) {
            try {
              await createCustomerTags({
                id: customerInfo.id,
                tag: 1,
              }).unwrap();
            } catch (e) {
              console.log(e);
            }
          }
        } else {
          const userTag = customerInfo.tags.find(item => item.tag === 1);
          if (!!userTag) {
            try {
              await deleteCustomerTags({
                id: customerInfo.id,
                tag: userTag.id,
              }).unwrap();
            } catch (e) {
              console.log(e);
            }
          }
        }
        if (customerInfo.state === 'I' || customerInfo.state === 'P') {
          if (!customerInfo.tags.find(item => item.tag === 2)) {
            try {
              await createCustomerTags({
                id: customerInfo.id,
                tag: 2,
                note: customerInfo.inactive_reason.trim(),
              }).unwrap();
            } catch (e) {
              console.log(e);
            }
          } else {
            const userTag = customerInfo.tags.find(item => item.tag === 2);
            try {
              await updateCustomerTags({
                id: customerInfo.id,
                tag: userTag.id,
                note: customerInfo.inactive_reason.trim(),
              }).unwrap();
            } catch (e) {
              console.log(e);
            }
          }
        } else {
          const userTag = customerInfo.tags.find(item => item.tag === 2);
          if (!!userTag) {
            try {
              await deleteCustomerTags({
                id: customerInfo.id,
                tag: userTag.id,
              }).unwrap();
            } catch (e) {
              console.log(e);
            }
          }
        }

        setState(prev => ({ ...prev, isLoading: false }));
        toast.success({
          title: intl.formatMessage({
            id: 'Customer is updated successfully!',
          }),
        });

        const res = await getCustomer({ id }).unwrap();
        const blacklist = res.tags.find(item => item.tag === 2) || {};
        setState(prev => ({
          ...prev,
          customerInfo: { ...res, inactive_reason: blacklist.note || '' },
          isVip: !!res.tags.find(item => item.tag === 1),
        }));
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
        if (validationErrorCodes.includes(e.data.code)) {
          toast.error({
            title: intl.formatMessage({
              id: e.msg.data.details[0].message,
            }),
          });
        } else {
          toast.error({
            title: intl.formatMessage({
              id: 'Updating customer is failure!',
            }),
          });
        }
      }
    }
  };

  const { customerInfo } = state;

  return (
    <div className="user_edit_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader type="user_edit" uid={state.id} onCreateCustomer={handleCustomer} />
      <div className="user_profile_wrapper">
        <div className="profile_header">
          <h3>
            <FormattedMessage id="User Profile" />
          </h3>
          <div className="user_state">
            <p>
              <FormattedMessage id="State" />
            </p>
            <DropMenu
              className={`dropdown ${customerInfo.isDeleted ? 'isDisabled' : ''}`}
              items={stateOptions}
              onMenu={key => handleFilter('state', key)}
              placeholder={state.userState || 'Active'}
              defaultValue={customerInfo.state || 'A'}
              isDisabled={customerInfo.isDeleted}
            />
            {(customerInfo.state === 'I' || customerInfo.state === 'P') && (
              <Input
                placeholder="Reason for inactivation"
                value={customerInfo.inactive_reason || ''}
                onChange={e => handleChange('inactive_reason', e.target.value)}
                disabled={customerInfo.isDeleted}
                style={customerInfo.isDeleted ? { opacity: 0.5 } : {}}
              />
            )}
          </div>
        </div>
        <div className="content_wrapper">
          <Form>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Name" />
              </p>
              <Input
                placeholder="Nombre"
                value={customerInfo.firstName || ''}
                onChange={e => handleChange('firstName', e.target.value)}
                disabled={customerInfo.isDeleted}
                style={
                  customerInfo.isDeleted
                    ? { opacity: 0.5 }
                    : invalidFields.includes('Name') && !customerInfo?.firstName
                    ? { border: '1px solid red' }
                    : {}
                }
              />
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Surnames" />
              </p>
              <Input
                placeholder="Apellidos"
                value={customerInfo.lastName || ''}
                onChange={e => handleChange('lastName', e.target.value)}
                disabled={customerInfo.isDeleted}
                style={
                  customerInfo.isDeleted
                    ? { opacity: 0.5 }
                    : invalidFields.includes('Surnames') && !customerInfo?.lastName
                    ? { border: '1px solid red' }
                    : {}
                }
              />
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Email" />
              </p>
              <Input
                placeholder="Correo electrónico"
                value={customerInfo.email || ''}
                onChange={e => handleChange('email', e.target.value)}
                disabled={customerInfo.isDeleted}
                style={
                  customerInfo.isDeleted
                    ? { opacity: 0.5 }
                    : invalidFields.includes('Email') && !customerInfo?.email
                    ? { border: '1px solid red' }
                    : {}
                }
              />
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Phone" />
              </p>
              <div className="phone_wrapper">
                <ReactPhoneInput
                  country="gt"
                  value={customerInfo.phone || ''}
                  onChange={handlePhoneChange}
                  disabled={customerInfo.isDeleted}
                  style={
                    customerInfo.isDeleted
                      ? { opacity: 0.5 }
                      : invalidFields.includes('Phone') && !customerInfo?.phone
                      ? { border: '1px solid red' }
                      : {}
                  }
                />
              </div>
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Date of birth" />
              </p>
              <DatePicker
                className="start_date"
                placeholder="Fecha de inicio"
                value={customerInfo.birthday ? dayjs(customerInfo.birthday, 'YYYY-MM-DD').tz('America/Guatemala') : ''}
                onChange={onStartDateChange}
                suffixIcon={<DateRange />}
                disabled={customerInfo.isDeleted}
                style={customerInfo.isDeleted ? { opacity: 0.5 } : {}}
              />
            </Form.Item>
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Gender" />
              </p>
              <DropMenu
                items={genderOptions}
                onMenu={key => handleFilter('gender', key)}
                placeHolder={state.gender}
                className={`dropdown ${customerInfo.isDeleted ? 'isDisabled' : ''}`}
                defaultValue={customerInfo.gender || ''}
                isDisabled={customerInfo.isDeleted}
              />
            </Form.Item>
            <Form.Item>
              <div className="password_label">
                <p className="title">
                  <FormattedMessage id="Password" />
                </p>
                <p className="note">
                  <FormattedMessage id="Leave blank to not change" />
                </p>
              </div>
              <Input.Password
                type="password"
                placeholder="Contraseña"
                value={customerInfo.password || ''}
                onChange={e => handlePasswordChange('password', e.target.value)}
                disabled={customerInfo.isDeleted}
                style={
                  customerInfo.isDeleted
                    ? { opacity: 0.5 }
                    : invalidFields.includes('Password') && !customerInfo?.password
                    ? { border: '1px solid red' }
                    : {}
                }
              />
              {errorState?.length > 0 && (
                <div className="error_wrapper">
                  {errorState?.map((item, index) => {
                    return (
                      <div key={index} className="error_message">
                        {intl.formatMessage({ id: `${item}` }, { rule: rulesOption[item] })}
                      </div>
                    );
                  })}
                </div>
              )}
            </Form.Item>
            <Form.Item>
              <div className="password_label">
                <p className="title">
                  <FormattedMessage id="Confirm Password" />
                </p>
                <p className="note">
                  <FormattedMessage id="Leave blank to not change" />
                </p>
              </div>
              <Input
                type="password"
                placeholder="Confirmar contraseña"
                value={customerInfo.confirm_password || ''}
                onChange={e => handleChange('confirm_password', e.target.value)}
                disabled={customerInfo.isDeleted}
                style={
                  customerInfo.isDeleted
                    ? { opacity: 0.5 }
                    : invalidFields.includes('Confirm Password') && !customerInfo?.confirm_password
                    ? { border: '1px solid red' }
                    : {}
                }
              />
            </Form.Item>
            <Form.Item>
              <div className={'user_vip'}>
                <Switch
                  checked={state.isVip}
                  onChange={checked => setState(prev => ({ ...prev, isVip: checked }))}
                  disabled={customerInfo.isDeleted}
                  style={customerInfo.isDeleted ? { opacity: 0.5 } : {}}
                />
                <p className="title_vip">VIP</p>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="user_order_wrapper">
        <div className="orders_wrapper">
          <PlacedOrders orderData={orderList || []} onSelectOrder={onSelectOrder} />
        </div>
        <div className="delivery_wrapper">
          <DeliveryAddresses addressList={addressList || []} onSelectOrder={onSelectAddress} />
        </div>
      </div>
      <OrderDetailModal
        orderDetail={state.orderDetail}
        isOpenModal={state.isOrdersOpenModal}
        onCloseModal={() => setState(prev => ({ ...prev, isOrdersOpenModal: false }))}
      />
      <DeliveryUpdateModal
        addressDetail={state.addressDetail}
        isOpenModal={state.isAddressOpenModal}
        onCloseModal={() => setState(prev => ({ ...prev, isAddressOpenModal: false }))}
      />
    </div>
  );
};

export default UserEdit;
