import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, RestaurantList } from './types';

const initialState: initState = {
  list: {
    offset: 0,
    rows: [],
    totalCount: 0,
  },
};

const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    setRestaurantList(state: initState, action: PayloadAction<{ list: RestaurantList }>) {
      state.list = action.payload.list;
    },
  },
});

export const { setRestaurantList } = restaurantSlice.actions;

export default restaurantSlice.reducer;
