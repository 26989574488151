import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Checkbox } from 'antd';
import { Form } from '@ant-design/compatible';
import ModalDlg from 'components/Basic/ModalDlg';
import LocationSearchInput from 'components/Basic/LocationSearchInput';
import { ArrowForward } from '@material-ui/icons';

function ConfigureRegionModal({
  form,
  title,
  description,
  buttonText,
  configueType,
  availableRestaurants,
  assignedRestaurants,
  isOpenModal,
  onSelectRegion,
  onCloseModal,
}) {
  const { getFieldDecorator } = form;
  const [regionInfo, setRegionInfo] = useState(null);
  const [autoAddress, setAutoAddress] = useState('');
  useEffect(() => {
    setAutoAddress('');
    setRegionInfo(null);
    const restaurantIds = assignedRestaurants.reduce((r, v) => {
      r.push(v.id);
      return r;
    }, []);
    form.setFieldsValue({ restaurant: restaurantIds });
  }, [isOpenModal]);
  const handleGeoInfo = (address, latLng, placeId) => {
    setRegionInfo({
      address,
      latLng,
      placeId,
    });
  };

  return (
    <ModalDlg className="configure_region_modal" visible={isOpenModal} width={668} handleCancel={onCloseModal}>
      <div className="modal_header">
        <div>
          <h3>{title}</h3>
          <p className="description">{description}</p>
        </div>
        <div>
          <Button onClick={() => onSelectRegion(regionInfo, form.getFieldValue('restaurant'))}>
            <span>{buttonText}</span>
            <ArrowForward />
          </Button>
        </div>
      </div>
      <div className="modal_content">
        <Form className="assign_restaurant_form">
          {configueType !== 'assigned_restaurants' && (
            <div className="address_wrapper">
              <p className="title">
                <FormattedMessage id="Location name" />
              </p>
              <p className="description">
                <FormattedMessage id="Location AutoComplete Description" />
              </p>
              <LocationSearchInput
                address={autoAddress}
                onAddressChange={value => setAutoAddress(value)}
                onGetGeoInfo={handleGeoInfo}
              />
            </div>
          )}
          {configueType === 'assigned_restaurants' && (
            <div className="restaurant_list">
              <Form.Item>
                {getFieldDecorator(
                  'restaurant',
                  {},
                )(
                  <Checkbox.Group>
                    {availableRestaurants.map(item => (
                      <Checkbox value={item.id} key={item.id}>
                        <p className="title">{item.description}</p>
                        <p className="description">{item.address}</p>
                      </Checkbox>
                    ))}
                  </Checkbox.Group>,
                )}
              </Form.Item>
            </div>
          )}
        </Form>
      </div>
    </ModalDlg>
  );
}

ConfigureRegionModal.defaultProps = {
  isOpenModal: false,
  title: '',
  description: '',
  buttonText: '',
  configueType: 'provinces',
  form: {},
  availableRestaurants: [],
  assignedRestaurants: [],
};

export default Form.create({ name: 'assign_restaurant_form' })(ConfigureRegionModal);
