import React, { useState, useEffect } from 'react';
import { Img } from 'react-image';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input, Button, Select } from 'antd';
import moment from 'moment-timezone';
import { ArrowDropDown } from '@material-ui/icons';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import AmbassadorMap from 'components/Dashboard/Ambassadors/AmbassadorMap';
import downloadImg from 'assets/img/file_download.svg';
import toast from 'components/Basic/Toast';
import Loading from 'components/Basic/Loading';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import {
  useLazyGetAmbassadorQuery,
  useUpdateAmbassadorMutation,
  useLazyGetAmbassadorSlotsQuery,
  useUpdateAmbassadorSlotsMutation,
} from 'core/ambassador/AmbassadorService';
import { setSelectedAmbassadorData } from 'core/ambassador/AmbassadorSlice';
import { useLazyGetRestaurantListQuery } from 'core/restaurant/RestaurantService';
import { setRestaurantList } from 'core/restaurant/RestaurantSlice';
import { validationErrorCodes } from '../../../utilities/constants';

const { Option } = Select;
const dayNames = ['Domingos', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábados'];

const AmbassadorEdit = ({ match }) => {
  const { params } = match;
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [timeRange, setTimeRange] = useState([]);

  const funcTimeRange = () => {
    const initialTimeRange = [];
    for (let i = 0; i < 24; i += 1) {
      for (let j = 0; j < 60; j += 5) {
        initialTimeRange.push({
          label: i < 10 ? `0${i}:${j < 10 ? `0${j}` : j}` : `${i}:${j < 10 ? `0${j}` : j}`,
          value: i < 10 ? `0${i}:${j < 10 ? `0${j}` : j}` : `${i}:${j < 10 ? `0${j}` : j}`,
        });
      }
    }
    setTimeRange(initialTimeRange);
  };

  const [statuses, setStatuses] = useState([
    { label: 'Disponible', value: 'A' },
    { label: 'No disponible', value: 'I' },
  ]);

  const [state, setState] = useState({
    ambassadorInfo: {},
    id: params.id,
    startAt: '',
    endAt: '',
    ambassadorTimeSlot: [
      {
        state: 'I',
        schedule: [],
      },
      {
        state: 'I',
        schedule: [],
      },
      {
        state: 'I',
        schedule: [],
      },
      {
        state: 'I',
        schedule: [],
      },
      {
        state: 'I',
        schedule: [],
      },
      {
        state: 'I',
        schedule: [],
      },
      {
        state: 'I',
        schedule: [],
      },
    ],
    isLoading: false,
  });

  const { list: restaurantList } = useAppSelector(state => state.restaurantReducer);

  const [getAmbassador] = useLazyGetAmbassadorQuery();
  const [updateAmbassador] = useUpdateAmbassadorMutation();
  const [getAmbassadorSlots] = useLazyGetAmbassadorSlotsQuery();
  const [updateAmbassadorSlots] = useUpdateAmbassadorSlotsMutation();
  const [getRestaurantList] = useLazyGetRestaurantListQuery();

  useEffect(() => {
    funcTimeRange();
    getLoadInitialData();
  }, []);

  const getLoadInitialData = async () => {
    const { ambassadorTimeSlot } = state;
    try {
      setState(prev => ({ ...prev, isLoading: true }));
      const ambassadorInfo = await getAmbassador({ id: params.id }).unwrap();
      dispatch(setSelectedAmbassadorData({ selectedAmbassador: ambassadorInfo }));
      setState(prev => ({ ...prev, ambassadorInfo: ambassadorInfo }));

      const restaurantList = await getRestaurantList({}).unwrap();
      dispatch(setRestaurantList({ list: restaurantList }));

      const startAt = moment().tz('America/Guatemala').startOf('isoWeek').add(-1, 'day').toDate();
      const endAt = moment().tz('America/Guatemala').endOf('isoWeek').add(-1, 'day').toDate();
      setState(prev => ({ ...prev, startAt: startAt, endAt: endAt }));
      const res = await getAmbassadorSlots({
        id: params.id,
        startAt,
        endAt,
      }).unwrap();
      for (let i = 0; i < res.rows.length; i += 1) {
        const days = moment(res.rows[i].startAt).tz('America/Guatemala').day();
        ambassadorTimeSlot[days].state = res.rows[i].state;
        ambassadorTimeSlot[days].schedule.push(res.rows[i]);
      }
      setState(prev => ({ ...prev, ambassadorTimeSlot: [...ambassadorTimeSlot] }));
    } catch (e) {
      console.log(e);
    } finally {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const handleChange = (field, value) => {
    const { ambassadorInfo } = state;
    setState(prev => ({ ...prev, ambassadorInfo: { ...ambassadorInfo, [field]: value } }));
  };

  const handleUpdateAmbassador = async () => {
    const { ambassadorInfo, id, startAt, endAt, ambassadorTimeSlot } = state;
    if (ambassadorInfo.password && ambassadorInfo.password.length !== 0) {
      if (ambassadorInfo.password === ambassadorInfo.confirm_password) {
        setState(prev => ({ ...prev, isLoading: true }));
        try {
          await updateAmbassador({
            id,
            updateInfo: ambassadorInfo,
          }).unwrap();
          dispatch(setSelectedAmbassadorData({ selectedAmbassador: ambassadorInfo }));
          setState(prev => ({ ...prev, isLoading: false }));
          toast.success({
            title: intl.formatMessage({
              id: 'Ambassador password is updated successfully!',
            }),
          });
        } catch (e) {
          setState(prev => ({ ...prev, isLoading: false }));
          if (validationErrorCodes.includes(e.data.code)) {
            toast.error({
              title: intl.formatMessage({
                id: e.msg.data.details[0].message,
              }),
            });
          } else {
            toast.error({
              title: intl.formatMessage({
                id: 'Updating password is failure!',
              }),
            });
          }
        }
      } else {
        toast.error({
          title: intl.formatMessage({
            id: 'Password is not matched with confirm password!',
          }),
        });
      }
    }
    const updateSlots = [];
    for (let i = 0; i < ambassadorTimeSlot.length; i += 1) {
      for (let j = 0; j < ambassadorTimeSlot[i].schedule.length; j += 1) {
        if (
          !ambassadorTimeSlot[i].schedule[j].restaurant.id ||
          !ambassadorTimeSlot[i].schedule[j].startAt ||
          !ambassadorTimeSlot[i].schedule[j].endAt
        ) {
          toast.error({
            title: intl.formatMessage({
              id: 'All the information below is required',
            }),
          });
          return;
        }
        updateSlots.push({
          restaurant: ambassadorTimeSlot[i].schedule[j].restaurant.id,
          ambassador: params.id,
          startAt: ambassadorTimeSlot[i].schedule[j].startAt,
          endAt: ambassadorTimeSlot[i].schedule[j].endAt,
          state: ambassadorTimeSlot[i].state,
        });
      }
    }
    if (updateSlots.length !== 0) {
      setState(prev => ({ ...prev, isLoading: true }));
      try {
        await updateAmbassadorSlots({
          id,
          startAt,
          endAt,
          updateSlots,
        }).unwrap();
        setState(prev => ({ ...prev, isLoading: false }));
        toast.success({
          title: intl.formatMessage({
            id: 'Ambassador time slot is updated successfully!',
          }),
        });
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
        if (validationErrorCodes.includes(e.data.code)) {
          toast.error({
            title: intl.formatMessage({
              id: e.msg.data.details[0].message,
            }),
          });
        } else {
          toast.error({
            title: intl.formatMessage({
              id: 'Updating time slot is failure!',
            }),
          });
        }
      }
    }
  };

  const handleAmbassadorStatus = async (selectedAmbassador, v) => {
    try {
      await updateAmbassador({
        id: selectedAmbassador.id,
        updateInfo: { state: v },
      }).unwrap();
      const res = await getAmbassador({ id: selectedAmbassador.id }).unwrap();
      dispatch(setSelectedAmbassadorData({ selectedAmbassador: res }));
      setState(prev => ({ ...prev, ambassadorInfo: res }));
    } catch (e) {
      console.log(e);
    }
  };

  const handleSlotStateChange = (index, field, value) => {
    const { ambassadorTimeSlot } = state;
    ambassadorTimeSlot[index][field] = value;
    if (ambassadorTimeSlot[index].schedule.length === 0) {
      ambassadorTimeSlot[index].schedule = value === 'A' ? [{ state: 'A', restaurant: {} }] : [];
    }
    setState(prev => ({ ...prev, ambassadorTimeSlot: [...ambassadorTimeSlot] }));
  };

  const handleSlotChange = (index, slotIndex, field, value, dateString) => {
    const { ambassadorTimeSlot } = state;
    if (field !== 'restaurant') {
      ambassadorTimeSlot[index].schedule[slotIndex][field] = moment(`${dateString} ${value}`)
        .tz('America/Guatemala')
        .format();
    } else {
      ambassadorTimeSlot[index].schedule[slotIndex][field].id = value;
    }
    setState(prev => ({ ...prev, ambassadorTimeSlot: [...ambassadorTimeSlot] }));
  };

  const { ambassadorInfo, ambassadorTimeSlot } = state;
  const startAt = moment().tz('America/Guatemala').startOf('isoWeek').add(-1, 'day').toDate();
  const endAt = moment().tz('America/Guatemala').endOf('isoWeek').add(-1, 'day').toDate();
  return (
    <div className="ambassador_edit_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader
        type="ambassador_edit"
        onChangeAmbassadorStatus={handleAmbassadorStatus}
        onUpdateAmbassador={handleUpdateAmbassador}
      />
      <div className="ambassador_edit_wrapper">
        <div className="profile_wrapper">
          <h3>
            <FormattedMessage id="Ambassador Profile" />
          </h3>
          <div className="profile_info">
            {!ambassadorInfo.avatar && <div className="avatar" />}
            {ambassadorInfo.avatar && (
              <Img src={`${process.env.REACT_APP_SERVER}/${ambassadorInfo.avatar}`} alt="avatar" />
            )}
            <div className="info">
              <p className="title">
                <FormattedMessage id="Name" />
              </p>
              <p className="description">{`${ambassadorInfo.firstName || ''} ${ambassadorInfo.lastName || ''}`}</p>
            </div>
            <div className="info">
              <p className="title">
                <FormattedMessage id="Resource type" />
              </p>
              <p className="description">
                {ambassadorInfo.type === 'E'
                  ? intl.formatMessage({ id: 'Ambassador' })
                  : intl.formatMessage({
                      id: 'Floating Ambassador',
                    })}
              </p>
            </div>
            <div className="info">
              <p className="title">
                <FormattedMessage id="Assigned phone" />
              </p>
              <p className="description">{ambassadorInfo.phone || ambassadorInfo.phone2 || ''}</p>
            </div>
            <div className="info">
              <p className="title">
                <FormattedMessage id="Motorcycle" />
              </p>
              <p className="description">{ambassadorInfo.vihicle || ''}</p>
            </div>
            <div className="info">
              <p className="title">
                <FormattedMessage id="Last access to the system" />
              </p>
              {ambassadorInfo && (
                <p className="description">
                  {ambassadorInfo.lastAccessedAt
                    ? `${moment(ambassadorInfo.lastAccessedAt).tz('America/Guatemala').format('LL')}
                      @${moment(ambassadorInfo.lastAccessedAt).tz('America/Guatemala').format('HH:mm')}`
                    : ''}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="profile_setting_wrapper">
          <div className="week_assignment">
            <div className="week_assignment_header">
              <h3>
                <FormattedMessage id="Assignment of the week" />
              </h3>
              <Button className="flex align-center file_download_btn">
                <img src={downloadImg} alt="file download" />
              </Button>
            </div>
            {ambassadorTimeSlot.map((item, index1) => (
              <div className="card" key={index1}>
                <p className="title">{dayNames[index1]}</p>
                <div className="schedule_per_day">
                  <Select
                    defaultValue={item.state}
                    value={item.state}
                    onChange={v => handleSlotStateChange(index1, 'state', v)}
                    dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                  >
                    {statuses.map(s => (
                      <Option value={s.value}>{s.label}</Option>
                    ))}
                  </Select>
                  <div className="divider" />
                  <div className="time_slot_wrapper">
                    {item.schedule &&
                      item.schedule.length !== 0 &&
                      item.state !== 'I' &&
                      item.schedule.map((schedule, index2) => (
                        <div className="time_slot" key={index2}>
                          <Select
                            defaultValue={
                              schedule.startAt ? moment(schedule.startAt).tz('America/Guatemala').format('HH:mm') : ''
                            }
                            dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                            onChange={v =>
                              handleSlotChange(
                                index1,
                                index2,
                                'startAt',
                                v,
                                moment(
                                  schedule.startAt
                                    ? schedule.startAt
                                    : moment(startAt)
                                        .add(index1 % 7, 'day')
                                        .toDate(),
                                )
                                  .tz('America/Guatemala')
                                  .format('YYYY-MM-DD'),
                              )
                            }
                            suffixIcon={<ArrowDropDown />}
                          >
                            {timeRange.map(s => (
                              <Option value={s.value}>{s.label}</Option>
                            ))}
                          </Select>
                          <Select
                            defaultValue={
                              schedule.endAt ? moment(schedule.endAt).tz('America/Guatemala').format('HH:mm') : ''
                            }
                            dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                            onChange={v =>
                              handleSlotChange(
                                index1,
                                index2,
                                'endAt',
                                v,
                                moment(
                                  schedule.endAt
                                    ? schedule.endAt
                                    : moment(startAt)
                                        .add(index1 % 7, 'day')
                                        .toDate(),
                                )
                                  .tz('America/Guatemala')
                                  .format('YYYY-MM-DD'),
                              )
                            }
                            suffixIcon={<ArrowDropDown />}
                          >
                            {timeRange.map(s => (
                              <Option value={s.value}>{s.label}</Option>
                            ))}
                          </Select>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            optionFilterProp="children"
                            defaultValue={schedule.restaurant ? schedule.restaurant.id : ''}
                            onChange={v => handleSlotChange(index1, index2, 'restaurant', v, '')}
                            dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            suffixIcon={<ArrowDropDown />}
                          >
                            {restaurantList.rows.map(s => (
                              <Option value={s.id}>{s.description || ''}</Option>
                            ))}
                          </Select>
                        </div>
                      ))}
                    {item.schedule.length === 0 && (
                      <p className="description">
                        <FormattedMessage id="Resource not available" />
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="other_detail">
            <h3>
              <FormattedMessage id="Other details" />
            </h3>
            <AmbassadorMap ambassadorData={[{ ...ambassadorInfo }]} />
            <div className="other_settings">
              <p className="title change_password_title">
                <FormattedMessage id="Other settings" />
              </p>
              <Form.Item>
                <div className="password_label">
                  <p className="title">
                    <FormattedMessage id="Password" />
                  </p>
                  <p className="note">
                    <FormattedMessage id="Leave blank to not change" />
                  </p>
                </div>
                <Input
                  type="password"
                  placeholder="Contraseña"
                  value={ambassadorInfo.password || ''}
                  onChange={e => handleChange('password', e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <div className="password_label">
                  <p className="title">
                    <FormattedMessage id="Confirm Password" />
                  </p>
                  <p className="note">
                    <FormattedMessage id="Leave blank to not change" />
                  </p>
                </div>
                <Input
                  type="password"
                  placeholder="Confirmar contraseña"
                  value={ambassadorInfo.confirm_password || ''}
                  onChange={e => handleChange('confirm_password', e.target.value)}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmbassadorEdit;
