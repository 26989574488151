import React, { useState, useEffect } from 'react';
import { Tooltip, Button, Pagination } from 'antd';
import { Delete, Edit, ArrowDownwardOutlined, ArrowUpwardOutlined } from '@material-ui/icons';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import SegmentsViewModal from 'components/Dashboard/Segments/SegmentsViewModal';
import DeleteConfirmModal from 'components/Basic/DeleteConfirmModal';
import toast from 'components/Basic/Toast';
import { useAppDispatch } from 'utilities/redux';
import { useLazyGetSegmentsListQuery, useDeleteSegmentsMutation } from 'core/segments/SegmentsService';
import { setSegmentsListData } from 'core/segments/SegmentsSlice';

type Segment = {
  id: number;
  name: string;
  type: string;
  customersCount: number;
  createdAt: string;
  updatedAt: string;
};

type SegmentsTableType = {
  segmentsData: Array<Segment>;
  onChangePage: (current: number, size: number) => void;
  total: number;
  setCurrent: (e: number) => void;
  current: number;
  pageSize: number;
  setPageSize: (e: number) => void;
};

const SegmentsTable: React.FC<SegmentsTableType> = ({
  segmentsData,
  onChangePage,
  total,
  setCurrent,
  current,
  pageSize,
  setPageSize,
}) => {
  const [isShowDetailModal, setShowDetailModal] = useState(false);
  const [segmentsInfo, setSegmentsInfo] = useState<Segment | null>(null);
  const [isRemoveModal, setIsRemoveModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [getSegmentsList] = useLazyGetSegmentsListQuery();
  const [deleteSegments] = useDeleteSegmentsMutation();

  useEffect(() => {
    onChangePage(1, 10);
  }, []);

  const handleChangePage = (page: number, size: number) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage(page, size);
  };

  const handleShowSizeChange = (c: number, s: number) => {
    handleChangePage(1, s);
  };

  const handleDeleteButtonClick = async () => {
    setIsLoading(true);
    setIsRemoveModal(false);
    const queryString = buildQueryString({});

    try {
      await deleteSegments({ id: segmentsInfo!.id }).unwrap();
      const res = await getSegmentsList({ queryString }).unwrap();
      dispatch(setSegmentsListData(res));

      toast.success({
        title: intl.formatMessage({
          id: 'Segment is deleted successfully!',
        }),
      });
    } catch (error) {
      toast.error({
        title: intl.formatMessage({
          id: 'Segment is deleted failure!',
        }),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="segments_table_layout">
      <div className="table_header">
        <div className="id">
          <FormattedMessage id="ID" />
        </div>
        <div className="name">
          <FormattedMessage id="Name" />
        </div>
        <div className="offers">
          <FormattedMessage id="Num of users" />
        </div>
        <div className="actions">
          <FormattedMessage id="Actions" />
        </div>
      </div>
      <div>
        <div className="table_content">
          {Array.isArray(segmentsData) &&
            segmentsData.map((item, index) => (
              <div className={`table_item`} key={index}>
                <div className="id">
                  <p>{item.id}</p>
                </div>
                <div className="name">
                  <p>{item.name}</p>
                </div>
                <div className="offers">
                  <p>{item.customersCount}</p>
                </div>
                <div className="actions">
                  <Tooltip placement="top" title={intl.formatMessage({ id: 'Edit segment' })}>
                    <Button
                      className="edit_btn"
                      onClick={() => {
                        setSegmentsInfo(item);
                        setShowDetailModal(true);
                      }}
                    >
                      <Edit />
                    </Button>
                  </Tooltip>
                  <Tooltip placement="top" title={intl.formatMessage({ id: 'Delete segment' })}>
                    <Button
                      className="edit_btn"
                      onClick={() => {
                        setSegmentsInfo(item);
                        setIsRemoveModal(true);
                      }}
                    >
                      <Delete />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            ))}
        </div>
        <div className="pagination_wrapper">
          <Pagination
            showSizeChanger
            defaultCurrent={1}
            defaultPageSize={10}
            current={current}
            pageSize={pageSize}
            pageSizeOptions={['5', '10', '20', '30']}
            total={total}
            onChange={handleChangePage}
            onShowSizeChange={handleShowSizeChange}
          />
          <div className="total">Resultados: {total}</div>
        </div>
      </div>
      <SegmentsViewModal
        isOpenModal={isShowDetailModal}
        onCloseModal={() => setShowDetailModal(false)}
        segmentsInfo={segmentsInfo}
        setPageSize={setPageSize}
      />
      <DeleteConfirmModal
        isOpenModal={isRemoveModal}
        title={intl.formatMessage({ id: 'Do you want to remove the segment?' })}
        subtitle={intl.formatMessage({
          id: 'This action cannot be undone. Continue deleting the segment?',
        })}
        onCancel={() => setIsRemoveModal(false)}
        onOk={() => handleDeleteButtonClick()}
      />
    </div>
  );
};

SegmentsTable.defaultProps = {
  segmentsData: [],
  total: 0,
};

export default SegmentsTable;

const buildQueryString = ({
  search = '',
  page = 1,
  size = 10,
  count = true,
  sort_by_id = 'desc',
  sort_by_name = '',
}) => {
  const params = [`search=${encodeURIComponent(search)}`, `page=${page}`, `size=${size}`, `count=${count}`];

  if (sort_by_id) {
    params.push(`sort[id]=${sort_by_id}`);
  }

  if (sort_by_name) {
    params.push(`sort[name]=${sort_by_name}`);
  }

  return params.join('&');
};
