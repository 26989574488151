import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { compose } from 'react-recompose';
import { Input, Button, Select } from 'antd';
import { Refresh, ArrowDropDown, Search } from '@material-ui/icons';

const { Option } = Select;

function RestaurantFilter({ intl, searchText, onStateFilter, onSort, onSearch, onKeyDownSearch, onApplyFilter }) {
  const restaurantStates = [
    { value: 'A', label: intl.formatMessage({ id: 'Active' }) },
    { value: 'I', label: intl.formatMessage({ id: 'Inactive' }) },
  ];
  const restaurantSorts = [
    { value: 'state desc', label: intl.formatMessage({ id: 'By state' }) },
    { value: 'rid desc', label: intl.formatMessage({ id: 'By ID' }) },
    {
      value: 'addresses desc',
      label: intl.formatMessage({ id: 'By number of assigned addresses' }),
    },
    {
      value: 'processingOrders desc',
      label: intl.formatMessage({ id: 'By orders now' }),
    },
    {
      value: 'deliveryAreas desc',
      label: intl.formatMessage({ id: 'By total distribution areas' }),
    },
  ];

  const handleStateFilter = values => {
    onStateFilter(values);
  };

  const handleSort = value => {
    onSort(value);
  };

  const handleSearch = e => {
    onSearch(e);
  };

  return (
    <div className="restaurant_filter_layout">
      <Select
        className="active_states"
        suffixIcon={<ArrowDropDown />}
        mode="multiple"
        placeholder="estados activos"
        onChange={handleStateFilter}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {restaurantStates.map(item => (
          <Option value={item.value} key={item.label}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Select
        className="sort_by"
        suffixIcon={<ArrowDropDown />}
        placeholder="Ordernar por"
        onChange={handleSort}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {restaurantSorts.map(item => (
          <Option value={item.value} key={item.label}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Button className="apply_filters_btn" onClick={onApplyFilter}>
        <FormattedMessage id="Apply filters" />
        <Refresh />
      </Button>
      <Input
        suffix={<Search />}
        placeholder="Buscar restaurante específico"
        value={searchText}
        onChange={e => handleSearch(e)}
        onKeyDown={e => onKeyDownSearch(e)}
      />
    </div>
  );
}

export default compose(injectIntl)(RestaurantFilter);
