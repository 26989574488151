export const checkValidProducts = (strings: string[]) => {
  if (!Array.isArray(strings)) {
    return false;
  }

  const products = strings?.filter(item => item?.length > 0);

  const isValid = products?.every(str => {
    if (
      str.length > 0 &&
      str !== '0' &&
      !str.startsWith('0') &&
      !str.startsWith('|') &&
      !str.endsWith('|') &&
      str.split('|').every(segment => segment.length <= 15)
    ) {
      const pipeIndex = str.indexOf('|');
      if (pipeIndex !== -1 && pipeIndex < str.length - 1) {
        const afterPipe = str[pipeIndex + 1];
        if (afterPipe !== '0' && afterPipe !== '|') {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  });

  return isValid;
};
