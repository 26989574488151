import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Tooltip, Button, Pagination } from 'antd';
import { Apps, Map, Edit } from '@material-ui/icons';

function RestaurantsTable({ intl, restaurantData, total, onChangePage, isModal, action }) {
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    onChangePage(0, 10);
  }, []);

  const handleChangePage = (page, size) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage((page - 1) * size, size);
  };

  const handleShowSizeChange = (c, s) => {
    handleChangePage(1, s);
  };
  const handleClickEdit = id => {
    action(id);
  };
  return (
    <div className="restaurants_table_layout">
      <div className="table_header">
        <div className="restaurant">
          <Apps />
          <FormattedMessage id="Restaurant" />
        </div>
        <div className="location">
          <FormattedMessage id="Location" />
        </div>
        <div className="addresses_attended">
          <FormattedMessage id="Addresses attended" />
        </div>
        <div className="orders_now">
          <FormattedMessage id="Orders now" />
        </div>
        <div className="cast_details">
          <FormattedMessage id="Cast details" />
        </div>
        <div className="actions">
          <FormattedMessage id="Actions" />
        </div>
      </div>
      <div className="table_content">
        {restaurantData &&
          restaurantData.map((item, index) => (
            <div className={`table_item state_${item.state}`} key={index}>
              <div className="restaurant">
                <p className="code">{item.id}</p>
                <p className="name">{item.description}</p>
                <p className="phone">{item.phone || ''}</p>
              </div>
              <div className="location">
                <p className="address1">{item.address}</p>
                {item.zones &&
                  item.zones.map(z => (
                    <p className="address2" key={z.id}>
                      {z.description || ''}
                    </p>
                  ))}
              </div>
              <div className="addresses_attended">
                <p className="count">{item.addresses || 0}</p>
                <p className="customers">{`de ${item.customers || 0} clientes`}</p>
              </div>
              <div className="orders_now">
                <p className="process">{`${item.processingOrders || 0} procesando`}</p>
                <p className="route">{`${item.assignedOrders || 0} en ruta`}</p>
                <p className="door">{`${item.atTheDoorOrders || 0} en puerta`}</p>
              </div>
              <div className="cast_details">
                <p className="areas">{`${item.deliveryAreas || 0} zonas de reparto`}</p>
                <p className="ambassadors">
                  {`${item.onlineAmbassadors || 0} ${intl.formatMessage({
                    id: 'online ambassadors',
                  })}`}
                </p>
              </div>
              <div className="actions">
                {isModal ? (
                  <Button style={{ marginRight: 10 }} className="map_btn">
                    <Map />
                  </Button>
                ) : (
                  <Tooltip placement="top" title={intl.formatMessage({ id: 'Manage delivery areas' })}>
                    <NavLink to={`/dashboard/restaurants/${item.id}/deliveryzone`}>
                      <Button className="map_btn">
                        <Map />
                      </Button>
                    </NavLink>
                  </Tooltip>
                )}
                {isModal ? (
                  <Button className="edit_btn" onClick={() => handleClickEdit(item.id)}>
                    <Edit />
                  </Button>
                ) : (
                  <Tooltip placement="top" title={intl.formatMessage({ id: 'Edit Restaurant' })}>
                    <NavLink to={`/dashboard/restaurants/${item.id}`}>
                      <Button className="edit_btn">
                        <Edit />
                      </Button>
                    </NavLink>
                  </Tooltip>
                )}
              </div>
            </div>
          ))}
      </div>
      <div className="pagination_wrapper">
        <Pagination
          showSizeChanger
          defaultCurrent={1}
          defaultPageSize={10}
          current={current}
          pageSize={pageSize}
          pageSizeOptions={['5', '10', '20', '30']}
          total={total}
          onChange={handleChangePage}
          onShowSizeChange={handleShowSizeChange}
        />
        <div className="total">Resultados: {total}</div>
      </div>
    </div>
  );
}

RestaurantsTable.defaultProps = {
  restaurantData: [],
  total: '0',
};

export default compose(injectIntl)(RestaurantsTable);
