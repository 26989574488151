import { api } from '..';
import { DistributionList, SectorList } from './types';

export const distributionApi = api.enhanceEndpoints({ addTagTypes: ['Distribution'] }).injectEndpoints({
  endpoints: build => ({
    getDistributionList: build.query<
      DistributionList,
      { offset: number; limit: number; or: boolean; filter: string; sort: string; restaurant: string }
    >({
      query: ({ offset, limit, or, filter, sort, restaurant }) => ({
        url: `v0/distributions?offset=${offset || 0}&limit=${limit || 10}${
          restaurant ? `&restaurant=${restaurant}` : ''
        }${or ? `&or=${or}` : ''}${filter || ''}${sort ? `&sort=${sort}` : '&sort=id asc'}`,
        method: 'GET',
      }),
    }),
    getSectorList: build.query<
      SectorList,
      { offset: number; limit: number; or: boolean; filter: string; sort: string }
    >({
      query: ({ offset, limit, or, filter, sort }) => ({
        url: `v0/sectors?details=true&offset=${offset || 0}&limit=${limit || 5}${or ? `&or=${or}` : ''}${filter || ''}${
          sort ? `&sort=${sort}` : '&sort=createdAt desc'
        }`,
        method: 'GET',
      }),
    }),
    getSector: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `v0/sectors/${id}?details=true`,
        method: 'GET',
      }),
    }),
    createSector: build.mutation<any, any>({
      query: body => ({
        url: `v0/sectors`,
        method: 'POST',
        body,
      }),
    }),
    updateSector: build.mutation<any, any>({
      query: body => ({
        url: `v0/sectors/${body.id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteSector: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `v0/sectors/${id}`,
        method: 'DELETE',
      }),
    }),
    getSectorStatistics: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `v0/distributions/sectorStatistics/${id}`,
        method: 'GET',
      }),
    }),
    getOrderStatistics: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `v0/distributions/orderStatistics/${id}`,
        method: 'GET',
      }),
    }),
    getAmbassadorStatistics: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `v0/distributions/ambassadorStatistics/${id}`,
        method: 'GET',
      }),
    }),
    getOrderDetailStatistics: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `v0/distributions/orderDetailStatistics/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyGetDistributionListQuery,
  useLazyGetSectorListQuery,
  useLazyGetSectorQuery,
  useCreateSectorMutation,
  useUpdateSectorMutation,
  useDeleteSectorMutation,
  useLazyGetSectorStatisticsQuery,
  useLazyGetOrderStatisticsQuery,
  useLazyGetAmbassadorStatisticsQuery,
  useLazyGetOrderDetailStatisticsQuery,
} = distributionApi;
