import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import TrafficMonitorFilter from 'components/Dashboard/Distribution/TrafficMonitorFilter';
import TrafficMonitorTable from 'components/Dashboard/Distribution/TrafficMonitorTable';
import Loading from 'components/Basic/Loading';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyGetRestaurantListQuery } from 'core/restaurant/RestaurantService';
import { setRestaurantList } from 'core/restaurant/RestaurantSlice';

const TrafficMonitor = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [state, setState] = useState({
    searchText: '',
    offset: 0,
    limit: 10,
    filterQuery: '',
    searchFilterQuery: '',
    sort: '',
    isLoading: false,
  });

  const { list } = useAppSelector(state => state.restaurantReducer);

  const [getRestaurantList] = useLazyGetRestaurantListQuery();

  const handleChangePage = async (offset, limit, filterQuery, sort) => {
    setState(prev => ({ ...prev, offset: offset, limit: limit, isLoading: true }));
    try {
      const res = await getRestaurantList({
        offset,
        limit,
        or: false,
        filter: filterQuery || state.filterQuery + state.searchFilterQuery,
        sort: `${sort || state.sort}`,
      }).unwrap();
      dispatch(setRestaurantList({ list: res }));
      setState(prev => ({ ...prev, isLoading: false }));
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const handleSectorFilter = values => {
    if (values.length !== 0) {
      let filterQuery = '&filter=sector in';
      values.map(v => {
        filterQuery += ` ${v}`;
        return filterQuery;
      });
      setState(prev => ({ ...prev, filterQuery: filterQuery }));
    } else {
      setState(prev => ({ ...prev, filterQuery: '' }));
    }
  };

  const handleSort = value => {
    setState(prev => ({ ...prev, sort: value }));
  };

  const handleSearch = e => {
    setState(prev => ({ ...prev, searchText: e.target.value }));
  };

  const handleKeyDownSearch = async e => {
    let searchFilterQuery = '';
    if (e.keyCode === 13) {
      const { offset, limit, filterQuery, sort, searchText } = state;
      if (searchText) {
        if (!isNaN(searchText, 10)) {
          searchFilterQuery += `&filter=or.rid eq ${searchText}`;
        }
        searchFilterQuery += `&filter=or.description iLike %25${searchText}%25&filter=or.address iLike %25${searchText}%25`;
      } else {
        searchFilterQuery = '';
      }
      setState(prev => ({ ...prev, searchFilterQuery: searchFilterQuery, isLoading: true }));
      try {
        const res = await getRestaurantList({
          offset: 0,
          limit: 10,
          or: false,
          filter: filterQuery + searchFilterQuery,
          sort: `${sort}`,
        }).unwrap();
        dispatch(setRestaurantList({ list: res }));
        setState(prev => ({ ...prev, isLoading: false }));
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    }
  };

  const handleApplyFilter = async () => {
    const { filterQuery, sort, searchFilterQuery } = state;
    setState(prev => ({ ...prev, isLoading: true }));
    try {
      const res = await getRestaurantList({
        offset: 0,
        limit: 10,
        or: false,
        filter: filterQuery + searchFilterQuery,
        sort: `${sort}`,
      }).unwrap();
      dispatch(setRestaurantList({ list: res }));
      setState(prev => ({ ...prev, isLoading: false }));
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  return (
    <div className="distribution_traffic_list_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader type="distribution_traffic_list" />
      <div className="distribution_traffic_filter_wrapper">
        <TrafficMonitorFilter
          searchText={state.searchText}
          onSectorFilter={handleSectorFilter}
          onSort={handleSort}
          onSearch={handleSearch}
          onKeyDownSearch={handleKeyDownSearch}
          onApplyFilter={handleApplyFilter}
        />
        <TrafficMonitorTable restaurantData={list.rows || []} onChangePage={handleChangePage} total={list.totalCount} />
      </div>
    </div>
  );
};

export default TrafficMonitor;
