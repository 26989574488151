import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DealPreviewType, DealType, DealsConfigurationType, initState } from './types';

const initialState: initState = {
  configuration: {
    id: 0,
    ordering: '',
    codePrefix: '',
    createAt: new Date(),
    updateAt: new Date(),
  },
  dealsPreview: {
    deals: [],
    totalCount: null,
  },
};

const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    setConfiguration(state: initState, action: PayloadAction<DealsConfigurationType>) {
      state.configuration = action.payload;
    },
    setDealsPreview(state: initState, action: PayloadAction<DealPreviewType>) {
      state.dealsPreview = action.payload;
    },
  },
});

export const { setConfiguration, setDealsPreview } = dealsSlice.actions;

export default dealsSlice.reducer;
