import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const Marker = ({ title, description }) => {
  return (
    <div className="map_marker">
      <div className="oval" data-tip data-for="map" />
      {title && (
        <ReactTooltip id="map" effect="solid">
          {title && <p className="title">{title}</p>}
          {description && <p className="address">{description}</p>}
        </ReactTooltip>
      )}
    </div>
  );
};

Marker.defaultProps = {
  title: '',
  description: '',
};
export default Marker;
