import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Tooltip, Button, Pagination } from 'antd';
import { Edit } from '@material-ui/icons';
import DistributionAnalyticsModal from 'components/Dashboard/Distribution/DistributionAnalyticsModal';
import pieChartImg from 'assets/img/pie-chart.svg';

const sectorState = {
  H: 'Alta demanda',
  M: 'Media demanda',
  L: 'Poca demanda',
};

function DistributionTable({ intl, distributionData, total, onChangePage }) {
  const [sectorInfo, setSectorInfo] = useState({});
  const [isShowAnalyticModal, setIsShowAnalyticModal] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    onChangePage(0, 10);
  }, []);

  const handleChangePage = (page, size) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage((page - 1) * size, size);
  };

  const handleShowSizeChange = (c, s) => {
    handleChangePage(1, s);
  };

  const getStateFromTime = time => {
    if (time < 30) {
      return 'L';
    }
    if (time >= 30 && time <= 45) {
      return 'M';
    }
    return 'H';
  };

  return (
    <div className="distribution_table_layout">
      <div className="table_header">
        <div className="sector_id">
          <FormattedMessage id="Sector ID" />
        </div>
        <div className="name">
          <FormattedMessage id="Sector name" />
        </div>
        <div className="restaurants">
          <FormattedMessage id="Restaurants" />
        </div>
        <div className="ambassadors">
          <FormattedMessage id="Ambassadors" />
        </div>
        <div className="order_now">
          <FormattedMessage id="Order now" />
        </div>
        <div className="sector_state">
          <FormattedMessage id="State of the sector" />
        </div>
        <div className="actions">
          <FormattedMessage id="Actions" />
        </div>
      </div>
      <div className="table_content">
        {distributionData &&
          distributionData.map((item, index) => (
            <div className={`table_item table_${getStateFromTime(+item.deliveryTime)}`} key={index}>
              <div className="sector_id">
                <p className="title">{item.id || ''}</p>
              </div>
              <div className="name">
                <p className="title">{`${item.name || ''}`}</p>
              </div>
              <div className="restaurants">
                <p className="title">{`${item.restaurants} restaurantes asignados`}</p>
              </div>
              <div className="ambassadors">
                <p className="title">
                  <span>{`${item.ambassadors || 0} embajadores / `}</span>
                  <span className="active-count">{`${item.activeAmbassadors || 0} activos ahora`}</span>
                </p>
              </div>
              <div className="order_now">
                <p className="title">{item.nowOrders || 0}</p>
              </div>
              <div className="sector_state">
                <p className={`title demand_${getStateFromTime(+item.deliveryTime)}`}>
                  {sectorState[getStateFromTime(+item.deliveryTime)]}
                </p>
              </div>
              <div className="actions">
                <Tooltip placement="top" title={intl.formatMessage({ id: 'Real time analysis' })}>
                  <Button
                    className="analytics_btn"
                    onClick={() => {
                      setSectorInfo(item);
                      setIsShowAnalyticModal(true);
                    }}
                  >
                    <img src={pieChartImg} alt="analytics" />
                  </Button>
                </Tooltip>
                <Tooltip placement="top" title={intl.formatMessage({ id: 'Edit sector' })}>
                  <NavLink to={`/dashboard/distribution/${item.id}`}>
                    <Button className="edit_btn">
                      <Edit />
                    </Button>
                  </NavLink>
                </Tooltip>
              </div>
            </div>
          ))}
      </div>
      <div className="pagination_wrapper">
        <Pagination
          showSizeChanger
          defaultCurrent={1}
          defaultPageSize={10}
          current={current}
          pageSize={pageSize}
          pageSizeOptions={['5', '10', '20', '30']}
          total={total}
          onChange={handleChangePage}
          onShowSizeChange={handleShowSizeChange}
        />
      </div>
      <DistributionAnalyticsModal
        isOpenModal={isShowAnalyticModal}
        state={getStateFromTime(+sectorInfo.deliveryTime)}
        onCloseModal={() => setIsShowAnalyticModal(false)}
        data={sectorInfo}
      />
    </div>
  );
}

DistributionTable.defaultProps = {
  distributionData: [],
  total: 0,
};

export default compose(injectIntl)(DistributionTable);
