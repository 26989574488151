import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import PromotionList from 'containers/Dashboard/Promotions/PromotionList';

class Promotions extends PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path="/dashboard/promotions" component={PromotionList} />
      </Switch>
    );
  }
}

export default Promotions;
