import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Input } from 'antd';
import RestaurantSchedule from 'components/Dashboard/Restaurants/RestaurantSchedule';
import DropMenu from 'components/Basic/DropMenu';

const Minutes = new Array(36).fill().map((_, index) => (index + 1) * 5);
const ReserveDays = new Array(46).fill().map((_, index) => index);
const Ambassadors = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const AmbassadorFactors = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];

class RestaurantSettings extends PureComponent {
  handleFilterSelect = (field, key) => {
    if (field === 'deliveryTime1') {
      this.props.onChange(field, Minutes[key]);
    } else if (field === 'reserveDays') {
      this.props.onChange(field, ReserveDays[key]);
    } else if (field === 'ambassadorCapacity') {
      this.props.onChange(field, Ambassadors[key]);
    } else {
      this.props.onChange(field, AmbassadorFactors[key]);
    }
  };

  handleChangeSARcode = (field, value) => {
    this.props.onChange(field, value);
  };

  render() {
    const { restaurantInfo } = this.props;
    const { params } = this.props.match;
    return (
      <div className="restaurant_settings_layout">
        <div className="delivery_setting">
          <h3>
            <FormattedMessage id="Lots of time and availability at times" />
          </h3>
          <p className="description">
            <FormattedMessage id="Delivery Setting Description" />
          </p>
          <div className="divider" />
          <div className="minutes">
            <p className="description">
              <FormattedMessage id="Minutes Description" />
            </p>
            <div>
              <DropMenu
                items={Minutes}
                onMenu={key => this.handleFilterSelect('deliveryTime1', key)}
                defaultValue={restaurantInfo.deliveryTime1 || 15}
              />
              <p className="title">
                <FormattedMessage id="Minutes" />
              </p>
            </div>
          </div>
          <div className="divider" />
          <div className="days">
            <p className="description">
              <FormattedMessage id="Days Description" />
            </p>
            <div>
              <DropMenu
                items={ReserveDays}
                onMenu={key => this.handleFilterSelect('reserveDays', key)}
                defaultValue={restaurantInfo.reserveDays || 0}
              />
              <p className="title">
                <FormattedMessage id="Days" />
              </p>
            </div>
          </div>
          <div className="divider" />
          <div className="ambassadors">
            <p className="description">
              <FormattedMessage id="Ambassador Capacity Description" />
            </p>
            <div>
              <DropMenu
                items={Ambassadors}
                onMenu={key => this.handleFilterSelect('ambassadorCapacity', key)}
                defaultValue={restaurantInfo.ambassadorCapacity || 1}
              />
              <p className="title">
                <FormattedMessage id="Ambassadors" />
              </p>
            </div>
          </div>
          <div className="divider" />
          <div className="factor">
            <p className="description">
              <FormattedMessage id="Ambassador Factor Description" />
            </p>
            <div>
              <DropMenu
                items={AmbassadorFactors}
                onMenu={key => this.handleFilterSelect('ambassadorFactor', key)}
                defaultValue={restaurantInfo.ambassadorFactor || 1}
              />
              <p className="title">
                <FormattedMessage id="By ambassador" />
              </p>
            </div>
          </div>
          <div className="divider" />
          <div className="sar_code">
            <p className="description">
              <FormattedMessage id="Unique Restaurant Id" />
            </p>
            <div>
              <Input
                value={restaurantInfo.id || ''}
                readOnly={params.id}
                onChange={e => this.handleChangeSARcode('id', e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="restaurant_schedule">
          <RestaurantSchedule
            restaurantInfo={restaurantInfo}
            onChange={(field, value) => this.props.onChange(field, value)}
          />
        </div>
      </div>
    );
  }
}

RestaurantSettings.defaultProps = {
  restaurantInfo: {},
  match: {},
};
export default compose(withRouter)(RestaurantSettings);
