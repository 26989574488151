import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DeliverySchedule from 'components/Dashboard/Restaurants/DeliverySchedule';

class DeliverySetting extends PureComponent {
  render() {
    return (
      <div className="delivery_settings_layout">
        <div className="attend_schedule">
          <h3>
            <FormattedMessage id="Attention schedule" />
          </h3>
          <p className="description">
            <FormattedMessage id="Delivery Area Description" />
          </p>
        </div>
        <div className="delivery_schedule">
          <DeliverySchedule
            deliveryInfo={this.props.deliveryInfo}
            onChange={(field, value) => this.props.onChange(field, value)}
          />
        </div>
      </div>
    );
  }
}

DeliverySetting.defaultProps = {
  deliveryInfo: {},
};
export default DeliverySetting;
