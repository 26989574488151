import { api } from '..';
import { DealPreviewType, DealsConfigurationType, UpdateConfigurationType, StatisticsDeals } from './types';

type FieldValueObject = {
  field: string;
  value: string;
};

type GetDealPreviewArgsType = {
  sort: FieldValueObject;
  filter: FieldValueObject;
};

export const dealsApi = api.enhanceEndpoints({ addTagTypes: ['Deals'] }).injectEndpoints({
  endpoints: build => ({
    getConfiguration: build.query<DealsConfigurationType, Partial<GetDealPreviewArgsType>>({
      query: () => ({
        url: '/v1/deals/configuration',
        method: 'GET',
      }),
    }),
    createConfiguration: build.mutation<DealsConfigurationType, Partial<UpdateConfigurationType>>({
      query: (body: UpdateConfigurationType) => ({
        url: '/v1/deals/configuration',
        method: 'POST',
        body,
      }),
    }),
    updateConfiguration: build.mutation<any, any>({
      query: body => ({
        url: `/v1/deals/configuration`,
        method: 'PUT',
        body,
      }),
    }),
    getDealsPreview: build.query<DealPreviewType, { query: string }>({
      query: ({ query }) => ({
        url: `v1/deals/preview?${query}`,
        method: 'GET',
      }),
    }),
    getDealStatistic: build.query<StatisticsDeals, { id: number }>({
      query: ({ id }) => ({
        url: `/v1/deals/${id}/stats`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyGetConfigurationQuery,
  useCreateConfigurationMutation,
  useUpdateConfigurationMutation,
  useLazyGetDealsPreviewQuery,
  useLazyGetDealStatisticQuery,
} = dealsApi;
