import React, { useState } from 'react';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import AgentFilter from 'components/Dashboard/OtsAgents/AgentFilter';
import AgentTable from 'components/Dashboard/OtsAgents/AgentTable';
import Loading from 'components/Basic/Loading';
import { useLazyGetAgentListQuery } from 'core/agent/AgentService';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { setAgentData } from 'core/agent/AgentSlice';

const OtsAgentList = () => {
  const [state, setState] = useState({
    searchText: '',
    offset: 0,
    limit: 10,
    filterQuery: '',
    stateFilter: '',
    availableFilter: '',
    searchFilterQuery: '',
    sort: '',
    isLoading: false,
  });
  const dispatch = useAppDispatch();

  const [getAgentList] = useLazyGetAgentListQuery();

  const { list } = useAppSelector(state => state.agentReducer);

  const handleChangePage = async (offset, limit, filterQuery, sort) => {
    setState(prev => ({ ...prev, offset: offset, limit: limit, isLoading: true }));
    try {
      const res = await getAgentList({
        offset,
        limit,
        or: false,
        filter: filterQuery || state.filterQuery + state.searchFilterQuery,
        sort: sort || state.sort,
      }).unwrap();
      dispatch(setAgentData({ list: res }));
      setState(prev => ({ ...prev, isLoading: false }));
    } catch (err) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const handleStateFilter = values => {
    if (values.length !== 0) {
      let filterQuery = '&filter=state in';
      values.map(v => {
        filterQuery += ` ${v}`;
        return filterQuery;
      });
      setState(prev => ({ ...prev, stateFilter: filterQuery }));
    } else {
      setState(prev => ({ ...prev, stateFilter: '' }));
    }
  };

  const handleAvailableFilter = value => {
    if (value) {
      setState(prev => ({ ...prev, availableFilter: `&filter=online eq ${value === 1}` }));
    } else {
      setState(prev => ({ ...prev, availableFilter: '' }));
    }
  };

  const handleSort = value => {
    setState(prev => ({ ...prev, sort: value }));
  };

  const handleSearch = e => {
    setState(prev => ({ ...prev, searchText: e.target.value }));
  };

  const handleKeyDownSearch = async e => {
    let searchFilterQuery = '';
    if (e.keyCode === 13) {
      const { offset, limit, filterQuery, sort, searchText } = state;
      if (searchText) {
        if (!isNaN(searchText, 10) && searchText < 100000000) {
          searchFilterQuery += `&filter=or.id eq ${searchText}`;
        }
        searchFilterQuery += `&filter=or.fullName iLike %25${searchText}%25&filter=or.eid iLike %25${searchText}%25&filter=ex.or.phone iLike %25${searchText}%25`;
      } else {
        searchFilterQuery = '';
      }
      setState(prev => ({ ...prev, searchFilterQuery: searchFilterQuery, isLoading: true }));
      try {
        const res = await getAgentList({
          offset: 0,
          limit: 10,
          or: false,
          filter: filterQuery + searchFilterQuery,
          sort: sort,
        }).unwrap();
        dispatch(setAgentData({ list: res }));
        setState(prev => ({ ...prev, isLoading: false }));
      } catch (err) {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    }
  };

  const handleApplyFilter = async () => {
    const { stateFilter, availableFilter, sort, searchFilterQuery } = state;
    setState(prev => ({ ...prev, filterQuery: stateFilter + availableFilter, isLoading: true }));
    try {
      const res = await getAgentList({
        offset: 0,
        limit: 10,
        or: false,
        filter: stateFilter + availableFilter + searchFilterQuery,
        sort: sort,
      }).unwrap();
      dispatch(setAgentData({ list: res }));
      setState(prev => ({ ...prev, isLoading: false }));
    } catch (err) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  return (
    <div className="ots_agent_list_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader type="ots_agent" />
      <div className="ots_filter_wrapper">
        <AgentFilter
          searchText={state.searchText}
          onStateFilter={handleStateFilter}
          onAvailableFilter={handleAvailableFilter}
          onSort={handleSort}
          onSearch={handleSearch}
          onKeyDownSearch={handleKeyDownSearch}
          onApplyFilter={handleApplyFilter}
        />
        <AgentTable agentData={list.rows || []} onChangePage={handleChangePage} total={list.totalCount} />
      </div>
    </div>
  );
};

export default OtsAgentList;
