import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Input } from 'antd';
import { Search, ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import toast from 'components/Basic/Toast';
import Loading from 'components/Basic/Loading';
import { useAppDispatch } from 'utilities/redux';
import {
  useLazyGetSectorQuery,
  useCreateSectorMutation,
  useUpdateSectorMutation,
  useDeleteSectorMutation,
} from 'core/distribution/DistributionService';
import { useLazyGetRestaurantListQuery } from 'core/restaurant/RestaurantService';
import { setRestaurantList } from 'core/restaurant/RestaurantSlice';

const DistributionEdit = ({ match }) => {
  const { params } = match;
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [state, setState] = useState({
    restaurantList: [],
    sectorRestaurants: [],
    restaurantSearchText: '',
    sectorRestaurantSearchText: '',
    sectorName: '',
    sectorInfo: {},
    isLoading: false,
  });

  const [getRestaurantList] = useLazyGetRestaurantListQuery();
  const [getSector] = useLazyGetSectorQuery();
  const [createSector] = useCreateSectorMutation();
  const [updateSector] = useUpdateSectorMutation();
  const [deleteSector] = useDeleteSectorMutation();

  useEffect(() => {
    getLoadInitialData();
  }, []);

  const getLoadInitialData = async () => {
    setState(prev => ({ ...prev, isLoading: true }));
    if (params.id) {
      try {
        const res = await getSector({ id: params.id }).unwrap();
        setState(prev => ({
          ...prev,
          sectorInfo: res,
          sectorName: res.name || '',
          sectorRestaurants: res.restaurants,
        }));
      } catch (e) {
        console.log(e);
      }
    }
    try {
      const restaurantList = await getRestaurantList({ filter: '&filter=sector eq null' }).unwrap();
      dispatch(setRestaurantList({ list: restaurantList }));
      setState(prev => ({ ...prev, restaurantList: restaurantList.rows, isLoading: false }));
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const handleSelectRestaurant = item => {
    const { restaurantList } = state;

    const sectorRestaurants = [];
    sectorRestaurants.push(item);

    setState(prev => ({
      ...prev,
      sectorRestaurants: sectorRestaurants,
      restaurantList: restaurantList.filter(r => r.id !== item.id),
    }));
  };

  const handleSelectSectorRestaurant = item => {
    const { restaurantList, sectorRestaurants } = state;

    const newRestaurantList = restaurantList;
    newRestaurantList.push(item);

    setState(prev => ({
      ...prev,
      restaurantList: newRestaurantList,
      sectorRestaurants: sectorRestaurants.filter(r => r.id !== item.id),
    }));
  };

  const handleSectorName = e => {
    setState(prev => ({ ...prev, sectorName: e.target.value }));
  };

  const handleSearchRestaurant = e => {
    setState(prev => ({ ...prev, restaurantSearchText: e.target.value }));
  };

  const handleSearchSectorRestaurant = e => {
    setState(prev => ({ ...prev, sectorRestaurantSearchText: e.target.value }));
  };

  const handleCreateSector = async () => {
    const { sectorName, sectorRestaurants } = state;
    const restaurants = [];

    for (let i = 0; i < sectorRestaurants.length; i += 1) {
      restaurants.push(sectorRestaurants[i].id);
    }

    if (sectorName.replace(/\s/g, '').length === 0) {
      toast.error({
        title: intl.formatMessage({
          id: 'The name of the sector is required',
        }),
      });
    } else {
      try {
        await createSector({
          name: sectorName,
          restaurants,
        }).unwrap();

        toast.success({
          title: intl.formatMessage({
            id: 'Sector is created successfully',
          }),
        });
        history.push('/dashboard/distribution');
      } catch (e) {
        toast.error({
          title: intl.formatMessage({
            id: 'Create sector is a failure',
          }),
        });
      }
    }
  };

  const handleUpdateSector = async () => {
    const { sectorName, sectorRestaurants, sectorInfo } = state;
    const restaurants = [];

    for (let i = 0; i < sectorRestaurants.length; i += 1) {
      restaurants.push(sectorRestaurants[i].id || sectorRestaurants[i].rid);
    }

    if (sectorName.replace(/\s/g, '').length === 0) {
      toast.error({
        title: intl.formatMessage({
          id: 'The name of the sector is required',
        }),
      });
    } else {
      try {
        await updateSector({
          id: sectorInfo.id,
          name: sectorName,
          restaurants,
        }).unwrap();

        toast.success({
          title: intl.formatMessage({
            id: 'Sector is updated successfully',
          }),
        });
      } catch (e) {
        toast.error({
          title: intl.formatMessage({
            id: 'Update sector is a failure',
          }),
        });
      }
    }
  };

  const handleDeleteSector = async () => {
    const { sectorInfo } = state;
    try {
      await deleteSector({ id: sectorInfo.id }).unwrap();
      toast.success({
        title: intl.formatMessage({
          id: 'Sector is deleted successfully',
        }),
      });
    } catch (e) {
      toast.error({
        title: intl.formatMessage({
          id: 'Delete sector is a failure',
        }),
      });
    }
  };

  const { restaurantList, sectorRestaurants, restaurantSearchText, sectorRestaurantSearchText, sectorInfo } = state;
  return (
    <div className="distribution_edit_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader
        type="distribution_edit"
        sectorInfo={sectorInfo}
        onCreateSector={handleCreateSector}
        onUpdateSector={handleUpdateSector}
        onDeleteSector={handleDeleteSector}
      />
      <div className="sector_wrapper">
        <div className="sector_name_wrapper">
          <div className="card_header">
            <p>
              <FormattedMessage id="Sector schemes" />
            </p>
          </div>
          <div className="card_list">
            <p className="description">
              <FormattedMessage id="Sector description" />
            </p>
            <div className="sector_input_wrapper">
              <p className="sector_label">
                <FormattedMessage id="Sector name" />
              </p>
              <Input value={state.sectorName} placeholder="Nombre del sector" onChange={handleSectorName} />
            </div>
          </div>
        </div>
        <div className="availabe_restaurant">
          <div className="card_header">
            <p>
              <FormattedMessage id="List of available restaurants" />
            </p>
          </div>
          <div className="card_list">
            <Input
              suffix={<Search />}
              value={state.restaurantSearchText}
              placeholder="Buscar restaurante"
              onChange={handleSearchRestaurant}
            />
            <div className="list scrollbar">
              {restaurantList &&
                restaurantList
                  .filter(r => r.description.toLowerCase().indexOf(restaurantSearchText.toLowerCase()) !== -1)
                  .map((item, index) => (
                    <div className="flex align-center just-between item" key={index}>
                      <div className="restaurant-info">
                        <p className="title">{item.description}</p>
                        {item.address && <p className="description">{item.address}</p>}
                      </div>
                      <ArrowForwardIos className="pointer" onClick={() => handleSelectRestaurant(item)} />
                    </div>
                  ))}
            </div>
          </div>
        </div>
        {sectorRestaurants.length === 0 && (
          <div className="restaurant_info empty_wrapper">
            <p className="empty_text">
              <FormattedMessage id="Select a restaurant to add it to the sector" />
            </p>
          </div>
        )}
        {sectorRestaurants.length !== 0 && (
          <div className="availabe_restaurant">
            <div className="card_header">
              <p>{`${sectorRestaurants.length} restaurantes seleccionados`}</p>
            </div>
            <div className="card_list">
              <Input
                suffix={<Search />}
                value={state.sectorRestaurantSearchText}
                placeholder="Buscar restaurante"
                onChange={handleSearchSectorRestaurant}
              />
              <div className="list scrollbar">
                {sectorRestaurants &&
                  sectorRestaurants
                    .filter(r => r.description.toLowerCase().indexOf(sectorRestaurantSearchText.toLowerCase()) !== -1)
                    .map((item, index) => (
                      <div className="flex align-center just-between item" key={index}>
                        <ArrowBackIos className="pointer" onClick={() => handleSelectSectorRestaurant(item)} />
                        <div className="sector-restaurant-info">
                          <p className="title">{item.description}</p>
                          {item.address && <p className="description">{item.address}</p>}
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

DistributionEdit.defaultProps = {
  match: {},
  history: {},
};

export default DistributionEdit;
