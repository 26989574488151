import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Select, Button } from 'antd';
import { Add, Edit, ArrowDropDown } from '@material-ui/icons';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyGetTabsListQuery } from 'core/tabs/TabsService';
import { setTabsListData } from 'core/tabs/TabsSlice';
import { useLazyGetCategoriesListQuery } from 'core/categories/CategoriesService';
import { setCategoriesListData } from 'core/categories/CategoriesSlice';
import TabsViewModal from '../Tabs/TabsViewModal';
import CategoriesViewModal from '../Categories/CategoriesViewModal';

type TabCategoryType = {
  isValid: boolean;
  selectedTabs: number[];
  selectedCategory: number;
  setSelectedTabs: (values: number[]) => void;
  setSelectedCategory: (values: number) => void;
};

const TabCategory: React.FC<TabCategoryType> = ({
  isValid,
  selectedTabs,
  selectedCategory,
  setSelectedTabs,
  setSelectedCategory,
}) => {
  const [isShowTabDetailModal, setShowTabDetailModal] = useState(false);
  const [isShowCatDetailModal, setShowCatDetailModal] = useState(false);

  const [tabInfo, setTabInfo] = useState<any>();
  const [categoryInfo, setCategoryInfo] = useState<any>();

  const dispatch = useAppDispatch();
  const [getTabsList] = useLazyGetTabsListQuery();
  const [getCategoriesList] = useLazyGetCategoriesListQuery();

  const { list: tabs } = useAppSelector(state => state.tabsReducer);
  const { list } = useAppSelector(state => state.categoriesReducer);

  useEffect(() => {
    getInitialData();
  }, [isShowCatDetailModal, isShowTabDetailModal, list, tabs]);

  const getInitialData = async () => {
    try {
      const queryString = buildQueryString({});

      const categoriesList = await getCategoriesList({ queryString }).unwrap();
      const tabsList = await getTabsList({ search: '' }).unwrap();

      dispatch(setCategoriesListData({ list: categoriesList }));
      dispatch(setTabsListData({ list: tabsList }));
    } catch (error) {
      console.error('Error fetching campaign list:', error);
    }
  };

  let tabArr = tabs?.tabs?.map((item: any) => ({
    id: item.id,
    name: item.name,
  }));

  let catArr = list?.categories?.map((item: any) => ({
    id: item.id,
    name: item.name,
  }));

  const checkField = (value: any) => {
    if (isValid) return;
    if (value) {
      if (value?.length === 0) return true;
      const valid = value.length > 0 || value;
      return !valid;
    } else {
      return true;
    }
  };

  const closeModalTab = () => {
    setTabInfo(null);
  };

  const openModalTab = (tabIds: number[]) => {
    if (tabIds?.length === 1) {
      const item = tabs?.tabs?.find((item: any) => item.id === tabIds[0]);
      setTabInfo(item);
    }
    setShowTabDetailModal(true);
  };

  const openModalCategory = (id: number) => {
    if (id) {
      const item = list?.categories?.find((item: any) => item.id === id);
      setCategoryInfo(item);
    }
    setShowCatDetailModal(true);
  };

  const closeModalCatategory = () => {
    catArr = list?.categories?.map((item: any) => {
      if (item.id === categoryInfo) {
        const _item = {
          id: categoryInfo.id,
          name: categoryInfo.name,
        };
        return _item;
      } else {
        return item;
      }
    });
    setSelectedCategory(categoryInfo?.id);
    setCategoryInfo(null);
  };

  return (
    <>
      <div className="" id="campaign_form_wrapper">
        <div className="basic_info_form">
          <div className="tabs_input">
            <div style={{ display: 'flex', gap: '4px' }}>
              <Form.Item>
                <p className="title_deals">
                  <FormattedMessage id="Tab" />
                </p>
                <Select
                  mode="multiple"
                  className={`select_modal ${checkField(selectedTabs) && 'has-error'}`}
                  suffixIcon={<ArrowDropDown />}
                  onChange={setSelectedTabs}
                  id="campaign_status_filter"
                  value={selectedTabs}
                  dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                  style={{ width: '250px' }}
                >
                  {tabArr?.map(item => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <div className="upload-btn">
                <Button
                  onClick={() => openModalTab(selectedTabs)}
                  style={selectedTabs?.length > 1 ? { pointerEvents: 'none', opacity: '.5' } : undefined}
                >
                  {selectedTabs?.length >= 1 ? <Edit /> : <Add />}
                  {selectedTabs?.length >= 1 ? (
                    <span>
                      <FormattedMessage id="Edit" />
                    </span>
                  ) : (
                    <span>
                      <FormattedMessage id="Create" />
                    </span>
                  )}
                </Button>
              </div>
            </div>
            {checkField(selectedTabs) && (
              <div style={{ marginTop: -20, paddingBottom: 14 }}>
                <span className="error_msg">
                  <FormattedMessage id="Field is required" />
                </span>
              </div>
            )}
            <div style={{ display: 'flex', gap: '4px' }}>
              <Form.Item>
                <p className="title_deals">
                  <FormattedMessage id="Category" />
                </p>
                <Select
                  className={`select_modal ${checkField(selectedCategory) && 'has-error'}`}
                  suffixIcon={<ArrowDropDown />}
                  onChange={setSelectedCategory}
                  id="campaign_status_filter"
                  value={selectedCategory}
                  dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                  style={{ width: '250px' }}
                >
                  {catArr?.map(item => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <div className="upload-btn">
                <Button onClick={() => openModalCategory(selectedCategory)}>
                  {selectedCategory ? <Edit /> : <Add />}
                  {selectedCategory ? (
                    <span>
                      <FormattedMessage id="Edit" />
                    </span>
                  ) : (
                    <span>
                      <FormattedMessage id="Create" />
                    </span>
                  )}
                </Button>
              </div>
            </div>
            {checkField(selectedCategory) && (
              <div style={{ marginTop: -20, marginBottom: 14 }}>
                <span className="error_msg">
                  <FormattedMessage id="Field is required" />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <TabTableModal isOpenModal={isShowTabDetailModal} onCloseModal={() => setShowTabDetailModal(false)} /> */}
      <TabsViewModal
        isOpenModal={isShowTabDetailModal}
        onCloseModal={() => {
          setShowTabDetailModal(false);
          closeModalTab();
        }}
        tabsInfo={tabInfo}
      />
      {/* <CategoriesTableModal isOpenModal={isShowCatDetailModal} onCloseModal={() => setShowCatDetailModal(false)} /> */}
      <CategoriesViewModal
        isOpenModal={isShowCatDetailModal}
        onCloseModal={() => {
          setShowCatDetailModal(false);
          closeModalCatategory();
        }}
        categoriesInfo={categoryInfo}
        setPageSize={() => {}}
      />
    </>
  );
};

export default TabCategory;

const buildQueryString = ({
  search = '',
  page = 1,
  size = 10,
  count = true,
  sort_by_name = '',
  sort_by_id = 'desc',
}) => {
  const params = [`search=${encodeURIComponent(search)}`, `page=${page}`, `count=${count}`];

  if (sort_by_id) {
    params.push(`sort[id]=${sort_by_id}`);
  }

  if (sort_by_name) {
    params.push(`sort[name]=${sort_by_name}`);
  }

  return params.join('&');
};
