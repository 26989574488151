import { api } from '..';
import { RestaurantList, DealRestaurantsResponse } from './types';

export const restaurantApi = api.enhanceEndpoints({ addTagTypes: ['Restaurant'] }).injectEndpoints({
  endpoints: build => ({
    getRestaurantList: build.query<
      RestaurantList,
      { details?: boolean; offset?: number; limit?: number; or?: boolean; filter?: string; sort?: string }
    >({
      query: ({ details, offset, limit, or, filter, sort }) => ({
        url: `v0/restaurants?${details ? 'details' : 'zones'}=true${offset !== undefined ? `&offset=${offset}` : ''}${
          limit !== undefined ? `&limit=${limit}` : ''
        }${or ? `&or=${or}` : ''}${filter || ''}${sort ? `&sort=${sort}` : '&sort=createdAt desc'}`,
        method: 'GET',
      }),
    }),
    getRestaurant: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `v0/restaurants/${id}?details=true`,
        method: 'GET',
      }),
    }),
    createRestaurant: build.mutation<void, any>({
      query: body => ({
        url: `v0/restaurants`,
        method: 'POST',
        body,
      }),
    }),
    updateRestaurant: build.mutation<void, any>({
      query: body => ({
        url: `v0/restaurants/${body.id}`,
        method: 'PUT',
        body: body.restaurantInfo,
      }),
    }),
    deleteRestaurant: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `v0/restaurants/${id}`,
        method: 'DELETE',
      }),
    }),
    getDeliveryAreaList: build.query<
      any,
      { offset: number; limit: number; or: boolean; filter: string; sort: string; id: number }
    >({
      query: ({ offset, limit, or, filter, sort, id }) => ({
        url: `v0/restaurants/${id}/deliveryAreas?details=true&offset=${offset || 0}&limit=${limit || 5}${
          or ? `&or=${or}` : ''
        }${filter || ''}${sort ? `&sort=${sort}` : '&sort=createdAt desc'}`,
        method: 'GET',
      }),
    }),
    getDeliveryArea: build.query<any, { rid: number; id: number }>({
      query: ({ rid, id }) => ({
        url: `v0/restaurants/${rid}/deliveryAreas/${id}?details=true`,
        method: 'GET',
      }),
    }),
    getDeliveryAreas: build.query<any, any>({
      query: () => ({
        url: `v0/restaurants/deliveryAreas`,
        method: 'GET',
      }),
    }),
    createDeliveryArea: build.mutation<void, any>({
      query: body => ({
        url: `v0/restaurants/${body.deliveryInfo.restaurant}/deliveryAreas`,
        method: 'POST',
        body: body.deliveryInfo,
      }),
    }),
    updateDeliveryArea: build.mutation<void, any>({
      query: body => ({
        url: `v0/restaurants/${body.deliveryInfo.restaurant}/deliveryAreas/${body.id}`,
        method: 'PUT',
        body: body.deliveryInfo,
      }),
    }),
    deleteDeliveryArea: build.mutation<void, { rid: number; id: number }>({
      query: ({ rid, id }) => ({
        url: `v0/restaurants/${rid}/deliveryAreas/${id}`,
        method: 'DELETE',
      }),
    }),
    getAssignedAmbassadors: build.query<any, { id: number; byAssignedOrders: string }>({
      query: ({ id, byAssignedOrders }) => ({
        url: `v0/restaurants/${id}/ambassadors?${byAssignedOrders || ''}`,
        method: 'GET',
      }),
    }),
    getNeighbourAmbassadors: build.query<any, { id: number; ambassadorType: string; byAssignedOrders: string }>({
      query: ({ id, ambassadorType, byAssignedOrders }) => ({
        url: `v0/restaurants/${id}/neighbours/ambassadors?ambassadorType=${ambassadorType}${byAssignedOrders || ''}`,
        method: 'GET',
      }),
    }),
    getSectorAmbassadors: build.query<any, { id: number; byAssignedOrders: string }>({
      query: ({ id, byAssignedOrders }) => ({
        url: `v0/restaurants/${id}/sector/ambassadors?${byAssignedOrders || ''}`,
        method: 'GET',
      }),
    }),
    getRestaurantsForDeals: build.query<DealRestaurantsResponse, any>({
      query: () => ({
        url: 'v1/restaurants/short',
        method: 'GET',
        keepUnusedDataFor: 1,
      }),
    }),
  }),
});

export const {
  useLazyGetRestaurantListQuery,
  useLazyGetRestaurantQuery,
  useCreateRestaurantMutation,
  useUpdateRestaurantMutation,
  useDeleteRestaurantMutation,
  useLazyGetDeliveryAreaListQuery,
  useLazyGetDeliveryAreaQuery,
  useLazyGetDeliveryAreasQuery,
  useCreateDeliveryAreaMutation,
  useUpdateDeliveryAreaMutation,
  useDeleteDeliveryAreaMutation,
  useLazyGetAssignedAmbassadorsQuery,
  useLazyGetNeighbourAmbassadorsQuery,
  useLazyGetSectorAmbassadorsQuery,
  useGetRestaurantsForDealsQuery,
} = restaurantApi;
