import React, { useState, useEffect } from 'react';
import { Input, Button } from 'antd';
import { ArrowBack, ArrowForward, Search } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import TabsTable from 'components/Dashboard/Tabs/TabsTable';
import TabsViewModal from 'components/Dashboard/Tabs/TabsViewModal';
import { NavLink } from 'react-router-dom';
import Loading from 'components/Basic/Loading';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyGetTabsListQuery } from 'core/tabs/TabsService';
import { setTabsListData } from 'core/tabs/TabsSlice';

const Tabs: React.FC = () => {
  const [isShowDetailModal, setShowDetailModal] = useState(false);
  const [offersInfo, setOffersInfo] = useState(null);
  const [isRemoveModal, setIsRemoveModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const dispatch = useAppDispatch();
  const [getTabsList] = useLazyGetTabsListQuery();

  const { list } = useAppSelector(state => state.tabsReducer);

  useEffect(() => {
    getInitialData();
    setSearchText('');
  }, []);

  const getInitialData = async () => {
    setIsLoading(true);
    try {
      const tabsList = await getTabsList({ search: '' }).unwrap();
      dispatch(setTabsListData({ list: tabsList }));
    } catch (error) {
      console.error('Error fetching tabs list:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async (e: any) => {
    const searchTextValue = e.target.value.trimStart();
    setSearchText(searchTextValue);
    if (e.keyCode === 13) {
      setIsLoading(true);
      try {
        const tabsList = await getTabsList({ search: searchText }).unwrap();
        dispatch(setTabsListData({ list: tabsList }));
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="configure_tab_header_tabs">
      <div className="header_title">
        <NavLink to="/dashboard/deals/offers">
          <div className="back_btn">
            <ArrowBack />
          </div>
        </NavLink>
        <FormattedMessage id="Configure offer tabs" />
      </div>
      <div className="btn_wrapper">
        <Button className="add_btn" onClick={() => setShowDetailModal(true)}>
          <FormattedMessage id="Add new tab" />
          <ArrowForward />
        </Button>
      </div>
      <div className="input_search">
        <Input
          suffix={<Search />}
          placeholder="Buscar pestaña"
          value={searchText}
          onChange={e => handleSearch(e)}
          onKeyDown={e => handleSearch(e)}
          maxLength={200}
        />
      </div>
      <TabsTable tabsData={list.tabs || null} offerModal={false} />
      <TabsViewModal isOpenModal={isShowDetailModal} onCloseModal={() => setShowDetailModal(false)} tabsInfo={null} />
      {isLoading && <Loading visible={isLoading} />}
    </div>
  );
};

export default Tabs;
