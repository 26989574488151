import React, { useState, useEffect } from 'react';
import ModalDlg from 'components/Basic/ModalDlg';
import { Form, Button, Switch, TimePicker, DatePicker, Input, Checkbox } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { ArrowForward, Close } from '@material-ui/icons';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment-timezone';
import toast from '../../../components/Basic/Toast';
import { useUpdateSinglePromoItemMutation } from 'core/catalog/CatalogService';
import Loading from 'components/Basic/Loading';
import { sliceDateTimeToDayjsUtcString, transformDateTimeToDayjsUtc } from 'utilities/transformDateTime';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { setProductsData } from 'core/catalog/CatalogSlice';

type ItemPromoType = {
  id: number;
  isExtraPromo: boolean;
  isHidden: boolean;
  startDate: Dayjs | null;
  startTime: Dayjs | null;
  endDate: Dayjs | null;
  endTime: Dayjs | null;
  minAmount: string;
  productAmount: string;
};

type PromoConfigurationModalType = {
  isShowModal: boolean;
  onClosePromoModal: () => void;
  item: ItemPromoType;
};

type ErrorType = 'all' | 'date' | 'amount' | 'product' | '';

const PromoConfigurationModal = ({ isShowModal, onClosePromoModal, item }: PromoConfigurationModalType) => {
  const [state, setState] = useState<Partial<ItemPromoType>>({
    isExtraPromo: false,
    isHidden: false,
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
    minAmount: '',
    productAmount: '',
  });
  const intl = useIntl();
  const [updatePromoItem, { isLoading: promoUpdateLoading }] = useUpdateSinglePromoItemMutation();
  const dispatch = useAppDispatch();
  const { products } = useAppSelector(state => state.catalogReducer);
  const [isError, setError] = useState<ErrorType>('');

  const onDateChange = (date: any, dateString: any, key: string) => {
    if (date) {
      const value = dayjs(date).set('h', 0).set('m', 0).set('s', 0).set('ms', 0);
      setState(prev => ({ ...prev, [key]: value }));
    } else {
      setState(prev => ({ ...prev, [key]: '' }));
    }
    if (isError === 'date') {
      setError('');
    }
  };

  const onTimeChange = (value: Dayjs | null, dateString: any, key: string) => {
    if (value) {
      const time = dayjs(value).set('s', 0).set('ms', 0);
      setState(prev => ({ ...prev, [key]: time }));
    } else {
      setState(prev => ({ ...prev, [key]: '' }));
    }
    if (isError === 'date') {
      setError('');
    }
  };

  const handleTitleChange = (e: any, type: 'decimal' | 'number') => {
    const value = e.target.value.trimStart();
    switch (type) {
      case 'decimal':
        const parseValue = () => {
          if (value.includes('.') || value.indexOf('.') === 0) {
            let [integerPart, decimalPart] = value.split('.');

            decimalPart = decimalPart.replace(/[^\d]/g, '');
            decimalPart = decimalPart.slice(0, 2);

            const formattedValue = `${integerPart || 0}.${decimalPart}`;

            return formattedValue;
          } else {
            const formattedValue = value.replace(/[^\d]/g, '');
            return formattedValue === '' ? '' : `${Number(formattedValue)}`;
          }
        };
        setState(prev => ({ ...prev, minAmount: parseValue() }));
        break;
      case 'number':
        const numericValue = value.replace(/[^\d]/g, '');
        setState(prev => ({ ...prev, productAmount: numericValue === '' ? '' : `${Number(numericValue)}` }));
        break;
    }
    if (isError === 'amount' || isError === 'product') {
      setError('');
    }
  };

  const onSwitchChange = (check: boolean) => {
    setState(prev => ({ ...prev, isExtraPromo: check }));
    setError('');
  };

  const checkValidData = () => {
    const formatedStartDate = dayjs(state.startDate).format('YYYY-MM-DD');
    const formatedEndDate = dayjs(state.endDate).format('YYYY-MM-DD');
    const formatedStartTime = dayjs(state.startTime).format('HH:mm');
    const formatedEndTime = dayjs(state.endTime).format('HH:mm');

    const startMoment = moment(`${formatedStartDate} ${formatedStartTime}`);
    const endMoment = moment(`${formatedEndDate} ${formatedEndTime}`);

    if (startMoment.isAfter(endMoment)) {
      toast.error({ title: intl.formatMessage({ id: 'startEnd2' }) });
      setError('date');
      return false;
    }

    if (startMoment.isSame(endMoment)) {
      toast.error({ title: intl.formatMessage({ id: 'startEnd2' }) });
      setError('date');
      return false;
    }
    if (state.minAmount === '' || Number(state.minAmount) === 0) {
      toast.error({ title: intl.formatMessage({ id: 'Minimum total order amount must be greater than 0' }) });
      setError('amount');
      return false;
    }
    if (state.productAmount === '' || Number(state.productAmount) === 0) {
      toast.error({ title: intl.formatMessage({ id: 'Max amount of promo product must be greater than 0' }) });
      setError('product');
      return false;
    }
    return true;
  };

  const saveConfiguration = async () => {
    if (state.isExtraPromo) {
      if (
        !state.startDate ||
        !state.startTime ||
        !state.endDate ||
        !state.endTime ||
        !state.minAmount ||
        !state.productAmount
      ) {
        toast.error({ title: intl.formatMessage({ id: 'All fields are required' }) });
        setError('all');
        return;
      }
      const isValid = checkValidData();
      if (isValid) {
        const amount = Number(state.minAmount) * 100;
        const payload = {
          isExtraPromo: state.isExtraPromo,
          isHidden: state.isHidden || false,
          extraPromoSchedule: {
            startDate: sliceDateTimeToDayjsUtcString(state.startDate) || '',
            startTime: sliceDateTimeToDayjsUtcString(state.startTime) || '',
            endDate: sliceDateTimeToDayjsUtcString(state.endDate) || '',
            endTime: sliceDateTimeToDayjsUtcString(state.endTime) || '',
          },
          extraPromoConfiguration: {
            minPurchaseAmount: amount,
            maxQuantityPerOrder: Number(state.productAmount),
          },
        };
        try {
          const promoItem = await updatePromoItem({ id: item.id, body: payload }).unwrap();
          const items = products.rows?.map(item => {
            return item.id === promoItem.id ? promoItem : item;
          }) as any;
          dispatch(setProductsData({ products: { ...products, ...{ rows: items } } }));
          onClosePromoModal();
          toast.success({ title: intl.formatMessage({ id: 'The product configuration update was successful' }) });
        } catch (error: any) {
          toast.error({
            title: intl.formatMessage({
              id:
                error.data.code === 33007
                  ? 'There can be only 1 active promo item'
                  : 'The product configuration update has failed',
            }),
          });
        } finally {
          setError('');
        }
      }
    } else {
      const currentDate = dayjs().format('YYYY-MM-DD');
      const defaultTime = '1970-01-01T00:00:00.000Z';
      const payload = {
        isExtraPromo: state.isExtraPromo || false,
        isHidden: state.isHidden || false,
        //date and time required
        extraPromoSchedule: {
          startDate: `${currentDate}T00:00:00.000Z`,
          startTime: defaultTime,
          endDate: `${currentDate}T00:00:00.000Z`,
          endTime: defaultTime,
        },
        extraPromoConfiguration: {
          minPurchaseAmount: 0,
          maxQuantityPerOrder: 0,
        },
      };
      try {
        const promoItem = await updatePromoItem({ id: item.id, body: payload }).unwrap();
        const items = products.rows?.map(item => {
          return item.id === promoItem.id ? promoItem : item;
        }) as any;
        dispatch(setProductsData({ products: { ...products, ...{ rows: items } } }));
        toast.success({ title: intl.formatMessage({ id: 'The product configuration update was successful' }) });
        onClosePromoModal();
      } catch (error) {
        toast.error({ title: intl.formatMessage({ id: 'The product configuration update has failed' }) });
      } finally {
        setError('');
      }
    }
  };

  useEffect(() => {
    if (isShowModal) {
      setError('');
      setState({
        isExtraPromo: item.isExtraPromo || false,
        isHidden: item.isHidden || false,
        startDate: item.startDate || null,
        startTime: transformDateTimeToDayjsUtc(item.startTime || null),
        endDate: item.endDate || null,
        endTime: transformDateTimeToDayjsUtc(item.endTime || null),
        minAmount: item.minAmount || '',
        productAmount: item.productAmount || '',
      });
    }
  }, [isShowModal]);

  const checkField = (value: any) => {
    if (isError !== 'all') return;
    if (value) {
      const valid = value.length > 0 || value;
      return !valid;
    } else {
      return true;
    }
  };

  return (
    <ModalDlg
      className="promo_config_modal"
      visible={isShowModal}
      handleCancel={onClosePromoModal}
      width={460}
      isCenter
    >
      <>
        <Loading visible={promoUpdateLoading} />
        <div className="modal_header">
          <div className="name_wrapper">
            <h3>
              <FormattedMessage id={'Promotional configuration'} />
            </h3>
            <div className="name_wrapper_id">
              <FormattedMessage id="OfferId" values={{ id: item.id }} />
            </div>
            <div className="close_modal" onClick={onClosePromoModal}>
              <Close />
            </div>
          </div>
        </div>
        <div className="promo_form_wrapper">
          <Form className="basic_info_form" id={'promo_product_form'}>
            <div className="aditional_detail">
              <Switch
                checked={state.isExtraPromo}
                onChange={checked => onSwitchChange(checked)}
                id="promo_form_switcher"
              />
              <p id="promo_switcher_label">
                <FormattedMessage id="Make this product promotional" />
              </p>
            </div>
            <div className={`inputs_wrapper ${state.isExtraPromo ? 'expanded' : ''}`}>
              <div className="two_line">
                <div className="date_wrapper">
                  <p className="title">
                    <FormattedMessage id="Start date" />
                  </p>
                  <DatePicker
                    value={state.startDate ? dayjs(state.startDate, 'YYYY-MM-DD') : null}
                    className={`date_input ${
                      checkField(state.startDate) || isError === 'date' ? 'has-error_area' : ''
                    }`}
                    placeholder="00/00/0000"
                    onChange={(date, dateString) => onDateChange(date, dateString, 'startDate')}
                  />
                </div>
                <div className="date_wrapper">
                  <p className="title">
                    <FormattedMessage id="Start time" />
                  </p>
                  <TimePicker
                    className={`date_input ${
                      checkField(state.startTime) || isError === 'date' ? 'has-error_area' : ''
                    }`}
                    format="HH:mm"
                    placeholder="00:00"
                    value={state.startTime ? dayjs(state.startTime, 'HH:mm') : null}
                    onChange={(date, dateString) => onTimeChange(date, dateString, 'startTime')}
                    minuteStep={10}
                  />
                </div>
              </div>
              <div className="two_line">
                <div className="date_wrapper">
                  <p className="title">
                    <FormattedMessage id="End date" />
                  </p>
                  <DatePicker
                    value={state.endDate ? dayjs(state.endDate, 'YYYY-MM-DD') : null}
                    className={`date_input ${checkField(state.endDate) || isError === 'date' ? 'has-error_area' : ''}`}
                    placeholder="00/00/0000"
                    onChange={(date, dateString) => onDateChange(date, dateString, 'endDate')}
                  />
                </div>
                <div className="date_wrapper">
                  <p className="title">
                    <FormattedMessage id="End time" />
                  </p>
                  <TimePicker
                    className={`date_input ${checkField(state.endTime) || isError === 'date' ? 'has-error_area' : ''}`}
                    format="HH:mm"
                    placeholder="00:00"
                    value={state.endTime ? dayjs(state.endTime, 'HH:mm') : null}
                    onChange={(date, dateString) => onTimeChange(date, dateString, 'endTime')}
                    minuteStep={10}
                  />
                </div>
              </div>
              <p className="title_deals">
                <FormattedMessage id="Minimum total order amount" />
              </p>
              <Input
                placeholder="00.00"
                value={state.minAmount}
                onChange={e => handleTitleChange(e, 'decimal')}
                maxLength={10}
                className={checkField(state.minAmount) || isError === 'amount' ? 'amount_promo_error' : 'amount_promo'}
              />
              <p className="title_deals" style={{ marginTop: '20px' }}>
                <FormattedMessage id="Max amount of promo products to be added to the cart" />
              </p>
              <Input
                placeholder="1"
                maxLength={2}
                className={
                  checkField(state.productAmount) || isError === 'product' ? 'amount_promo_error' : 'amount_promo'
                }
                value={state.productAmount}
                onChange={e => handleTitleChange(e, 'number')}
              />
            </div>
            <div className="checkbox_wrapper">
              <p className="title_deals" style={{ marginRight: '12px', marginBottom: '0', marginTop: '0' }}>
                <FormattedMessage id="Hide from catalog" />
              </p>
              <Checkbox
                indeterminate={state.isHidden}
                checked={state.isHidden}
                onChange={e => setState(prev => ({ ...prev, isHidden: e.target.checked }))}
              />
            </div>

            <div className="footer">
              <div className="btn_wrapper">
                <p onClick={onClosePromoModal}>
                  <FormattedMessage id="Cancel" />
                </p>
                <Button className="save_button" onClick={saveConfiguration}>
                  <FormattedMessage id="Save changes" />
                  <ArrowForward />
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </>
    </ModalDlg>
  );
};

export default PromoConfigurationModal;
