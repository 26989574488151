import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Form, Input, Button, Switch } from 'antd';
import ModalDlg from 'components/Basic/ModalDlg';
import { Close } from '@material-ui/icons';
import { ArrowForward } from '@material-ui/icons';
import toast from 'components/Basic/Toast';
import Loading from 'components/Basic/Loading';
import { useAppDispatch } from 'utilities/redux';
import {
  useCreateCampaignMutation,
  useUpdateCampaignMutation,
  useLazyGetCampaignListQuery,
} from 'core/campaign/CampaignService';
import { setCampaignData } from 'core/campaign/CampaignSlice';

type CampaignData = {
  id: number;
  name: string;
  isActive: boolean;
  dealCount: number;
  createdAt: string;
  updatedAt: string;
};

type CampaignViewModalType = {
  isOpenModal: boolean;
  campaignInfo: CampaignData | null;
  setPageSize: (e: number) => void;
  setCurrent: (e: number) => void;
  onCloseModal: () => void;
};

const CampaignViewModal: React.FC<CampaignViewModalType> = ({
  isOpenModal,
  campaignInfo,
  setPageSize,
  setCurrent,
  onCloseModal,
}) => {
  const [campaignName, setCampaignName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState<boolean>();
  const intl = useIntl();

  const dispatch = useAppDispatch();
  const [getCampaignList] = useLazyGetCampaignListQuery();
  const [createCampaign] = useCreateCampaignMutation();
  const [updateCampaign] = useUpdateCampaignMutation();

  const handleCampaignNameChange = (e: any) => {
    setCampaignName(e.target.value.trimStart());
  };

  const handleSaveButtonClick = async () => {
    setIsLoading(true);
    setPageSize(10);
    setCurrent(1);
    const queryString = 'search=&page=1&size=10&count=true&sort[id]=desc';
    if (campaignInfo && campaignInfo.id) {
      try {
        await updateCampaign({ name: campaignName.trim(), isActive: isActive, id: campaignInfo.id }).unwrap();

        const res = await getCampaignList({ queryString }).unwrap();
        dispatch(setCampaignData({ list: res }));

        setIsLoading(false);
        toast.success({
          title: intl.formatMessage({
            id: 'Campaign is updated successfully!',
          }),
        });
      } catch (e) {
        console.log('e', e);
        setIsLoading(false);
        toast.error({
          title: intl.formatMessage({
            id: 'Updating campaign is failure!',
          }),
        });
      }
    } else {
      try {
        await createCampaign({ name: campaignName.trim(), isActive: true }).unwrap();

        const res = await getCampaignList({ queryString }).unwrap();
        dispatch(setCampaignData({ list: res }));

        setIsLoading(false);
        toast.success({
          title: intl.formatMessage({
            id: 'Campaign is created successfully!',
          }),
        });
        setCampaignName('');
      } catch (e) {
        console.log('e', e);
        setIsLoading(false);
        toast.error({
          title: intl.formatMessage({
            id: 'Creating campaign is failure!',
          }),
        });
      }
    }
  };

  useEffect(() => {
    setCampaignName('');
    if (campaignInfo) {
      setCampaignName(campaignInfo.name || '');
      setIsActive(campaignInfo.isActive);
    } else {
      setCampaignName('');
    }
  }, [isOpenModal]);

  const campaignInfoName = campaignInfo ? campaignInfo.name : null;
  const isDisabled =
    !campaignName || !(campaignInfoName !== campaignName || (campaignInfo && campaignInfo.isActive !== isActive));

  return (
    <ModalDlg className="campaign_view_modal" visible={isOpenModal} width={430} handleCancel={onCloseModal} isCenter>
      <>
        <div className="modal_header">
          <div className="name_wrapper">
            <h3>
              <FormattedMessage id={campaignInfo && campaignInfo.id ? 'Edit campaign' : 'New campaign'} />
            </h3>
            <div className="close_modal" onClick={onCloseModal} id="campaign_close_modal">
              <Close />
            </div>
          </div>
        </div>
        <div className="new_campaign_form_wrapper" id="campaign_form_wrapper">
          <Form className="basic_info_form">
            <div className="campaign_input">
              <Form.Item>
                <p className="title_deals" id="campaign_form_label_name">
                  <FormattedMessage id="Campaign name" />
                </p>
                <Input
                  placeholder={intl.formatMessage({ id: 'Campaign name' })}
                  value={campaignName}
                  onChange={handleCampaignNameChange}
                  id="campaign_form_input_name"
                  maxLength={200}
                />
              </Form.Item>
            </div>
            <Form.Item>
              <div className={campaignInfo && campaignInfo.id ? 'side_btns' : 'side_btns_camp'}>
                {campaignInfo && campaignInfo.id && (
                  <div className="aditional_detail">
                    <p id="campaign_form_switcher_label">
                      <FormattedMessage id="Active" />
                    </p>
                    <Switch checked={isActive} onChange={checked => setIsActive(checked)} id="campaign_form_switcher" />
                  </div>
                )}
                <div className="btn_wrapper" id="campaign_form_btns_wrapper">
                  {campaignInfo &&
                  campaignInfo.id &&
                  (campaignInfo.name !== campaignName || campaignInfo.isActive !== isActive) ? (
                    <p
                      className="cancel_btn_campaign"
                      onClick={() => {
                        setCampaignName(campaignInfo.name);
                        setIsActive(campaignInfo.isActive);
                      }}
                      id="campaign_form_btn_cancel"
                    >
                      <FormattedMessage id="Cancel" />
                    </p>
                  ) : (
                    <></>
                  )}
                  <Button
                    className="save_sar_btn"
                    onClick={() => {
                      handleSaveButtonClick();
                      onCloseModal();
                    }}
                    disabled={isDisabled}
                    id="campaign_form_btn_save"
                  >
                    <FormattedMessage id={campaignInfo && campaignInfo.id ? 'Save changes' : 'Save'} />
                    <ArrowForward />
                  </Button>
                  {isLoading && <Loading visible={isLoading} />}
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
      </>
    </ModalDlg>
  );
};

CampaignViewModal.defaultProps = {};

export default CampaignViewModal;
