import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, AgentData } from './types';

const initialState: initState = {
  list: {
    limit: 10,
    offset: 0,
    rows: [
      {
        id: 0,
        eid: '',
        firstName: '',
        lastName: '',
        online: false,
        todayOrders: 0,
        state: '',
        createdAt: '',
        updatedAt: '',
      },
    ],
    totalCount: 0,
  },
};

const agentSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAgentData(state: initState, action: PayloadAction<{ list: AgentData }>) {
      state.list = action.payload.list;
    },
  },
});

export const { setAgentData } = agentSlice.actions;

export default agentSlice.reducer;
