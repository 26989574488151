import { FormattedMessage, useIntl } from 'react-intl';
import { Input } from 'antd';

type DataGTType = {
  selectedTemplates: string;
  setPromoName: (value: any) => void;
  promoName: string;
  gtShortDesc: string;
  gtLongDesc: string;
  setGtShortDesc: (value: any) => void;
  setGtLongDesc: (value: any) => void;
  setGtProductOne: (value: any) => void;
  setGtQuantityOne: (value: any) => void;
  setGtPriceOne: (value: any) => void;
  setGtDiscountLimitOne: (value: any) => void;
  gtProductOne: string;
  gtQuantityOne: number;
  gtPriceOne: any;
  gtDiscountLimitOne: any;
  setGtProductTwo: (value: any) => void;
  setGtQuantityTwo: (value: any) => void;
  setGtPriceTwo: (value: any) => void;
  setGtDiscountLimitTwo: (value: any) => void;
  gtProductTwo: string;
  gtQuantityTwo: number;
  gtPriceTwo: any;
  gtDiscountLimitTwo: any;
  setGtProductCode: (value: any) => void;
  setGtDiscountAmount: (value: any) => void;
  gtProductCode: string;
  gtDiscountAmount: number;
  setGtProductThree: (value: any) => void;
  setGtQuantityThree: (value: any) => void;
  setGtPriceThree: (value: any) => void;
  setGtDiscountLimitThree: (value: any) => void;
  gtProductThree: string;
  gtQuantityThree: number;
  gtPriceThree: any;
  gtDiscountLimitThree: any;
  setGtProductFour: (value: any) => void;
  setGtQuantityFour: (value: any) => void;
  setGtPriceFour: (value: any) => void;
  setGtDiscountLimitFour: (value: any) => void;
  gtProductFour: string;
  gtQuantityFour: number;
  gtPriceFour: any;
  gtDiscountLimitFour: any;
  setGtReducedQuantity: (value: any) => void;
  gtReducedQuantity: string;
  isValid: boolean;
};

const DataGT: React.FC<DataGTType> = ({
  selectedTemplates,
  setPromoName,
  promoName,
  gtShortDesc,
  gtLongDesc,
  setGtShortDesc,
  setGtLongDesc,
  setGtProductOne,
  setGtQuantityOne,
  setGtPriceOne,
  setGtDiscountLimitOne,
  gtProductOne,
  gtQuantityOne,
  gtPriceOne,
  gtDiscountLimitOne,
  setGtProductTwo,
  setGtQuantityTwo,
  setGtPriceTwo,
  setGtDiscountLimitTwo,
  gtProductTwo,
  gtQuantityTwo,
  gtPriceTwo,
  gtDiscountLimitTwo,
  setGtProductCode,
  setGtDiscountAmount,
  gtProductCode,
  gtDiscountAmount,
  setGtProductThree,
  setGtQuantityThree,
  setGtPriceThree,
  setGtDiscountLimitThree,
  gtProductThree,
  gtQuantityThree,
  gtPriceThree,
  gtDiscountLimitThree,
  setGtProductFour,
  setGtQuantityFour,
  setGtPriceFour,
  setGtDiscountLimitFour,
  gtProductFour,
  gtQuantityFour,
  gtPriceFour,
  gtDiscountLimitFour,
  setGtReducedQuantity,
  gtReducedQuantity,
  isValid,
}) => {
  const TYPE_OF_INPUT = {
    string: 'string',
    number: 'number',
    product_code: 'product_code',
    decimals: 'decimals',
  };

  const intl = useIntl();

  const onChange = (event: any, type: string, callback: any) => {
    const value = event.target.value.trimStart();

    const parseValue = () => {
      switch (type) {
        case TYPE_OF_INPUT.number:
          const numericValue = value.replace(/[^\d]/g, '');
          return numericValue === '' ? '' : Number(numericValue);
        case TYPE_OF_INPUT.product_code:
          const regex = /[^\|\d]/g;
          return value.replace(regex, '');
        case TYPE_OF_INPUT.decimals:
          if (value.includes('.') || value.indexOf('.') === 0) {
            let [integerPart, decimalPart] = value.split('.');

            decimalPart = decimalPart.replace(/[^\d]/g, '');
            decimalPart = decimalPart.slice(0, 2);

            const formattedValue = `${integerPart || 0}.${decimalPart}`;

            return formattedValue;
          } else {
            const formattedValue = value.replace(/[^\d]/g, '');
            return formattedValue === '' ? '' : Number(formattedValue);
          }
        default:
          return value;
      }
    };

    callback(parseValue());
  };

  const checkField = (value: any, decimals: boolean, productCode: boolean) => {
    if (isValid) return;
    if (decimals) {
      if (value.length === 0) {
        return true;
      }
    } else if (productCode) {
      if (value.length === 0) {
        return true;
      }
      if (value[0] === '0') {
        return 'productCode';
      }
      for (let i = 0; i < value.length; i++) {
        if (value[i] === '|') {
          if (value[i + 1] === '0' || value[i + 1] === value[i]) {
            return 'productCode';
          }
          if (i === 0 || i === value.length - 1) {
            return 'productCode';
          }
        }
      }
      if (value.split('|').some((segment: string) => segment.length > 15)) {
        return 'productCode';
      }
    } else {
      if (value) {
        const valid = value.length > 0 || value;
        return !valid;
      } else {
        return true;
      }
    }
  };

  return (
    <>
      <div className="" id="campaign_form_wrapper">
        <div className="basic_info_form">
          <div className="tabs_input">
            <>
              <p className="title_deals" style={{ marginTop: '-14px' }}>
                <FormattedMessage id="Promotion name" />
              </p>
              <Input
                placeholder=""
                value={promoName}
                maxLength={200}
                onChange={event => onChange(event, TYPE_OF_INPUT.string, setPromoName)}
                className={checkField(promoName, false, false) ? 'has-error' : ''}
              />
              {checkField(promoName, false, false) && (
                <span className="error_msg">
                  <FormattedMessage id="Field is required" />
                </span>
              )}

              <p className="title_deals" style={{ marginTop: '20px' }}>
                <FormattedMessage id="Promotion Short Description" />
              </p>
              <Input
                placeholder=""
                value={gtShortDesc}
                maxLength={200}
                onChange={event => onChange(event, TYPE_OF_INPUT.string, setGtShortDesc)}
                className={checkField(gtShortDesc, false, false) ? 'has-error' : ''}
              />
              {checkField(gtShortDesc, false, false) && (
                <span className="error_msg">
                  <FormattedMessage id="Field is required" />
                </span>
              )}

              <p className="title_deals" style={{ marginTop: '20px' }}>
                <FormattedMessage id="Promotion Long Description" />
              </p>
              <Input
                placeholder=""
                value={gtLongDesc}
                maxLength={200}
                onChange={event => onChange(event, TYPE_OF_INPUT.string, setGtLongDesc)}
                className={checkField(gtLongDesc, false, false) ? 'has-error' : ''}
              />
              {checkField(gtLongDesc, false, false) && (
                <span className="error_msg">
                  <FormattedMessage id="Field is required" />
                </span>
              )}
              <div className="line_divider" />
              {(selectedTemplates === 'price_deal_two_gt' ||
                selectedTemplates === 'price_deal_three_gt' ||
                selectedTemplates === 'price_deal_four_gt') && (
                <>
                  <p className="title_deals" style={{ marginTop: '20px' }}>
                    <FormattedMessage id="Eligible Product 1" />
                  </p>
                  <Input
                    placeholder=""
                    value={gtProductOne}
                    onChange={event => onChange(event, TYPE_OF_INPUT.product_code, setGtProductOne)}
                    className={checkField(gtProductOne, false, true) ? 'has-error' : ''}
                  />
                  {checkField(gtProductOne, false, true) === 'productCode' ? (
                    <span className="error_msg">
                      <FormattedMessage id="Invalid data" />
                    </span>
                  ) : (
                    checkField(gtProductOne, false, true) && (
                      <span className="error_msg">
                        <FormattedMessage id="Field is required" />
                      </span>
                    )
                  )}
                  <div className="input_group">
                    <div className="input_item">
                      <p className="title_deals" style={{ marginTop: '20px' }}>
                        <FormattedMessage id="Quantity" />
                      </p>
                      <Input
                        placeholder=""
                        value={gtQuantityOne}
                        onChange={event => onChange(event, TYPE_OF_INPUT.number, setGtQuantityOne)}
                        maxLength={200}
                        style={{ width: '100%' }}
                        min={0}
                        className={checkField(gtQuantityOne, true, false) ? 'has-error' : ''}
                      />
                    </div>
                    <div className="input_item">
                      <p className="title_deals" style={{ marginTop: '20px' }}>
                        <FormattedMessage id="Price" />
                      </p>
                      <Input
                        placeholder="0.00"
                        value={gtPriceOne}
                        onChange={event => onChange(event, TYPE_OF_INPUT.decimals, setGtPriceOne)}
                        maxLength={200}
                        style={{ width: '100%' }}
                        min={0}
                        className={checkField(gtPriceOne, true, false) ? 'has-error' : ''}
                      />
                    </div>
                    <div className="input_item">
                      <p className="title_deals" style={{ marginTop: '20px' }}>
                        <FormattedMessage id="Discount Limit" />
                      </p>
                      <Input
                        placeholder="0.00"
                        value={gtDiscountLimitOne}
                        onChange={event => onChange(event, TYPE_OF_INPUT.decimals, setGtDiscountLimitOne)}
                        maxLength={200}
                        style={{ width: '100%' }}
                        min={0}
                        className={checkField(gtDiscountLimitOne, true, false) ? 'has-error' : ''}
                      />
                    </div>
                  </div>
                  {checkField(gtDiscountLimitOne, true, false) ||
                  checkField(gtPriceOne, true, false) ||
                  checkField(gtQuantityOne, false, false) ? (
                    <span className="error_msg">
                      <FormattedMessage id="All fields required" />
                    </span>
                  ) : (
                    <></>
                  )}
                  <p className="title_deals" style={{ marginTop: '20px' }}>
                    <FormattedMessage id="Eligible Product 2" />
                  </p>
                  <Input
                    placeholder=""
                    value={gtProductTwo}
                    className={checkField(gtProductTwo, false, true) ? 'has-error' : ''}
                    onChange={event => onChange(event, TYPE_OF_INPUT.product_code, setGtProductTwo)}
                  />
                  {checkField(gtProductTwo, false, true) === 'productCode' ? (
                    <span className="error_msg">
                      <FormattedMessage id="Invalid data" />
                    </span>
                  ) : (
                    checkField(gtProductTwo, false, true) && (
                      <span className="error_msg">
                        <FormattedMessage id="Field is required" />
                      </span>
                    )
                  )}
                  <div className="input_group">
                    <div className="input_item">
                      <p className="title_deals" style={{ marginTop: '20px' }}>
                        <FormattedMessage id="Quantity" />
                      </p>
                      <Input
                        placeholder=""
                        value={gtQuantityTwo}
                        onChange={event => onChange(event, TYPE_OF_INPUT.number, setGtQuantityTwo)}
                        maxLength={200}
                        style={{ width: '100%' }}
                        min={0}
                        className={checkField(gtQuantityTwo, false, false) ? 'has-error' : ''}
                      />
                    </div>
                    <div className="input_item">
                      <p className="title_deals" style={{ marginTop: '20px' }}>
                        <FormattedMessage id="Price" />
                      </p>
                      <Input
                        placeholder="0.00"
                        value={gtPriceTwo}
                        onChange={event => onChange(event, TYPE_OF_INPUT.decimals, setGtPriceTwo)}
                        maxLength={200}
                        style={{ width: '100%' }}
                        min={0}
                        className={checkField(gtPriceTwo, true, false) ? 'has-error' : ''}
                      />
                    </div>
                    <div className="input_item">
                      <p className="title_deals" style={{ marginTop: '20px' }}>
                        <FormattedMessage id="Discount Limit" />
                      </p>
                      <Input
                        placeholder="0.00"
                        value={gtDiscountLimitTwo}
                        onChange={event => onChange(event, TYPE_OF_INPUT.decimals, setGtDiscountLimitTwo)}
                        maxLength={200}
                        style={{ width: '100%' }}
                        min={0}
                        className={checkField(gtDiscountLimitTwo, true, false) ? 'has-error' : ''}
                      />
                    </div>
                  </div>
                  {checkField(gtDiscountLimitTwo, true, false) ||
                  checkField(gtPriceTwo, true, false) ||
                  checkField(gtQuantityTwo, false, false) ? (
                    <span className="error_msg">
                      <FormattedMessage id="All fields required" />
                    </span>
                  ) : (
                    <></>
                  )}
                  {(selectedTemplates === 'price_deal_three_gt' || selectedTemplates === 'price_deal_four_gt') && (
                    <>
                      <p className="title_deals" style={{ marginTop: '20px' }}>
                        <FormattedMessage id="Eligible Product 3" />
                      </p>
                      <Input
                        placeholder=""
                        value={gtProductThree}
                        className={checkField(gtProductThree, false, true) ? 'has-error' : ''}
                        onChange={event => onChange(event, TYPE_OF_INPUT.product_code, setGtProductThree)}
                      />
                      {checkField(gtProductThree, false, true) === 'productCode' ? (
                        <span className="error_msg">
                          <FormattedMessage id="Invalid data" />
                        </span>
                      ) : (
                        checkField(gtProductThree, false, true) && (
                          <span className="error_msg">
                            <FormattedMessage id="Field is required" />
                          </span>
                        )
                      )}
                      <div className="input_group">
                        <div className="input_item">
                          <p className="title_deals" style={{ marginTop: '20px' }}>
                            <FormattedMessage id="Quantity" />
                          </p>
                          <Input
                            placeholder=""
                            value={gtQuantityThree}
                            onChange={event => onChange(event, TYPE_OF_INPUT.number, setGtQuantityThree)}
                            maxLength={200}
                            style={{ width: '100%' }}
                            min={0}
                            className={checkField(gtQuantityThree, false, false) ? 'has-error' : ''}
                          />
                        </div>
                        <div className="input_item">
                          <p className="title_deals" style={{ marginTop: '20px' }}>
                            <FormattedMessage id="Price" />
                          </p>
                          <Input
                            placeholder="0.00"
                            value={gtPriceThree}
                            onChange={event => onChange(event, TYPE_OF_INPUT.decimals, setGtPriceThree)}
                            maxLength={200}
                            style={{ width: '100%' }}
                            min={0}
                            className={checkField(gtPriceThree, true, false) ? 'has-error' : ''}
                          />
                        </div>
                        <div className="input_item">
                          <p className="title_deals" style={{ marginTop: '20px' }}>
                            <FormattedMessage id="Discount Limit" />
                          </p>
                          <Input
                            placeholder="0.00"
                            value={gtDiscountLimitThree}
                            onChange={event => onChange(event, TYPE_OF_INPUT.decimals, setGtDiscountLimitThree)}
                            maxLength={200}
                            style={{ width: '100%' }}
                            min={0}
                            className={checkField(gtDiscountLimitThree, true, false) ? 'has-error' : ''}
                          />
                        </div>
                      </div>
                      {checkField(gtDiscountLimitThree, true, false) ||
                      checkField(gtPriceThree, true, false) ||
                      checkField(gtQuantityThree, false, false) ? (
                        <span className="error_msg">
                          <FormattedMessage id="All fields required" />
                        </span>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  {selectedTemplates === 'price_deal_four_gt' && (
                    <>
                      <p className="title_deals" style={{ marginTop: '20px' }}>
                        <FormattedMessage id="Eligible Product 4" />
                      </p>
                      <Input
                        placeholder=""
                        value={gtProductFour}
                        className={checkField(gtProductFour, false, true) ? 'has-error' : ''}
                        onChange={event => onChange(event, TYPE_OF_INPUT.product_code, setGtProductFour)}
                      />
                      {checkField(gtProductFour, false, true) === 'productCode' ? (
                        <span className="error_msg">
                          <FormattedMessage id="Invalid data" />
                        </span>
                      ) : (
                        checkField(gtProductFour, false, true) && (
                          <span className="error_msg">
                            <FormattedMessage id="Field is required" />
                          </span>
                        )
                      )}
                      <div className="input_group">
                        <div className="input_item">
                          <p className="title_deals" style={{ marginTop: '20px' }}>
                            <FormattedMessage id="Quantity" />
                          </p>
                          <Input
                            placeholder=""
                            value={gtQuantityFour}
                            onChange={event => onChange(event, TYPE_OF_INPUT.number, setGtQuantityFour)}
                            maxLength={200}
                            style={{ width: '100%' }}
                            className={checkField(gtQuantityFour, false, false) ? 'has-error' : ''}
                          />
                        </div>
                        <div className="input_item">
                          <p className="title_deals" style={{ marginTop: '20px' }}>
                            <FormattedMessage id="Price" />
                          </p>
                          <Input
                            placeholder="0.00"
                            value={gtPriceFour}
                            onChange={event => onChange(event, TYPE_OF_INPUT.decimals, setGtPriceFour)}
                            maxLength={200}
                            style={{ width: '100%' }}
                            min={0}
                            className={checkField(gtPriceFour, true, false) ? 'has-error' : ''}
                          />
                        </div>
                        <div className="input_item">
                          <p className="title_deals" style={{ marginTop: '20px' }}>
                            <FormattedMessage id="Discount Limit" />
                          </p>
                          <Input
                            placeholder="0.00"
                            value={gtDiscountLimitFour}
                            onChange={event => onChange(event, TYPE_OF_INPUT.decimals, setGtDiscountLimitFour)}
                            maxLength={200}
                            style={{ width: '100%' }}
                            min={0}
                            className={checkField(gtDiscountLimitFour, true, false) ? 'has-error' : ''}
                          />
                        </div>
                      </div>
                      {checkField(gtDiscountLimitFour, true, false) ||
                      checkField(gtPriceFour, true, false) ||
                      checkField(gtQuantityFour, false, false) ? (
                        <span className="error_msg">
                          <FormattedMessage id="All fields required" />
                        </span>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}
              {(selectedTemplates === 'discount_off_product_gt' ||
                selectedTemplates === 'discount_off_total_order_gt' ||
                selectedTemplates === 'buy_one_get_two_gt') && (
                <>
                  {(selectedTemplates === 'discount_off_product_gt' || selectedTemplates === 'buy_one_get_two_gt') && (
                    <>
                      <p className="title_deals" style={{ marginTop: '20px' }}>
                        <FormattedMessage id="Product code" />
                      </p>
                      <Input
                        placeholder=""
                        value={gtProductCode}
                        min={0}
                        className={checkField(gtProductCode, false, true) ? 'has-error' : ''}
                        onChange={event => onChange(event, TYPE_OF_INPUT.product_code, setGtProductCode)}
                        type="string"
                      />
                      {checkField(gtProductCode, false, true) === 'productCode' ? (
                        <span className="error_msg">
                          <FormattedMessage id="Invalid data" />
                        </span>
                      ) : (
                        checkField(gtProductCode, false, true) && (
                          <span className="error_msg">
                            <FormattedMessage id="Field is required" />
                          </span>
                        )
                      )}
                      {selectedTemplates === 'buy_one_get_two_gt' && (
                        <>
                          <p className="title_deals" style={{ marginTop: '20px' }}>
                            <FormattedMessage id="Quantity" />
                          </p>
                          <Input
                            placeholder=""
                            value={gtReducedQuantity}
                            onChange={event => onChange(event, TYPE_OF_INPUT.string, setGtReducedQuantity)}
                            maxLength={200}
                            className={checkField(gtLongDesc, false, false) ? 'has-error' : ''}
                          />
                          {checkField(gtLongDesc, false, false) && (
                            <span className="error_msg">
                              <FormattedMessage id="Field is required" />
                            </span>
                          )}
                          <p className="title_deals" style={{ marginTop: '20px' }}>
                            <FormattedMessage id="Eligible Product 1" />
                          </p>
                          <Input
                            placeholder=""
                            value={gtProductOne}
                            className={checkField(gtProductOne, false, true) ? 'has-error' : ''}
                            onChange={event => onChange(event, TYPE_OF_INPUT.string, setGtProductOne)}
                          />
                          {checkField(gtProductOne, false, true) === 'productCode' ? (
                            <span className="error_msg">
                              <FormattedMessage id="Invalid data" />
                            </span>
                          ) : (
                            checkField(gtProductOne, false, true) && (
                              <span className="error_msg">
                                <FormattedMessage id="Field is required" />
                              </span>
                            )
                          )}
                          <div className="input_group">
                            <div className="input_item">
                              <p className="title_deals" style={{ marginTop: '20px' }}>
                                <FormattedMessage id="Product 1 Price" />
                              </p>
                              <Input
                                placeholder=""
                                value={gtPriceFour}
                                onChange={event => onChange(event, TYPE_OF_INPUT.number, setGtPriceFour)}
                                maxLength={200}
                                style={{ width: '165px' }}
                                type="number"
                                min={0}
                                className={checkField(gtPriceFour, false, false) ? 'has-error' : ''}
                              />
                              {checkField(gtPriceFour, false, false) && (
                                <span className="error_msg">
                                  <FormattedMessage id="Field is required" />
                                </span>
                              )}
                            </div>
                            <div className="input_item">
                              <p className="title_deals" style={{ marginTop: '20px' }}>
                                <FormattedMessage id="Discount Limit" />
                              </p>
                              <Input
                                placeholder=""
                                value={gtQuantityOne}
                                onChange={event => onChange(event, TYPE_OF_INPUT.number, setGtQuantityOne)}
                                maxLength={200}
                                style={{ width: '165px' }}
                                type="number"
                                min={0}
                                className={checkField(gtQuantityOne, false, false) ? 'has-error' : ''}
                              />
                              {checkField(gtQuantityOne, false, false) && (
                                <span className="error_msg">
                                  <FormattedMessage id="Field is required" />
                                </span>
                              )}
                            </div>
                          </div>
                          <p className="title_deals" style={{ marginTop: '20px' }}>
                            <FormattedMessage id="Eligible Product 2" />
                          </p>
                          <Input
                            placeholder=""
                            value={gtProductTwo}
                            className={checkField(gtProductTwo, false, true) ? 'has-error' : ''}
                            onChange={event => onChange(event, TYPE_OF_INPUT.string, setGtProductTwo)}
                          />
                          {checkField(gtProductTwo, false, true) === 'productCode' ? (
                            <span className="error_msg">
                              <FormattedMessage id="Invalid data" />
                            </span>
                          ) : (
                            checkField(gtProductTwo, false, true) && (
                              <span className="error_msg">
                                <FormattedMessage id="Field is required" />
                              </span>
                            )
                          )}
                          <div className="input_group">
                            <div className="input_item">
                              <p className="title_deals" style={{ marginTop: '20px' }}>
                                <FormattedMessage id="Product 1 Price" />
                              </p>
                              <Input
                                placeholder=""
                                value={gtPriceTwo}
                                onChange={event => onChange(event, TYPE_OF_INPUT.number, setGtPriceTwo)}
                                maxLength={200}
                                style={{ width: '165px' }}
                                min={0}
                                className={checkField(gtPriceTwo, false, false) ? 'has-error' : ''}
                              />
                              {checkField(gtPriceTwo, false, false) && (
                                <span className="error_msg">
                                  <FormattedMessage id="Field is required" />
                                </span>
                              )}
                            </div>
                            <div className="input_item">
                              <p className="title_deals" style={{ marginTop: '20px' }}>
                                <FormattedMessage id="Discount Limit" />
                              </p>
                              <Input
                                placeholder=""
                                value={gtQuantityTwo}
                                onChange={event => onChange(event, TYPE_OF_INPUT.number, setGtQuantityTwo)}
                                maxLength={200}
                                style={{ width: '165px' }}
                                type="number"
                                min={0}
                                className={checkField(gtQuantityTwo, false, false) ? 'has-error' : ''}
                              />
                              {checkField(gtQuantityTwo, false, false) && (
                                <span className="error_msg">
                                  <FormattedMessage id="Field is required" />
                                </span>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {(selectedTemplates === 'discount_off_product_gt' ||
                    selectedTemplates === 'discount_off_total_order_gt') && (
                    <>
                      <p className="title_deals" style={{ marginTop: '20px' }}>
                        <FormattedMessage id="Discount amount" />
                      </p>
                      <Input
                        placeholder="0"
                        value={gtDiscountAmount}
                        onChange={event => onChange(event, TYPE_OF_INPUT.decimals, setGtDiscountAmount)}
                        maxLength={200}
                        // className={checkField(gtDiscountAmount, true) && 'has-error'}
                        disabled
                      />
                      {/* {checkField(gtDiscountAmount, true) === 'zero' && (
                        <span className="error_msg">
                          <FormattedMessage id="Invalid data" />
                        </span>
                      )} */}
                      <p className="title_deals" style={{ marginTop: '20px' }}>
                        <FormattedMessage id="Price" />
                      </p>
                      <Input
                        placeholder="0.00"
                        value={gtPriceOne}
                        onChange={event => onChange(event, TYPE_OF_INPUT.decimals, setGtPriceOne)}
                        maxLength={200}
                        className={checkField(gtPriceOne, true, false) ? 'has-error' : ''}
                      />
                      {checkField(gtPriceOne, true, false) && (
                        <span className="error_msg">
                          <FormattedMessage id="Field is required" />
                        </span>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataGT;
