import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ModalDlg from 'components/Basic/ModalDlg';
import { ArrowBack, AccessTime, ChevronRightOutlined } from '@material-ui/icons';
import { useAppSelector } from 'utilities/redux';
import { formatDays, sliceDateTimeToDayjsUtcString } from 'utilities/transformDateTime';
import checkmark from 'assets/img/checkmark.svg';
import dineInTable from 'assets/img/dineInTable.svg';
import dayjs from 'dayjs';

type OffersPreviewModalType = {
  isShowModal: boolean;
  deal: any;
  onShowModal: () => void;
};

const OffersPreviewModal: React.FC<OffersPreviewModalType> = ({ isShowModal, deal, onShowModal }) => {
  const intl = useIntl();
  const { title, description, images, schedule, locationType, restaurantIds } = deal;
  const { dailyStartTime, dailyEndTime, isMonday, isTuesday, isWednesday, isThursday, isFriday, isSaturday, isSunday } =
    schedule as any;

  const days = [
    isMonday && intl.formatMessage({ id: 'Mon' }),
    isTuesday && intl.formatMessage({ id: 'Tue' }),
    isWednesday && intl.formatMessage({ id: 'Wed' }),
    isThursday && intl.formatMessage({ id: 'Thu' }),
    isFriday && intl.formatMessage({ id: 'Fri' }),
    isSaturday && intl.formatMessage({ id: 'Sat' }),
    isSunday && intl.formatMessage({ id: 'Sun' }),
  ];

  const { list } = useAppSelector(state => state.restaurantReducer);
  const [currentLocation, setCurrentLocation] = useState<any>([]);
  useEffect(() => {
    if (isShowModal) {
      const filteredLocations = list.rows.filter((restaurant: any) => restaurantIds?.includes(restaurant.id));
      setCurrentLocation(filteredLocations);
    }
  }, [isShowModal]);

  const correctTime = (time: any) => {
    return dayjs(sliceDateTimeToDayjsUtcString(time)).utc().format('HH:mm');
  };

  const correctImage = (item: any) => {
    if (item) {
      const { image, base64 } = item;

      if (image) return image;

      if (base64) return `data:image/jpeg;base64, ${base64}`;
    }
  };

  const OfferCatalogItem = () => {
    return (
      <div className="item">
        <p className="item_title">
          <FormattedMessage id={'Offer catalog'} />
        </p>
        <div className="item_card">
          <div className="catalog_preview">
            {images && images[0] ? (
              <img src={correctImage(images[0])} alt={'preview'} width="100%" height="100%" />
            ) : (
              <div style={{ height: '100px' }} />
            )}
            <div className="body">
              <p className="title">{title}</p>
              <div className="time">
                <AccessTime className="time_icon" fontSize={'inherit'} />
                <p className="time_value">
                  {correctTime(dailyStartTime)} - {correctTime(dailyEndTime)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DetailOfferItem = () => {
    return (
      <div className="item">
        <p className="item_title">
          <FormattedMessage id={'Detail offer'} />
        </p>
        <div className="item_card">
          <div className="detail_preview">
            {images && images[0] ? (
              <img src={correctImage(images[0])} alt={'preview'} width="180px" height="139px" />
            ) : (
              <div style={{ height: '100px' }} />
            )}
            <div className="body">
              <p className="title">{title}</p>
              <p className="desc">{description}</p>
              <div className="conditions">
                <p className="condition">
                  <img className="checkmark" src={checkmark} alt="checkmark" height={16} width={16} />
                  <FormattedMessage id={'Register in the app'} />
                </p>
                <p className="condition">
                  <img className="checkmark" src={checkmark} alt="checkmark" height={16} width={16} />
                  {correctTime(dailyStartTime)} - {correctTime(dailyEndTime)}
                </p>
                {days.length !== 0 && (
                  <p className="condition">
                    <img className="checkmark" src={checkmark} alt="checkmark" height={16} width={16} />
                    {formatDays(days)}
                  </p>
                )}
                {locationType === 'geofence' && currentLocation && (
                  <p className="location">
                    <img className="checkmark" src={checkmark} alt="checkmark" height={16} width={16} />
                    <div className="descriptionLocation">
                      Aplica solo en restaurante: {currentLocation.map((item: any) => item.description).join(' / ')}
                    </div>
                    <ChevronRightOutlined className="chevron" />
                  </p>
                )}
                {locationType === 'everywhere' && (
                  <p className="condition">
                    <img className="checkmark" src={checkmark} alt="checkmark" height={16} width={16} />
                    {intl.formatMessage({ id: 'All restaurants' })}
                  </p>
                )}
                <p className="terms">Terminos v Conditiones</p>
              </div>
              <p className="subtitle">
                <FormattedMessage id={'How use deal'} />
              </p>
              <div className="redirect">
                <div className="redirect_inner">
                  <img className="dine_in_table" src={dineInTable} alt="dineInTable" height={14} width={14} />
                  <p className="text">
                    <FormattedMessage id={'Use at restaurant'} />
                  </p>
                </div>
                <ChevronRightOutlined className="chevron" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ModalDlg className="offers_preview_modal" visible={isShowModal} width={514} handleCancel={onShowModal} isCenter>
      <div className="modal_header">
        <button type="button" className="back_button" onClick={onShowModal}>
          <ArrowBack fontSize={'inherit'} />
        </button>
        <h3 className="modal_header_title">
          <FormattedMessage id={'Offer preview'} />
        </h3>
      </div>
      <div className="modal_body">
        <OfferCatalogItem />
        <DetailOfferItem />
      </div>
    </ModalDlg>
  );
};

OffersPreviewModal.defaultProps = {};

export default OffersPreviewModal;
