import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import ConfigureRegionModal from 'components/Dashboard/Restaurants/ConfigureRegionModal';
import { AddBox, SettingsApplications, Delete } from '@material-ui/icons';
import toast from 'components/Basic/Toast';
import { getCountry } from 'utilities/common';
import DeleteConfirmModal from '../../../components/Basic/DeleteConfirmModal';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import {
  useLazyGetDepartmentsQuery,
  useСreateDepartmentMutation,
  useDeleteDepartmentMutation,
  useLazyGetMunicipalitiesQuery,
  useСreateMunicipalityMutation,
  useDeleteMunicipalityMutation,
  useLazyGetZonesQuery,
  useCreateZoneMutation,
  useDeleteZoneMutation,
  useAssignRestaurantsMutation,
  useLazyGetRestaurantsFromZoneQuery,
} from 'core/region/RegionService';
import { setDepartmentsData, setMunicipalitiesData, setZonesData } from 'core/region/RegionSlice';
import { useLazyGetRestaurantListQuery } from 'core/restaurant/RestaurantService';

const ConfigureRegion = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [state, setState] = useState({
    isOpenConfigueModal: false,
    title: '',
    description: '',
    buttonText: '',
    configueType: '',
    selectedDepartment: null,
    selectedMunicipality: null,
    selectedZone: null,
    availableRestaurants: [],
    assignedRestaurants: [],
    isOpenDeleteModal: false,
    regionType: '',
    itemForDelete: null,
    titleForModal: '',
  });

  const [getDepartments] = useLazyGetDepartmentsQuery();
  const [createDepartment] = useСreateDepartmentMutation();
  const [deleteDepartment] = useDeleteDepartmentMutation();
  const [getMunicipalities] = useLazyGetMunicipalitiesQuery();
  const [createMunicipality] = useСreateMunicipalityMutation();
  const [deleteMunicipality] = useDeleteMunicipalityMutation();
  const [getZones] = useLazyGetZonesQuery();
  const [createZone] = useCreateZoneMutation();
  const [deleteZone] = useDeleteZoneMutation();
  const [assignRestaurants] = useAssignRestaurantsMutation();
  const [getRestaurantsFromZone] = useLazyGetRestaurantsFromZoneQuery();
  const [getRestaurantList] = useLazyGetRestaurantListQuery();

  const { departments, municipalities, zones } = useAppSelector(state => state.regionReducer);

  useEffect(async () => {
    try {
      setInitialRegion();
      const res = await getDepartments({ offset: 0, limit: 100 }).unwrap();
      dispatch(setDepartmentsData({ departments: res.rows }));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const setInitialRegion = () => {
    dispatch(setMunicipalitiesData({ municipalities: [] }));
    dispatch(setZonesData({ zones: [] }));
  };

  const handleDepartment = async item => {
    setState(prev => ({
      ...prev,
      selectedDepartment: item,
      selectedMunicipality: null,
      selectedZone: null,
      availableRestaurants: [],
      assignedRestaurants: [],
    }));
    try {
      const res = await getMunicipalities({ offset: 0, limit: 100, filter: `&filter=deptCode eq ${item.id}` }).unwrap();
      dispatch(setMunicipalitiesData({ municipalities: res.rows }));
      dispatch(setZonesData({ zones: [] }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleMunicipality = async item => {
    setState(prev => ({
      ...prev,
      selectedMunicipality: item,
      selectedZone: null,
      availableRestaurants: [],
      assignedRestaurants: [],
    }));
    try {
      const res = await getZones({
        offset: 0,
        limit: 100,
        filter: `&filter=deptCode eq ${state.selectedDepartment.id}&filter=municipalityCode eq ${item.id}`,
      }).unwrap();
      dispatch(setZonesData({ zones: res.rows }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleZone = async item => {
    try {
      const res = await getRestaurantList({
        filter: `&filter=ex.radius eq 30&filter=ex.zoneLong eq ${item.long}&filter=ex.zoneLat eq ${item.lat}`,
      }).unwrap();
      await getRestaurantsFromZone({ id: item.id })
        .unwrap()
        .then(data => {
          const restaurantsFromZone = [];
          data.rows.map(r => {
            if (r.restaurant) {
              restaurantsFromZone.push(r.restaurant);
            }
          });
          setState(prev => ({
            ...prev,
            assignedRestaurants: restaurantsFromZone,
            availableRestaurants: [...res.rows],
          }));
        });
    } catch (err) {
      console.log(err);
    }
    setState(prev => ({ ...prev, selectedZone: item }));
  };

  const handleConfigureModal = type => {
    if (type === 'provinces') {
      setState(prev => ({
        ...prev,
        isOpenConfigueModal: true,
        configueType: 'provinces',
        title: 'Crear un departamento / provincia',
        description: 'Busca la sugerencia en los resultados de Google Maps',
        buttonText: 'Crear registro',
      }));
    } else if (type === 'sectors') {
      setState(prev => ({
        ...prev,
        isOpenConfigueModal: true,
        configueType: 'sectors',
        title: 'Crear un municipio o sector',
        description: 'Busca la sugerencia en los resultados de Google Maps',
        buttonText: 'Crear registro',
      }));
    } else if (type === 'zone') {
      setState(prev => ({
        ...prev,
        isOpenConfigueModal: true,
        configueType: 'zone',
        title: 'Crear una zona o área',
        description: 'Busca la sugerencia en los resultados de Google Maps',
        buttonText: 'Crear registro',
      }));
    } else {
      setState(prev => ({
        ...prev,
        isOpenConfigueModal: true,
        configueType: 'assigned_restaurants',
        title: 'Selecciona los restaurantes de la zona',
        description: 'Se muestran recomendaciones de restaurantes cercanos',
        buttonText: 'Guardar configuración',
      }));
    }
  };

  const handleCloseModal = () => {
    setState(prev => ({ ...prev, isOpenConfigueModal: false }));
  };

  const handleSelectRegion = async (regionInfo, restaurantIds) => {
    const { selectedDepartment, selectedMunicipality, selectedZone, configueType } = state;
    if (regionInfo) {
      setState(prev => ({ ...prev, isOpenConfigueModal: false }));
      if (configueType === 'provinces') {
        try {
          await createDepartment({
            placeId: regionInfo.placeId,
            lat: regionInfo.latLng.lat,
            long: regionInfo.latLng.lng,
            description: regionInfo.address,
          }).unwrap();
          const res = await getDepartments({ offset: 0, limit: 100 }).unwrap();
          dispatch(setDepartmentsData({ departments: res.rows }));
          toast.success({
            title: intl.formatMessage({
              id: `${configueType} is created successfully!`,
            }),
          });
        } catch (err) {
          toast.error({
            title: intl.formatMessage({
              id: `Creating ${configueType} is failure!`,
            }),
          });
        }
      } else if (configueType === 'sectors') {
        if (selectedDepartment) {
          try {
            await createMunicipality({
              placeId: regionInfo.placeId,
              lat: regionInfo.latLng.lat,
              long: regionInfo.latLng.lng,
              deptCode: selectedDepartment.id,
              description: regionInfo.address,
            }).unwrap();
            const res = await getMunicipalities({
              offset: 0,
              limit: 100,
              filter: `&filter=deptCode eq ${selectedDepartment.id}`,
            }).unwrap();
            dispatch(setMunicipalitiesData({ municipalities: res.rows }));
            dispatch(setZonesData({ zones: [] }));
            toast.success({
              title: intl.formatMessage({
                id: `${configueType} is created successfully!`,
              }),
            });
          } catch (err) {
            toast.error({
              title: intl.formatMessage({
                id: `Creating ${configueType} is failure!`,
              }),
            });
          }
        } else {
          toast.error({
            title: intl.formatMessage({
              id: 'Department/province is not selected!',
            }),
          });
        }
      } else if (configueType === 'zone') {
        if (selectedDepartment && selectedMunicipality) {
          try {
            await createZone({
              placeId: regionInfo.placeId,
              lat: regionInfo.latLng.lat,
              long: regionInfo.latLng.lng,
              deptCode: selectedDepartment.id,
              municipalityCode: selectedMunicipality.id,
              description: regionInfo.address,
            }).unwrap();
            const res = await getZones({
              offset: 0,
              limit: 100,
              filter: `&filter=deptCode eq ${selectedDepartment.id}&filter=municipalityCode eq ${selectedMunicipality.id}`,
            }).unwrap();
            dispatch(setZonesData({ zones: res.rows }));
            toast.success({
              title: intl.formatMessage({
                id: `${configueType} is created successfully!`,
              }),
            });
          } catch (err) {
            toast.error({
              title: intl.formatMessage({
                id: `Creating ${configueType} is failure!`,
              }),
            });
          }
        } else {
          toast.error({
            title: intl.formatMessage({
              id: 'Department or Sector is not selected!',
            }),
          });
        }
      }
    } else if (configueType !== 'assigned_restaurants') {
      toast.error({
        title: intl.formatMessage({
          id: 'Location name is not selected!',
        }),
      });
    }
    if (configueType !== 'assigned_restaurants') {
      setState(prev => ({ ...prev, isOpenConfigueModal: false }));
    } else if (configueType === 'assigned_restaurants') {
      if (selectedZone) {
        try {
          await assignRestaurants({ id: selectedZone.id, restaurants: restaurantIds || [] }).unwrap();
          const res = await getRestaurantsFromZone({ id: selectedZone.id }).unwrap();
          const restaurantsFromZone = res.rows.map(r => {
            return r.restaurant;
          });
          setState(prev => ({ ...prev, assignedRestaurants: restaurantsFromZone }));
          toast.success({
            title: intl.formatMessage({
              id: 'Restaurant is assigned successfully!',
            }),
          });
        } catch (err) {
          console.log(err);
        }
        setState(prev => ({ ...prev, isOpenConfigueModal: false }));
      } else {
        toast.error({
          title: intl.formatMessage({ id: 'Zone is not selected!' }),
        });
      }
    }
  };

  const handleDeleteFromModal = async () => {
    const { selectedDepartment, selectedMunicipality, regionType, itemForDelete: item } = state;
    if (regionType === 'provinces') {
      try {
        await deleteDepartment({ id: item.id }).unwrap();
        setInitialRegion();
        const res = await getDepartments({ offset: 0, limit: 100 }).unwrap();
        dispatch(setDepartmentsData({ departments: res.rows }));
        setState(prev => ({
          ...prev,
          selectedDepartment: null,
          availableRestaurants: [],
          assignedRestaurants: [],
        }));
        toast.success({
          title: intl.formatMessage({
            id: `${regionType} is deleted successfully!`,
          }),
        });
      } catch (err) {
        console.log(err);
      }
    } else if (regionType === 'sectors') {
      try {
        await deleteMunicipality({ id: item.id }).unwrap();
        dispatch(setZonesData({ zones: [] }));
        const res = await getMunicipalities({
          offset: 0,
          limit: 100,
          filter: `&filter=deptCode eq ${selectedDepartment.id}`,
        }).unwrap();
        dispatch(setMunicipalitiesData({ municipalities: res.rows }));
        setState(prev => ({
          ...prev,
          selectedMunicipality: null,
          availableRestaurants: [],
          assignedRestaurants: [],
        }));
        toast.success({
          title: intl.formatMessage({
            id: `${regionType} is deleted successfully!`,
          }),
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        await deleteZone({ id: item.id }).unwrap();
        const res = await getZones({
          offset: 0,
          limit: 100,
          filter: `&filter=deptCode eq ${selectedDepartment.id}&filter=municipalityCode eq ${selectedMunicipality.id}`,
        }).unwrap();
        dispatch(setZonesData({ zones: res.rows }));
        setState(prev => ({
          ...prev,
          selectedZone: null,
          availableRestaurants: [],
          assignedRestaurants: [],
        }));
        toast.success({
          title: intl.formatMessage({
            id: `${regionType} is deleted successfully!`,
          }),
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleDeleteRegion = (regionType, item) => {
    let type = '';
    if (regionType === 'provinces') {
      type = 'provincia';
    }
    if (regionType === 'sectors') {
      type = 'sector';
    }
    if (regionType === 'zone') {
      type = 'zona';
    }
    const titleForModal = `¿Está seguro de que desea eliminar ${type}? Eliminar ${type} también eliminará todo lo asociado con él`;
    setState(prev => ({
      ...prev,
      isOpenDeleteModal: true,
      regionType,
      itemForDelete: item,
      titleForModal,
    }));
  };

  return (
    <div className="configure_region_layout">
      <DeleteConfirmModal
        isOpenModal={state.isOpenDeleteModal}
        title={state.titleForModal}
        onCancel={() => {
          setState(prev => ({ ...prev, isOpenDeleteModal: false }));
        }}
        onOk={() => {
          handleDeleteFromModal();
          setState(prev => ({ ...prev, isOpenDeleteModal: false }));
        }}
      />
      <ContentHeader type="configure_region" />
      <div className="region_description">
        <div>
          <h3>
            <FormattedMessage id="Regions and reverse geocoding settings" />
          </h3>
          <p className="description">
            <FormattedMessage id="Configue Region Description" />
          </p>
        </div>
        <div>
          <p className="description">
            <FormattedMessage id="Geocoding country" />
          </p>
          <p className="title">{getCountry()}</p>
        </div>
      </div>
      <div className="region_setting_wrapper">
        <div className="card">
          <div className="card_header">
            <p className="description">
              <FormattedMessage id="Departments / Provinces" />
            </p>
            <AddBox onClick={() => handleConfigureModal('provinces')} />
          </div>
          <div className="card_list">
            {departments &&
              departments.map((item, key) => (
                <div
                  className={state.selectedDepartment && state.selectedDepartment.id === item.id ? 'active' : ''}
                  key={key}
                >
                  <p className="title">
                    <span onClick={() => handleDepartment(item)}>{item.description}</span>
                    <Delete onClick={() => handleDeleteRegion('provinces', item)} />
                  </p>
                  <p className="description">{item.placeId}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="card">
          <div className="card_header">
            <p className="description">
              <FormattedMessage id="Municipalities / Sectors" />
            </p>
            <AddBox onClick={() => handleConfigureModal('sectors')} />
          </div>
          <div className="card_list">
            {municipalities &&
              municipalities.map((item, key) => (
                <div
                  className={state.selectedMunicipality && state.selectedMunicipality.id === item.id ? 'active' : ''}
                  key={key}
                >
                  <p className="title">
                    <span onClick={() => handleMunicipality(item)}>{item.description}</span>
                    <Delete onClick={() => handleDeleteRegion('sectors', item)} />
                  </p>
                  <p className="description">{item.placeId}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="card">
          <div className="card_header">
            <p className="description">
              <FormattedMessage id="Zones / Areas" />
            </p>
            <AddBox onClick={() => handleConfigureModal('zone')} />
          </div>
          <div className="card_list">
            {zones &&
              zones.map((item, key) => (
                <div className={state.selectedZone && state.selectedZone.id === item.id ? 'active' : ''} key={key}>
                  <p className="title">
                    <span onClick={() => handleZone(item)}>{item.description}</span>
                    <Delete onClick={() => handleDeleteRegion('zone', item)} />
                  </p>
                  <p className="description">{item.placeId}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="card">
          <div className="card_header">
            <p className="description">
              <FormattedMessage id="Assigned restaurants" />
            </p>
            <SettingsApplications onClick={() => handleConfigureModal('assigned_restaurants')} />
          </div>
          <div className="card_list">
            {state.assignedRestaurants.map((item, key) => (
              <div key={key}>
                <p className="title">{item.description}</p>
                <p className="description">{item.address}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ConfigureRegionModal
        isOpenModal={state.isOpenConfigueModal}
        configueType={state.configueType}
        title={state.title}
        availableRestaurants={state.availableRestaurants}
        assignedRestaurants={state.assignedRestaurants}
        description={state.description}
        buttonText={state.buttonText}
        onSelectRegion={handleSelectRegion}
        onCloseModal={handleCloseModal}
      />
    </div>
  );
};

export default ConfigureRegion;
