import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import CouponList from 'containers/Dashboard/Coupons/CouponList';

class Promotions extends PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path="/dashboard/coupons" component={CouponList} />
      </Switch>
    );
  }
}

export default Promotions;
