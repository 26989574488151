import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Button, Switch } from 'antd';
import ModalDlg from 'components/Basic/ModalDlg';
import { ArrowForward, Edit, Delete } from '@material-ui/icons';
import { useAppSelector } from 'utilities/redux';

const CouponViewModal = ({ intl, couponInfo, isOpenModal, onCloseModal, onEdit, onDelete }) => {
  const [isActive, setIsActive] = useState(false);

  const { products } = useAppSelector(state => state.catalogReducer);

  const conditions = {
    CO: [
      {
        value: 'CO',
        label: intl.formatMessage({ id: 'Customer must enter Coupon Only' }),
      },
      {
        value: 'MV',
        label: intl.formatMessage({
          id: 'The customer must reach a Minimum Value in the cart',
        }),
      },
      {
        value: 'MQ',
        label: intl.formatMessage({
          id: 'Have a Minimum Quantity of the same product',
        }),
      },
    ],
    MP: [
      {
        value: 'CO',
        label: intl.formatMessage({ id: 'Customer must enter Coupon Only' }),
      },
      {
        value: 'SP',
        label: intl.formatMessage({
          id: 'The customer must have the Same Product within the order',
        }),
      },
    ],
  };
  if (!couponInfo) {
    return null;
  }

  const valueCondition = () => {
    if (couponInfo.type === 'MP') {
      const value = conditions['MP'].find(item => {
        return item.value === couponInfo.condition;
      });
      return value.label;
    } else {
      return conditions[couponInfo.type][0].label;
    }
  };

  return (
    <ModalDlg className="coupon_view_modal" visible={isOpenModal} width={574} handleCancel={onCloseModal} isCenter>
      <div className="modal_header">
        <div className="name_wrapper">
          <h3>{couponInfo.name || ''}</h3>
          <p className="last_import_time">
            {`Inicio: ${
              couponInfo.startAt ? `${moment(couponInfo.startAt).tz('America/Guatemala').format('LL')}` : ''
            } - Final: ${couponInfo.endAt ? `${moment(couponInfo.endAt).tz('America/Guatemala').format('LL')}` : ''}`}
          </p>
        </div>
        <div className="code_wrapper">
          <p>{`COD: ${couponInfo.code}`}</p>
          <p>Utilizado {couponInfo.usedCount || 0} veces</p>
        </div>
      </div>
      <div className="modal_content">
        <div className="coupon_condition">
          <p className="title">
            <FormattedMessage id="Condition" />
          </p>
          <p className="description">{valueCondition()}</p>
        </div>
        <div className="coupon_outcome">
          <p className="title">Resultado</p>
          {couponInfo.rewards.map(r => (
            <p className="description">
              {products.rows.filter(p => p.id == r.product).length !== 0 && (
                <strong>
                  Regala producto - {r.quantity} x {products.rows.filter(p => p.id == r.product)[0].name}
                </strong>
              )}
            </p>
          ))}
        </div>
        <div className="coupon_limit">
          <p className="title">
            <FormattedMessage id="Limit" />
          </p>
          <p className="description">
            El cupón puede ser usado un máximo de <strong>{couponInfo.userLimit} veces por usuario</strong>
          </p>
        </div>
      </div>
      <div className="modal_footer">
        <div className="aditional_detail">
          <p>Activo</p>
          <Switch
            checked={couponInfo.state === 'A'}
            // onChange={checked => setIsActive(checked)}
            // checked={isActive}
          />
        </div>
        <div className="btn_wrapper">
          <Button className="delete_btn" onClick={onDelete}>
            <Delete />
          </Button>
          <Button className="edit_btn" onClick={onEdit}>
            <Edit />
          </Button>
          <Button className="close_btn" onClick={onCloseModal}>
            <FormattedMessage id="Close" />
            <ArrowForward />
          </Button>
        </div>
      </div>
    </ModalDlg>
  );
};
CouponViewModal.defaultProps = {
  isOpenModal: false,
  couponInfo: null,
  products: {},
};

export default compose(injectIntl)(CouponViewModal);
