import { api } from '..';
import { ListData, SelectedUserData } from './types';

export const customerApi = api.enhanceEndpoints({ addTagTypes: ['Customer'] }).injectEndpoints({
  endpoints: build => ({
    getCustomerList: build.query<
      ListData,
      { offset: number; limit: number; or: boolean; filter: string; sort: string }
    >({
      query: ({ offset, limit, or, filter, sort }) => ({
        url: `v0/customers?offset=${offset || 0}&limit=${limit || 5}${or ? `&or=${or}` : ''}${filter || ''}${
          sort ? `&sort=${sort}` : '&sort=createdAt desc'
        }`,
        method: 'GET',
      }),
    }),
    getCustomer: build.query<ListData, { id: number }>({
      query: ({ id }) => ({
        url: `v0/customers/${id}`,
        method: 'GET',
      }),
    }),
    createCustomer: build.mutation<any, any>({
      query: body => ({
        url: `v0/customers`,
        method: 'POST',
        body,
      }),
    }),
    updateCustomer: build.mutation<any, any>({
      query: body => ({
        url: `v0/customers/${body.id}`,
        method: 'PUT',
        body,
      }),
    }),
    getCustomerTags: build.query<any, { offset: number; limit: number }>({
      query: ({ offset, limit }) => ({
        url: `v0/customers/tags?offset=${offset || 0}&limit=${limit || 1000}`,
        method: 'GET',
      }),
    }),
    createCustomerTags: build.mutation<any, any>({
      query: body => ({
        url: `v0/customers/${body.id}/tags`,
        method: 'POST',
        body,
      }),
    }),
    updateCustomerTags: build.mutation<any, any>({
      query: ({ body, id, tag }) => ({
        url: `v0/customers/${id}/tags/${tag}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteCustomerTags: build.mutation<any, { id: number; tag: number }>({
      query: ({ id, tag }) => ({
        url: `v0/customers/${id}/tags/${tag}`,
        method: 'DELETE',
      }),
    }),
    deleteCustomer: build.mutation<void, number>({
      query: id => ({
        url: `v0/customers/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetCustomerListQuery,
  useLazyGetCustomerQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useLazyGetCustomerTagsQuery,
  useCreateCustomerTagsMutation,
  useUpdateCustomerTagsMutation,
  useDeleteCustomerTagsMutation,
  useDeleteCustomerMutation,
} = customerApi;
