import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, CampaignData } from './types';

const initialState: initState = {
  list: {
    campaigns: [],
    totalCount: 0,
  },
};

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaignData(state: initState, action: PayloadAction<{ list: CampaignData }>) {
      state.list = action.payload.list;
    },
  },
});

export const { setCampaignData } = campaignSlice.actions;

export default campaignSlice.reducer;
