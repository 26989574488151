import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import StatusCard from 'components/Dashboard/Overview/StatusCard';
import SaleChart from 'components/Dashboard/Overview/SaleChart';
import TopSaleList from 'components/Dashboard/Overview/TopSaleList';
import Loading from 'components/Basic/Loading';
import { useLazyGetOverviewQuery, useLazyGetSalesQuery, useLazyGetTopsQuery } from 'core/tracking/TrackingService';
import { useLazyGetItemsQuery } from 'core/catalog/CatalogService';
import { setOverviewData, setSalesData, setTopSalesData } from 'core/tracking/TrackingSlice';
import { useAppSelector, useAppDispatch } from 'utilities/redux';
import { setProductsData } from 'core/catalog/CatalogSlice';

const data = [
  {
    name: 'Dom',
    Última: 0,
    Actual: 0,
  },
  {
    name: 'Lun',
    Última: 0,
    Actual: 0,
  },
  {
    name: 'Mar',
    Última: 0,
    Actual: 0,
  },
  {
    name: 'Mie',
    Última: 0,
    Actual: 0,
  },
  {
    name: 'Jue',
    Última: 0,
    Actual: 0,
  },
  {
    name: 'Vie',
    Última: 0,
    Actual: 0,
  },
  {
    name: 'Sab',
    Última: 0,
    Actual: 0,
  },
];

const Overview = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState(data);

  const [getOverview] = useLazyGetOverviewQuery();
  const [getSales] = useLazyGetSalesQuery();
  const [getTops] = useLazyGetTopsQuery();
  const [getItems] = useLazyGetItemsQuery();

  const dispatch = useAppDispatch();

  const { overviewData, topSales } = useAppSelector(state => state.trackingReducer);
  const { products } = useAppSelector(state => state.catalogReducer);

  useEffect(async () => {
    setIsLoading(true);
    getOverviewData();
    getSalesData();
    getTopsData();
  }, []);

  const getOverviewData = async () => {
    await getOverview({})
      .unwrap()
      .then(res => {
        dispatch(
          setOverviewData({
            overviewData: res,
          }),
        );
      });
  };

  const getTopsData = async () => {
    await getTops({
      from: moment().tz('America/Guatemala').toString(),
      to: moment().tz('America/Guatemala').toString(),
      limit: 5,
    })
      .unwrap()
      .then(async res => {
        dispatch(
          setTopSalesData({
            topSales: res,
          }),
        );
        if (Array.isArray(res) && res.length !== 0) {
          let items = '';
          for (let i = 0; i < res.length; i += 1) {
            items += ` ${res[i].item}`;
          }
          await getItems({
            offset: 0,
            limit: 5,
            filter: `&filter=id in${items}`,
          })
            .unwrap()
            .then(products => {
              dispatch(
                setProductsData({
                  products: products,
                }),
              );
              setIsLoading(false);
            });
        } else {
          dispatch(
            setProductsData({
              products: {},
            }),
          );
          setIsLoading(false);
        }
      })
      .catch(error => {
        setIsLoading(false);
      });
  };

  const getSalesData = async () => {
    moment.updateLocale('en', {
      week: {
        dow: 0,
      },
    });
    const current_from = moment().tz('America/Guatemala').startOf('week');
    const current_to = moment().tz('America/Guatemala').endOf('week');
    const last_from = moment().tz('America/Guatemala').subtract(1, 'weeks').startOf('week');
    // const last_to = moment().tz('America/Guatemala').subtract(1, 'weeks').endOf('week');

    await getSales({
      from: last_from.toString(),
      to: current_to.toString(),
    })
      .unwrap()
      .then(res => {
        dispatch(
          setSalesData({
            sales: res,
          }),
        );
        for (let i = 0; i < res.length; i += 1) {
          const days = moment(res[i].date).tz('America/Guatemala').day();
          if (moment(res[i].date).diff(current_from, 'days') >= 0) {
            chartData[days].Actual = res[i].orders;
          } else {
            chartData[days].Última = res[i].orders;
          }
        }
        setChartData([...chartData]);
      });
  };

  return (
    <div className="overview_layout">
      <Loading visible={isLoading} />
      <ContentHeader type="overview" />
      <div className="overview_wrapper">
        <div className="status_wrapper">
          <StatusCard count={overviewData.onlineCustomers || 0} description={<FormattedMessage id="Online users" />} />
          <StatusCard count={overviewData.todayOrders || 0} description={<FormattedMessage id="Orders today" />} />
          <StatusCard
            count={overviewData.processingOrders || 0}
            description={<FormattedMessage id="Orders processing" />}
          />
          <StatusCard
            count={overviewData.scheduledOrders || 0}
            description={<FormattedMessage id="Scheduled Orders" />}
          />
        </div>
        <div className="sale_wrapper">
          <div className="graph_wrapper">
            <div className="chart_wrapper">
              <div className="chart_title">
                <p>
                  <FormattedMessage id="Home sales of the week" />
                </p>
              </div>
              <SaleChart data={chartData} />
              <div className="chart_period">
                <p className="last_week">
                  <FormattedMessage id="Last week" />
                </p>
                <p className="current_week">
                  <FormattedMessage id="Current week" />
                </p>
              </div>
            </div>
            <div className="blank_wrapper" />
          </div>
          <div className="top_sale_wrapper">
            <TopSaleList topProducts={products.rows} topSales={topSales} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
