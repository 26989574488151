import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import ModalDlg from 'components/Basic/ModalDlg';
import { Edit, ArrowForward } from '@material-ui/icons';

function AgentViewModal({ agentInfo, isOpenModal, onCloseModal }) {
  if (!agentInfo) return null;

  return (
    <ModalDlg className="ots_agent_detail_modal" visible={isOpenModal} width={574} handleCancel={onCloseModal} isCenter>
      <div className="modal_header">
        <div>
          <h3>{`${agentInfo.firstName} ${agentInfo.lastName}`}</h3>
          <p className="description">{agentInfo.lastOrderDate || '-'}</p>
        </div>
        <p className="userid">{`ID ${agentInfo.id}`}</p>
      </div>
      <div className="modal_content">
        <div className="user_analytics">
          <div>
            <p className="quantity">{agentInfo.todayOrders || 0}</p>
            <p className="status_name">
              <FormattedMessage id="Orders taken today" />
            </p>
          </div>
          <div>
            <p className="quantity">-</p>
            <p className="status_name">
              <FormattedMessage id="Connection hours today" />
            </p>
          </div>
          <div>
            <p className="quantity">
              {agentInfo.todayCallTime ? parseFloat(agentInfo.todayCallTime / 3600000).toFixed(2) : 0}
            </p>
            <p className="status_name">
              <FormattedMessage id="Hours taking orders today" />
            </p>
          </div>
        </div>
      </div>
      <div className="modal_footer">
        <div className="aditional_detail">
          <p className="last_access_date">{agentInfo.last_access_date || '-'}</p>
          <p className="ip_address">{agentInfo.last_access_ip || '-'}</p>
        </div>
        <div className="btn_wrapper">
          <NavLink to={`/dashboard/ots/${agentInfo.id}`}>
            <Button className="edit_btn">
              <Edit />
            </Button>
          </NavLink>
          <Button className="close_btn" onClick={onCloseModal}>
            <FormattedMessage id="Close" />
            <ArrowForward />
          </Button>
        </div>
      </div>
    </ModalDlg>
  );
}

AgentViewModal.defaultProps = {
  isOpenModal: false,
  agentInfo: null,
};

export default AgentViewModal;
