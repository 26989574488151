import dayjs from 'dayjs';
import { transformDateTimeToDayjsUtc } from 'utilities/transformDateTime';

type ValidationDates = boolean | string;
const animations = [
  { id: 'flare', text: 'Flare' },
  { id: 'snow', text: 'Snow' },
  { id: 'confetti_effect', text: 'Confetti Effect' },
  { id: 'only_restaurant', text: 'Only Restaurant' },
];

const generateRedemptionConfiguration = (offerId: number, redemptionConfiguration: any) => ({
  type: offerId ? redemptionConfiguration?.type : 'short_code',
  expiration: offerId ? redemptionConfiguration?.expiration : '',
  limitType: offerId ? redemptionConfiguration?.limitType : 'unlimited',
  limit: offerId ? redemptionConfiguration?.limit : 0,
  isRepeatable: offerId ? redemptionConfiguration?.isRepeatable : false,
  repeatDaysCount: offerId ? redemptionConfiguration?.repeatDaysCount : 0,
  repeatStartTime: offerId ? transformDateTimeToDayjsUtc(redemptionConfiguration?.repeatStartTime) : null,
  repeatCount: offerId ? redemptionConfiguration?.repeatCount : 0,
  numberOfUsed: offerId ? redemptionConfiguration?.numberOfUsed : -1,
});

const generateSchedule = (offerId: number, schedule: any) => ({
  startDate: offerId ? schedule?.startDate?.slice(0, 10) : null,
  startTime: offerId ? transformDateTimeToDayjsUtc(schedule?.startTime) : null,
  endDate: offerId ? schedule?.endDate?.slice(0, 10) : null,
  endTime: offerId ? transformDateTimeToDayjsUtc(schedule?.endTime) : null,
  dailyStartTime: offerId ? transformDateTimeToDayjsUtc(schedule?.dailyStartTime) : null,
  dailyEndTime: offerId ? transformDateTimeToDayjsUtc(schedule?.dailyEndTime) : null,
  isMonday: offerId ? schedule?.isMonday : true,
  isTuesday: offerId ? schedule?.isTuesday : true,
  isWednesday: offerId ? schedule?.isWednesday : true,
  isThursday: offerId ? schedule?.isThursday : true,
  isFriday: offerId ? schedule?.isFriday : true,
  isSaturday: offerId ? schedule?.isSaturday : true,
  isSunday: offerId ? schedule?.isSunday : true,
});

const imageObject = (id: number, language: string, offer: any) => {
  if (offer?.images) {
    if (!offer?.images[id]) {
      return null;
    }

    return {
      type: 'jpeg',
      language: language,
      image: offer?.images?.find((i: any) => i?.language === language)?.image,
    };
  }

  return null;
};

const defaultProductOne = (offerId: any, extendedData: any) => {
  if (offerId) {
    if (extendedData?.price || extendedData?.price === 0) {
      return extendedData?.price;
    } else if (extendedData?.productOne?.price || extendedData?.productOne?.price === 0) {
      return extendedData?.productOne?.price;
    }
  }

  return '';
};

export const genetateDefaultDealState = (offerId: any, offer: any) => {
  const toCamelCase = (str: string) => str?.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());

  const extendedDataType = toCamelCase(offer?.extendedData?.type);
  const extendedData = offerId ? offer.extendedData?.data[extendedDataType] : undefined;

  const lowerList = animations.filter(item => offer?.contentTags.find((tag: any) => tag.type === item.id));
  const upperList = animations.filter(item => !offer?.contentTags.find((tag: any) => tag.type === item.id));

  return {
    statusValid: true,
    redemptionsValid: true,
    extendedValid: true,
    imageValid: true,
    detailsValid: true,
    tabcatValid: true,
    datesValid: true,
    validationDates: true as ValidationDates,
    segmentValid: true,
    locationValid: true,
    isLoading: false,
    isActive: offerId ? offer?.isActive : false,
    activeTab: 0,
    tabScroll: 0,
    name: offerId ? offer?.name : '',
    campaign: offerId ? offer.campaign : '',
    location: offerId ? offer?.location?.map((l: any) => l.restaurantId) : [],
    redemptionConfiguration: generateRedemptionConfiguration(offerId, offer?.redemptionConfiguration),
    selectedTemplates: offerId ? offer?.extendedData?.type : '',
    promoName: offerId ? extendedData?.nameGt : '',
    gtShortDesc: offerId ? extendedData?.shortDescriptionGt : '',
    gtLongDesc: offerId ? extendedData?.longDescriptionGt : '',
    promoNameEn: offerId ? extendedData?.nameEn : '',
    enShortDesc: offerId ? extendedData?.shortDescriptionEn : '',
    enLongDesc: offerId ? extendedData?.longDescriptionEn : '',
    gtProductOne: offerId ? extendedData?.productOne?.productCode : '',
    gtQuantityOne: offerId ? extendedData?.productOne?.quantity : '',
    gtPriceOne: defaultProductOne(offerId, extendedData),
    gtDiscountLimitOne: offerId ? extendedData?.productOne?.discountLimit : '',
    gtProductTwo: offerId ? extendedData?.productTwo?.productCode : '',
    gtQuantityTwo: offerId ? extendedData?.productTwo?.quantity : '',
    gtPriceTwo: offerId ? extendedData?.productTwo?.price : '',
    gtDiscountLimitTwo: offerId ? extendedData?.productTwo?.discountLimit : '',
    gtProductCode: offerId ? extendedData?.productCode : '',
    gtDiscountAmount: offerId ? extendedData?.discountAmount : '',
    gtProductThree: offerId ? extendedData?.productThree?.productCode : '',
    gtQuantityThree: offerId ? extendedData?.productThree?.quantity : '',
    gtPriceThree: offerId ? extendedData?.productThree?.price : '',
    gtDiscountLimitThree: offerId ? extendedData?.productThree?.discountLimit : '',
    gtProductFour: offerId ? extendedData?.productFour?.productCode : '',
    gtQuantityFour: offerId ? extendedData?.productFour?.quantity : '',
    gtPriceFour: offerId ? extendedData?.productFour?.price : '',
    gtDiscountLimitFour: offerId ? extendedData?.productFour?.discountLimit : '',
    gtReducedQuantity: '',
    images: offerId ? [imageObject(0, 'english', offer), imageObject(1, 'spanish', offer)] : null,
    title: offerId ? offer?.title : '',
    description: offerId ? offer?.description : '',
    terms: offerId ? offer?.terms : '',
    schedule: generateSchedule(offerId, offer?.schedule),
    isUser: null,
    segment: offerId ? offer?.segment : [],
    selectedFile: null,
    arrUsers: [],
    tabs: offerId ? offer?.tabs?.map((tab: any) => tab.tabId) : [],
    category: offerId ? offer?.category : [],
    lowerList: lowerList,
    upperList: upperList,
    locationType: offerId ? offer?.locationType : 'everywhere',
    locations: offerId ? offer?.locations?.map((r: any) => r.restaurantId) : [],
    restaurantIds: offerId ? offer?.restaurantIds : [],
    isShortUrl: offerId ? offer?.isShortUrl : false,
    isHidden: offerId ? offer?.isHidden : false,
    isSecret: offerId ? offer?.isSecret : false,
  };
};
