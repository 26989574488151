import { api } from '..';
import { OperatorData } from './types';

export const operatorApi = api.enhanceEndpoints({ addTagTypes: ['Operator'] }).injectEndpoints({
  endpoints: build => ({
    getOperatorList: build.query<
      OperatorData,
      { offset: number; limit: number; or: boolean; filter: string; sort: string }
    >({
      query: ({ offset, limit, or, filter, sort }) => ({
        url: `v0/administrators?details=true&&offset=${offset || 0}&limit=${limit || 5}${or ? `&or=${or}` : ''}
        ${filter || ''}${sort ? `&sort=${sort}` : '&sort=createdAt desc'}`,
        method: 'GET',
      }),
    }),
    getOperator: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `v0/administrators/${id}?details=true`,
        method: 'GET',
      }),
    }),
    createOperator: build.mutation<any, any>({
      query: body => ({
        url: `v0/administrators`,
        method: 'POST',
        body: body.formData,
      }),
    }),
    updateOperator: build.mutation<any, any>({
      query: body => ({
        url: `v0/administrators/${body.id}`,
        method: 'PUT',
        body: body.formDataUpdate,
      }),
    }),
    deleteOperator: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `v0/administrators/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetOperatorListQuery,
  useLazyGetOperatorQuery,
  useCreateOperatorMutation,
  useUpdateOperatorMutation,
  useDeleteOperatorMutation,
} = operatorApi;
