import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import moment from 'moment-timezone';
import { withRouter, NavLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Button, Select } from 'antd';
import { ArrowForward, ArrowBack, Search, DeleteOutline, MapOutlined } from '@material-ui/icons';
import regionImg from '../../assets/img/region.svg';
import hambugerImg from 'assets/img/hambuger.svg';
import clockImg from 'assets/img/clock.svg';
import layerImg from 'assets/img/layer.svg';
import monitorImg from 'assets/img/monitor.svg';
import ambassadorImg from 'assets/img/ambassador.svg';
import scheduleImg from 'assets/img/schedule.svg';
import restaurantImg from 'assets/img/available_restaurant.svg';
import trafficImg from 'assets/img/monitor_traffic.svg';
import analyticsImg from 'assets/img/restaurant_analytics.svg';
import kpiImg from 'assets/img/report_kpi.svg';
import { useAppSelector } from 'utilities/redux';

const { Option } = Select;

const statuses = [
  { label: 'Disponible', value: 'A' },
  { label: 'No disponible', value: 'I' },
];

function ContentHeader({
  type,
  isEdit,
  rid,
  zid,
  uid,
  searchText,
  match,
  sectorInfo,
  onKeyDownSearch,
  onSearch,
  onSyncCatalog,
  onSyncAvailability,
  onSyncAmbassador,
  onSyncSchedule,
  onDeleteRestaurant,
  onUpdateRestaurant,
  onCreateDeliveryZone,
  onDeleteDeliveryZone,
  onCreateCustomer,
  onCreateOtsAgent,
  onCreateOperatorAgent,
  onUpdateAmbassador,
  onNewPromotion,
  onNewCoupon,
  onSaveOrderSchemes,
  onSaveBanner,
  onSaveLoyalty,
  onChangeAmbassadorStatus,
  onCreateSector,
  onUpdateSector,
  onDeleteSector,
  content,
}) {
  const { params } = match;

  const { selectedUser } = useAppSelector(state => state.customerReducer);
  const { selectedAmbassador } = useAppSelector(state => state.ambassadorReducer);
  const intl = useIntl();

  const handleSearch = e => {
    onSearch(e);
  };
  return (
    <div className="content_header_layout">
      {type === 'overview' && <FormattedMessage id="Statistical Operations Dashboard" />}
      {type === 'order' && (
        <div className="orders_header">
          <FormattedMessage id="McDonald’s Delivery Orders" />
          <Input
            suffix={<Search />}
            placeholder="Buscar pedido específico"
            value={searchText}
            onChange={e => handleSearch(e)}
            onKeyDown={e => onKeyDownSearch(e)}
          />
        </div>
      )}
      {type === 'restaurant' && (
        <div className="restaurants_header">
          <FormattedMessage id="Restaurants" />
          <div className="btn_wrapper">
            <NavLink to="/dashboard/restaurants/new">
              <Button className="new_restaurant_btn">
                <FormattedMessage id="New restaurant" />
                <ArrowForward />
              </Button>
            </NavLink>
            <NavLink to="/dashboard/restaurants/regions">
              <Button>
                <FormattedMessage id="Configure regions" />
                <img src={regionImg} alt="region" />
              </Button>
            </NavLink>
            <NavLink to="/dashboard/restaurants/deliveryzones">
              <Button className="delivery_zones_btn">
                <MapOutlined />
              </Button>
            </NavLink>
          </div>
        </div>
      )}
      {type === 'deliveryzones' && (
        <div className="deliveryzones_header">
          <div className="header_title">
            <NavLink to="/dashboard/restaurants">
              <div className="back_btn">
                <ArrowBack />
              </div>
            </NavLink>
            <FormattedMessage id="Global Trading Areas" />
          </div>
        </div>
      )}
      {type === 'restaurant_new' && (
        <div className="restaurants_new_header">
          <div className="header_title">
            <NavLink to="/dashboard/restaurants">
              <div className="back_btn">
                <ArrowBack />
              </div>
            </NavLink>
            {params.id ? <FormattedMessage id="Edit Restaurant" /> : <FormattedMessage id="New restaurant" />}
          </div>
          <div className="btn_wrapper">
            {params.id && (
              <Button className="delete_restaurant_btn" onClick={onDeleteRestaurant}>
                <FormattedMessage id="Delete Restaurant" />
                <DeleteOutline />
              </Button>
            )}
            <Button className="new_restaurant_btn" onClick={onUpdateRestaurant}>
              {params.id ? <FormattedMessage id="Save Restaurant" /> : <FormattedMessage id="Create Restaurant" />}
              <ArrowForward />
            </Button>
          </div>
        </div>
      )}
      {type === 'restaurant_new_offer' && (
        <div className="restaurants_new_header">
          <div className="btn_wrapper">
            {content > 0 && typeof content === 'number' && (
              <Button className="delete_restaurant_btn" onClick={onDeleteRestaurant}>
                <FormattedMessage id="Delete Restaurant" />
                <DeleteOutline />
              </Button>
            )}
            <Button className="new_restaurant_btn" onClick={onUpdateRestaurant}>
              {content > 0 && typeof content === 'number' ? (
                <FormattedMessage id="Save Restaurant" />
              ) : (
                <FormattedMessage id="Create Restaurant" />
              )}
              <ArrowForward />
            </Button>
          </div>
        </div>
      )}
      {type === 'delivery_zone' && (
        <div className="delivery_header">
          <div className="header_title">
            <NavLink to="/dashboard/restaurants">
              <div className="back_btn">
                <ArrowBack />
              </div>
            </NavLink>
            <FormattedMessage id="Delivery areas" />
          </div>
          <div className="btn_wrapper">
            <NavLink to={`/dashboard/restaurants/${rid}/deliveryzone/new`}>
              <Button className="new_delivery_btn">
                <FormattedMessage id="Create new zone" />
                <ArrowForward />
              </Button>
            </NavLink>
          </div>
        </div>
      )}
      {type === 'deliveryzone_new' && (
        <div className="delivery_new_header">
          <div className="header_title">
            <NavLink to={`/dashboard/restaurants/${rid}/deliveryzone`}>
              <div className="back_btn">
                <ArrowBack />
              </div>
            </NavLink>
            {!zid ? <FormattedMessage id="New delivery area" /> : <FormattedMessage id="Edit delivery area" />}
          </div>
          <div className="btn_wrapper">
            {zid && (
              <Button className="delete_delivery_btn" onClick={onDeleteDeliveryZone}>
                <FormattedMessage id="Delete delivery area" />
                <DeleteOutline />
              </Button>
            )}
            <Button className="new_delivery_btn" onClick={onCreateDeliveryZone}>
              {!zid ? <FormattedMessage id="Create delivery area" /> : <FormattedMessage id="Update delivery area" />}
              <ArrowForward />
            </Button>
          </div>
        </div>
      )}
      {type === 'configure_region' && (
        <div className="configure_region_header">
          <div className="header_title">
            <NavLink to="/dashboard/restaurants">
              <div className="back_btn">
                <ArrowBack />
              </div>
            </NavLink>
            <FormattedMessage id="Region" />
          </div>
          <div className="btn_wrapper">
            <Button className="save_region_btn">
              <FormattedMessage id="Save changes" />
              <ArrowForward />
            </Button>
          </div>
        </div>
      )}
      {type === 'configure_region_offer' && (
        <div className="configure_region_header">
          <div className="btn_wrapper">
            <Button className="save_region_btn">
              <FormattedMessage id="Save changes" />
              <ArrowForward />
            </Button>
          </div>
        </div>
      )}
      {type === 'catalogue' && (
        <div className="catalogue_header">
          <div className="header_title">
            <FormattedMessage id="Catalogue" />
          </div>
          <div className="catalogue_btn_wrapper">
            <Button onClick={onSyncCatalog}>
              <img src={hambugerImg} alt="synchronize cataloge" />
              <FormattedMessage id="Synchronize Catalog" />
            </Button>
            <Button onClick={onSyncAvailability}>
              <img src={clockImg} alt="Synchronize availabilities" />
              <FormattedMessage id="Synchronize availabilities" />
            </Button>
            <NavLink to="/dashboard/catalogue/manage">
              <Button>
                <img src={layerImg} alt="manage cataloge" />
                <FormattedMessage id="Manage categories" />
              </Button>
            </NavLink>
          </div>
        </div>
      )}
      {type === 'catalogue_manage' && (
        <div className="catalogue_manage_header">
          <div className="header_title">
            <NavLink to="/dashboard/catalogue">
              <div className="back_btn">
                <ArrowBack />
              </div>
            </NavLink>
            <FormattedMessage id="Manage categories" />
          </div>
          <div className="btn_wrapper">
            <Button className="new_delivery_btn" onClick={onSaveOrderSchemes}>
              <FormattedMessage id="Save Changes" />
            </Button>
          </div>
        </div>
      )}
      {type === 'users' && (
        <div className="user_list_header">
          <div className="header_title">
            <FormattedMessage id="Users" />
          </div>
          <div className="btn_wrapper">
            <NavLink to="/dashboard/users/new">
              <Button>
                <FormattedMessage id="New User" />
                <ArrowForward />
              </Button>
            </NavLink>
          </div>
        </div>
      )}
      {type === 'user_edit' && (
        <div className="user_edit_header">
          <div className="header_title">
            <NavLink to="/dashboard/users">
              <div className="back_btn">
                <ArrowBack />
              </div>
            </NavLink>
            {uid && (
              <div className="user_info">
                <h3>
                  Usuario: {`${selectedUser.firstName || ''} ${selectedUser.lastName || ''}`}{' '}
                  {selectedUser.isDeleted && <p className="deleted">{intl.formatMessage({ id: 'Deleted' })}</p>}
                </h3>
                <span>
                  ID {selectedUser.id || ''} - Registrado el{' '}
                  {moment(selectedUser.createdAt).tz('America/Guatemala').format('LL')}
                </span>
                <span>{`Última actualización ${moment(selectedUser.phoneUpdatedAt || selectedUser.createdAt)
                  .tz('America/Guatemala')
                  .format('LL')}`}</span>
              </div>
            )}
            {!uid && <FormattedMessage id="New User" />}
          </div>
          <div className="btn_wrapper">
            <Button className="user_update_btn" onClick={onCreateCustomer} disabled={selectedUser.isDeleted}>
              <FormattedMessage id="Save and update details" />
              <ArrowForward />
            </Button>
          </div>
        </div>
      )}
      {type === 'ots_agent' && (
        <div className="ots_agent_header_wrapper">
          <div className="ots_agent_list_header">
            <div className="header_title">
              <FormattedMessage id="OTS agents" />
            </div>
            <div className="btn_wrapper">
              <NavLink to="/dashboard/ots/new">
                <Button>
                  <FormattedMessage id="New Agent" />
                  <ArrowForward />
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="managment_monitor_wrapper">
            <NavLink to="/dashboard/ots/monitor">
              <Button>
                <img src={monitorImg} alt="monitor" />
                <FormattedMessage id="Management monitor" />
              </Button>
            </NavLink>
          </div>
        </div>
      )}
      {type === 'ots_agent_edit' && (
        <div className="ots_agent_edit_header">
          <div className="header_title">
            <NavLink to="/dashboard/ots">
              <div className="back_btn">
                <ArrowBack />
              </div>
            </NavLink>
            {!isEdit ? <FormattedMessage id="New OTS Agent" /> : <FormattedMessage id="Edit OTS Agent" />}
          </div>
          <div className="btn_wrapper">
            <Button onClick={onCreateOtsAgent}>
              {!isEdit && <FormattedMessage id="Create New Agent" />}
              {isEdit && <FormattedMessage id="Save and update details" />}
              <ArrowForward />
            </Button>
          </div>
        </div>
      )}
      {type === 'ots_agent_monitor' && (
        <div className="ots_agent_monitor_header">
          <div className="header_title">
            <NavLink to="/dashboard/ots">
              <div className="back_btn">
                <ArrowBack />
              </div>
            </NavLink>
            <FormattedMessage id="OTS agent monitoring interface" />
          </div>
        </div>
      )}
      {type === 'ambassador_list' && (
        <div className="ambassador_list_header">
          <div className="header_title">
            <FormattedMessage id="Ambassadors" />
          </div>
          <div className="ambassador_header_btn_wrapper">
            <Button onClick={onSyncAmbassador}>
              <img src={ambassadorImg} alt="ambassador" />
              <FormattedMessage id="Synchronize Ambassadors" />
            </Button>
            <Button onClick={onSyncSchedule}>
              <img src={scheduleImg} alt="schedule" />
              <FormattedMessage id="Synchronize schedules" />
            </Button>
            <NavLink to="/dashboard/ambassadors/restaurants">
              <Button>
                <img src={restaurantImg} alt="restauarnt" />
                <FormattedMessage id="Availability of restaurants" />
              </Button>
            </NavLink>
            {/* <NavLink to="/dashboard/ambassadors/monitor">
              <Button>
                <img src={monitorImg} alt="monitor" />
                <FormattedMessage id="Management monitor" />
              </Button>
            </NavLink> */}
          </div>
        </div>
      )}
      {type === 'ambassador_edit' && (
        <div className="ambassador_edit_header">
          <div className="header_title">
            <NavLink to="/dashboard/ambassadors">
              <div className="back_btn">
                <ArrowBack />
              </div>
            </NavLink>
            <div className="user_info">
              <h3>Embajador: {`${selectedAmbassador.firstName || ''} ${selectedAmbassador.lastName || ''}`}</h3>
              <span>
                ID {selectedAmbassador.id || ''} - SAR ID {selectedAmbassador.eid || ''}
              </span>
            </div>
          </div>
          <div className="btn_wrapper">
            <Select
              className="ambassador_status"
              defaultValue={selectedAmbassador.state}
              value={selectedAmbassador.state}
              onChange={v => onChangeAmbassadorStatus(selectedAmbassador, v)}
              dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
            >
              {statuses.map(s => (
                <Option value={s.value}>{s.label}</Option>
              ))}
            </Select>
            <Button className="user_update_btn" onClick={onUpdateAmbassador}>
              <FormattedMessage id="Save and update details" />
              <ArrowForward />
            </Button>
          </div>
        </div>
      )}
      {type === 'distribution_list' && (
        <div className="distribution_list_header">
          <div className="header_title">
            <FormattedMessage id="Distribution control center" />
            <NavLink to="/dashboard/distribution/new">
              <Button className="new_sector_btn">
                <FormattedMessage id="New sector" />
                <ArrowForward />
              </Button>
            </NavLink>
          </div>
          <div className="distribution_header_btn_wrapper">
            <NavLink to="/dashboard/distribution/restaurants">
              <Button>
                <img src={restaurantImg} alt="restauarnt" />
                <FormattedMessage id="Availability of restaurants" />
              </Button>
            </NavLink>
            <NavLink to="/dashboard/distribution/monitor">
              <Button>
                <img src={monitorImg} alt="monitor" />
                <FormattedMessage id="Management monitor" />
              </Button>
            </NavLink>
            <NavLink to="/dashboard/distribution/traffic">
              <Button>
                <img src={trafficImg} alt="traffic" />
                <FormattedMessage id="Traffic monitor" />
              </Button>
            </NavLink>
            <NavLink to="/dashboard/distribution/analysis">
              <Button>
                <img src={analyticsImg} alt="monitor" />
                <FormattedMessage id="Restaurant analysis" />
              </Button>
            </NavLink>
            <NavLink to="/dashboard/distribution/report">
              <Button>
                <img src={kpiImg} alt="monitor" />
                <FormattedMessage id="Reports and KPIs" />
              </Button>
            </NavLink>
          </div>
        </div>
      )}
      {type === 'distribution_edit' && (
        <div className="distribution_edit_header">
          <div className="header_title">
            <NavLink to="/dashboard/distribution">
              <div className="back_btn">
                <ArrowBack />
              </div>
            </NavLink>
            <div className="user_info">
              {sectorInfo.id ? (
                <FormattedMessage id="Edit distribution sector" />
              ) : (
                <FormattedMessage id="New distribution sector" />
              )}
            </div>
          </div>
          <div className="btn_wrapper">
            {sectorInfo.id && (
              <Button className="delete_sector" onClick={onDeleteSector}>
                <FormattedMessage id="Delete sector" />
                <DeleteOutline />
              </Button>
            )}
            <Button className="update_sector" onClick={() => (sectorInfo.id ? onUpdateSector() : onCreateSector())}>
              {sectorInfo.id ? <FormattedMessage id="Update sector" /> : <FormattedMessage id="Create sector" />}
              <ArrowForward />
            </Button>
          </div>
        </div>
      )}
      {type === 'distribution_traffic_list' && (
        <div className="distribution_traffic_list_header">
          <div className="header_title">
            <NavLink to="/dashboard/distribution">
              <div className="back_btn">
                <ArrowBack />
              </div>
            </NavLink>
            <div className="header_description">
              <p>
                <FormattedMessage id="Dynamic restaurant management" />
              </p>
              <p className="description">
                <FormattedMessage id="Traffic description" />
              </p>
            </div>
          </div>
          {/* <div className="btn_wrapper">
            <Button className="update_traffic">
              <FormattedMessage id="Save changes" />
              <ArrowForward />
            </Button>
          </div> */}
        </div>
      )}
      {type === 'distribution_report' && (
        <div className="distribution_report_header">
          <div className="header_title">
            <NavLink to="/dashboard/distribution">
              <div className="back_btn">
                <ArrowBack />
              </div>
            </NavLink>
            <div className="header_description">
              <p>
                <FormattedMessage id="Reports and KPIs" />
              </p>
              <p className="description">
                <FormattedMessage id="Statistical scheme of distribution results" />
              </p>
            </div>
          </div>
        </div>
      )}
      {type === 'available_restaurant' && (
        <div className="available_restaurant_header">
          <div className="header_title">
            <NavLink to="/dashboard/ambassadors">
              <div className="back_btn">
                <ArrowBack />
              </div>
            </NavLink>
            <FormattedMessage id="Availability of restaurants" />
          </div>
        </div>
      )}
      {type === 'ambassador_monitor' && (
        <div className="ambassador_monitor_header">
          <div className="header_title">
            <NavLink to="/dashboard/ambassadors">
              <div className="back_btn">
                <ArrowBack />
              </div>
            </NavLink>
            <FormattedMessage id="Ambassador Monitoring Interface" />
          </div>
        </div>
      )}
      {type === 'distribution_ambassador_monitor' && (
        <div className="ambassador_monitor_header">
          <div className="header_title">
            <NavLink to="/dashboard/distribution">
              <div className="back_btn">
                <ArrowBack />
              </div>
            </NavLink>
            <FormattedMessage id="Ambassador Monitoring Interface" />
          </div>
        </div>
      )}
      {type === 'operator_list' && (
        <div className="operator_agent_list_header">
          <div className="header_title">
            <FormattedMessage id="Management of the administrative scheme" />
          </div>
          <div className="btn_wrapper">
            <NavLink to="/dashboard/operators/new">
              <Button className="new_delivery_btn">
                <FormattedMessage id="New operator" />
                <ArrowForward />
              </Button>
            </NavLink>
          </div>
        </div>
      )}
      {type === 'operator_agent_edit' && (
        <div className="operator_agent_edit_header">
          <div className="header_title">
            <NavLink to="/dashboard/operators">
              <div className="back_btn">
                <ArrowBack />
              </div>
            </NavLink>
            {!isEdit ? <FormattedMessage id="New user operator" /> : <FormattedMessage id="Edit user operator" />}
          </div>
          <div className="btn_wrapper">
            <Button onClick={onCreateOperatorAgent}>
              {!isEdit && <FormattedMessage id="Create operator" />}
              {isEdit && <FormattedMessage id="Save and update details" />}
              <ArrowForward />
            </Button>
          </div>
        </div>
      )}
      {type === 'promotion_list' && (
        <div className="promotion_list_header">
          <div className="header_title">
            <FormattedMessage id="Promotions - Action Screens" />
          </div>
          <div className="btn_wrapper">
            <Button onClick={onNewPromotion}>
              <FormattedMessage id="New action screen" />
              <ArrowForward />
            </Button>
          </div>
        </div>
      )}
      {type === 'coupon_list' && (
        <div className="coupon_list_header">
          <div className="header_title">
            <FormattedMessage id="Coupons - Discount Codes" />
          </div>
          <div className="btn_wrapper">
            <Button onClick={onNewCoupon}>
              <FormattedMessage id="New coupon" />
              <ArrowForward />
            </Button>
          </div>
        </div>
      )}
      {type === 'banner' && (
        <div className="banner_setting_header">
          <div className="header_title">
            <FormattedMessage id="Settings - Mobile client application" />
          </div>
          <div className="btn_wrapper">
            <Button onClick={onSaveBanner}>
              <FormattedMessage id="Save details" />
              <ArrowForward />
            </Button>
          </div>
        </div>
      )}
      {type === 'loyalty' && (
        <div className="banner_setting_header">
          <div className="header_title">
            <FormattedMessage id="Loyalty program settings" />
          </div>
          <div className="btn_wrapper">
            <Button onClick={onSaveLoyalty}>
              <FormattedMessage id="Save settings" />
              <ArrowForward />
            </Button>
          </div>
        </div>
      )}
      {type === 'sar_config' && (
        <div className="sar_config_header_wrapper">
          <div className="sar_config_list_header">
            <div className="header_title">
              <FormattedMessage id="SAR Configuration" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

ContentHeader.defaultProps = {
  type: '',
  isEdit: false,
  rid: '1',
  zid: null,
  uid: '',
  searchText: '',
  match: {},
  selectedAmbassador: {},
  sectorInfo: {},
  onSearch: () => {},
  onKeyDownSearch: () => {},
  onSyncCatalog: () => {},
  onSyncAvailability: () => {},
  onSyncAmbassador: () => {},
  onSyncSchedule: () => {},
  onDeleteRestaurant: () => {},
  onUpdateRestaurant: () => {},
  onCreateDeliveryZone: () => {},
  onDeleteDeliveryZone: () => {},
  onCreateCustomer: () => {},
  onUpdateAmbassador: () => {},
  onCreateOtsAgent: () => {},
  onCreateOperatorAgent: () => {},
  onNewPromotion: () => {},
  onNewCoupon: () => {},
  onSaveOrderSchemes: () => {},
  onSaveBanner: () => {},
  onSaveLoyalty: () => {},
  onChangeAmbassadorStatus: () => {},
  onCreateSector: () => {},
  onUpdateSector: () => {},
  onDeleteSector: () => {},
};

export default compose(withRouter)(ContentHeader);
