import React, { useState, useEffect } from 'react';
import { Tooltip, Button, Pagination } from 'antd';
import { Edit } from '@material-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import CampaignViewModal from 'components/Dashboard/Campaign/CampaignViewModal';

type CampaignData = {
  id: number;
  name: string;
  isActive: boolean;
  dealCount: number;
  createdAt: string;
  updatedAt: string;
};

type CampaignTableType = {
  campaignData: Array<CampaignData | null>;
  total: number;
  current: number;
  pageSize: number;
  onChangePage: (page: number, size: number) => void;
  setCurrent: (e: number) => void;
  setPageSize: (e: number) => void;
};

const CampaignTable: React.FC<CampaignTableType> = ({
  campaignData,
  total,
  current,
  pageSize,
  onChangePage,
  setCurrent,
  setPageSize,
}) => {
  const [isShowDetailModal, setShowDetailModal] = useState(false);
  const [campaignInfo, setCampaignInfo] = useState<CampaignData | null>(null);
  const intl = useIntl();

  useEffect(() => {
    onChangePage(1, 10);
  }, []);

  const handleChangePage = (page: number, size: number) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage(page, size);
  };

  const handleShowSizeChange = (c: number, s: number) => {
    handleChangePage(1, s);
  };

  return (
    <div className="campaign_table_layout" id="campaign_table_layout">
      <div className="table_header">
        <div className="id">
          <FormattedMessage id="ID" />
        </div>
        <div className="name">
          <FormattedMessage id="Name" />
        </div>
        <div className="offers">
          <FormattedMessage id="Offers" />
        </div>
        <div className="actions">
          <FormattedMessage id="Actions" />
        </div>
      </div>
      <div>
        <div className="table_content" id="campaign_table_content">
          {Array.isArray(campaignData) &&
            campaignData.map((item, index) => (
              <div className={`table_item type_state_${item?.isActive}`} key={index} id={`campaign_item_${item?.id}`}>
                <div className="id">
                  <p>{item?.id}</p>
                </div>
                <div className="name">
                  <p>{item?.name}</p>
                </div>
                <div className="offers">
                  <p>{item?.dealCount}</p>
                </div>
                <div className="actions">
                  <Tooltip
                    placement="top"
                    title={intl.formatMessage({ id: 'Edit campaign' })}
                    id="campaign_edit_tooltip"
                  >
                    <Button
                      className="edit_btn"
                      onClick={() => {
                        setCampaignInfo(item);
                        setShowDetailModal(true);
                      }}
                      id="campaign_edit_btn"
                    >
                      <Edit />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            ))}
        </div>
        <div className="pagination_wrapper">
          <Pagination
            showSizeChanger
            defaultCurrent={1}
            defaultPageSize={10}
            current={current}
            pageSize={pageSize}
            pageSizeOptions={['5', '10', '20', '30']}
            total={total}
            onChange={handleChangePage}
            onShowSizeChange={handleShowSizeChange}
          />
          <div className="total">Resultados: {total}</div>
        </div>
      </div>
      <CampaignViewModal
        isOpenModal={isShowDetailModal}
        onCloseModal={() => setShowDetailModal(false)}
        campaignInfo={campaignInfo}
        setPageSize={setPageSize}
        setCurrent={setCurrent}
      />
    </div>
  );
};

export default CampaignTable;
