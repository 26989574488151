import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import PromotionFilter from 'components/Dashboard/Promotions/PromotionFilter';
import PromotionTable from 'components/Dashboard/Promotions/PromotionTable';
import PromotionNewModal from 'components/Dashboard/Promotions/PromotionNewModal';
import toast from 'components/Basic/Toast';
import Loading from 'components/Basic/Loading';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import {
  useLazyGetPromotionListQuery,
  useCreatePromotionMutation,
  useUpdatePromotionMutation,
  useDeletePromotionMutation,
} from 'core/promotion/PromotionService';
import { setPromotionData } from 'core/promotion/PromotionSlice';
import { useLazyGetSizesQuery, useLazyGetItemsQuery } from 'core/catalog/CatalogService';
import { setSizesData, setProductsData } from 'core/catalog/CatalogSlice';

const PromotionList = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [state, setState] = useState({
    searchText: '',
    offset: 0,
    limit: 10,
    filterQuery: '',
    stateFilter: '',
    typeFilter: '',
    searchFilterQuery: '',
    sort: '',
    isCreatePromotion: true,
    promotion: {},
    isLoading: false,
  });

  const { products, sizes } = useAppSelector(state => state.catalogReducer);
  const { list } = useAppSelector(state => state.promotionReducer);
  const { admin } = useAppSelector(state => state.loginReducer);

  const [getSizes] = useLazyGetSizesQuery();
  const [getItems] = useLazyGetItemsQuery();
  const [getPromotionList] = useLazyGetPromotionListQuery();
  const [createPromotion] = useCreatePromotionMutation();
  const [updatePromotion] = useUpdatePromotionMutation();
  const [deletePromotion] = useDeletePromotionMutation();

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    try {
      const items = await getItems({ filter: '&filter=channel eq APP' }).unwrap();
      dispatch(setProductsData({ products: items }));
      const sizes = await getSizes({}).unwrap();
      dispatch(setSizesData({ sizes: sizes.rows }));
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangePage = async (offset, limit, filterQuery, sort) => {
    setState(prev => ({ ...prev, offset: offset, limit: limit, isLoading: true }));
    try {
      const res = await getPromotionList({
        offset,
        limit,
        or: false,
        filter: filterQuery || state.filterQuery + state.searchFilterQuery,
        sort: sort || state.sort,
      }).unwrap();

      dispatch(setPromotionData({ list: res }));

      setState(prev => ({ ...prev, isLoading: false }));
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const handleStateFilter = values => {
    if (values.length !== 0) {
      let filterQuery = '&filter=state in';
      values.map(v => {
        filterQuery += ` ${v}`;
        return filterQuery;
      });
      setState(prev => ({ ...prev, stateFilter: filterQuery }));
    } else {
      setState(prev => ({ ...prev, stateFilter: '' }));
    }
  };

  const handleTypeFilter = value => {
    if (value !== 'ALL') {
      setState(prev => ({ ...prev, typeFilter: `&filter=type eq ${value}` }));
    } else {
      setState(prev => ({ ...prev, typeFilter: '' }));
    }
  };

  const handleSort = value => {
    setState(prev => ({ ...prev, sort: value }));
  };

  const handleSearch = e => {
    setState(prev => ({ ...prev, searchText: e.target.value }));
  };

  const handleKeyDownSearch = async e => {
    let searchFilterQuery = '';
    if (e.keyCode === 13) {
      const { offset, limit, filterQuery, sort, searchText } = state;

      if (searchText) {
        if (!isNaN(searchText, 10) && searchText < 100000000) {
          searchFilterQuery += `&filter=or.id eq ${searchText}`;
        } else {
          searchFilterQuery += `&filter=or.name iLike %25${searchText}%25&filter=or.value iLike %25${searchText}%25`;
        }
      } else {
        searchFilterQuery = '';
      }

      setState(prev => ({ ...prev, searchFilterQuery: searchFilterQuery, isLoading: true }));

      try {
        const res = await getPromotionList({
          offset: 0,
          limit: 10,
          or: false,
          filter: filterQuery + searchFilterQuery,
          sort: sort,
        }).unwrap();

        dispatch(setPromotionData({ list: res }));

        setState(prev => ({ ...prev, isLoading: false }));
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    }
  };

  const handleApplyFilter = async () => {
    const { stateFilter, typeFilter, sort, searchFilterQuery } = state;

    setState(prev => ({ ...prev, filterQuery: stateFilter + typeFilter, isLoading: true }));

    try {
      const res = await getPromotionList({
        offset: 0,
        limit: 10,
        or: false,
        filter: stateFilter + typeFilter + searchFilterQuery,
        sort: sort,
      }).unwrap();

      dispatch(setPromotionData({ list: res }));

      setState(prev => ({ ...prev, isLoading: false }));
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const handleSubmitPromotion = async values => {
    const { isCreatePromotion, promotion } = state;
    setState(prev => ({ ...prev, isShowPromotionModal: false, isLoading: true }));

    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }

    if (isCreatePromotion) {
      try {
        await createPromotion({ formData }).unwrap();
        toast.success({
          title: intl.formatMessage({
            id: 'Promotion is created successfully',
          }),
        });
        handleApplyFilter();
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
        toast.error({
          title: intl.formatMessage({
            id: 'Promotion creation is failure',
          }),
        });
      }
    } else {
      try {
        await updatePromotion({ id: promotion.id, formData }).unwrap();
        toast.success({
          title: intl.formatMessage({
            id: 'Promotion is updated successfully',
          }),
        });
        handleApplyFilter();
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
        toast.error({
          title: intl.formatMessage({
            id: 'Updating promotion is failure',
          }),
        });
      }
    }
  };

  const handleDeletePromotion = async promotionInfo => {
    try {
      await deletePromotion({ id: promotionInfo.id }).unwrap();
      toast.success({
        title: intl.formatMessage({
          id: 'Promotion is deleted successfully',
        }),
      });
      handleApplyFilter();
    } catch (e) {
      toast.error({
        title: intl.formatMessage({
          id: 'Deleting promotion is failure',
        }),
      });
    }
  };

  const handleEditPromotion = promotion => {
    setState(prev => ({
      ...prev,
      isShowPromotionModal: true,
      promotion,
      isCreatePromotion: false,
    }));
  };

  const { promotion } = state;
  return (
    <div className="promotion_list_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader
        type="promotion_list"
        onNewPromotion={() =>
          setState(prev => ({
            ...prev,
            isShowPromotionModal: true,
            isCreatePromotion: true,
          }))
        }
      />
      <div className="promotion_filter_wrapper">
        <PromotionFilter
          searchText={state.searchText}
          onStateFilter={handleStateFilter}
          onTypeFilter={handleTypeFilter}
          onSort={handleSort}
          onSearch={handleSearch}
          onKeyDownSearch={handleKeyDownSearch}
          onApplyFilter={handleApplyFilter}
        />
        <PromotionTable
          promotionData={list.rows || []}
          products={products.rows || []}
          sizes={sizes || []}
          onDeletePromotion={handleDeletePromotion}
          onEditPromotion={handleEditPromotion}
          onChangePage={handleChangePage}
          total={list.totalCount}
        />
      </div>
      <PromotionNewModal
        isCreatePromotion={state.isCreatePromotion}
        promotion={promotion}
        products={(products.rows || []).filter(p => p.state === 'A')}
        sizes={sizes || []}
        isOpenModal={state.isShowPromotionModal}
        onSubmitPromotion={handleSubmitPromotion}
        onCloseModal={() => setState(prev => ({ ...prev, isShowPromotionModal: false }))}
      />
    </div>
  );
};

PromotionList.defaultProps = {
  list: {},
  products: {},
  sizes: [],
};

export default PromotionList;
