import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Menu } from 'antd';
import { ArrowDropDown } from '@material-ui/icons';

function DropMenu({ placeHolder, defaultValue, onMenu, items, isDisabled, ...props }) {
  const [text, setText] = useState(placeHolder);
  useEffect(() => {
    items.forEach(item => {
      if (typeof item === 'object') {
        if (item.key.toString() === defaultValue.toString()) {
          setText(item.value);
        }
      } else if (item.toString() === defaultValue.toString()) {
        setText(item);
      }
    });
  }, [items, defaultValue]);

  const handleMenu = e => {
    onMenu(e.key);
    if (typeof items[e.key] === 'object') {
      setText(items[e.key].value);
    } else {
      setText(items[e.key]);
    }
  };

  const menu = () => {
    return (
      <Menu onClick={e => handleMenu(e)}>
        {items.map((item, index) => (
          <Menu.Item key={index}>{typeof item === 'object' ? item.value : item}</Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <Dropdown overlay={menu} trigger={['click']} {...props} disabled={isDisabled}>
      <div>
        <span>{text}</span> <ArrowDropDown />
      </div>
    </Dropdown>
  );
}

DropMenu.propTypes = {
  placeHolder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onMenu: PropTypes.func.isRequired,
  items: PropTypes.array,
  isDisabled: PropTypes.bool,
};

DropMenu.defaultProps = {
  placeHolder: '',
  defaultValue: '',
  items: [],
  isDisabled: false,
  onMenu: () => {},
};

export default DropMenu;
