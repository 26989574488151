import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import AmbassadorList from 'containers/Dashboard/Ambassadors/AmbassadorList';
import AmbassadorEdit from 'containers/Dashboard/Ambassadors/AmbassadorEdit';
import AvailableRestaurants from 'containers/Dashboard/Ambassadors/AvailableRestaurants';
// import AmbassadorMonitor from 'containers/Dashboard/Ambassadors/AmbassadorMonitor';

class Ambassadors extends PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path="/dashboard/ambassadors" component={AmbassadorList} />
        <Route exact path="/dashboard/ambassadors/restaurants" component={AvailableRestaurants} />
        {/* <Route
          exact
          path="/dashboard/ambassadors/monitor"
          component={AmbassadorMonitor}
        /> */}
        <Route exact path="/dashboard/ambassadors/:id" component={AmbassadorEdit} />
      </Switch>
    );
  }
}

export default Ambassadors;
