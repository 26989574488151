import moment from 'moment';
const compareExtend = (obj1: any, obj2: any): boolean => {
  const isObject = (val: any) => typeof val === 'object' && val !== null;

  const deepEqual = (a: any, b: any): boolean => {
    if (a === b) {
      return true;
    }

    if (!isObject(a) || !isObject(b)) {
      return false;
    }

    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) {
      return false;
    }

    for (const key of keysA) {
      if (!keysB.includes(key)) {
        return false;
      }

      const valA = a[key];
      const valB = b[key];

      if (!deepEqual(valA, valB)) {
        return false;
      }
    }

    return true;
  };

  return deepEqual(obj1, obj2);
};

const compareRedemption = (obj1: any, obj2: any): boolean => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  for (const key of keys1) {
    if (keys2.includes(key)) {
      const value1 = obj1[key];
      const value2 = obj2[key];
      if (typeof value2 === 'string' && value2.includes('T') && value2.includes('-')) {
        if (value2.split('T')[1] !== value1.split('T')[1]) {
          return false;
        }
      } else if (value2 && value1 !== value2) {
        return false;
      } else if (value1 && value2 === null) {
        return false;
      }
    }
  }

  return true;
};

const compareSchedule = (obj1: any, obj2: any) => {
  const booleanKeys = ['isFriday', 'isMonday', 'isSaturday', 'isSunday', 'isThursday', 'isTuesday', 'isWednesday'];
  const stringKeys = ['startDate', 'endDate'];
  const dateKeys = ['dailyEndTime', 'dailyStartTime', 'endTime', 'startTime'];

  for (const key of booleanKeys) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  for (const key of stringKeys) {
    const date1 = obj1[key] && moment(obj1[key]?.toString().slice(0, 10)).format('YYYY-MM-DD');

    const date2 = obj2[key] && moment(obj2[key]?.toString().slice(0, 10)).format('YYYY-MM-DD');

    if (date1 !== date2) {
      return false;
    }
  }

  for (const key of dateKeys) {
    const time1 = moment(obj1[key]).tz('America/Guatemala').format('HH:mm');
    const time2 = moment(obj2[key]).tz('America/Guatemala').format('HH:mm');
    if (time1 !== time2) {
      return false;
    }
  }

  return true;
};

const checkImages = (arr: any) => {
  const data = arr.filter((item: any) => {
    if (item) {
      return item;
    }
  });
  const newImage = arr.map((item: any) => {
    if (item) {
      const keys = Object.keys(item);
      const base = keys.some((item: string) => item === 'base64');
      return base;
    }
  });
  const changeImage = data.length !== 2 || newImage.includes(true);
  return !changeImage;
};

function arraysAreEqual(oldDataLocations: any, newData: any) {
  if (!oldDataLocations?.length || !newData?.length) return true;

  const oldData = oldDataLocations.map((item: any) => item.restaurantId);

  if (oldData?.length !== newData?.length) {
    return false;
  }

  for (let i = 0; i < oldData?.length; i++) {
    if (oldData[i] !== newData[i]) {
      return false;
    }
  }
  return true;
}

export const generateStateDifferences = (oldData: any, newData: any) => {
  if (!oldData) return;
  const list1 = oldData?.contentTags?.map((item: any) => item.type);
  const list2 = newData?.contentTags?.map((item: any) => item.type);
  const exDataOffer = oldData?.extendedData?.data[Object.keys(oldData.extendedData.data)[0]];
  const exDataState = newData?.extendedData?.data[Object.keys(oldData.extendedData.data)[0]];

  const editFields = {
    baseWeight: oldData.baseWeight,
    campaign: oldData.campaign.id === newData.campaignId && oldData.name === newData.name,
    category: oldData.category.id === newData.categoryId,
    contentTags: JSON.stringify(list1) === JSON.stringify(list2),
    description: oldData.description === newData.description,
    extendedDataType: oldData.extendedData.type === newData.extendedData.type,
    extendedData: compareExtend(exDataOffer, exDataState),
    isActive: oldData.isActive === newData.isActive,
    isHidden: oldData.isHidden === newData.isHidden,
    isSecret: oldData.isSecret === newData.isSecret,
    isShortUrl: oldData.isShortUrl === newData.isShortUrl,
    locationType: oldData.locationType === newData.locationType,
    restaurantIds: arraysAreEqual(oldData?.locations, newData?.restaurantIds),
    images: checkImages(newData?.images),
    redemption: compareRedemption(oldData.redemptionConfiguration, newData.redemptionConfiguration),
    terms: oldData.terms === newData.terms,
    title: oldData.title === newData.title,
    tabs: JSON.stringify(oldData.tabs.map((tab: any) => tab.tabId)) === JSON.stringify(newData.tabIds),
    segment: oldData.segment.id === newData.segmentId,
    schedule: compareSchedule(oldData.schedule, newData.schedule),
  };
  const allFieldsAreTrue = Object.values(editFields).every(field => field);
  return !allFieldsAreTrue;
};
