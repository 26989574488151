import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Button, Switch } from 'antd';
import ModalDlg from 'components/Basic/ModalDlg';
import { ArrowForward } from '@material-ui/icons';
import toast from 'components/Basic/Toast';
import { useUpdateSuperCatalogMutation, useLazyGetSuperCatalogsQuery } from 'core/catalog/CatalogService';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { setSuperCatalogsData } from 'core/catalog/CatalogSlice';

function CatalogueSetupModal({ title, catalogueInfo, buttonText, isOpenModal, onCloseModal }) {
  const [fileGreyElement, setFileGreyElement] = useState(null);
  const [fileYellowElement, setFileYellowElement] = useState(null);
  const [fileGrey, setFileGrey] = useState(null);
  const [fileYellow, setFileYellow] = useState(null);
  const [isSpecial, setIsSpecial] = useState(false);

  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [updateSuperCatalogs] = useUpdateSuperCatalogMutation();
  const [getSuperCatalogs] = useLazyGetSuperCatalogsQuery();

  useEffect(() => {
    if (!isOpenModal) {
      setFileGreyElement(null);
      setFileYellowElement(null);
      setFileGrey(null);
      setFileYellow(null);
    } else {
      setIsSpecial(!!(catalogueInfo && catalogueInfo.orderOncePerCustomer));
    }
  }, [isOpenModal]);

  const openChooseFile = type => {
    if (type === 'grey') {
      fileGreyElement.click();
    } else {
      fileYellowElement.click();
    }
  };

  const handleFileChange = (event, type) => {
    if (event.target.files.length !== 0) {
      if (event.target.files[0].size > 1024 * 1024) {
        toast.error({
          title: intl.formatMessage({
            id: 'Image size should be less than 1MB',
          }),
        });
      } else if (type === 'grey') {
        setFileGrey(event.target.files[0]);
      } else {
        setFileYellow(event.target.files[0]);
      }
    }
  };

  const handleUpdateCatalog = async () => {
    const params = {
      id: catalogueInfo.id,
      orderOncePerCustomer: isSpecial,
    };
    if (fileGrey) {
      params.customizedImage = fileGrey;
    }
    if (fileYellow) {
      params.customizedSelectedImage = fileYellow;
    }

    try {
      const formData = new FormData();
      for (const key in params) {
        formData.append(key, params[key]);
      }

      await updateSuperCatalogs({ id: params.id, formData }).unwrap();
      const res = await getSuperCatalogs({}).unwrap();
      dispatch(setSuperCatalogsData({ superCataloges: res.superClasses.rows }));
      toast.success({
        title: intl.formatMessage({ id: 'Catalog image is updated' }),
      });
    } catch (err) {
      toast.error({
        title: intl.formatMessage({ id: 'Updating catalog is failure!' }),
      });
    }

    onCloseModal();
  };
  return (
    <ModalDlg
      className="catalogue_setup_modal"
      visible={isOpenModal}
      width={434}
      handleCancel={() => {
        onCloseModal();
        setFileGreyElement(null);
        setFileYellowElement(null);
        setFileGrey(null);
        setFileYellow(null);
      }}
      isCenter
    >
      <div className="modal_header">
        <div>
          <h3>{title}</h3>
          <p className="description">{catalogueInfo ? catalogueInfo.name : ''}</p>
        </div>
      </div>
      <div className="modal_content">
        <div className="icon_upload_wrapper">
          <p>
            <FormattedMessage id="PNG Icon (Gray)" />
          </p>
          <Button onClick={() => openChooseFile('grey')}>
            {fileGrey
              ? fileGrey.name
              : catalogueInfo && catalogueInfo.customizedImage
              ? catalogueInfo.customizedImage.split('/')[1]
              : 'Upload'}
          </Button>
          {isOpenModal && (
            <input
              type="file"
              className="file_input"
              accept="image/*, .svg"
              ref={input => setFileGreyElement(input)}
              onChange={e => handleFileChange(e, 'grey')}
            />
          )}
        </div>
        <div className="icon_upload_wrapper">
          <p>
            <FormattedMessage id="PNG Icon (Yellow)" />
          </p>
          <Button onClick={() => openChooseFile('yellow')}>
            {fileYellow
              ? fileYellow.name
              : catalogueInfo && catalogueInfo.customizedSelectedImage
              ? catalogueInfo.customizedSelectedImage.split('/')[1]
              : 'Upload'}
          </Button>
          {isOpenModal && (
            <input
              type="file"
              className="file_input"
              accept="image/*, .svg"
              ref={input => setFileYellowElement(input)}
              onChange={e => handleFileChange(e, 'yellow')}
            />
          )}
        </div>
        {/* <div className="category_schedule">
          <div className="schedule_from">
            <p>Horario desde</p>
            <DropMenu
              items={items}
              onMenu={key => handleSchedule('from', key)}
              placeHolder="desde"
            />
          </div>
          <div className="schedule_to">
            <p>Horario hasta</p>
            <DropMenu
              items={items}
              onMenu={key => handleSchedule('from', key)}
              placeHolder="hasta"
            />
          </div>
        </div> */}
        <div className="special-catalogue-wrapper">
          <div className="setting">
            <p className="title">
              <FormattedMessage id="Is it promotional?" />
            </p>
            <Switch onChange={checked => setIsSpecial(checked)} checked={isSpecial} />
          </div>
        </div>
      </div>
      <div className="modal_footer">
        <Button onClick={handleUpdateCatalog}>
          <span>{buttonText}</span>
          <ArrowForward />
        </Button>
      </div>
    </ModalDlg>
  );
}
CatalogueSetupModal.defaultProps = {
  isOpenModal: false,
  catalogueInfo: null,
  title: '',
  buttonText: '',
};

export default CatalogueSetupModal;
