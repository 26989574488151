import { StateType } from '../RewardViewModal';
export const generatePayload = (rewardData: StateType) => {
  const data = {
    campaignId: rewardData.campaign,
    image1: rewardData.image,
    locationType: rewardData.locationType,
    isActive: rewardData.isActive,
    terms: rewardData.terms.trimEnd(),
    description: rewardData.description.trimEnd(),
    weight: Number(rewardData.weight),
    contentTags: rewardData.lowerList.map((item: any) => ({ type: item.id })),
    subtabs: [rewardData.subtype],
    restaurantIds: [...rewardData.locations],
  };
  return data;
};
