import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import { ArrowDropDown } from '@material-ui/icons';
import Loading from 'components/Basic/Loading';
import OrderAnalysis from 'components/Dashboard/Distribution/RestaurantAnalysis/OrderAnalysis';
import AmbassadorAnalysis from 'components/Dashboard/Distribution/RestaurantAnalysis/AmbassadorAnalysis';
import DayAnalysis from 'components/Dashboard/Distribution/RestaurantAnalysis/DayAnalysis';
import orderImg from 'assets/img/order.svg';
import ambassadorImg from 'assets/img/ambassador.svg';
import statisticsImg from 'assets/img/statistics.svg';
import { useAppSelector } from 'utilities/redux';
import { useLazyGetRestaurantListQuery } from 'core/restaurant/RestaurantService';
import {
  useLazyGetOrderStatisticsQuery,
  useLazyGetAmbassadorStatisticsQuery,
  useLazyGetOrderDetailStatisticsQuery,
} from 'core/distribution/DistributionService';

const { Option } = Select;

const RestaurantAnalysis = () => {
  const prevState = useRef({
    selectedRestaurant: {},
    analysisType: 'order',
  });
  const timeInterval = useRef();

  const { list } = useAppSelector(state => state.restaurantReducer);

  const [state, setState] = useState({
    isLoading: false,
    analysisType: 'order',
    restaurants: [],
    selectedRestaurant: list.rows[0] || {},
    orderStatistics: {},
    ambassadorStatistics: {},
    orderDetailStatistics: {},
  });

  const [getRestaurantList] = useLazyGetRestaurantListQuery();
  const [getOrderStatistics] = useLazyGetOrderStatisticsQuery();
  const [getAmbassadorStatistics] = useLazyGetAmbassadorStatisticsQuery();
  const [getOrderDetailStatistics] = useLazyGetOrderDetailStatisticsQuery();

  useEffect(async () => {
    try {
      const res = await getRestaurantList({}).unwrap();
      if (res && res.rows.length !== 0) {
        setState(prev => ({
          ...prev,
          restaurants: res.rows,
          selectedRestaurant: res.rows[0],
        }));
      }
    } catch (e) {
      setState(prev => ({ ...prev, restaurants: [], selectedRestaurant: {} }));
    }
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(timeInterval.current);
    };
  }, []);

  useEffect(() => {
    if (
      prevState.current.selectedRestaurant !== state.selectedRestaurant ||
      prevState.current.analysisType !== state.analysisType
    ) {
      prevState.current = {
        selectedRestaurant: state.selectedRestaurant,
        analysisType: state.analysisType,
      };
      getStatistics();
    }
  }, [state.selectedRestaurant, state.analysisType]);

  const getStatistics = async () => {
    const { selectedRestaurant, analysisType } = state;

    if (selectedRestaurant.id && analysisType === 'order') {
      try {
        const res = await getOrderStatistics({ id: selectedRestaurant.id }).unwrap();
        setState(prev => ({ ...prev, orderStatistics: res }));
        clearInterval(timeInterval.current);
      } catch (e) {
        setState(prev => ({ ...prev, orderStatistics: {} }));
      }

      timeInterval.current = setTimeout(() => {
        clearInterval(timeInterval.current);
        getStatistics();
      }, 60 * 1000);
    } else if (selectedRestaurant.id && analysisType === 'ambassador') {
      try {
        const res = await getAmbassadorStatistics({ id: selectedRestaurant.id }).unwrap();
        setState(prev => ({ ...prev, ambassadorStatistics: res }));
        clearInterval(timeInterval.current);
      } catch (e) {
        setState(prev => ({ ...prev, ambassadorStatistics: {} }));
      }

      timeInterval.current = setTimeout(() => {
        clearInterval(timeInterval.current);
        getStatistics();
      }, 15 * 1000);
    } else {
      try {
        const res = await getOrderDetailStatistics({ id: selectedRestaurant.id }).unwrap();
        setState(prev => ({ ...prev, orderDetailStatistics: res }));
        clearInterval(timeInterval.current);
      } catch (e) {
        setState(prev => ({ ...prev, orderDetailStatistics: {} }));
      }

      timeInterval.current = setTimeout(() => {
        clearInterval(timeInterval.current);
        getStatistics();
      }, 15 * 1000);
    }
  };

  const handleChangeTab = tabType => {
    setState(prev => ({ ...prev, analysisType: tabType }));
  };

  const handleSelectRestaurant = value => {
    const { restaurants } = state;
    setState(prev => ({ ...prev, selectedRestaurant: restaurants.filter(r => r.id === value)[0] }));
  };

  const {
    restaurants,
    selectedRestaurant,
    analysisType,
    orderStatistics,
    ambassadorStatistics,
    orderDetailStatistics,
  } = state;

  return (
    <div className="restaurant_analysis_layout">
      <Loading visible={state.isLoading} />
      <div className="analysis_content_header">
        <div className="title_wrapper">
          <p className="title">
            <FormattedMessage id="Order status" />
          </p>
          <p className="subtitle">Restaurante: {selectedRestaurant.description || ''}</p>
        </div>
        <div className="tab_wrapper">
          <Select
            className="restaurant_list"
            defaultValue={selectedRestaurant.id}
            value={selectedRestaurant.id}
            style={{ width: '100%' }}
            onChange={v => handleSelectRestaurant(v)}
            suffixIcon={<ArrowDropDown />}
            dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
          >
            {restaurants.map(s => (
              <Option value={s.id} key={s.id}>
                {s.description || ''}
              </Option>
            ))}
          </Select>
          <div className="divider" />
          <div className="tabs">
            <div
              className={`tab_item ${analysisType === 'order' ? 'tab-active' : ''}`}
              onClick={() => handleChangeTab('order')}
            >
              <img src={orderImg} alt="tab" />
              <FormattedMessage id="Order now" />
            </div>
            <div
              className={`tab_item ${analysisType === 'ambassador' ? 'tab-active' : ''}`}
              onClick={() => handleChangeTab('ambassador')}
            >
              <img src={ambassadorImg} alt="tab" />
              <FormattedMessage id="Ambassadors" />
            </div>
            <div
              className={`tab_item ${analysisType === 'statistics' ? 'tab-active' : ''}`}
              onClick={() => handleChangeTab('statistics')}
            >
              <img src={statisticsImg} alt="tab" />
              <FormattedMessage id="Statistics of the day" />
            </div>
          </div>
        </div>
      </div>
      <div className="analysis_content_wrapper">
        {analysisType === 'order' && <OrderAnalysis data={orderStatistics} />}
        {analysisType === 'ambassador' && <AmbassadorAnalysis data={ambassadorStatistics} />}
        {analysisType === 'statistics' && <DayAnalysis data={orderDetailStatistics} />}
      </div>
    </div>
  );
};

export default RestaurantAnalysis;
