import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, OperatorData } from './types';

const initialState: initState = {
  list: {
    limit: 10,
    offset: 0,
    rows: [
      {
        id: 0,
        eid: '',
        email: '',
        firstName: '',
        lastName: '',
        gender: '',
        orderAccess: false,
        restaurantAccess: false,
        catalogAccess: false,
        customerAccess: false,
        agentAccess: false,
        ambassadorAccess: false,
        operatorAccess: false,
        promotionAccess: false,
        couponAccess: false,
        dealAccess: false,
        rewardsAccess: false,
        notificationAccess: false,
        analyticsAccess: false,
        settingAccess: false,
        lastAccessedAt: '',
        lastAccessedFrom: '',
        state: '',
        createdAt: '',
        updatedAt: '',
      },
    ],
    totalCount: 0,
  },
};

const operatorSlice = createSlice({
  name: 'operator',
  initialState,
  reducers: {
    setOperatorData(state: initState, action: PayloadAction<{ list: OperatorData }>) {
      state.list = action.payload.list;
    },
  },
});

export const { setOperatorData } = operatorSlice.actions;

export default operatorSlice.reducer;
