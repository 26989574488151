import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Form, Input, Button, Select, Radio } from 'antd';
import ModalDlg from 'components/Basic/ModalDlg';
import { Close, CachedOutlined } from '@material-ui/icons';
import { ArrowForward, ArrowDropDown } from '@material-ui/icons';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import toast from 'components/Basic/Toast';
import Loading from 'components/Basic/Loading';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import {
  useLazyGetSegmentsListQuery,
  useCreateSegmnetsMutation,
  useUpdateSegmentsMutation,
  useCsvSegmentsMutation,
  useCalculateSegmentsMutation,
  useLazyDownloadCustomersQuery,
} from 'core/segments/SegmentsService';
import { setSegmentsListData, setCsvInfoData, setCalculateInfoData } from 'core/segments/SegmentsSlice';
import { segmentTypes } from 'utilities/constants';
import downloadImg from 'assets/img/download.svg';
import { UserResultSetType } from 'core/segments/types';
import ConfirmUpdatingCsvViewModal from 'components/Basic/ConfirmUpdatingCsvViewModal';

type Segment = {
  id: number;
  name: string;
  type: string;
  customersCount: number;
  createdAt: string;
  updatedAt: string;
};

type SegmentsViewModalType = {
  isOpenModal: boolean;
  segmentsInfo: Segment | null;
  setPageSize: (e: number) => void;
  onCloseModal: () => void;
};

const SegmentsViewModal: React.FC<SegmentsViewModalType> = ({
  isOpenModal,
  segmentsInfo,
  setPageSize,
  onCloseModal,
}) => {
  const intl = useIntl();

  const { Option } = Select;

  const defaultPageSize = 10;
  const sortOptions = [
    { value: '0', label: intl.formatMessage({ id: 'Type of user' }) },
    { value: '1', label: intl.formatMessage({ id: 'Personalised' }) },
  ];

  const inputFile = useRef<HTMLInputElement | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [id, setId] = useState<number>();
  const [selectedSortStatus, setSelectedSortStatus] = useState<string>('');

  const [allUsers, setAllUsers] = useState<UserResultSetType[] | null>(null);
  const [invalidUsers, setInvalidUsers] = useState<UserResultSetType[] | null>(null);

  const [allUsersCount, setAllUsersCount] = useState<number>(0);
  const [validUsersCount, setValidUsersCount] = useState<number>(0);
  const [invalidUsersCount, setInvalidUsersCount] = useState<number>(0);

  const [calculateData, setCalculateData] = useState(0);

  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [newFileUpload, setNewFileUpload] = useState(false);

  const [isShowConfirmUpdatingCsvModal, setShowConfirmUpdatingCsvModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const [getSegmentsList] = useLazyGetSegmentsListQuery();
  const [createSegments] = useCreateSegmnetsMutation();
  const [updateSegments] = useUpdateSegmentsMutation();
  const [csvSegments] = useCsvSegmentsMutation();
  const [calculateSegments] = useCalculateSegmentsMutation();
  const [downloadCustomers] = useLazyDownloadCustomersQuery();

  const { csvInfo } = useAppSelector(state => state.segmentsReducer);

  useEffect(() => {
    if (segmentsInfo) {
      const { name, type, customersCount, id } = segmentsInfo;

      setName(name || '');
      setType(type);
      setId(id);

      if (segmentsInfo.type !== segmentTypes.personalized) {
        setSelectedSortStatus('0');
        setValidUsersCount(0);
        setCalculateData(customersCount);
      } else {
        setSelectedSortStatus('1');
        setCalculateData(0);
        setValidUsersCount(customersCount);
      }
    } else {
      setName('');
      setType('');
      setSelectedSortStatus('');
      setValidUsersCount(0);
      setCalculateData(0);
    }

    setAllUsers(null);
    setInvalidUsers(null);
    setSelectedFile(null);
    setAllUsersCount(0);
    setInvalidUsersCount(0);
  }, [isOpenModal]);

  const resetState = () => {
    setAllUsers(null);
    setInvalidUsers(null);
    setSelectedFile(null);
    setAllUsersCount(0);
    setValidUsersCount(0);
    setInvalidUsersCount(0);
  };

  const readFileAsync = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = e => resolve(e?.target?.result);
      reader.onerror = error => reject(error);
      reader.readAsText(file);
    });
  };

  const onNameChange = (e: any) => {
    setName(e.target.value);
  };

  const onRadioChange = (e: any) => {
    setType(e.target.value);
  };

  const onOpenExplorerClick = () => {
    if (inputFile && inputFile?.current) {
      inputFile.current.click();
    }
  };

  const onFileChange = () => {
    if (!!selectedFile || (segmentsInfo && segmentsInfo.id)) {
      setShowConfirmUpdatingCsvModal(true);
    } else {
      onOpenExplorerClick();
    }
  };

  const onValidateFile = async (e: any) => {
    const file = e.target.files[0];

    if (file) {
      try {
        setIsLoading(true);

        const content = await readFileAsync(file);
        setSelectedFile(content);

        const updatedCsvInfo: any = await csvSegments({ csv: content }).unwrap();
        dispatch(setCsvInfoData(updatedCsvInfo));

        if (updatedCsvInfo && updatedCsvInfo.resultSet) {
          const { count, validCount, invalidCount } = updatedCsvInfo;

          const filteredUsers = updatedCsvInfo.resultSet.filter((user: UserResultSetType) => user.id);

          setNewFileUpload(true);

          setAllUsers(filteredUsers);
          setInvalidUsers(updatedCsvInfo.invalidSet);
          setAllUsersCount(count || 0);
          setValidUsersCount(validCount || 0);
          setInvalidUsersCount(invalidCount || 0);

          toast.success({ title: intl.formatMessage({ id: 'Csv is uploaded successfully!' }) });
        }
      } catch {
        resetState();

        toast.error({ title: intl.formatMessage({ id: 'Csv segments is failure!' }) });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onRefreshClick = async () => {
    if (selectedFile) {
      try {
        setIsLoading(true);

        const prevResultSet = [...csvInfo?.resultSet];
        const validPrevResultSet = prevResultSet.filter(item => item.valid !== false);

        const { count, validCount, invalidCount, resultSet } = csvInfo;

        const response: any = await csvSegments({ csv: selectedFile }).unwrap();
        dispatch(setCsvInfoData(response));

        setAllUsersCount(count);
        setValidUsersCount(validCount);
        setInvalidUsersCount(invalidCount);
        setAllUsers(prevResultSet[0].valid ? resultSet : validPrevResultSet);

        toast.success({ title: intl.formatMessage({ id: 'Csv is uploaded successfully!' }) });
      } catch {
        resetState();

        toast.error({ title: intl.formatMessage({ id: 'Csv segments is failure!' }) });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onDownloadInvalidUsersClick = () => {
    if (invalidUsers) {
      const fileName = 'invalid_users.csv';
      const fileType = 'text/plain;charset=utf-8';

      const link = document.createElement('a');

      let header = `#,Correo electrónico,Razón\n`;

      const rows = invalidUsers.map((user: UserResultSetType, index: number) => {
        return `${index + 1},${user.email},${intl.formatMessage({ id: user.reason })}`;
      });

      const content = header + rows.join('\n');

      const file = new Blob([content], { type: fileType });

      link.href = URL.createObjectURL(file);
      link.download = fileName;
      link.click();

      URL.revokeObjectURL(link.href);
    }
  };

  const onSaveClick = async () => {
    let isUpdate = false;

    try {
      setIsLoading(true);
      setPageSize(defaultPageSize);

      const queryString = buildQueryString({});

      const body = {
        id: segmentsInfo ? segmentsInfo.id : undefined,
        name: name.trim(),
        type: type,
        customers: type === segmentTypes.personalized && allUsersCount > 0 ? allUsers : undefined,
      };

      if (segmentsInfo && segmentsInfo.id) {
        isUpdate = true;
        await updateSegments(body).unwrap();
      } else {
        await createSegments(body).unwrap();
      }

      const response = await getSegmentsList({ queryString }).unwrap();
      dispatch(setSegmentsListData(response));

      setName('');

      const successMessageId = isUpdate ? 'Segments is updated successfully!' : 'Segments is created successfully!';
      toast.success({ title: intl.formatMessage({ id: successMessageId }) });

      setNewFileUpload(false);

      onCloseModal();
    } catch {
      const errorMessageId = isUpdate ? 'Updating segments is failure!' : 'Creating segments is failure!';
      toast.error({ title: intl.formatMessage({ id: errorMessageId }) });
    } finally {
      setIsLoading(false);
    }
  };

  const onSortChange = (value: string) => {
    setSelectedSortStatus(value);

    const newType = value === '1' ? segmentTypes.personalized : segmentsInfo?.type || '';

    setType(newType);

    setSelectedFile(null);
  };

  const onCalculateClick = async () => {
    try {
      if (type) {
        setIsLoading(true);

        const response: any = await calculateSegments({ type, sid: id }).unwrap();

        if (response) {
          dispatch(setCalculateInfoData(response));
          setCalculateData(response.totalCount);
        }
      }
    } catch {
      setCalculateData(0);
    } finally {
      setIsLoading(false);
    }
  };

  const onCancelClick = () => {
    const filterStatus = isRelevantSegmentType ? '0' : '1';

    setName(segmentsInfo?.name || '');
    setType(segmentsInfo?.type || '');
    setSelectedSortStatus(filterStatus);
    setCalculateData(segmentsInfo?.customersCount || 0);
    setValidUsersCount(segmentsInfo?.customersCount || 0);
  };

  const isDisabledOption = (type: string) => {
    if (segmentsInfo && segmentsInfo.id && segmentsInfo.type === 'personalized' && type === '0') {
      return true;
    }
    return false;
  };

  const onDownloadCsvClick = async () => {
    if (segmentsInfo) {
      await downloadCustomers({ id: segmentsInfo.id }).unwrap();
    }
  };

  const onClose = async () => {
    setIsLoading(true);
    const queryString = buildQueryString({});
    const response = await getSegmentsList({ queryString }).unwrap();

    dispatch(setSegmentsListData(response));

    setName('');
    setType('');
    setSelectedSortStatus('');

    setNewFileUpload(false);
    onCloseModal();
    setIsLoading(false);
  };

  let isDisabled = segmentsInfo
    ? !name.length || (segmentsInfo.name === name && segmentsInfo.type === type)
    : !(name && type);
  let isDisabledCsv = segmentsInfo
    ? newFileUpload
      ? false || name.length === 0
      : segmentsInfo.name === name || name.length === 0
    : !name || !selectedFile;
  const isRelevantSegmentType = segmentsInfo?.type !== segmentTypes.personalized;
  const isSegmentInfoValid = segmentsInfo && segmentsInfo.id;
  const isSegmentsDifferent =
    segmentsInfo?.name !== name || (isRelevantSegmentType ? segmentsInfo?.type !== type : selectedSortStatus !== '1');

  return (
    <ModalDlg className="segments_view_modal" visible={isOpenModal} width={430} handleCancel={onClose} isCenter>
      <>
        <div className="modal_header">
          <div className="name_wrapper">
            <h3>
              <FormattedMessage id={segmentsInfo && segmentsInfo.id ? 'Edit segment' : 'New segment'} />
            </h3>
            <div className="close_modal" onClick={onClose}>
              <Close />
            </div>
          </div>
        </div>
        <div className="new_segments_form_wrapper">
          <Form className="basic_info_form">
            <div className="segments_input">
              <Form.Item>
                <p className="title_deals">
                  <FormattedMessage id="Segment name" />
                </p>
                <Input
                  placeholder={intl.formatMessage({ id: 'Segment name' })}
                  value={name}
                  onChange={onNameChange}
                  maxLength={200}
                />
              </Form.Item>
              <Form.Item>
                <p className="title_deals">
                  <FormattedMessage id="Characteristic" />
                </p>
                <Select
                  className="select_modal"
                  suffixIcon={<ArrowDropDown />}
                  onChange={onSortChange}
                  disabled={!!segmentsInfo?.id}
                  id="campaign_status_filter"
                  value={selectedSortStatus}
                  dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                >
                  {sortOptions.map(item => (
                    <Option
                      value={item.value}
                      key={item.label}
                      style={{ fontFamily: 'Lato', color: '#242c40' }}
                      disabled={isDisabledOption(item.value)}
                    >
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {selectedSortStatus === '0' ? (
                <>
                  <Form.Item>
                    <p className="title_deals">
                      <FormattedMessage id="Characteristic" />
                    </p>
                    <Radio.Group onChange={onRadioChange} value={type} disabled={!!segmentsInfo?.id}>
                      <Radio value={'new_customer'}>{intl.formatMessage({ id: 'New' })}</Radio>
                      <Radio value={'recurrent_customer'}>{intl.formatMessage({ id: 'Recurrent' })}</Radio>
                      <Radio value={'everyone'}>{intl.formatMessage({ id: 'Everyone' })}</Radio>
                      <Radio value={'welcome_offer'}>{intl.formatMessage({ id: 'Welcome offer' })}</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {((type === 'welcome_offer' && segmentsInfo?.id) || type !== 'welcome_offer') && (
                    <Form.Item>
                      <p className="title_deals">
                        <FormattedMessage id="Number of user estimated" />
                        <span className="users_number">{calculateData || 0}</span>
                        <div className="icon_upload_wrapper">
                          <Button onClick={onCalculateClick}>
                            <CachedOutlined />
                            {intl.formatMessage({ id: 'Calculate' })}
                          </Button>
                        </div>
                      </p>
                    </Form.Item>
                  )}
                </>
              ) : (
                <></>
              )}
              {selectedSortStatus === '1' ? (
                <>
                  <Form.Item>
                    <div className="csv-info">
                      {(!segmentsInfo?.id || selectedFile) && (
                        <>
                          <div>{intl.formatMessage({ id: 'Number of user' })}</div>
                          <div>{allUsersCount}</div>
                        </>
                      )}
                      <div>{intl.formatMessage({ id: 'Number of user loaded' })}</div>
                      <div>{validUsersCount}</div>
                      {(!segmentsInfo?.id || selectedFile) && (
                        <>
                          <div>{intl.formatMessage({ id: 'User invalid' })}</div>
                          <div className="invalid_users">
                            <p className="invalid_user_value">{invalidUsersCount}</p>
                            {invalidUsersCount > 0 && (
                              <button className="download_button" onClick={onDownloadInvalidUsersClick}>
                                <img src={downloadImg} alt="download" width={16} height={16} />
                              </button>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="csv-buttons">
                      <label
                        className="custom_button"
                        onClick={onRefreshClick}
                        style={selectedFile ? {} : { opacity: '0.5' }}
                      >
                        <CachedOutlined />
                        <span className="csvbtn">{intl.formatMessage({ id: 'Refresh' })}</span>
                      </label>
                      <input ref={inputFile} type="file" accept=".csv" onChange={onValidateFile} hidden />
                      {segmentsInfo?.id && (
                        <button
                          className="user_dowload_button"
                          style={isLoading ? { opacity: '0.5' } : {}}
                          onClick={onDownloadCsvClick}
                          disabled={newFileUpload}
                        >
                          <img src={downloadImg} alt="download" width={16} height={16} />
                          <span className="csvbtn">{intl.formatMessage({ id: 'Download csv' })}</span>
                        </button>
                      )}
                      <button
                        className="custom_button"
                        style={isLoading ? { opacity: '0.5' } : {}}
                        onClick={onFileChange}
                      >
                        <FileUploadOutlinedIcon />
                        <span className="csvbtn">{intl.formatMessage({ id: 'Upload' })}</span>
                      </button>
                    </div>
                  </Form.Item>
                </>
              ) : (
                <></>
              )}
            </div>
            <Form.Item>
              <div className="side_btns_cat">
                <div className="btn_wrapper">
                  {isSegmentInfoValid && isSegmentsDifferent ? (
                    <p className="cancel_btn_segments" onClick={onCancelClick}>
                      <FormattedMessage id="Cancel" />
                    </p>
                  ) : null}
                  <Button
                    className="save_sar_btn"
                    onClick={onSaveClick}
                    disabled={selectedSortStatus === '0' ? isDisabled || false : isDisabledCsv}
                  >
                    <FormattedMessage id={isSegmentInfoValid ? 'Save changes' : 'Save'} />
                    <ArrowForward />
                  </Button>
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
        {isLoading && <Loading visible={isLoading} />}
        <ConfirmUpdatingCsvViewModal
          isShowModal={isShowConfirmUpdatingCsvModal}
          onConfirm={onOpenExplorerClick}
          onShowModal={setShowConfirmUpdatingCsvModal}
        />
      </>
    </ModalDlg>
  );
};

export default SegmentsViewModal;

const buildQueryString = ({
  search = '',
  page = 1,
  size = 10,
  count = true,
  sort_by_id = 'desc',
  sort_by_name = '',
}) => {
  const params = [`search=${encodeURIComponent(search)}`, `page=${page}`, `size=${size}`, `count=${count}`];

  if (sort_by_id) {
    params.push(`sort[id]=${sort_by_id}`);
  }

  if (sort_by_name) {
    params.push(`sort[name]=${sort_by_name}`);
  }

  return params.join('&');
};
