import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import DistributionList from 'containers/Dashboard/Distribution/DistributionList';
import DistributionEdit from 'containers/Dashboard/Distribution/DistributionEdit';
import DistributionRestaurants from 'containers/Dashboard/Distribution/DistributionRestaurants';
import DistributionMonitor from 'containers/Dashboard/Distribution/DistributionMonitor';
import TrafficMonitor from 'containers/Dashboard/Distribution/TrafficMonitor';
import RestaurantAnalysis from 'containers/Dashboard/Distribution/RestaurantAnalysis';
import DistributionReport from 'containers/Dashboard/Distribution/DistributionReport';

class Ambassadors extends PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path="/dashboard/distribution" component={DistributionList} />
        <Route exact path="/dashboard/distribution/restaurants" component={DistributionRestaurants} />
        <Route exact path="/dashboard/distribution/monitor" component={DistributionMonitor} />
        <Route exact path="/dashboard/distribution/traffic" component={TrafficMonitor} />
        <Route exact path="/dashboard/distribution/analysis" component={RestaurantAnalysis} />
        <Route exact path="/dashboard/distribution/report" component={DistributionReport} />
        <Route
          exact
          path={['/dashboard/distribution/new', '/dashboard/distribution/:id']}
          component={DistributionEdit}
        />
      </Switch>
    );
  }
}

export default Ambassadors;
