import { api } from '..';
import { CategoriesListData } from './types';

export const categoriesApi = api.enhanceEndpoints({ addTagTypes: ['Categories'] }).injectEndpoints({
  endpoints: build => ({
    getCategoriesList: build.query<CategoriesListData, any>({
      query: ({ queryString }) => ({
        url: `v1/deals/categories?${queryString}`,
        method: 'GET',
      }),
    }),
    createCategories: build.mutation<any, any>({
      query: body => ({
        url: `v1/deals/categories`,
        method: 'POST',
        body,
      }),
    }),
    updateCategories: build.mutation<any, any>({
      query: body => ({
        url: `v1/deals/categories/${body.id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteCategories: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `v1/deals/categories/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetCategoriesListQuery,
  useCreateCategoriesMutation,
  useUpdateCategoriesMutation,
  useDeleteCategoriesMutation,
} = categoriesApi;
