import React, { useState } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Input, Button, Select } from 'antd';
import { Refresh, Search, ArrowDropDown } from '@material-ui/icons';

const { Option } = Select;

type CampaignFilterType = {
  searchText: string;
  handleSearch: (e: any) => void;
  onAvailableFilter: (e: string) => void;
  onSort: (e: string) => void;
  onApplyFilter: () => void;
};

const CampaignFilter: React.FC<CampaignFilterType> = ({
  searchText,
  handleSearch,
  onAvailableFilter,
  onSort,
  onApplyFilter,
}) => {
  const [selectedFilterStatus, setSelectedFilterStatus] = useState<string>('');
  const [selectedSortedStatus, setSelectedSortedStatus] = useState<string>('');
  const intl = useIntl();
  const agentAvailable = [
    { value: '', label: intl.formatMessage({ id: 'All' }) },
    { value: 'true', label: intl.formatMessage({ id: 'Active' }) },
    { value: 'false', label: intl.formatMessage({ id: 'Inactive' }) },
  ];

  const agentSorts = [
    { value: 'Idesc', label: intl.formatMessage({ id: 'ID (desc)' }) },
    { value: 'Iasc', label: intl.formatMessage({ id: 'ID (asc)' }) },
    { value: 'Nasc', label: intl.formatMessage({ id: 'Name (A-Z)' }) },
    { value: 'Ndesc', label: intl.formatMessage({ id: 'Name (Z-A)' }) },
    { value: 'Sdesc', label: intl.formatMessage({ id: 'Status (Active first)' }) },
    { value: 'Sasc', label: intl.formatMessage({ id: 'Status (Inactive first)' }) },
  ];

  const handleAvailableFilter = (value: string) => {
    setSelectedFilterStatus(value);
    onAvailableFilter(value);
  };

  const handleSort = (value: string) => {
    setSelectedSortedStatus(value);
    onSort(value);
  };

  const sortOptions = agentSorts.map(item => {
    let isDisabled = false;

    if (selectedFilterStatus === 'false' || selectedFilterStatus === 'true') {
      isDisabled = item.value !== 'Ndesc' && item.value !== 'Nasc' && item.value !== 'Idesc' && item.value !== 'Iasc';
    }

    return (
      <Option
        value={item.value}
        key={item.label}
        disabled={isDisabled}
        style={{ fontFamily: 'Lato', color: '#242c40' }}
      >
        {item.label}
      </Option>
    );
  });

  const filterOptions = agentAvailable.map(item => {
    let isDisabled = false;

    if (selectedSortedStatus === 'Sdesc' || selectedSortedStatus === 'Sasc') {
      isDisabled = item.value !== '';
    }

    return (
      <Option
        value={item.value}
        key={item.label}
        disabled={isDisabled}
        style={{ fontFamily: 'Lato', color: '#242c40' }}
      >
        {item.label}
      </Option>
    );
  });

  return (
    <div className="campaign_filter_layout" id="campaign_filter_layout">
      <Select
        className="availability_by"
        suffixIcon={<ArrowDropDown />}
        placeholder={intl.formatMessage({ id: 'Status' })}
        onChange={handleAvailableFilter}
        id="campaign_status_filter"
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {filterOptions}
      </Select>
      <Select
        className="sort_by"
        suffixIcon={<ArrowDropDown />}
        placeholder={intl.formatMessage({ id: 'Sort by' })}
        onChange={handleSort}
        id="campaign_name_filter"
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {sortOptions}
      </Select>
      <Button className="apply_filters_btn" onClick={onApplyFilter} id="campaign_apply_filters_btn">
        <FormattedMessage id="Apply filters" />
        <Refresh />
      </Button>
      <Input
        suffix={<Search />}
        placeholder="Buscar categoría"
        value={searchText}
        onChange={e => handleSearch(e)}
        onKeyDown={e => handleSearch(e)}
        id="campaign_search_input"
        maxLength={200}
      />
    </div>
  );
};

export default CampaignFilter;
