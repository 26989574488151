import { api } from '..';
import { AmbassadorData, SelectedAmbassadorData, SyncedData, SyncedScheduleData } from './types';

export const ambassadorApi = api.enhanceEndpoints({ addTagTypes: ['Ambassador'] }).injectEndpoints({
  endpoints: build => ({
    getAmbassadorList: build.query<
      AmbassadorData,
      { offset: number; limit: number; or: boolean; filter: string; sort: string }
    >({
      query: ({ offset, limit, or, filter, sort }) => ({
        url: `v0/ambassadors?details=true&offset=${offset || 0}&limit=${limit || 5}${or ? `&or=${or}` : ''}${
          filter || ''
        }${sort ? `&sort=${sort}` : '&sort=createdAt desc'}`,
        method: 'GET',
      }),
    }),
    getAmbassador: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `v0/ambassadors/${id}`,
        method: 'GET',
      }),
    }),
    updateAmbassador: build.mutation<any, any>({
      query: body => ({
        url: `v0/ambassadors/${body.id}`,
        method: 'PUT',
        body: body.updateInfo,
      }),
    }),
    syncedAmbassador: build.query<any, any>({
      query: () => ({
        url: `v0/ambassadors/sync`,
        method: 'GET',
      }),
    }),
    syncedScheduleAmbassador: build.query<any, any>({
      query: () => ({
        url: `v0/ambassadors/syncSchedule`,
        method: 'GET',
      }),
    }),
    getAmbassadorSlots: build.query<any, { id: number; startAt: string; endAt: string }>({
      query: ({ id, startAt, endAt }) => ({
        url: `v0/ambassadors/${id}/restaurants?startAt=${startAt}&endAt=${endAt}`,
        method: 'GET',
      }),
    }),
    updateAmbassadorSlots: build.mutation<any, any>({
      query: body => ({
        url: `v0/ambassadors/${body.id}/restaurants?startAt=${body.startAt}&endAt=${body.endAt}`,
        method: 'PUT',
        body: body.updateSlots,
      }),
    }),
    updateAmbassadorRestaurants: build.mutation<any, any>({
      query: body => ({
        url: `v0/ambassadors/${body.id}/restaurants?startAt=${body.startAt}&endAt=${body.endAt}`,
        method: 'PUT',
        body: body.updateSlots,
      }),
    }),
  }),
});

export const {
  useLazyGetAmbassadorListQuery,
  useLazyGetAmbassadorQuery,
  useUpdateAmbassadorMutation,
  useLazySyncedAmbassadorQuery,
  useLazySyncedScheduleAmbassadorQuery,
  useLazyGetAmbassadorSlotsQuery,
  useUpdateAmbassadorSlotsMutation,
  useUpdateAmbassadorRestaurantsMutation,
} = ambassadorApi;
