import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import ModalDlg from 'components/Basic/ModalDlg';
import { ArrowForward } from '@material-ui/icons';

type Props = {
  isShowModal: boolean;
  onConfirm: () => void;
  onShowModal: (value: boolean) => void;
};

const ConfirmUpdatingCsvViewModal: React.FC<Props> = ({ isShowModal, onConfirm, onShowModal }: Props) => {
  const onConfirmAndClose = () => {
    onConfirm();
    onShowModal(false);
  };

  return (
    <ModalDlg
      className="confirm_update_csv_modal"
      visible={isShowModal}
      width={434}
      handleCancel={() => onShowModal(false)}
      isCenter
    >
      <div className="header">
        <h3>
          <FormattedMessage id="Are you sure you want to do this" />
        </h3>
        <p className="description">
          <FormattedMessage id="Once you upload and save the new file, the old users will be deleted" />
        </p>
      </div>
      <div className="footer">
        <div className="button_container">
          <p className="cancel_button" onClick={() => onShowModal(false)}>
            <FormattedMessage id="Cancel" />
          </p>
          <Button className="confirm_button" onClick={onConfirmAndClose}>
            <FormattedMessage id="Continue" />
            <ArrowForward />
          </Button>
        </div>
      </div>
    </ModalDlg>
  );
};

export default ConfirmUpdatingCsvViewModal;
