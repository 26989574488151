import { api } from '..';
import { CampaignData } from './types';

export const campaignApi = api.enhanceEndpoints({ addTagTypes: ['Campaign'] }).injectEndpoints({
  endpoints: build => ({
    getCampaignList: build.query<CampaignData, any>({
      query: ({ queryString }) => ({
        url: `v1/deals/campaigns/preview?${queryString}`,
        method: 'GET',
      }),
    }),
    createCampaign: build.mutation<any, any>({
      query: body => ({
        url: `v1/deals/campaigns`,
        method: 'POST',
        body,
      }),
    }),
    updateCampaign: build.mutation<any, any>({
      query: body => ({
        url: `v1/deals/campaigns/${body.id}`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const { useLazyGetCampaignListQuery, useCreateCampaignMutation, useUpdateCampaignMutation } = campaignApi;
