import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, SegmentsListData, CsvInfoType, CalculateInfoType } from './types';

const initialState: initState = {
  list: {
    segments: [],
    totalCount: 0,
  },
  csvInfo: {
    count: 0,
    invalidCount: 0,
    resultSet: [],
    validCount: 0,
  },
  calculateInfo: {
    totalCount: 0,
  },
};

const segmentsSlice = createSlice({
  name: 'segments',
  initialState,
  reducers: {
    setSegmentsListData(state: initState, action: PayloadAction<SegmentsListData>) {
      state.list = action.payload;
    },
    setCsvInfoData(state: initState, action: PayloadAction<CsvInfoType>) {
      state.csvInfo = action.payload;
    },
    setCalculateInfoData(state: initState, action: PayloadAction<CalculateInfoType>) {
      state.calculateInfo = action.payload;
    },
  },
});

export const { setSegmentsListData, setCsvInfoData, setCalculateInfoData } = segmentsSlice.actions;

export default segmentsSlice.reducer;
