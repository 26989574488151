import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import GoogleMapReact from 'google-map-react';
import Geocode from 'react-geocode';
import { Button } from 'antd';
import DropMenu from 'components/Basic/DropMenu';
import Marker from 'components/Basic/Marker';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import LocationSearchInput from 'components/Basic/LocationSearchInput';
import { getLocations } from 'utilities/common';
import SearchIcon from '@material-ui/icons/Search';

const distances = [1, 2, 3, 4, 5];
class RestaurantMap extends PureComponent {
  constructor(props) {
    super(props);
    this.circle = null;
    this.map = null;
    this.state = {
      distance: 0,
      isVisisbleSearch: false,
      fullScreenOpen: false,
    };
    this.floatingAmbassadors = [
      this.props.intl.formatMessage({ id: 'Allow floating driver' }),
      this.props.intl.formatMessage({ id: "Don't allow floating driver" }),
    ];
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.restaurantInfo.geoFence !== this.props.restaurantInfo.geoFence) {
      this.updateGeoFence(prevState);
    }
  }

  updateGeoFence = prevState => {
    this.setState({
      ...prevState,
      distance: this.props.restaurantInfo.geoFence || 0,
    });
    if (this.circle && this.map) {
      this.circle.setRadius(this.props.restaurantInfo.geoFence * 1000);
      const postion = this.map.getCenter();
      this.circle.setCenter(postion.toJSON());
    }
  };

  handleGoogleAPILoaded = ({ map, maps }) => {
    const { restaurantInfo } = this.props;
    // const _ = this;
    // const marker = new maps.Marker({
    //   position: {
    //     lat: restaurantInfo.lat || getLocations().lat,
    //     lng: restaurantInfo.long || getLocations().lng
    //   },
    //   map,
    //   draggable: false
    // });
    this.map = map;
    this.circle = new maps.Circle({
      radius: this.state.distance * 1000,
      center: {
        lat: restaurantInfo.lat || getLocations().lat,
        lng: restaurantInfo.long || getLocations().lng,
      },
      map,
      fillColor: '#FF2527',
      fillOpacity: 0.160238,
      strokeColor: '#FF2527',
    });

    document.addEventListener('fullscreenchange', this.onFullscreenChange);
    document.addEventListener('webkitfullscreenchange', this.onFullscreenChange);
    document.addEventListener('mozfullscreenchange', this.onFullscreenChange);
    document.addEventListener('MSFullscreenChange', this.onFullscreenChange);
  };

  onFullscreenChange = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      this.setState({ fullScreenOpen: true });
    } else {
      this.setState({ fullScreenOpen: false });
    }
  };

  handleDistance = key => {
    const { restaurantInfo } = this.props;
    if (restaurantInfo.lat && restaurantInfo.long) {
      this.setState({ distance: distances[key] }, () => {
        if (this.circle) {
          this.circle.setRadius(this.state.distance * 1000);
          // this.circle.setCenter({
          //   lat: restaurantInfo.lat || getLocations().lat,
          //   lng: restaurantInfo.long || getLocations().lng
          // });
        }
      });
      this.props.onChange('geoFence', distances[key]);
    }
    if (this.circle && this.map) {
      const postion = this.map.getCenter();
      this.setState({ distance: distances[key] });
      this.circle.setRadius(distances[key] * 1000);
      this.circle.setCenter(postion.toJSON());
    }
  };

  handleFloatingAb = key => {
    this.props.onChange(
      'floatingAmbassador',
      this.floatingAmbassadors[key] === this.props.intl.formatMessage({ id: 'Allow floating driver' }),
    );
  };

  handleDrag = map => {
    const postion = map.getCenter();
    if (this.circle) {
      this.circle.setCenter(postion.toJSON());
    }
    Geocode.fromLatLng(postion.toJSON().lat, postion.toJSON().lng).then(
      response => {
        const address = response.results[0].formatted_address;
        this.props.onDragMap(postion.toJSON(), address);
      },
      error => {
        console.error(error);
      },
    );
  };

  handleZoom = () => {
    const { restaurantInfo } = this.props;
    if (this.circle && this.map) {
      // const postion = this.map.getCenter();

      this.circle.setRadius(this.state.distance * 1000);
      this.map.setCenter({
        lat: restaurantInfo.lat,
        lng: restaurantInfo.long,
      });
      this.circle.setCenter({
        lat: restaurantInfo.lat,
        lng: restaurantInfo.long,
      });
    }
  };

  showSearchBox = () => {
    const { isVisisbleSearch } = this.state;
    this.setState({ isVisisbleSearch: !isVisisbleSearch });
  };

  handleGeoInfo = (address, latLng) => {
    if (this.circle && this.map) {
      this.circle.setRadius(this.state.distance * 1000);
      this.circle.setCenter({
        lat: latLng.lat,
        lng: latLng.lng,
      });
      this.props.onDragMap(latLng, address);
    }
  };

  render() {
    const { restaurantInfo } = this.props;
    const { isVisisbleSearch } = this.state;

    return (
      <div className="restaurant_map_layout">
        <div className="map_setting">
          <p className="title">
            <FormattedMessage id="Geo reference of the restaurant and ambassadors" />
          </p>
          <p className="description">
            <FormattedMessage id="Restaurant Map Description" />
          </p>
          <div className="geo_distance">
            <DropMenu
              items={distances}
              onMenu={key => this.handleDistance(key)}
              placeHolder="Geo-cerca a kilómetros"
              defaultValue={restaurantInfo.geoFence ? restaurantInfo.geoFence : 0}
            />
          </div>
          <div className="geo_allow">
            <DropMenu
              items={this.floatingAmbassadors}
              onMenu={key => this.handleFloatingAb(key)}
              placeHolder="Permite embajadores flotantes"
              defaultValue={
                restaurantInfo.floatingAmbassador
                  ? this.props.intl.formatMessage({
                      id: 'Allow floating driver',
                    })
                  : this.props.intl.formatMessage({
                      id: "Don't allow floating driver",
                    })
              }
            />
          </div>
        </div>
        <div className="map_wrapper">
          {isVisisbleSearch && <LocationSearchInput onGetGeoInfo={this.handleGeoInfo} />}
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAP_API,
            }}
            defaultCenter={{
              lat: getLocations().lat,
              lng: getLocations().lng,
            }}
            center={{
              lat: restaurantInfo.lat || getLocations().lat,
              lng: restaurantInfo.long || getLocations().lng,
            }}
            defaultZoom={20}
            zoom={11}
            onGoogleApiLoaded={this.handleGoogleAPILoaded}
            onDragEnd={this.handleDrag}
            onZoomAnimationEnd={this.handleZoom}
            draggable
          >
            {this.state.fullScreenOpen && (
              <Marker lat={restaurantInfo.lat || getLocations().lat} lng={restaurantInfo.long || getLocations().lng} />
            )}
          </GoogleMapReact>
          <Marker lat={restaurantInfo.lat || getLocations().lat} lng={restaurantInfo.long || getLocations().lng} />
          <Button className="search_btn" onClick={this.showSearchBox}>
            <SearchIcon />
          </Button>
        </div>
      </div>
    );
  }
}

RestaurantMap.defaultProps = {
  restaurantInfo: {},
};

export default compose(injectIntl)(RestaurantMap);
