import React, { useState, useEffect } from 'react';
import { Tooltip, Button, Pagination } from 'antd';
import { Delete, Edit, ArrowDownwardOutlined, ArrowUpwardOutlined } from '@material-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import TabsViewModal from 'components/Dashboard/Tabs/TabsViewModal';
import DeleteConfirmModal from 'components/Basic/DeleteConfirmModal';
import toast from 'components/Basic/Toast';
import Loading from 'components/Basic/Loading';
import { useAppDispatch } from 'utilities/redux';
import { useLazyGetTabsListQuery, useDeleteTabsMutation, useChangeTabsMutation } from 'core/tabs/TabsService';
import { setTabsListData } from 'core/tabs/TabsSlice';

type Tab = {
  id: number;
  name: string;
  position: number;
  createdAt: string;
  updatedAt: string;
};

type TabsTableType = {
  tabsData: Array<Tab | null>;
  offerModal: boolean;
};

const TabsTable: React.FC<TabsTableType> = ({ tabsData, offerModal = false }) => {
  const [isShowDetailModal, setShowDetailModal] = useState(false);
  const [tabsInfo, setTabsInfo] = useState<Tab | null>(null);
  const [newTabsData, setTabsData] = useState(tabsData);
  const [isRemoveModal, setIsRemoveModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();

  const dispatch = useAppDispatch();
  const [getTabsList] = useLazyGetTabsListQuery();
  const [deleteTabs] = useDeleteTabsMutation();
  const [changeTabs] = useChangeTabsMutation();

  const handleDeleteButtonClick = async () => {
    setIsLoading(true);
    setIsRemoveModal(false);
    try {
      await deleteTabs({ id: tabsInfo!.id }).unwrap();
      const tabsList = await getTabsList({ search: '' }).unwrap();
      dispatch(setTabsListData({ list: tabsList }));

      toast.success({
        title: intl.formatMessage({
          id: 'Tab is deleted successfully!',
        }),
      });
    } catch (error) {
      toast.error({
        title: intl.formatMessage({
          id: 'Tab is deleted failure!',
        }),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOrderChange = async (currentIndex: number, newIndex: number) => {
    const updatedTabsData = [...tabsData];
    const movedItem = updatedTabsData.splice(currentIndex, 1)[0];
    updatedTabsData.splice(newIndex, 0, movedItem);

    const newTabsData = [] as any;
    updatedTabsData.forEach((item, index) => {
      const newItem = { ...item, position: index + 1 };
      newTabsData.push(newItem);
    });

    setTabsData(newTabsData);
    setIsLoading(true);
    try {
      await changeTabs({ tabPositions: newTabsData }).unwrap();
      const tabsList = await getTabsList({ search: '' }).unwrap();
      dispatch(setTabsListData({ list: tabsList }));
    } catch (error) {
      console.error('Error fetching tabs list:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeButtonClick = (currentIndex: number, direction: string) => {
    const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
    if (newIndex >= 0 && newIndex < tabsData.length) {
      handleOrderChange(currentIndex, newIndex);
    }
  };

  return (
    <div className={offerModal ? 'tabs_table_layout_offer' : 'tabs_table_layout'}>
      <div className="table_header">
        <div className="id">
          <FormattedMessage id="ID" />
        </div>
        <div className="name">
          <FormattedMessage id="Position" />
        </div>
        <div className="offers">
          <FormattedMessage id="Tab name" />
        </div>
        <div className="actions">
          <FormattedMessage id="Actions" />
        </div>
      </div>
      <div className="table_content">
        {Array.isArray(tabsData) &&
          tabsData.map((item, index) => (
            <div className={`table_item type_state_true`} key={index}>
              <div className="id">
                <p>{item?.id}</p>
              </div>
              <div className="name">
                <p>{item?.position}</p>
              </div>
              <div className="offers">
                <p>{item?.name}</p>
              </div>
              <div className="actions">
                <Tooltip placement="top" title={intl.formatMessage({ id: 'Edit tab' })}>
                  <Button
                    className="edit_btn"
                    onClick={() => {
                      setTabsInfo(item);
                      setShowDetailModal(true);
                    }}
                  >
                    <Edit />
                  </Button>
                </Tooltip>
                <Tooltip placement="top" title={intl.formatMessage({ id: 'Delete tab' })}>
                  <Button
                    className="edit_btn"
                    onClick={() => {
                      setTabsInfo(item);
                      setIsRemoveModal(true);
                    }}
                  >
                    <Delete />
                  </Button>
                </Tooltip>
                <Tooltip placement="top" title={intl.formatMessage({ id: 'Down tab' })}>
                  <Button
                    className="edit_btn"
                    onClick={() => handleChangeButtonClick(index, 'down')}
                    style={index !== tabsData.length - 1 ? {} : { pointerEvents: 'none', opacity: 0.25 }}
                  >
                    <ArrowDownwardOutlined />
                  </Button>
                </Tooltip>
                <Tooltip placement="top" title={intl.formatMessage({ id: 'Up tab' })}>
                  <Button
                    className="edit_btn"
                    onClick={() => handleChangeButtonClick(index, 'up')}
                    style={index !== 0 ? {} : { pointerEvents: 'none', opacity: 0.25 }}
                  >
                    <ArrowUpwardOutlined />
                  </Button>
                </Tooltip>
              </div>
            </div>
          ))}
      </div>
      <TabsViewModal
        isOpenModal={isShowDetailModal}
        onCloseModal={() => setShowDetailModal(false)}
        tabsInfo={tabsInfo}
      />
      <DeleteConfirmModal
        isOpenModal={isRemoveModal}
        title={intl.formatMessage({ id: 'Do you want to remove the tab?' })}
        subtitle={intl.formatMessage({
          id: 'This action cannot be undone. Continue deleting the tab?',
        })}
        onCancel={() => setIsRemoveModal(false)}
        onOk={() => handleDeleteButtonClick()}
      />
    </div>
  );
};

export default TabsTable;
