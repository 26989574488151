import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment-timezone';

export const isDateRangeInSelectedDays = (startDate: Dayjs | null, endDate: Dayjs | null, selectedDays: any) => {
  const startDay = dayjs(startDate);
  const endDay = dayjs(endDate);
  if (startDate && startDay.isAfter(endDay)) {
    return 'You have selected an invalid start and end date';
  }
  if (startDate) {
    let currentDate = startDay.clone();
    while (currentDate.isSame(endDay) || currentDate.isBefore(endDay)) {
      const currentDayOfWeek = currentDate.day();
      if (selectedDays[currentDayOfWeek > 0 ? currentDayOfWeek - 1 : 6]) {
        return true;
      }

      currentDate = currentDate.add(1, 'day');
    }
  }

  return 'Please, check date period and weekdays';
};

export const isValidDateRange = (
  startDate: Dayjs | null,
  endDate: Dayjs | null,
  startTime: Dayjs | null,
  endTime: Dayjs | null,
  dailyStartTime: Dayjs | null,
  dailyEndTime: Dayjs | null,
) => {
  const formatedStartDate = dayjs(startDate).format('YYYY-MM-DD');
  const formatedEndDate = dayjs(endDate).format('YYYY-MM-DD');
  const formatedStartTime = dayjs(startTime).format('HH:mm');
  const formatedEndTime = dayjs(endTime).format('HH:mm');
  const formatedDailyStartTime = dayjs(dailyStartTime).format('HH:mm');
  const formatedDailyEndTime = dayjs(dailyEndTime).format('HH:mm');

  const dayStart = moment(`${formatedStartDate}`);
  const dayEnd = moment(`${formatedEndDate}`);
  const startMoment = moment(`${formatedStartDate} ${formatedStartTime}`);
  const endMoment = moment(`${formatedEndDate} ${formatedEndTime}`);
  const dailyStart = moment(`${formatedStartDate} ${formatedDailyStartTime}`);
  const dailyEnd = moment(`${formatedEndDate} ${formatedDailyEndTime}`);

  if (startMoment.isAfter(endMoment)) {
    return 'startEnd2';
  }

  if (dayStart.isAfter(dayEnd)) {
    return 'startEnd1';
  }

  if (startMoment.isSame(endMoment)) {
    return 'startEnd2';
  }

  if (dailyStart.isSameOrAfter(dailyEnd)) {
    return 'daily1';
  }

  if (dailyEnd.isSameOrBefore(moment(`${formatedEndDate} ${formatedDailyStartTime}`))) {
    return 'daily1';
  }

  if (dayStart.isSame(dayEnd)) {
    if (dailyStart.isBefore(startMoment) || dailyStart.isSameOrAfter(endMoment)) {
      return 'daily3';
    }

    if (dailyEnd.isAfter(endMoment) || dailyEnd.isSameOrBefore(startMoment)) {
      return 'daily3';
    }
  }

  if (dayEnd.diff(dayStart, 'days') === 1) {
    if (moment(`${formatedStartDate} ${formatedDailyStartTime}`).isBetween(startMoment, endMoment)) {
      return true;
    }

    if (
      !moment(`${formatedStartDate} ${formatedDailyStartTime}`).isBetween(startMoment, endMoment) &&
      moment(`${formatedStartDate} ${formatedDailyEndTime}`).isBetween(startMoment, endMoment)
    ) {
      return true;
    }

    if (moment(`${formatedEndDate} ${formatedDailyStartTime}`).isBetween(startMoment, endMoment)) {
      return true;
    }

    return 'daily4';
  }

  return true;
};
