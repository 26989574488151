import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import DropMenu from 'components/Basic/DropMenu';
import { InfoOutlined } from '@material-ui/icons';

const dayNames = ['Domingos', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábados'];

const dayEnNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

class RestaurantSchedule extends PureComponent {
  constructor(props) {
    super(props);
    this.times = [];
    this.orderAmounts = [];
    this.orderSets = [
      { key: 'O', value: this.props.intl.formatMessage({ id: 'Open' }) },
      { key: 'C', value: this.props.intl.formatMessage({ id: 'Close' }) },
    ];
    for (let i = 0; i <= 40; i += 1) {
      this.orderAmounts.push(i);
    }
    for (let i = 0; i < 24; i += 0.5) {
      this.times.push({
        key: `${parseInt(i, 10) < 10 ? `0${parseInt(i, 10)}` : parseInt(i, 10)}:${i % 1 != 0 ? '30' : '00'}`,
        value: `${parseInt(i, 10) < 10 ? `0${parseInt(i, 10)}` : parseInt(i, 10)}:${i % 1 != 0 ? '30' : '00'}`,
        num: i,
      });
    }
    this.state = {
      orderSettings: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.restaurantInfo.capacities !== this.props.restaurantInfo.capacities) {
      this.updateCapacities();
    }
  }

  updateCapacities = () => {
    const { orderSettings } = this.state;
    const { restaurantInfo } = this.props;
    let capacities;
    if (restaurantInfo.capacities) {
      for (let i = 0; i < 7; i += 1) {
        capacities = restaurantInfo.capacities.filter(c => c.weekday === i) || [];
        if (
          capacities.length !== 0 &&
          orderSettings.findIndex(o => o.days === i) === -1 &&
          this.times.filter(t => t.value === capacities[capacities.length - 1].endTime.substr(0, 5)).length !== 0 &&
          this.times.filter(t => t.value === capacities[0].startTime.substr(0, 5)).length !== 0
        ) {
          orderSettings.push({
            days: i,
            close: this.times.filter(t => t.value === capacities[capacities.length - 1].endTime.substr(0, 5))[0].num,
            open: this.times.filter(t => t.value === capacities[0].startTime.substr(0, 5))[0].num,
          });
        }
      }
      this.setState({ orderSettings: [...orderSettings] });
    }
  };

  handleOrderSet = (key, field) => {
    this.props.onChange(field, this.orderSets[key].key);
  };

  handleOrderOpen = async (key, index, field) => {
    const { orderSettings } = this.state;
    await this.props.onChange(field, this.times[key].key);
    const settingId = orderSettings.findIndex(c => {
      return c.days === index;
    });
    if (settingId === -1) {
      orderSettings.push({
        days: index,
        open: this.times[key].num,
        close: 0,
      });
    } else {
      orderSettings[settingId].open = this.times[key].num;
    }
    this.setState({ orderSettings });
    this.updateSlots(index);
  };

  handleOrderClose = async (key, index, field) => {
    const { orderSettings } = this.state;
    await this.props.onChange(field, this.times[key].key);
    const settingId = orderSettings.findIndex(c => {
      return c.days === index;
    });

    if (settingId === -1) {
      orderSettings.push({
        days: index,
        close: this.times[key].num,
        open: 0,
      });
    } else {
      orderSettings[settingId].close = this.times[key].num;
    }
    this.setState({ orderSettings });
    this.updateSlots(index);
  };

  updateSlots = async weekday => {
    const { orderSettings } = this.state;
    const { restaurantInfo } = this.props;
    const slotTime = orderSettings.filter(o => o.days == weekday)[0];
    const diff = (slotTime.close || 0) - (slotTime.open || 0);
    const filteredCapacities = restaurantInfo.capacities
      ? restaurantInfo.capacities.filter(c => c.weekday != weekday) || []
      : [];
    // Outer loop to create parent
    for (
      let i = (slotTime.open || 0) % 1 !== 0 ? 30 : 0;
      i <= ((slotTime.open || 0) % 1 !== 0 ? 30 : 0) + diff * 60;
      i += 15
    ) {
      const fromHour = parseInt(slotTime.open, 10) + i / 60;
      const from = `${parseInt(fromHour, 10) < 10 ? `0${parseInt(fromHour, 10)}` : parseInt(fromHour, 10)}:${
        i % 60 < 10 ? `0${i % 60}` : i % 60
      }`;
      if (i <= ((slotTime.open || 0) % 1 !== 0 ? 30 : 0) + diff * 60 - 15) {
        const endHour = parseInt(slotTime.open, 10) + parseInt((i + 15) / 60, 10);
        const end = `${parseInt(endHour, 10) < 10 ? `0${parseInt(endHour, 10)}` : parseInt(endHour, 10)}:${
          (i + 15) % 60 < 10 ? `0${(i + 15) % 60}` : (i + 15) % 60
        }`;

        filteredCapacities.push({
          weekday,
          startTime: from,
          endTime: end,
          capacity: 40,
        });
      }
    }
    await this.props.onChange('capacities', filteredCapacities);
  };

  handleShowSlotTime = index => {
    const { orderSettings } = this.state;
    const settingId = orderSettings.findIndex(c => {
      return c.days === index;
    });

    if (settingId !== -1) {
      if (orderSettings[settingId].close > orderSettings[settingId].open) {
        orderSettings[settingId].isShowTimeSlot = true;
        this.setState({ orderSettings: [] }, () => {
          this.setState({ orderSettings });
        });
      }
    }
  };

  handleOrderAmount = (key, weekday, from, end) => {
    const { restaurantInfo } = this.props;
    for (let i = 0; i < restaurantInfo.capacities.length; i += 1) {
      if (
        restaurantInfo.capacities[i].weekday == weekday &&
        restaurantInfo.capacities[i].startTime.substr(0, 5) === from &&
        restaurantInfo.capacities[i].endTime.substr(0, 5) === end
      ) {
        restaurantInfo.capacities[i].capacity = this.orderAmounts[key] || 40;
      }
    }
    this.props.onChange('capacities', restaurantInfo.capacities);
  };

  createTable = index => {
    const table = [];
    const { restaurantInfo } = this.props;
    let orderCount = 1;
    if (this.state.orderSettings.length !== 0) {
      const settingId = this.state.orderSettings.findIndex(c => {
        return c.days === index;
      });
      const slotTime = this.state.orderSettings[settingId];
      const diff = slotTime.close - slotTime.open;
      // Outer loop to create parent
      for (
        let i = (slotTime.open || 0) % 1 !== 0 ? 30 : 0;
        i <= ((slotTime.open || 0) % 1 !== 0 ? 30 : 0) + diff * 60;
        i += 15
      ) {
        const children = [];
        const fromHour = parseInt(slotTime.open, 10) + i / 60;
        const from = `${parseInt(fromHour, 10) < 10 ? `0${parseInt(fromHour, 10)}` : parseInt(fromHour, 10)}:${
          i % 60 < 10 ? `0${i % 60}` : i % 60
        }`;
        if (i <= ((slotTime.open || 0) % 1 !== 0 ? 30 : 0) + diff * 60 - 15) {
          const endHour = parseInt(slotTime.open, 10) + parseInt((i + 15) / 60, 10);
          const end = `${parseInt(endHour, 10) < 10 ? `0${parseInt(endHour, 10)}` : parseInt(endHour, 10)}:${
            (i + 15) % 60 < 10 ? `0${(i + 15) % 60}` : (i + 15) % 60
          }`;

          if (restaurantInfo.capacities) {
            if (
              restaurantInfo.capacities.filter(
                c => c.weekday === index && c.startTime.substr(0, 5) === from && c.endTime.substr(0, 5) === end,
              ).length !== 0
            ) {
              orderCount = restaurantInfo.capacities.filter(
                c => c.weekday === index && c.startTime.substr(0, 5) === from && c.endTime.substr(0, 5) === end,
              )[0].capacity;
            }
          }
          children.push(
            <div className={`slot_time ${(i / 15) % 4 !== 0 && 'divider'}`} key={`${index}-${i}`}>
              <p className="title">{`${from} - ${end}`}</p>
              <DropMenu
                items={this.orderAmounts}
                onMenu={key => this.handleOrderAmount(key, index, from, end)}
                placeHolder={`${orderCount || this.orderAmounts[40]}`}
              />
            </div>,
          );
        }
        table.push(children);
      }
    }
    return table;
  };

  orderSlotTimeText = index => {
    return (
      <div className="time_slot_set" onClick={() => this.handleShowSlotTime(index)}>
        <InfoOutlined />
        <p className="title">
          <FormattedMessage id="Select an opening and closing time" />
        </p>
      </div>
    );
  };

  render() {
    const { orderSettings } = this.state;
    const { restaurantInfo } = this.props;
    return (
      <div className="restaurant_schedule_layout">
        {dayNames.map((day, index) => (
          <div className="restaurant_schedule" key={day}>
            <div className="select_time_range">
              <div className="order_set">
                <p className="title">{day}</p>
                <DropMenu
                  items={this.orderSets}
                  onMenu={key => this.handleOrderSet(key, `${dayEnNames[index]}Schedule`)}
                  placeHolder="Abierto a pedidos"
                  defaultValue={restaurantInfo[`${dayEnNames[index]}Schedule`]}
                />
              </div>
              <div className="open_time">
                <p className="title">
                  <FormattedMessage id="Opening Hours" />
                </p>
                <DropMenu
                  items={this.times}
                  onMenu={key => this.handleOrderOpen(key, index, `${dayEnNames[index]}OpenTime`)}
                  placeHolder="Selecciona una hora"
                  defaultValue={restaurantInfo[`${dayEnNames[index]}OpenTime`]}
                />
              </div>
              <div className="close_time">
                <p className="title">
                  <FormattedMessage id="Closing hours" />
                </p>
                <DropMenu
                  items={this.times}
                  onMenu={key => this.handleOrderClose(key, index, `${dayEnNames[index]}CloseTime`)}
                  placeHolder="Selecciona una hora"
                  defaultValue={restaurantInfo[`${dayEnNames[index]}CloseTime`]}
                />
              </div>
            </div>
            {orderSettings.map((order, orderIndex) => {
              if (
                order.days === index &&
                order.open !== undefined &&
                order.close !== undefined &&
                order.open < order.close
              ) {
                return (
                  <div className="slot_time_table" key={orderIndex}>
                    {this.createTable(index)}
                  </div>
                );
              }
              return null;
            })}
          </div>
        ))}
      </div>
    );
  }
}

RestaurantSchedule.defaultProps = {
  restaurantInfo: {},
};

export default compose(injectIntl)(RestaurantSchedule);
