import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, DepartmentsData, MunicipalitiesData, ZonesData } from './types';

const initialState: initState = {
  departments: [
    {
      id: 0,
      placeId: '',
      long: 0,
      lat: 0,
      description: '',
      createdAt: '',
      updatedAt: '',
    },
  ],
  municipalities: [
    {
      id: 0,
      placeId: '',
      long: 0,
      lat: 0,
      deptCode: 0,
      description: '',
      createdAt: '',
      updatedAt: '',
    },
  ],
  zones: [
    {
      id: 0,
      placeId: '',
      long: 0,
      lat: 0,
      deptCode: 0,
      municipalityCode: 0,
      description: '',
      createdAt: '',
      updatedAt: '',
    },
  ],
};

const regionSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setDepartmentsData(state: initState, action: PayloadAction<{ departments: DepartmentsData }>) {
      state.departments = action.payload.departments;
    },
    setMunicipalitiesData(state: initState, action: PayloadAction<{ municipalities: MunicipalitiesData }>) {
      state.municipalities = action.payload.municipalities;
    },
    setZonesData(state: initState, action: PayloadAction<{ zones: ZonesData }>) {
      state.zones = action.payload.zones;
    },
  },
});

export const { setDepartmentsData, setMunicipalitiesData, setZonesData } = regionSlice.actions;

export default regionSlice.reducer;
