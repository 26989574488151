import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  initState,
  CallsReportData,
  CybersourceReportData,
  ListReportData,
  OrderReportData,
  PointReportData,
  UsersReportData,
} from './types';

const initialState: initState = {
  callsReport: {
    filename: '',
    message: '',
  },
  cybersourceReport: {
    filename: '',
    message: '',
  },
  listReport: {
    count: 0,
    rows: [],
  },
  orderReport: {
    filename: '',
    message: '',
  },
  pointReport: {
    filename: '',
    message: '',
  },
  usersReport: {
    filename: '',
    message: '',
  },
};

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setCallsReportData(state: initState, action: PayloadAction<{ callsReport: CallsReportData }>) {
      state.callsReport = action.payload.callsReport;
    },
    setCybersourceReportData(state: initState, action: PayloadAction<{ cybersourceReport: CybersourceReportData }>) {
      state.cybersourceReport = action.payload.cybersourceReport;
    },
    setListReportData(state: initState, action: PayloadAction<{ listReport: ListReportData }>) {
      state.listReport = action.payload.listReport;
    },
    setOrderReportData(state: initState, action: PayloadAction<{ orderReport: OrderReportData }>) {
      state.orderReport = action.payload.orderReport;
    },
    setPointReportData(state: initState, action: PayloadAction<{ pointReport: PointReportData }>) {
      state.pointReport = action.payload.pointReport;
    },
    setUsersReportData(state: initState, action: PayloadAction<{ usersReport: UsersReportData }>) {
      state.usersReport = action.payload.usersReport;
    },
  },
});

export const {
  setCallsReportData,
  setCybersourceReportData,
  setListReportData,
  setOrderReportData,
  setPointReportData,
  setUsersReportData,
} = reportSlice.actions;

export default reportSlice.reducer;
