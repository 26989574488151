import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, CybersourceReportData, ListData, PointReportData, SelectedUserData } from './types';

const initialState: initState = {
  cybersourceReport: {},
  list: {
    limit: 10,
    offset: 0,
    rows: [],
    totalCount: 0,
  },
  pointReport: {},
  selectedUser: {},
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setListData(state: initState, action: PayloadAction<{ list: ListData }>) {
      state.list = action.payload.list;
    },
    setSelectedUserData(state: initState, action: PayloadAction<{ selectedUser: SelectedUserData }>) {
      state.selectedUser = action.payload.selectedUser;
    },
  },
});

export const { setListData, setSelectedUserData } = customerSlice.actions;

export default customerSlice.reducer;
