import React, { useState, useEffect } from 'react';
import { Input, Button, Select } from 'antd';
import { Search, ArrowForward, ArrowDropDown } from '@material-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import AmbassadorMap from 'components/Dashboard/Ambassadors/AmbassadorMap';
import moment from 'moment-timezone';
import toast from 'components/Basic/Toast';
import Loading from 'components/Basic/Loading';
import {
  useLazyGetRestaurantListQuery,
  useLazyGetAssignedAmbassadorsQuery,
  useLazyGetSectorAmbassadorsQuery,
} from 'core/restaurant/RestaurantService';
import { useUpdateAmbassadorRestaurantsMutation } from 'core/ambassador/AmbassadorService';
import { validationErrorCodes } from '../../../utilities/constants';

const { Option } = Select;

const States = [
  {
    value: 'AV',
    label: 'Disponible',
    color: '#7ED321',
  },
  {
    value: 'PA',
    label: 'Preasignado',
    color: '#E8A159',
  },
  {
    value: 'NA',
    label: 'No disponible',
    color: '#BCBCBC',
  },
  {
    value: 'OR',
    label: 'En ruta',
    color: '#50E2C2',
  },
  {
    value: 'FP',
    label: 'Solucionar un problema',
    color: '#FF2527',
  },
  {
    value: 'ORT',
    label: 'En reposo',
    color: '#687992',
  },
  {
    value: 'OT',
    label: 'En otra tarea',
    color: '#5A9D8E',
  },
  {
    value: 'PV',
    label: 'Problemas con el vehículo',
    color: '#FFC200',
  },
];

const DistributionRestaurants = () => {
  const intl = useIntl();

  const [timeRange, setTimeRange] = useState([]);

  const [restaurantTypes, setRestaurantTypes] = useState([
    {
      label: intl.formatMessage({ id: 'With more orders first' }),
      value: 'processingOrders desc',
    },
    {
      label: intl.formatMessage({ id: 'With fewer orders first' }),
      value: 'processingOrders asc',
    },
    {
      label: intl.formatMessage({
        id: 'More ambassadors assigned first',
      }),
      value: 'employeeAmbassadors desc',
    },
    {
      label: intl.formatMessage({
        id: 'Fewer ambassadors assigned first',
      }),
      value: 'employeeAmbassadors asc',
    },
    {
      label: intl.formatMessage({
        id: 'More floating dealers assigned first',
      }),
      value: 'floatingAmbassadors desc',
    },
    {
      label: intl.formatMessage({
        id: 'Fewer floating deliverers assigned first',
      }),
      value: 'floatingAmbassadors asc',
    },
  ]);

  const [ambassadorTypes, setAmbassadorTypes] = useState([
    {
      label: intl.formatMessage({ id: 'Most Active First' }),
      value: 'byAssignedOrders desc',
    },
    {
      label: intl.formatMessage({ id: 'Least Active First' }),
      value: 'byAssignedOrders asc',
    },
  ]);

  const [state, setState] = useState({
    restaurantList: [],
    selectedRestaurant: {},
    selectedAmbassador: {},
    ambassadors: [],
    sectorAmbassadors: [],
    startAt: '',
    endAt: '',
    restaurantSearchText: '',
    ambassadorSearchText: '',
    sectorAmbassadorSearchText: '',
    isLoading: false,
  });

  const [getRestaurantList] = useLazyGetRestaurantListQuery();
  const [getAssignedAmbassadors] = useLazyGetAssignedAmbassadorsQuery();
  const [getSectorAmbassadors] = useLazyGetSectorAmbassadorsQuery();
  const [updateAmbassadorRestaurants] = useUpdateAmbassadorRestaurantsMutation();

  useEffect(async () => {
    setState(prev => ({ ...prev, isLoading: true }));
    setTimeRange(() => {
      return Array.from({ length: 23 }).map((_, i) => {
        return {
          label: i < 10 ? `0${i}:00` : `${i}:00`,
          value: i < 10 ? `0${i}:00` : `${i}:00`,
        };
      });
    });
    try {
      const res = await getRestaurantList({}).unwrap();
      setState(prev => ({ ...prev, restaurantList: res.rows, isLoading: false }));
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  }, []);

  const handleSelectRestaurant = async item => {
    setState(prev => ({
      ...prev,
      selectedRestaurant: item,
      selectedAmbassador: {},
      isLoading: true,
    }));
    // const now = moment().tz('America/Guatemala');
    // const startAt = now.startOf('day').toString();
    // const endAt = now.endOf('day').toString();
    try {
      const assignedAmbassadors = await getAssignedAmbassadors({ id: item.id }).unwrap();
      if (assignedAmbassadors.rows) {
        setState(prev => ({
          ...prev,
          ambassadors: assignedAmbassadors.rows.filter((r, pos) => {
            return assignedAmbassadors.rows.indexOf(r) === pos;
          }),
        }));
      }

      const sectorAmbassadors = await getSectorAmbassadors({ id: item.id }).unwrap();
      if (sectorAmbassadors.rows) {
        setState(prev => ({
          ...prev,
          sectorAmbassadors: sectorAmbassadors.rows.filter((r, pos) => {
            return sectorAmbassadors.rows.indexOf(r) === pos;
          }),
        }));
      }

      setState(prev => ({ ...prev, isLoading: false }));
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const handleSelectAmbassador = (item, isSectorAmbassador) => {
    setState(prev => ({
      ...prev,
      selectedAmbassador: item,
      isSectorAmbassador,
    }));
  };

  const handleTimeChange = (v, type) => {
    if (type === 'startAt') {
      setState(prev => ({ ...prev, startAt: v }));
    } else {
      setState(prev => ({ ...prev, endAt: v }));
    }
  };

  const handleTransfer = async () => {
    const { selectedRestaurant, selectedAmbassador, startAt, endAt } = state;

    const now = moment().tz('America/Guatemala');
    const startDate = now.startOf('day').toString();
    const endDate = now.endOf('day').toString();
    const updateSlots = [
      {
        restaurant: selectedRestaurant.id,
        ambassador: selectedAmbassador.ambassador.id,
        startAt: moment(`${moment().format('YYYY-MM-DD')} ${startAt}`)
          .tz('America/Guatemala')
          .toString(),
        endAt: moment(`${moment().format('YYYY-MM-DD')} ${endAt}`)
          .tz('America/Guatemala')
          .toString(),
        state: 'A',
      },
    ];
    setState(prev => ({ ...prev, isLoading: true }));
    try {
      await updateAmbassadorRestaurants({
        id: selectedAmbassador.ambassador.id,
        startAt: startDate,
        endAt: endDate,
        updateSlots,
      }).unwrap();
      setState(prev => ({ ...prev, isLoading: false }));
      toast.success({
        title: intl.formatMessage({
          id: 'Floating Ambassador is assigned successfully',
        }),
      });
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
      if (validationErrorCodes.includes(e.data.code)) {
        toast.error({
          title: intl.formatMessage({
            id: e.msg.data.details[0].message,
          }),
        });
      } else {
        toast.error({
          title: intl.formatMessage({
            id: 'Transfer floating Ambassador is failure',
          }),
        });
      }
    }
  };

  const handleSearchRestaurant = e => {
    setState(prev => ({ ...prev, restaurantSearchText: e.target.value }));
  };

  const handleSearchAmbassador = e => {
    setState(prev => ({ ...prev, ambassadorSearchText: e.target.value }));
  };

  const handleSearchFloating = e => {
    setState(prev => ({ ...prev, sectorAmbassadorSearchText: e.target.value }));
  };

  const handleRestaurantType = async v => {
    setState(prev => ({ ...prev, isLoading: true }));
    if (v === 0) {
      try {
        const res = await getRestaurantList({}).unwrap();
        setState(prev => ({ ...prev, restaurantList: res.rows, isLoading: false }));
      } catch (e) {
        console.log(e);
      }
    } else if (v === 'processingOrders desc') {
      try {
        const res = await getRestaurantList({ sort: 'processingOrders desc' }).unwrap();
        setState(prev => ({ ...prev, restaurantList: res.rows, isLoading: false }));
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    } else if (v === 'processingOrders asc') {
      try {
        const res = await getRestaurantList({ sort: 'processingOrders asc' }).unwrap();
        setState(prev => ({ ...prev, restaurantList: res.rows, isLoading: false }));
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    } else if (v === 'employeeAmbassadors desc') {
      try {
        const res = await getRestaurantList({ sort: 'employeeAmbassadors desc' }).unwrap();
        setState(prev => ({ ...prev, restaurantList: res.rows, isLoading: false }));
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    } else if (v === 'employeeAmbassadors asc') {
      try {
        const res = await getRestaurantList({ sort: 'employeeAmbassadors asc' }).unwrap();
        setState(prev => ({ ...prev, restaurantList: res.rows, isLoading: false }));
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    } else if (v === 'floatingAmbassadors desc') {
      try {
        const res = await getRestaurantList({ sort: 'floatingAmbassadors desc' }).unwrap();
        setState(prev => ({ ...prev, restaurantList: res.rows, isLoading: false }));
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    } else if (v === 'floatingAmbassadors asc') {
      try {
        const res = await getRestaurantList({ sort: 'floatingAmbassadors asc' }).unwrap();
        setState(prev => ({ ...prev, restaurantList: res.rows, isLoading: false }));
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    }
  };

  const handleAmbassadorType = async v => {
    const { selectedRestaurant } = state;
    // const now = moment().tz('America/Guatemala');
    // const startAt = now.startOf('day').toString();
    // const endAt = now.endOf('day').toString();
    let byAssignedOrders;
    if (v === 0) {
      byAssignedOrders = undefined;
    } else {
      byAssignedOrders = `byAssignedOrders=${v === 'byAssignedOrders desc' ? 'desc' : 'asc'}`;
    }

    setState(prev => ({ ...prev, isLoading: true }));

    try {
      const res = await getAssignedAmbassadors({
        id: selectedRestaurant.id,
        byAssignedOrders,
      }).unwrap();

      if (res.rows) {
        setState(prev => ({
          ...prev,
          ambassadors: res.rows.filter((r, pos) => {
            return res.rows.indexOf(r) === pos;
          }),
        }));
      }

      setState(prev => ({ ...prev, isLoading: false }));
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const handleFloatingType = async v => {
    const { selectedRestaurant } = state;
    // const now = moment().tz('America/Guatemala');
    // const startAt = now.startOf('day').toString();
    // const endAt = now.endOf('day').toString();
    let byAssignedOrders;
    if (v === 0) {
      byAssignedOrders = undefined;
    } else {
      byAssignedOrders = `byAssignedOrders=${v === 'byAssignedOrders desc' ? 'desc' : 'asc'}`;
    }

    setState(prev => ({ ...prev, isLoading: true }));

    try {
      const res = await getSectorAmbassadors({
        id: selectedRestaurant.id,
        byAssignedOrders,
      }).unwrap();

      if (res.rows) {
        setState(prev => ({
          ...prev,
          sectorAmbassadors: res.rows.filter((r, pos) => {
            return res.rows.indexOf(r) === pos;
          }),
        }));
      }

      setState(prev => ({ ...prev, isLoading: false }));
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const {
    restaurantList,
    selectedRestaurant,
    selectedAmbassador,
    ambassadors,
    sectorAmbassadors,
    startAt,
    endAt,
    restaurantSearchText,
    ambassadorSearchText,
    sectorAmbassadorSearchText,
    isSectorAmbassador,
  } = state;

  return (
    <div className="available_restaurant_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader type="distribution_available_restaurant" />
      <div className="restaurants_wrapper">
        <div className="availabe_restaurant">
          <div className="card_header">
            <p>
              <FormattedMessage id="Restaurants" />
            </p>
          </div>
          <div className="card_list">
            <p className="description">
              <FormattedMessage id="Sort restaurants by" />
            </p>
            <Select
              defaultValue={0}
              style={{ width: '100%' }}
              onChange={v => handleRestaurantType(v)}
              suffixIcon={<ArrowDropDown />}
              dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
            >
              <Option value={0}>{intl.formatMessage({ id: 'All types' })}</Option>
              {restaurantTypes.map(s => (
                <Option value={s.value} key={s.label}>
                  {s.label}
                </Option>
              ))}
            </Select>
            <Input
              suffix={<Search />}
              value={state.restaurantSearchText}
              placeholder="Buscar restaurante"
              onChange={handleSearchRestaurant}
            />
            <div className="list scrollbar">
              {restaurantList &&
                restaurantList
                  .filter(r => r.description.toLowerCase().indexOf(restaurantSearchText.toLowerCase()) !== -1)
                  .map((item, index) => (
                    <div
                      className={`item ${selectedRestaurant.id === item.id ? 'active' : ''}`}
                      key={index}
                      onClick={() => handleSelectRestaurant(item)}
                    >
                      <p className="title">{item.description}</p>
                      <p className="description">{item.address}</p>
                    </div>
                  ))}
            </div>
          </div>
        </div>
        {!selectedRestaurant.id && (
          <div className="restaurant_info empty_wrapper">
            <p className="empty_text">
              <FormattedMessage id="Select a restaurant to load data and possibilities" />
            </p>
          </div>
        )}
        {selectedRestaurant.id && (
          <div className="restaurant_info">
            <div className="card">
              <div className="card_header">
                <p>
                  {ambassadors.length} <FormattedMessage id="Assigned ambassadors" />
                </p>
              </div>
              <div className="card_list">
                <p className="description">
                  <FormattedMessage id="Sort ambassadors by" />
                </p>
                <Select
                  defaultValue={0}
                  style={{ width: '100%' }}
                  onChange={v => handleAmbassadorType(v)}
                  suffixIcon={<ArrowDropDown />}
                  dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                >
                  <Option value={0}>{intl.formatMessage({ id: 'All types' })}</Option>
                  {ambassadorTypes.map(s => (
                    <Option value={s.value} key={s.label}>
                      {s.label}
                    </Option>
                  ))}
                </Select>
                <Input
                  suffix={<Search />}
                  placeholder="Buscar embajador"
                  value={ambassadorSearchText}
                  onChange={handleSearchAmbassador}
                />
                <div className="list scrollbar">
                  {ambassadors &&
                    ambassadors
                      .filter(
                        a => a.ambassador.firstName.toLowerCase().indexOf(ambassadorSearchText.toLowerCase()) !== -1,
                      )
                      .map((item, index) => (
                        <div
                          className={`flex align-center item ${
                            (selectedAmbassador.ambassador ? selectedAmbassador.ambassador.id : null) ===
                            item.ambassador.id
                              ? 'active'
                              : ''
                          }`}
                          key={`${item.ambassador.eid}${index}`}
                          onClick={() => handleSelectAmbassador(item, false)}
                        >
                          {States.filter(s => s.value === item.ambassador.detailedState).length !== 0 && (
                            <div
                              className={`status-circle status-${item.ambassador.detailedState}`}
                              style={{
                                backgroundColor: States.filter(s => s.value === item.ambassador.detailedState)[0].color,
                              }}
                            />
                          )}
                          <p className="title">{item.ambassador.firstName}</p>
                          <p className="description">({item.ambassador.eid || ''})</p>
                        </div>
                      ))}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card_header">
                <p>
                  <FormattedMessage id="Sector delivery men" />
                </p>
              </div>
              <div className="card_list">
                <p className="description">
                  <FormattedMessage id="Sort ambassadors by" />
                </p>
                <Select
                  defaultValue={0}
                  style={{ width: '100%' }}
                  onChange={v => handleFloatingType(v)}
                  suffixIcon={<ArrowDropDown />}
                  dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                >
                  <Option value={0}>{intl.formatMessage({ id: 'All types' })}</Option>
                  {ambassadorTypes.map(s => (
                    <Option value={s.value} key={s.label}>
                      {s.label}
                    </Option>
                  ))}
                </Select>
                <Input
                  suffix={<Search />}
                  placeholder="Buscar repartidor"
                  value={sectorAmbassadorSearchText}
                  onChange={handleSearchFloating}
                />
                <div className="list scrollbar">
                  {sectorAmbassadors &&
                    sectorAmbassadors
                      .filter(
                        a =>
                          a.ambassador.firstName.toLowerCase().indexOf(sectorAmbassadorSearchText.toLowerCase()) !== -1,
                      )
                      .map((item, index) => (
                        <div
                          className={`flex align-center item ${
                            (selectedAmbassador.ambassador ? selectedAmbassador.ambassador.id : null) ===
                            item.ambassador.id
                              ? 'active'
                              : ''
                          }`}
                          key={`${item.ambassador.eid}${index}`}
                          onClick={() => handleSelectAmbassador(item, true)}
                        >
                          {States.filter(s => s.value === item.ambassador.detailedState).length !== 0 && (
                            <div
                              className={`status-circle status-${item.ambassador.detailedState}`}
                              style={{
                                backgroundColor: States.filter(s => s.value === item.ambassador.detailedState)[0].color,
                              }}
                            />
                          )}
                          <p className="title">{item.ambassador.firstName}</p>
                          <p className="description">({item.ambassador.eid})</p>
                        </div>
                      ))}
                </div>
              </div>
            </div>
            {!selectedAmbassador.ambassador && <div className="card" />}
            {selectedAmbassador.ambassador && (
              <div className="card">
                <div className="card_header">
                  <p>
                    <FormattedMessage id="Profile of the selected dealer" />
                  </p>
                </div>
                <div className="card_list">
                  <div className="profile_info">
                    <div className="avatar" />
                    <div>
                      <p className="title">
                        <FormattedMessage id="Name" />
                      </p>
                      <p className="description">{selectedAmbassador.ambassador.firstName || ''}</p>
                    </div>
                  </div>
                  <div className="profile_detail">
                    <div>
                      <div>
                        <p className="title">
                          <FormattedMessage id="Resource type" />
                        </p>
                        <p className="description">
                          {selectedAmbassador.ambassador.type === 'E' ? 'Embajadora  Empleada' : 'Repartidor flotante'}
                        </p>
                      </div>
                      <div>
                        <p className="title">
                          <FormattedMessage id="Assigned phone" />
                        </p>
                        <p className="description">{selectedAmbassador.ambassador.phone || ''}</p>
                      </div>
                    </div>
                    <div>
                      <div>
                        <p className="title">
                          <FormattedMessage id="Motorcycle" />
                        </p>
                        <p className="description">{selectedAmbassador.ambassador.vihicle || ''}</p>
                      </div>
                      <div>
                        <p className="title">
                          <FormattedMessage id="Last access to the system" />
                        </p>
                        <p className="description">
                          {selectedAmbassador.ambassador.lastAccessedAt && (
                            <p className="title">
                              {`${moment(selectedAmbassador.ambassador.lastAccessedAt)
                                .tz('America/Guatemala')
                                .format('LL')} @${moment(selectedAmbassador.ambassador.lastAccessedAt)
                                .tz('America/Guatemala')
                                .format('HH:mm')}`}
                            </p>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <AmbassadorMap ambassadorData={[{ ...selectedAmbassador.ambassador }]} />
                  {isSectorAmbassador && (
                    <div className="schedule_card">
                      <p className="title">
                        <FormattedMessage id="Transfer to" />: {selectedRestaurant.description || ''},{' '}
                        <FormattedMessage id="for today at the following times" />
                      </p>
                      <div className="schedule_range">
                        <div>
                          <p className="description">
                            <FormattedMessage id="Hours from" />
                          </p>
                          <Select
                            onChange={v => handleTimeChange(v, 'startAt')}
                            suffixIcon={<ArrowDropDown />}
                            dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                          >
                            {timeRange.map(s => (
                              <Option value={s.value} key={s.value}>
                                {s.label}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <div>
                          <p className="description">
                            <FormattedMessage id="Hours until" />
                          </p>
                          <Select
                            suffixIcon={<ArrowDropDown />}
                            onChange={v => handleTimeChange(v, 'endAt')}
                            dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                          >
                            {timeRange.map(s => (
                              <Option value={s.value} key={s.value}>
                                {s.label}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className="btn_wrapper">
                        <Button disabled={!startAt || !endAt} onClick={handleTransfer}>
                          <FormattedMessage id="Transfer for today" />
                          <ArrowForward />
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DistributionRestaurants;
