import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import DropMenu from 'components/Basic/DropMenu';

const deliveryTimes = new Array(36).fill().map((_, index) => (index + 1) * 5);
const delayAlarmTimes = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
const minutesBeforClosings = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
const floatingAmbassadors = ['Allow', 'Disallow'];
const commissionType = [
  { key: 'F', value: 'Fixed' },
  { key: 'P', value: 'Hourly' },
];

class DeliveryZoneBasicInfo extends PureComponent {
  handleFilter = (field, key) => {
    if (field === 'deliveryTime') {
      this.props.onChange('deliveryTime', deliveryTimes[key]);
    } else if (field === 'delayAlarmTime') {
      this.props.onChange('delayAlarmTime', delayAlarmTimes[key]);
    } else if (field === 'minutesBeforClosing') {
      this.props.onChange('minutesBeforClosing', minutesBeforClosings[key]);
    } else if (field === 'floatingAmbassador') {
      this.props.onChange('floatingAmbassador', floatingAmbassadors[key] === 'Allow');
    } else if (field === 'commissionType') {
      this.props.onChange('commissionType', commissionType[key].key);
    }
  };

  render() {
    const { deliveryInfo } = this.props;
    return (
      <div className="deliveryzone_basic_info_layout">
        <div className="deliveryzone_info_wrapper">
          <h3>
            <FormattedMessage id="Conditional and informative logic" />
          </h3>
          <div className="content_wrapper">
            <Form className="basic_info_form">
              <Form.Item>
                <p className="title">
                  <FormattedMessage id="Minimum purchase" />
                </p>
                <p className="description">
                  <FormattedMessage id="Minimum economic delivery schedule" />
                </p>
                <div className="minium-purchase-input">
                  <Input
                    type="number"
                    placeholder="Antes de 11AM"
                    value={deliveryInfo.breakfastMinimumPurchase / 100 || ''}
                    min={0}
                    onChange={e => this.props.onChange('breakfastMinimumPurchase', parseFloat(e.target.value * 100))}
                  />
                  <Input
                    type="number"
                    placeholder="Después de 11AM"
                    value={deliveryInfo.lunchMinimumPurchase / 100 || ''}
                    min={0}
                    onChange={e => this.props.onChange('lunchMinimumPurchase', parseFloat(e.target.value * 100))}
                  />
                </div>
              </Form.Item>
              <Form.Item>
                <p className="title">
                  <FormattedMessage id="Delivery time" />
                </p>
                <p className="description">
                  <FormattedMessage id="Estimated delivery time scheme" />
                </p>
                <div>
                  <DropMenu
                    items={deliveryTimes}
                    onMenu={key => this.handleFilter('deliveryTime', key)}
                    placeHolder="Selecciona una opción"
                    defaultValue={deliveryInfo.deliveryTime || ''}
                  />
                </div>
              </Form.Item>
              <Form.Item>
                <p className="title">
                  <FormattedMessage id="Overdue alarm time" />
                </p>
                <p className="description">
                  <FormattedMessage id="Overdue minutes on request for notification" />
                </p>
                <div>
                  <DropMenu
                    items={delayAlarmTimes}
                    onMenu={key => this.handleFilter('delayAlarmTime', key)}
                    placeHolder="Selecciona una opción"
                    defaultValue={deliveryInfo.delayAlarmTime || ''}
                  />
                </div>
              </Form.Item>
              <Form.Item>
                <p className="title">
                  <FormattedMessage id="Minutes before closing" />
                </p>
                <p className="description">
                  <FormattedMessage id="Minutes to take orders before closing" />
                </p>
                <div>
                  <DropMenu
                    items={minutesBeforClosings}
                    onMenu={key => this.handleFilter('minutesBeforClosing', key)}
                    placeHolder="Selecciona una opción"
                    defaultValue={deliveryInfo.minutesBeforClosing || ''}
                  />
                </div>
              </Form.Item>
              <Form.Item>
                <p className="title">
                  <FormattedMessage id="Does it allow floating ambassadors?" />
                </p>
                <p className="description">
                  <FormattedMessage id="Assignment of orders to floating ambassadors" />
                </p>
                <div>
                  <DropMenu
                    items={floatingAmbassadors}
                    onMenu={key => this.handleFilter('floatingAmbassador', key)}
                    placeHolder="Si, permite embajadores flotantes"
                    defaultValue={deliveryInfo.floatingAmbassador ? 'Allow' : 'Disallow'}
                  />
                </div>
              </Form.Item>
              <Form.Item>
                <p className="title">
                  <FormattedMessage id="Commission type to the ambassador" />
                </p>
                <p className="description">
                  <FormattedMessage id="Fixed amount per delivered order" />
                </p>
                <div>
                  <DropMenu
                    items={commissionType}
                    onMenu={key => this.handleFilter('commissionType', key)}
                    placeHolder="Monto fijo por pedido entregado"
                    defaultValue={deliveryInfo.commissionType || ''}
                  />
                </div>
              </Form.Item>
              <Form.Item className="amount_per_order">
                <p className="title">
                  <FormattedMessage id="Amount per order delivered to the ambassador" />
                </p>
                <p className="description">
                  <FormattedMessage id="Value per order delivered according to selected configuration of commission type" />
                </p>
                <Input
                  type="number"
                  placeholder="Ingresa un monto, eg. “5.00”"
                  value={deliveryInfo.commissionValue || ''}
                  onChange={e => this.props.onChange('commissionValue', parseFloat(e.target.value))}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

DeliveryZoneBasicInfo.defaultProps = {
  deliveryInfo: {},
};
export default DeliveryZoneBasicInfo;
