import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import UserList from 'containers/Dashboard/Users/UserList';
import UserEdit from 'containers/Dashboard/Users/UserEdit';

class Users extends PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path="/dashboard/users" component={UserList} />
        <Route exact path="/dashboard/users/new" component={UserEdit} />
        <Route exact path="/dashboard/users/:id" component={UserEdit} />
      </Switch>
    );
  }
}

export default Users;
