import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, PromotionData } from './types';

const initialState: initState = {
  list: {
    rows: [
      {
        id: 0,
        code: '',
        name: '',
        type: '',
        condition: '',
        rewards: [
          {
            product: 0,
            quantity: 0,
          },
        ],
        totalCount: 0,
        userLimit: 0,
        usedCount: 0,
        startAt: '',
        endAt: '',
        description: '',
        state: '',
        createdAt: '',
        updatedAt: '',
      },
    ],
    totalCount: 0,
  },
};

const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    setPromotionData(state: initState, action: PayloadAction<{ list: PromotionData }>) {
      state.list = action.payload.list;
    },
  },
});

export const { setPromotionData } = promotionSlice.actions;

export default promotionSlice.reducer;
