import React from 'react';
import { Modal } from 'antd';

type ModalDlgType = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  visible: boolean;
  top?: number;
  handleCancel?: () => void;
  isCenter?: boolean;
  width?: number;
};

const ModalDlg: React.FC<ModalDlgType> = ({
  children,
  className,
  title,
  visible,
  top,
  handleCancel,
  isCenter,
  width,
}) => {
  return (
    <div className="basic-modal">
      <Modal
        title={null}
        open={visible}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        style={{ top: !isCenter ? top : undefined }}
        wrapClassName={className}
        maskClosable
        mask
        centered={isCenter}
        width={width}
      >
        {children}
      </Modal>
    </div>
  );
};

ModalDlg.defaultProps = {
  children: null,
  className: '',
  title: '',
  visible: false,
  top: 140,
  isCenter: false,
  handleCancel: () => {},
  width: 0,
};

export default ModalDlg;
