import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import GoogleMapReact from 'google-map-react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Button } from 'antd';
import Marker from 'components/Basic/Marker';
import LocationSearchInput from 'components/Basic/LocationSearchInput';
import { getLocations } from 'utilities/common';
import SearchIcon from '@material-ui/icons/Search';

let mapRef;
let mapsRef;
let drawingManager;

class GlobalAreasMap extends PureComponent {
  constructor(props) {
    super(props);
    this.circle = null;
    this.state = {
      center: {
        lat: getLocations().lat,
        lng: getLocations().lng,
      },
      zoom: 12,
      isLoadedShape: false,
      isVisisbleSearch: false,
      restaurantList: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deliveryAreas !== this.props.deliveryAreas || prevProps.restaurants !== this.props.restaurants) {
      this.initArea();
    }
  }

  initArea = () => {
    if (mapRef && mapsRef) {
      setTimeout(() => {
        this.drawOtherRegions();
      }, 2000);
    }
  };

  drawOtherRegions = () => {
    const { deliveryAreas, restaurants } = this.props;
    const { restaurantList } = this.state;
    const infoWindow = new mapsRef.InfoWindow();

    if (deliveryAreas) {
      for (let i = 0; i < deliveryAreas.length; i++) {
        if (deliveryAreas[i].area && deliveryAreas[i].area.areas && Array.isArray(deliveryAreas[i].area.areas)) {
          const shapes = [];
          deliveryAreas[i].area.areas.forEach(polygon => {
            const coords = [];
            polygon.forEach(p => {
              coords.push({
                lat: p.latitude || p.lat,
                lng: p.longitude || p.lng,
              });
            });
            shapes.push(coords);
          });
          const index = restaurants.findIndex(r => r.id === deliveryAreas[i].restaurant);

          shapes.forEach(shape => {
            const polygonsObj = new mapsRef.Polygon({
              paths: shape,
              strokeColor: deliveryAreas[i].type === 'I' ? '#000000' : '#D0021B',
              strokeWeight: 2,
              fillColor: deliveryAreas[i].type === 'I' ? '#000000' : '#D0021B',
              fillOpacity: 0.07,
            });
            polygonsObj.setMap(mapRef);
            mapsRef.event.addListener(polygonsObj, 'mousemove', e => {
              if (index !== -1) {
                infoWindow.setContent(`
                  <div class="zone_info_wrapper">
                    <p>Restaurante: <span>${restaurants[index] ? restaurants[index].description : ''}</span></p>
                    <p>Zona de reparto: <span>${
                      restaurants[index].zones && restaurants[index].zones.length !== 0
                        ? restaurants[index].zones[0].description
                        : ''
                    }</span></p>
                    <p>Tipo: <span>${deliveryAreas[i].type === 'I' ? 'Incluyente' : 'Excluyente'}</span></p>
                    <p>Estado: <span>${deliveryAreas[i].state === 'A' ? 'Activo' : 'Inactiva'}</span></p>
                  </div>
                `);
                infoWindow.setPosition(e.latLng);
                infoWindow.open(mapRef);
              }
            });
            mapsRef.event.addListener(polygonsObj, 'mouseout', e => {
              infoWindow.close();
            });
          });

          if (index !== -1 && restaurantList.findIndex(r => r.id === deliveryAreas[i].restaurant) == -1) {
            restaurantList.push(restaurants[index]);
            this.setState({ restaurantList: [...restaurantList] });
          }
        }
      }
      this.setState({ isLoadedShape: true });
    }
  };

  handleGoogleAPILoaded = ({ map, maps }) => {
    drawingManager = new maps.drawing.DrawingManager({
      // drawingMode: maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      drawingControlOptions: {
        position: maps.ControlPosition.TOP_CENTER,
        drawingModes: [maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        editable: false,
        strokeColor: '#D0021B',
        strokeWeight: 2,
        fillColor: '#D0021B',
        fillOpacity: 0.07,
        suppressUndo: false,
      },
    });
    mapRef = map;
    mapsRef = maps;
    drawingManager.setMap(map);
  };

  showSearchBox = () => {
    const { isVisisbleSearch } = this.state;
    this.setState({ isVisisbleSearch: !isVisisbleSearch });
  };

  handleGeoInfo = (address, latLng) => {
    this.setState({
      center: {
        lat: latLng.lat,
        lng: latLng.lng,
      },
    });
  };

  render() {
    const { center, isVisisbleSearch, restaurantList } = this.state;

    return (
      <div className="polygon_map_layout">
        <div className="google_map_wrapper">
          {isVisisbleSearch && <LocationSearchInput onGetGeoInfo={this.handleGeoInfo} />}
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAP_API,
              libraries: ['drawing'].join(','),
            }}
            yesIWantToUseGoogleMapApiInternals
            defaultCenter={{ lat: getLocations().lat, lng: getLocations().lng }}
            center={center}
            defaultZoom={20}
            zoom={this.state.zoom}
            onGoogleApiLoaded={this.handleGoogleAPILoaded}
            draggable
          >
            {restaurantList.map((r, index) => (
              <Marker key={index} lat={r.lat || getLocations().lat} lng={r.long || getLocations().lng} />
            ))}
          </GoogleMapReact>
          <Button className="search_btn" onClick={this.showSearchBox}>
            <SearchIcon />
          </Button>
        </div>
      </div>
    );
  }
}

export default compose(injectIntl)(GlobalAreasMap);
