/*
    Don't remove comments, because MCD team asked  hide the english tab, but not remove it
*/

import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Select, Tabs } from 'antd';
import { ArrowDropDown } from '@material-ui/icons';
import DataGT from './DataGT';
import DataEN from './DataEN';

type ExtendedDataType = {
  setSelectedTemplates: (value: any) => void;
  selectedTemplates: string;
  setPromoName: (value: any) => void;
  promoName: string;
  gtShortDesc: string;
  gtLongDesc: string;
  setGtShortDesc: (value: any) => void;
  setGtLongDesc: (value: any) => void;
  setGtProductOne: (value: any) => void;
  setGtQuantityOne: (value: any) => void;
  setGtPriceOne: (value: any) => void;
  setGtDiscountLimitOne: (value: any) => void;
  gtProductOne: string;
  gtQuantityOne: number;
  gtPriceOne: any;
  gtDiscountLimitOne: any;
  setGtProductTwo: (value: any) => void;
  setGtQuantityTwo: (value: any) => void;
  setGtPriceTwo: (value: any) => void;
  setGtDiscountLimitTwo: (value: any) => void;
  gtProductTwo: string;
  gtQuantityTwo: number;
  gtPriceTwo: any;
  gtDiscountLimitTwo: any;
  setGtProductCode: (value: any) => void;
  setGtDiscountAmount: (value: any) => void;
  gtProductCode: string;
  gtDiscountAmount: number;
  setGtProductThree: (value: any) => void;
  setGtQuantityThree: (value: any) => void;
  setGtPriceThree: (value: any) => void;
  setGtDiscountLimitThree: (value: any) => void;
  gtProductThree: string;
  gtQuantityThree: number;
  gtPriceThree: any;
  gtDiscountLimitThree: any;
  setGtProductFour: (value: any) => void;
  setGtQuantityFour: (value: any) => void;
  setGtPriceFour: (value: any) => void;
  setGtDiscountLimitFour: (value: any) => void;
  gtProductFour: string;
  gtQuantityFour: number;
  gtPriceFour: any;
  gtDiscountLimitFour: any;
  setGtReducedQuantity: (value: any) => void;
  gtReducedQuantity: string;
  setPromoNameEn: (value: any) => void;
  promoNameEn: string;
  enShortDesc: string;
  enLongDesc: string;
  setEnShortDesc: (value: any) => void;
  setEnLongDesc: (value: any) => void;
  isValid: boolean;
};

const ExtendedDataPart: React.FC<ExtendedDataType> = ({
  setSelectedTemplates,
  selectedTemplates,
  setPromoName,
  promoName,
  gtShortDesc,
  gtLongDesc,
  setGtShortDesc,
  setGtLongDesc,
  setGtProductOne,
  setGtQuantityOne,
  setGtPriceOne,
  setGtDiscountLimitOne,
  gtProductOne,
  gtQuantityOne,
  gtPriceOne,
  gtDiscountLimitOne,
  setGtProductTwo,
  setGtQuantityTwo,
  setGtPriceTwo,
  setGtDiscountLimitTwo,
  gtProductTwo,
  gtQuantityTwo,
  gtPriceTwo,
  gtDiscountLimitTwo,
  setGtProductCode,
  setGtDiscountAmount,
  gtProductCode,
  gtDiscountAmount,
  setGtProductThree,
  setGtQuantityThree,
  setGtPriceThree,
  setGtDiscountLimitThree,
  gtProductThree,
  gtQuantityThree,
  gtPriceThree,
  gtDiscountLimitThree,
  setGtProductFour,
  setGtQuantityFour,
  setGtPriceFour,
  setGtDiscountLimitFour,
  gtProductFour,
  gtQuantityFour,
  gtPriceFour,
  gtDiscountLimitFour,
  setGtReducedQuantity,
  gtReducedQuantity,
  setPromoNameEn,
  promoNameEn,
  enShortDesc,
  enLongDesc,
  setEnShortDesc,
  setEnLongDesc,
  isValid,
}) => {
  const LANGUAGE = {
    en: 'EN',
    gt: 'GT',
  };

  const intl = useIntl();

  const country = process.env.REACT_APP_COUNTRY?.toUpperCase();

  const sortOptions = [
    {
      value: 'price_deal_two_gt',
      label: intl.formatMessage({ id: 'Two Product Sets' }, { country: country }),
    },
    {
      value: 'discount_off_product_gt',
      label: intl.formatMessage({ id: 'Discount Off' }, { country: country }),
    },
    {
      value: 'price_deal_three_gt',
      label: intl.formatMessage({ id: 'Three Product Sets' }, { country: country }),
    },
    {
      value: 'price_deal_four_gt',
      label: intl.formatMessage({ id: 'Four Product Sets' }, { country: country }),
    },
    /* {
      value: 'discount_off_total_order_gt',
      label: intl.formatMessage({ id: 'GT Discount Off Total Order ($ Amount)' }),
    }, */
    /* { value: 'buy_one_get_two_gt', label: intl.formatMessage({ id: 'GT Buy One Get Two - Reduced Price' }) }, */
  ];

  const [activeTab, setActiveTab] = useState(LANGUAGE.gt);

  const handleSort = (e: any) => {
    setSelectedTemplates(e);
    setPromoName('');
    setGtShortDesc('');
    setGtLongDesc('');
    setGtProductOne('');
    setGtQuantityOne('');
    setGtPriceOne('');
    setGtDiscountLimitOne('');
    setGtProductTwo('');
    setGtQuantityTwo('');
    setGtPriceTwo('');
    setGtDiscountLimitTwo('');
    setGtProductCode('');
    setGtDiscountAmount('');
    setGtProductThree('');
    setGtQuantityThree('');
    setGtPriceThree('');
    setGtDiscountLimitThree('');
    setGtProductFour('');
    setGtQuantityFour('');
    setGtPriceFour('');
    setGtDiscountLimitFour('');
    setGtReducedQuantity('');
    setPromoNameEn('');
    setEnShortDesc('');
    setEnLongDesc('');
  };

  const checkField = (value: any) => {
    if (isValid) return;
    if (value) {
      const valid = value.length > 0;
      return !valid;
    } else {
      return true;
    }
  };

  const generateTabs = (): Array<any> => {
    let items = [];
    const gtTab =
      !isValid && (!promoName || !gtShortDesc || !gtLongDesc) ? (
        <>
          <p style={{ marginBottom: 4 }}>
            ES
            <span className="dot" />
          </p>
        </>
      ) : (
        <>
          <p style={{ marginBottom: 4 }}>ES</p>
        </>
      );
    const enTab =
      !isValid && (!promoNameEn || !enShortDesc || !enLongDesc) ? (
        <>
          <p style={{ marginBottom: 4 }}>
            EN
            <span className="dot" />
          </p>
        </>
      ) : (
        <>
          <p style={{ marginBottom: 4 }}>EN</p>
        </>
      );
    items[0] = gtTab;
    // items[1] = enTab;
    return items;
  };

  const callbackTabClicked = (key: any) => {
    key === '0' ? setActiveTab(LANGUAGE.gt) : setActiveTab(LANGUAGE.en);
  };

  // If the EN tab is uncommented, remove this useEffect
  useEffect(() => {
    setPromoNameEn(promoName);
    setEnLongDesc(gtLongDesc);
    setEnShortDesc(gtShortDesc);
  }, [promoName, gtLongDesc, gtShortDesc]);

  return (
    <>
      <div className="" id="campaign_form_wrapper">
        <div className="basic_info_form">
          <div className="tabs_input">
            <Form.Item>
              <div>
                <p className="title_deals">
                  <FormattedMessage id="Extended Data Templates" />
                </p>
                <Select
                  className={`select_modal ${checkField(selectedTemplates) && 'has-error'}`}
                  suffixIcon={<ArrowDropDown />}
                  onChange={handleSort}
                  id="campaign_status_filter"
                  value={selectedTemplates}
                  dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                >
                  {sortOptions.map(item => (
                    <Select.Option value={item.value} key={item.label} style={{ fontFamily: 'Lato', color: '#242c40' }}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
                {checkField(selectedTemplates) && (
                  <span className="error_msg">
                    <FormattedMessage id="Field is required" />
                  </span>
                )}
              </div>
              {selectedTemplates && (
                <>
                  <Tabs
                    defaultActiveKey="0"
                    tabPosition={'top'}
                    onTabClick={callbackTabClicked}
                    className="extend_tabs"
                    activeKey={`${activeTab === LANGUAGE.gt ? 0 : 1}`}
                    items={generateTabs().map((item, i) => {
                      const id = String(i);
                      return {
                        label: item,
                        key: id,
                      };
                    })}
                  />
                  {activeTab === LANGUAGE.gt && (
                    <DataGT
                      selectedTemplates={selectedTemplates}
                      setPromoName={setPromoName}
                      promoName={promoName}
                      gtShortDesc={gtShortDesc}
                      gtLongDesc={gtLongDesc}
                      setGtShortDesc={setGtShortDesc}
                      setGtLongDesc={setGtLongDesc}
                      setGtProductOne={setGtProductOne}
                      setGtQuantityOne={setGtQuantityOne}
                      setGtPriceOne={setGtPriceOne}
                      setGtDiscountLimitOne={setGtDiscountLimitOne}
                      gtProductOne={gtProductOne}
                      gtQuantityOne={gtQuantityOne}
                      gtPriceOne={gtPriceOne}
                      gtDiscountLimitOne={gtDiscountLimitOne}
                      setGtProductTwo={setGtProductTwo}
                      setGtQuantityTwo={setGtQuantityTwo}
                      setGtPriceTwo={setGtPriceTwo}
                      setGtDiscountLimitTwo={setGtDiscountLimitTwo}
                      gtProductTwo={gtProductTwo}
                      gtQuantityTwo={gtQuantityTwo}
                      gtPriceTwo={gtPriceTwo}
                      gtDiscountLimitTwo={gtDiscountLimitTwo}
                      setGtProductCode={setGtProductCode}
                      setGtDiscountAmount={setGtDiscountAmount}
                      gtProductCode={gtProductCode}
                      gtDiscountAmount={gtDiscountAmount}
                      setGtProductThree={setGtProductThree}
                      setGtQuantityThree={setGtQuantityThree}
                      setGtPriceThree={setGtPriceThree}
                      setGtDiscountLimitThree={setGtDiscountLimitThree}
                      gtProductThree={gtProductThree}
                      gtQuantityThree={gtQuantityThree}
                      gtPriceThree={gtPriceThree}
                      gtDiscountLimitThree={gtDiscountLimitThree}
                      setGtProductFour={setGtProductFour}
                      setGtQuantityFour={setGtQuantityFour}
                      setGtPriceFour={setGtPriceFour}
                      setGtDiscountLimitFour={setGtDiscountLimitFour}
                      gtProductFour={gtProductFour}
                      gtQuantityFour={gtQuantityFour}
                      gtPriceFour={gtPriceFour}
                      gtDiscountLimitFour={gtDiscountLimitFour}
                      setGtReducedQuantity={setGtReducedQuantity}
                      gtReducedQuantity={gtReducedQuantity}
                      isValid={isValid}
                    />
                  )}
                  {/* {activeTab === LANGUAGE.en && (
                    <DataEN
                      selectedTemplates={selectedTemplates}
                      setPromoNameEn={setPromoNameEn}
                      promoNameEn={promoNameEn}
                      enShortDesc={enShortDesc}
                      enLongDesc={enLongDesc}
                      setEnShortDesc={setEnShortDesc}
                      setEnLongDesc={setEnLongDesc}
                      setEnProductOne={setGtProductOne}
                      setEnQuantityOne={setGtQuantityOne}
                      setEnPriceOne={setGtPriceOne}
                      setEnDiscountLimitOne={setGtDiscountLimitOne}
                      enProductOne={gtProductOne}
                      enQuantityOne={gtQuantityOne}
                      enPriceOne={gtPriceOne}
                      enDiscountLimitOne={gtDiscountLimitOne}
                      setEnProductTwo={setGtProductTwo}
                      setEnQuantityTwo={setGtQuantityTwo}
                      setEnPriceTwo={setGtPriceTwo}
                      setEnDiscountLimitTwo={setGtDiscountLimitTwo}
                      enProductTwo={gtProductTwo}
                      enQuantityTwo={gtQuantityTwo}
                      enPriceTwo={gtPriceTwo}
                      enDiscountLimitTwo={gtDiscountLimitTwo}
                      setEnProductCode={setGtProductCode}
                      setEnDiscountAmount={setGtDiscountAmount}
                      enProductCode={gtProductCode}
                      enDiscountAmount={gtDiscountAmount}
                      setEnProductThree={setGtProductThree}
                      setEnQuantityThree={setGtQuantityThree}
                      setEnPriceThree={setGtPriceThree}
                      setEnDiscountLimitThree={setGtDiscountLimitThree}
                      enProductThree={gtProductThree}
                      enQuantityThree={gtQuantityThree}
                      enPriceThree={gtPriceThree}
                      enDiscountLimitThree={gtDiscountLimitThree}
                      setEnProductFour={setGtProductFour}
                      setEnQuantityFour={setGtQuantityFour}
                      setEnPriceFour={setGtPriceFour}
                      setEnDiscountLimitFour={setGtDiscountLimitFour}
                      enProductFour={gtProductFour}
                      enQuantityFour={gtQuantityFour}
                      enPriceFour={gtPriceFour}
                      enDiscountLimitFour={gtDiscountLimitFour}
                      setEnReducedQuantity={setGtReducedQuantity}
                      enReducedQuantity={gtReducedQuantity}
                      isValid={isValid}
                    />
                  )} */}
                </>
              )}
            </Form.Item>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExtendedDataPart;
