import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Img } from 'react-image';
import { getProductInfo, getCurrency } from 'utilities/common';
import ModalDlg from 'components/Basic/ModalDlg';
import { ArrowForward } from '@material-ui/icons';
import downloadImg from 'assets/img/file_download.svg';
import favoriteImg from 'assets/img/favorite.svg';
import placeHolderImg from 'assets/img/placeholder.svg';

function CatalogueViewModal({ prices, sizes, categoryInfo, categoryName, productPrice, isOpenModal, onCloseModal }) {
  if (!categoryInfo) {
    return null;
  }
  const getProduct = data => {
    return getProductInfo(data, prices, sizes);
  };

  const intl = useIntl();

  return (
    <ModalDlg className="catalogue_view_modal" visible={isOpenModal} width={574} handleCancel={onCloseModal} isCenter>
      <div className="modal_header">
        <div className="title_wrapper">
          <h3
            dangerouslySetInnerHTML={{
              __html: categoryInfo.name,
            }}
          />
          <p className="sku">{`SKU ${categoryInfo.productCode}`}</p>
        </div>
        <div className="info_wrapper">
          <p className="last_import_time">
            {`Última importación realizada el ${categoryInfo.last_import_date || ''} @ ${
              categoryInfo.last_import_time || ''
            }`}
          </p>
          <div className="label_wrapper">
            {categoryInfo.isExtraPromo && <div className="promo">{intl.formatMessage({ id: 'Promo' })}</div>}
            {categoryInfo.isHidden && <div className="hidden">{intl.formatMessage({ id: 'Hidden' })}</div>}
          </div>
        </div>
      </div>
      <div className="modal_content">
        <div className="food_photo">
          <Img
            src={
              categoryInfo.productCode !== undefined
                ? `${process.env.REACT_APP_S3}/${categoryInfo.channel === 'APP' ? 'customer' : 'ots'}/${
                    categoryInfo.productCode > 0 ? categoryInfo.productCode : getProduct(categoryInfo).productCode
                  }.jpg`
                : placeHolderImg
            }
            alt="food"
          />
        </div>
        <div className="detail">
          <p className="description">{categoryInfo.description || ''}</p>
          <p className="base_price">{`${getCurrency().symbol}${productPrice}`}</p>
        </div>
      </div>
      <div className="modal_footer">
        <div className="aditional_detail">
          <p className="category_name">{categoryName}</p>
          <p className="schedule_time">
            {`Disponible de ${categoryInfo.startTime || '-'} a ${categoryInfo.endTime || '-'} / Orden: ${
              categoryInfo.orders
            }`}
          </p>
        </div>
        <div className="btn_wrapper">
          <Button className="file_download_btn">
            <img src={downloadImg} alt="file download" />
          </Button>
          <Button className="favorite_btn">
            <img src={favoriteImg} alt="favorite" />
          </Button>
          <Button className="close_btn" onClick={onCloseModal}>
            <FormattedMessage id="Close" />
            <ArrowForward />
          </Button>
        </div>
      </div>
    </ModalDlg>
  );
}

CatalogueViewModal.defaultProps = {
  isOpenModal: false,
  categoryInfo: null,
  categoryName: '',
  productPrice: 0,
  prices: [],
  sizes: [],
};

export default CatalogueViewModal;
