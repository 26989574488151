import { AccessTime, ArrowForward } from '@mui/icons-material';
import { Button, Input } from 'antd';
import drag from 'assets/img/drag.svg';
import toast from 'components/Basic/Toast';
import {
  useCreateConfigurationMutation,
  useLazyGetConfigurationQuery,
  useLazyGetDealsPreviewQuery,
  useUpdateConfigurationMutation,
} from 'core/deals/DealsService';
import { setConfiguration, setDealsPreview } from 'core/deals/DealsSlice';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'utilities/redux';

const Configuration: React.FC = () => {
  const intl = useIntl();

  const [state, setState] = useState<any>({
    isNewConfiguration: false,
    deals: [],
    ordering: '',
    codePrefix: '',
  });

  const { dealsPreview, configuration } = useAppSelector(state => state.dealsReducer);

  const dispatch = useAppDispatch();

  const [getDealsPreview] = useLazyGetDealsPreviewQuery();
  const [getConfiguration] = useLazyGetConfigurationQuery();
  const [createConfiguration] = useCreateConfigurationMutation();
  const [updateConfiguration] = useUpdateConfigurationMutation();

  const rowHeight = 177;
  const dropZoneHeight = rowHeight * Math.ceil(state.deals.length / 2);

  const DEALS_CONFIGURATION_NOT_CREATED = 2023;

  const radiosButtons = [
    {
      sid: 'weight',
      text: intl.formatMessage({ id: 'Base Weight' }),
      value: 'weight',
    },
    {
      sid: 'date',
      text: intl.formatMessage({ id: 'Creation Date' }),
      value: 'date',
    },
  ];

  const configureGetDealsPreviewArgs = (ordering: string) => {
    if (ordering === 'weight') {
      // this query must be return after backend fix
      // return `sort[weight]=desc&sort[date]=desc&filter[status]=true`;
      return 'sort[weight]=desc&filter[status]=true';
    } else {
      return `sort[date]=desc&filter[status]=true`;
    }
  };

  const getData = async () => {
    try {
      const configuration = await getConfiguration({}).unwrap();

      const getDealsPreviewArgs = configureGetDealsPreviewArgs(configuration.ordering);

      const dealsPreview = await getDealsPreview({ query: getDealsPreviewArgs }).unwrap();

      dispatch(setDealsPreview(dealsPreview));
      dispatch(setConfiguration(configuration));
      setState({
        deals: dealsPreview.deals,
        ordering: configuration.ordering,
        codePrefix: configuration.codePrefix,
      });
    } catch (e: any) {
      if (e?.data?.code === DEALS_CONFIGURATION_NOT_CREATED) {
        setState({ ...state, isNewConfiguration: true });
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onSwap = (sourceId: any, sourceIndex: any, targetIndex: any) => {
    let nextDeals = swap(state.deals, sourceIndex, targetIndex) as any;

    if (sourceIndex !== targetIndex && !!nextDeals[targetIndex]) {
      const currentDeal = nextDeals[targetIndex];

      if (sourceIndex > targetIndex) {
        for (let i = targetIndex; i < sourceIndex; i++) {
          nextDeals[i] = { ...nextDeals[i], baseWeight: nextDeals[i + 1].baseWeight };
        }
      } else {
        for (let i = targetIndex; i > sourceIndex; i--) {
          nextDeals[i] = { ...nextDeals[i], baseWeight: nextDeals[i - 1].baseWeight };
        }
      }

      nextDeals[sourceIndex] = { ...nextDeals[sourceIndex], baseWeight: currentDeal.baseWeight };
    }

    setState({ ...state, deals: nextDeals });
  };

  const onChange = (event: any) => {
    const inputValue = event.target.value.replace(/[^A-Za-z]/g, '').toUpperCase();
    setState({ ...state, codePrefix: inputValue });
  };

  const onSelect = async (event: any) => {
    const ordering = event.target.value;

    const getDealsPreviewArgs = configureGetDealsPreviewArgs(ordering);

    const dealsPreview = await getDealsPreview({ query: getDealsPreviewArgs }).unwrap();

    dispatch(setDealsPreview(dealsPreview));

    setState({ ...state, ordering, deals: dealsPreview.deals });
  };

  const onSave = async () => {
    if (state.codePrefix.trim().length !== 1) {
      toast.error({ title: intl.formatMessage({ id: 'Code prefix is required' }) });
      return;
    }
    try {
      const body = {
        ordering: state.ordering,
        codePrefix: state.codePrefix.trim(),
        dealsWeight: !state.isNewConfiguration
          ? state.deals.map((deal: any) => ({ id: deal.id, baseWeight: deal.baseWeight }))
          : undefined,
      };

      let configuration;
      if (state.isNewConfiguration) {
        configuration = await createConfiguration(body).unwrap();
        setState({ ...state, isNewConfiguration: false });
      } else {
        configuration = await updateConfiguration(body).unwrap();
      }

      if (configuration) {
        dispatch(setConfiguration(configuration));

        toast.success({ title: intl.formatMessage({ id: 'Configuration is updated successfully' }) });
      }
    } catch (error) {
      toast.error({ title: intl.formatMessage({ id: 'Updating configuration is failure' }) });
    }
  };

  const onCancel = async () => {
    const getDealsPreviewArgs = configureGetDealsPreviewArgs(configuration.ordering);

    const dealsPreview = await getDealsPreview({ query: getDealsPreviewArgs }).unwrap();

    dispatch(setDealsPreview(dealsPreview));

    setState({
      deals: dealsPreview.deals,
      ordering: configuration.ordering,
      codePrefix: configuration.codePrefix,
    });
  };

  const OfferCatalogItem = ({ deal }: any) => {
    const { schedule } = deal;
    const { dailyStartTime, dailyEndTime } = schedule;

    return (
      <div className="configuration_catalog_preview">
        <img src={deal?.images[0]?.image} alt={deal?.images[0]?.image} width="80px" height="62px" />
        <div className="body">
          <p className="title">{deal.title}</p>
          <div className="time">
            <AccessTime className="time_icon" fontSize={'inherit'} />
            <p className="time_value">
              {dayjs(dailyStartTime).utc().format('HH:mm')} - {dayjs(dailyEndTime).utc().format('HH:mm')}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="configuration_wrapper">
        <div className="configuration_column">
          <p className="column_title">
            <FormattedMessage id={'Offers'} />
          </p>
          <div className="grid_context_wrapper">
            <GridContextProvider onChange={onSwap}>
              <GridDropZone id="deals" boxesPerRow={2} rowHeight={rowHeight} style={{ height: dropZoneHeight }}>
                {state.deals.length !== 0 &&
                  state?.deals?.map((deal: any) => (
                    <GridItem key={deal?.id}>
                      <div className="card">
                        <div className="title">
                          <p className="weight">w: {deal.baseWeight}</p>
                          <img src={drag} alt="Drag Icon" height={14} width={14} />
                        </div>
                        <div className="deal">
                          <OfferCatalogItem deal={deal} />
                        </div>
                      </div>
                    </GridItem>
                  ))}
              </GridDropZone>
            </GridContextProvider>
          </div>
        </div>
        <div className="configuration_column">
          <p className="column_title">
            <FormattedMessage id={'Order By'} />
          </p>
          <div className="order_by_radio_group">
            {radiosButtons.map((radio, index) => (
              <div className="radio_group" key={index}>
                <input
                  name="order_by"
                  id={`${index}`}
                  type="radio"
                  className="radio"
                  value={radio.value}
                  checked={state.ordering === radio.value}
                  onChange={onSelect}
                />
                <label className="value" htmlFor={`${index}`}>
                  {radio.text}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="configuration_column">
          <p className="column_title">
            <FormattedMessage id={'Code Prefix'} />
          </p>
          <Input className="order_prefix_input" value={state.codePrefix} onChange={onChange} maxLength={1} />
        </div>
      </div>
      <div className="buttons_wrapper">
        <Button className="save" disabled={false} onClick={onSave}>
          <FormattedMessage id={'Save'} />
          <ArrowForward className="icon" />
        </Button>
        <Button className="cancel" disabled={false} onClick={onCancel}>
          <FormattedMessage id={'Cancel'} />
        </Button>
      </div>
    </>
  );
};

export default Configuration;
