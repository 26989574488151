import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, OffersListData, OffersFullData } from './types';

const initialState: initState = {
  list: {
    deals: [],
    totalCount: 0,
  },
  offersFullData: {
    id: 0,
    name: '',
    title: '',
    description: '',
    terms: '',
    tabs: [],
    segment: {},
    campaign: {},
    category: {},
    schedule: {},
    redemptionConfiguration: {},
    images: [],
    extendedData: {},
    contentTags: [],
    baseWeight: 0,
    locationType: '',
    location: [],
    isHidden: false,
    isSecret: false,
    isShortUrl: false,
    isActive: false,
    qrCode: '',
    hiddenCode: '',
    createdAt: '',
    updatedAt: '',
  },
};

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    setOffertListData(state: initState, action: PayloadAction<{ list: OffersListData }>) {
      state.list = action.payload.list;
    },
    setOffersFullData(state: initState, action: PayloadAction<{ offersFullData: OffersFullData | null }>) {
      state.offersFullData = action.payload.offersFullData;
    },
    setDefaultFullData(state: initState) {
      state.offersFullData = state.offersFullData;
    },
    setDeleteOffer(state: initState, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      const updatedOffers = state.list.deals.filter(offer => offer.id !== id);

      state.list = {
        ...state.list,
        deals: updatedOffers,
      };
    },
  },
});

export const { setOffertListData, setOffersFullData, setDefaultFullData, setDeleteOffer } = offersSlice.actions;

export default offersSlice.reducer;
