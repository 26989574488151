import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OverviewData, initState, SalesData, TopSalesData } from './types';

const initialState: initState = {
  ambassadorLocations: [],
  overviewData: {
    onlineCustomers: 0,
    processingOrders: 0,
    scheduledOrders: 0,
    todayOrders: 0,
  },
  sales: [
    {
      data: '',
      orders: '',
    },
  ],
  topSales: [
    {
      item: 0,
      total: 0,
    },
  ],
};

const trackingSlice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {
    setOverviewData(state: initState, action: PayloadAction<{ overviewData: OverviewData }>) {
      state.overviewData = action.payload.overviewData;
    },
    setSalesData(state: initState, action: PayloadAction<{ sales: SalesData }>) {
      state.sales = action.payload.sales;
    },
    setTopSalesData(state: initState, action: PayloadAction<{ topSales: TopSalesData }>) {
      state.topSales = action.payload.topSales;
    },
  },
});

export const { setOverviewData, setSalesData, setTopSalesData } = trackingSlice.actions;

export default trackingSlice.reducer;
