import React, { useState, useEffect } from 'react';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import ProfileStatistic from 'components/Dashboard/OtsAgents/ProfileStatistic';
import OtsMonitorFilter from 'components/Dashboard/OtsAgents/OtsMonitorFilter';
import OtsMonitorTable from 'components/Dashboard/OtsAgents/OtsMonitorTable';
import Loading from 'components/Basic/Loading';
import { useLazyGetAgentsOverviewQuery } from 'core/tracking/TrackingService';
import { useLazyGetAgentListQuery } from 'core/agent/AgentService';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { setAgentData } from 'core/agent/AgentSlice';

const OtsMonitor = () => {
  const [state, setState] = useState({
    searchText: '',
    offset: 0,
    limit: 10,
    minOrder: 0,
    maxOrder: 0,
    filterQuery: '',
    stateFilter: '',
    availableFilter: '',
    actionFilter: '',
    searchFilterQuery: '',
    sort: '',
    statisticInfo: [
      {
        quantity: 0,
        text: 'Agentes activos',
      },
      {
        quantity: 0,
        text: 'Agentes inactivos',
      },
      {
        quantity: 0,
        text: 'Agentes tomando pedido ahora',
      },
      {
        quantity: 0,
        text: 'Agentes esperando tomar un pedido',
      },
      {
        quantity: 0,
        text: 'Pedidos tomados hoy',
      },
      {
        quantity: 0,
        text: 'Pedidos fuera de tiempo (3+ minutos)',
      },
    ],
    isLoading: false,
  });

  const dispatch = useAppDispatch();

  const [getAgentList] = useLazyGetAgentListQuery();
  const [getAgentsOverview] = useLazyGetAgentsOverviewQuery();

  const { list } = useAppSelector(state => state.agentReducer);

  useEffect(async () => {
    const { statisticInfo } = state;
    setState(prev => ({ ...prev, isLoading: true }));
    try {
      const res = await getAgentsOverview({}).unwrap();
      statisticInfo[0].quantity = res.onlineAgents || 0;
      statisticInfo[1].quantity = res.offlineAgents || 0;
      statisticInfo[2].quantity = res.orderTakingAgents || 0;
      statisticInfo[3].quantity = res.orderWaitingAgents || 0;
      statisticInfo[4].quantity = res.todayOrders || 0;
      statisticInfo[5].quantity = res.todayLateOrders || 0;
      setState(prev => ({ ...prev, statisticInfo: [...statisticInfo], isLoading: false }));
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  }, []);

  const handleChangePage = async (offset, limit, filterQuery, sort) => {
    setState(prev => ({ ...prev, offset: offset, limit: limit, isLoading: true }));

    try {
      const res = await getAgentList({
        offset,
        limit,
        or: false,
        filter: filterQuery || state.filterQuery + state.searchFilterQuery,
        sort: sort || state.sort,
      }).unwrap();
      dispatch(setAgentData({ list: res }));
      setState(prev => ({ ...prev, isLoading: false }));
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const handleStateFilter = values => {
    if (values.length !== 0) {
      let filterQuery = '&filter=state in';
      values.map(v => {
        filterQuery += ` ${v}`;
        return filterQuery;
      });
      setState(prev => ({ ...prev, stateFilter: filterQuery }));
    } else {
      setState(prev => ({ ...prev, stateFilter: '' }));
    }
  };

  const handleAvailableFilter = value => {
    if (value) {
      setState(prev => ({ ...prev, availableFilter: `&filter=online eq ${value === 1}` }));
    } else {
      setState(prev => ({ ...prev, availableFilter: '' }));
    }
  };

  const handleActionFilter = value => {
    if (value) {
      setState(prev => ({
        ...prev,
        actionFilter:
          value === 1 ? `&filter=takingOrder eq true` : `&filter=takingOrder eq false&filter=online eq true`,
      }));
    } else {
      setState(prev => ({ ...prev, actionFilter: '' }));
    }
  };

  const handleMinOrderFilter = value => {
    setState(prev => ({ ...prev, minOrder: value }));
  };

  const handleMaxOrderFilter = value => {
    setState(prev => ({ ...prev, maxOrder: value }));
  };

  const handleSearch = e => {
    setState(prev => ({ ...prev, searchText: e.target.value }));
  };

  const handleKeyDownSearch = async e => {
    let searchFilterQuery = '';
    if (e.keyCode === 13) {
      const { offset, limit, filterQuery, sort, searchText } = state;
      if (searchText) {
        if (!isNaN(searchText, 10) && searchText < 100000000) {
          searchFilterQuery += `&filter=or.id eq ${searchText}`;
        }
        searchFilterQuery += `&filter=or.fullName iLike %25${searchText}%25&filter=or.eid iLike %25${searchText}%25&filter=ex.or.phone iLike %25${searchText}%25`;
      } else {
        searchFilterQuery = '';
      }

      setState(prev => ({ ...prev, searchFilterQuery: searchFilterQuery, isLoading: true }));

      try {
        const res = await getAgentList({
          offset: 0,
          limit: 10,
          or: false,
          filter: filterQuery + searchFilterQuery,
          sort: sort,
        }).unwrap();
        dispatch(setAgentData({ list: res }));
        setState(prev => ({ ...prev, isLoading: false }));
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    }
  };

  const handleApplyFilter = async () => {
    const { minOrder, maxOrder, stateFilter, availableFilter, actionFilter, sort, searchFilterQuery } = state;
    let filterOrderAmount = '';
    if (maxOrder) {
      filterOrderAmount = `&filter=todayOrders between ${minOrder} ${maxOrder}`;
    }

    setState(prev => ({
      ...prev,
      filterQuery: stateFilter + availableFilter + actionFilter + filterOrderAmount,
      isLoading: true,
    }));

    try {
      const res = await getAgentList({
        offset: 0,
        limit: 10,
        or: false,
        filter: stateFilter + availableFilter + actionFilter + filterOrderAmount + searchFilterQuery,
        sort: sort,
      }).unwrap();
      dispatch(setAgentData({ list: res }));
      setState(prev => ({ ...prev, isLoading: false }));
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const { statisticInfo } = state;
  return (
    <div className="ots_monitor_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader type="ots_agent_monitor" />
      <ProfileStatistic statisticInfo={statisticInfo} />
      <div className="ots_monitor_filter_wrapper">
        <OtsMonitorFilter
          searchText={state.searchText}
          onStateFilter={handleStateFilter}
          onAvailableFilter={handleAvailableFilter}
          onActionFilter={handleActionFilter}
          onMinOrderFilter={handleMinOrderFilter}
          onMaxOrderFilter={handleMaxOrderFilter}
          onSearch={handleSearch}
          onKeyDownSearch={handleKeyDownSearch}
          onApplyFilter={handleApplyFilter}
        />
        <OtsMonitorTable agentData={list.rows || []} onChangePage={handleChangePage} total={list.totalCount} />
      </div>
    </div>
  );
};

export default OtsMonitor;
