import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import CatalogueFilter from 'components/Dashboard/Catalogue/CatalogueFilter';
import CatalogueTable from 'components/Dashboard/Catalogue/CatalogueTable';
import CatalogueSyncModal from 'components/Dashboard/Catalogue/CatalogueSyncModal';
import SyncConfirmModal from 'components/Basic/SyncConfirmModal';
import Loading from 'components/Basic/Loading';
import { gtag } from '../../../utilities/common';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { setProductsData, setPricesData, setSizesData, setCatalogesData } from 'core/catalog/CatalogSlice';
import {
  useLazyGetItemsQuery,
  useLazyGetPricesQuery,
  useLazyGetSizesQuery,
  useLazyGetCatalogsQuery,
  useLazySyncedCatalogQuery,
  useLazySyncedStockQuery,
} from 'core/catalog/CatalogService';

const CatalogueList = () => {
  const intl = useIntl();
  const [state, setState] = useState({
    syncType: '',
    isShowSyncCatalogModal: false,
    isShowSyncStockModal: false,
    isConfirmModal: false,
    searchText: '',
    errMsg: '',
    isExistSyncResult: false,
    offset: 0,
    limit: 10,
    filterQuery: '',
    stateFilter: '',
    channelFilter: '',
    categoryFilter: '',
    searchFilterQuery: '',
    sort: '',
    syncedData: {},
    isLoading: false,
  });

  const dispatch = useAppDispatch();

  const { products, cataloges, prices, sizes } = useAppSelector(state => state.catalogReducer);
  const { admin } = useAppSelector(state => state.loginReducer);

  const [getCatalogs] = useLazyGetCatalogsQuery();
  const [getItems] = useLazyGetItemsQuery();
  const [getPrices] = useLazyGetPricesQuery();
  const [getSizes] = useLazyGetSizesQuery();
  const [syncCatalogRequest] = useLazySyncedCatalogQuery();
  const [syncStockRequest] = useLazySyncedStockQuery();

  useEffect(async () => {
    try {
      const cataloges = await getCatalogs({}).unwrap();
      dispatch(setCatalogesData({ cataloges: cataloges.rows }));
      const prices = await getPrices({}).unwrap();
      dispatch(setPricesData({ prices: prices.rows }));
      const sizes = await getSizes({}).unwrap();
      dispatch(setSizesData({ sizes: sizes.rows }));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleChangePage = async (offset, limit, filterQuery, sort) => {
    setState(prev => ({ ...prev, offset: offset, limit: limit, isLoading: true }));
    try {
      const res = await getItems({
        offset,
        limit,
        or: false,
        filter: filterQuery || state.filterQuery + state.searchFilterQuery,
        sort: sort || state.sort,
      }).unwrap();
      dispatch(setProductsData({ products: res }));
      setState(prev => ({ ...prev, isLoading: false }));
    } catch (err) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const handleStateFilter = values => {
    if (values.length !== 0) {
      let filterQuery = '&filter=state in';
      values.map(v => {
        filterQuery += ` ${v}`;
        return filterQuery;
      });
      setState(prev => ({ ...prev, stateFilter: filterQuery }));
    } else {
      setState(prev => ({ ...prev, stateFilter: '' }));
    }
  };

  const handleChannelFilter = value => {
    if (value !== 'ALL') {
      setState(prev => ({ ...prev, channelFilter: `&filter=channel eq ${value}` }));
    } else {
      setState(prev => ({ ...prev, channelFilter: '' }));
    }
  };

  const handleCategoryFilter = value => {
    if (value) {
      setState(prev => ({ ...prev, categoryFilter: `&filter=classCode eq ${value}` }));
    } else {
      setState(prev => ({ ...prev, categoryFilter: '' }));
    }
  };

  const handleSort = value => {
    setState(prev => ({ ...prev, sort: value }));
  };

  const handleSearch = e => {
    setState(prev => ({ ...prev, searchText: e.target.value }));
  };

  const handleKeyDownSearch = async e => {
    let searchFilterQuery = '';
    if (e.keyCode === 13) {
      const { offset, limit, filterQuery, sort, searchText } = state;
      if (searchText) {
        if (!isNaN(searchText, 10)) {
          searchFilterQuery += `&filter=or.id eq ${searchText}`;
        }
        if (!isNaN(searchText, 10)) {
          searchFilterQuery += `&filter=or.productCode eq ${searchText}`;
        }
        searchFilterQuery += `&filter=or.name iLike %25${searchText}%25&filter=or.description iLike %25${searchText}%25`;
      } else {
        searchFilterQuery = '';
      }
      setState(prev => ({ ...prev, searchFilterQuery: searchFilterQuery, offset: 0, limit: 10, isLoading: true }));
      try {
        const res = await getItems({
          offset: 0,
          limit: 10,
          or: false,
          filter: filterQuery + searchFilterQuery,
          sort: sort,
        }).unwrap();
        dispatch(setProductsData({ products: res }));
        setState(prev => ({ ...prev, isLoading: false }));
      } catch (err) {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    }
  };

  const handleApplyFilter = async () => {
    const { stateFilter, channelFilter, categoryFilter, sort, searchFilterQuery } = state;
    setState(prev => ({
      ...prev,
      filterQuery: stateFilter + channelFilter + categoryFilter,
      offset: 0,
      limit: 10,
      isLoading: true,
    }));
    try {
      const res = await getItems({
        offset: 0,
        limit: 10,
        or: false,
        filter: stateFilter + channelFilter + searchFilterQuery + categoryFilter,
        sort: sort,
      }).unwrap();
      dispatch(setProductsData({ products: res }));
      setState(prev => ({ ...prev, isLoading: false }));
    } catch (err) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const handleSyncCatalog = () => {
    setState(prev => ({ ...prev, syncType: 'catalog', isConfirmModal: true }));
  };

  const handleSyncAvailability = () => {
    setState(prev => ({ ...prev, syncType: 'stock', isConfirmModal: true }));
  };

  const syncCatalog = async () => {
    setState(prev => ({
      ...prev,
      errMsg: '',
      isExistSyncResult: false,
      isConfirmModal: false,
      isShowSyncCatalogModal: true,
    }));
    try {
      const res = await syncCatalogRequest({}).unwrap();
      setState(prev => ({ ...prev, isExistSyncResult: true, syncedData: res }));
    } catch (err) {
      setState(prev => ({ ...prev, errMsg: err.msg, isExistSyncResult: true }));
    }
  };

  const syncStock = async () => {
    setState(prev => ({
      ...prev,
      errMsg: '',
      isExistSyncResult: false,
      isConfirmModal: false,
      isShowSyncStockModal: true,
    }));
    try {
      const res = await syncStockRequest({}).unwrap();
      setState(prev => ({ ...prev, isExistSyncResult: true, syncedData: res }));
    } catch (err) {
      setState(prev => ({ ...prev, errMsg: err.msg, isExistSyncResult: true }));
    }
  };

  const handleConfirm = () => {
    const { syncType } = state;
    gtag('event', 'synchronize', {
      fecha: new Date().toLocaleDateString(),
      Usuario: admin.email,
    });
    if (syncType === 'catalog') {
      syncCatalog();
    } else {
      syncStock();
    }
  };

  const { syncType, isExistSyncResult, errMsg, syncedData } = state;
  return (
    <div className="catalogue_list_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader type="catalogue" onSyncCatalog={handleSyncCatalog} onSyncAvailability={handleSyncAvailability} />
      <div className="catalogue_filter_wrapper">
        <CatalogueFilter
          cataloges={cataloges}
          searchText={state.searchText}
          onStateFilter={handleStateFilter}
          onChannelFilter={handleChannelFilter}
          onCategoryFilter={handleCategoryFilter}
          onSort={handleSort}
          onSearch={handleSearch}
          onKeyDownSearch={handleKeyDownSearch}
          onApplyFilter={handleApplyFilter}
        />
        <CatalogueTable
          cataloges={cataloges}
          sizes={sizes}
          prices={prices}
          productsData={products.rows || []}
          onChangePage={handleChangePage}
          total={products.totalCount}
        />
      </div>
      <CatalogueSyncModal
        isOpenModal={state.isShowSyncCatalogModal}
        title={
          errMsg ||
          `${
            isExistSyncResult
              ? intl.formatMessage({
                  id: 'Catalog Synchronization Successful',
                })
              : `${intl.formatMessage({ id: 'Syncing' })}...`
          }`
        }
        description={
          errMsg
            ? ''
            : `${
                isExistSyncResult
                  ? `${intl.formatMessage({
                      id: 'Total synchronized products',
                    })}: ${syncedData.item ? syncedData.item.success : 0}.`
                  : ''
              }`
        }
        buttonText="Cerrar"
        onCloseModal={() => setState(prev => ({ ...prev, isShowSyncCatalogModal: false }))}
      />
      <CatalogueSyncModal
        isOpenModal={state.isShowSyncStockModal}
        title={
          errMsg ||
          `${
            isExistSyncResult
              ? intl.formatMessage({
                  id: 'Stock Synchronization Successful',
                })
              : `${intl.formatMessage({ id: 'Syncing' })}...`
          }`
        }
        description={
          errMsg
            ? ''
            : `${
                isExistSyncResult
                  ? `${intl.formatMessage({
                      id: 'Total synchronized stocks',
                    })}: ${syncedData.success ? syncedData.success : 0}.`
                  : ''
              }`
        }
        buttonText="Cerrar"
        onCloseModal={() => setState(prev => ({ ...prev, isShowSyncStockModal: false }))}
      />
      <SyncConfirmModal
        isOpenModal={state.isConfirmModal}
        onCancel={() => {
          setState(prev => ({ ...prev, isConfirmModal: false }));
        }}
        onOk={handleConfirm}
      >
        <p className="title">
          {syncType === 'catalog' ? (
            <FormattedMessage id="Do you want to synchronize the product catalog?" />
          ) : (
            <FormattedMessage id="Do you want to synchronize stock?" />
          )}
        </p>
        <p className="description">
          <FormattedMessage id="This action will import the latest available catalog settings as well as new products added to the system." />
        </p>
      </SyncConfirmModal>
    </div>
  );
};

export default CatalogueList;
