import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input, Button, Switch } from 'antd';
import ModalDlg from 'components/Basic/ModalDlg';
import { Close, Edit, Delete } from '@material-ui/icons';
import { ArrowForward } from '@material-ui/icons';
import toast from 'components/Basic/Toast';
import Loading from 'components/Basic/Loading';
import { useAppDispatch } from 'utilities/redux';
import {
  useLazyGetCategoriesListQuery,
  useCreateCategoriesMutation,
  useUpdateCategoriesMutation,
} from 'core/categories/CategoriesService';
import { setCategoriesListData } from 'core/categories/CategoriesSlice';

type CategoryItem = {
  id: number;
  name: string;
  updatedAt: string;
  createdAt: string;
};

type CategoriesViewModalType = {
  isOpenModal: boolean;
  onCloseModal: () => void;
  categoriesInfo: CategoryItem | null;
  setPageSize: (e: number) => void;
  /* setOption, */
};

const CategoriesViewModal: React.FC<CategoriesViewModalType> = ({
  isOpenModal,
  onCloseModal,
  categoriesInfo,
  setPageSize,
  /* setOption, */
}) => {
  const [categoriesName, setCategoriesName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [getCategoriesList] = useLazyGetCategoriesListQuery();
  const [createCategories] = useCreateCategoriesMutation();
  const [updateCategories] = useUpdateCategoriesMutation();

  const handleCategoriesNameChange = (e: any) => {
    setCategoriesName(e.target.value.trimStart());
  };

  const handleSaveButtonClick = async () => {
    setIsLoading(true);
    setPageSize && setPageSize(10);

    const queryString = buildQueryString({});
    /* setOption(''); */
    if (categoriesInfo && categoriesInfo.id) {
      try {
        await updateCategories({ name: categoriesName.trim(), id: categoriesInfo.id }).unwrap();
        const categoriesList = await getCategoriesList({ queryString }).unwrap();
        dispatch(setCategoriesListData({ list: categoriesList }));

        setIsLoading(false);
        toast.success({
          title: intl.formatMessage({
            id: 'Categories is updated successfully!',
          }),
        });
      } catch (e) {
        console.log('e', e);
        setIsLoading(false);
        toast.error({
          title: intl.formatMessage({
            id: 'Updating categories is failure!',
          }),
        });
      }
    } else {
      try {
        await createCategories({ name: categoriesName.trim() }).unwrap();
        const categoriesList = await getCategoriesList({ queryString }).unwrap();
        dispatch(setCategoriesListData({ list: categoriesList }));

        setIsLoading(false);
        toast.success({
          title: intl.formatMessage({
            id: 'Categories is created successfully!',
          }),
        });
        setCategoriesName('');
      } catch (e) {
        console.log('e', e);
        setIsLoading(false);
        toast.error({
          title: intl.formatMessage({
            id: 'Creating categories is failure!',
          }),
        });
      }
    }
  };

  useEffect(() => {
    if (categoriesInfo) {
      setCategoriesName(categoriesInfo.name || '');
      // setIsActive(categoriesInfo.isActive);
    } else {
      setCategoriesName('');
    }
  }, [isOpenModal]);

  return (
    <ModalDlg className="categories_view_modal" visible={isOpenModal} width={430} handleCancel={onCloseModal} isCenter>
      <>
        <div className="modal_header">
          <div className="name_wrapper">
            <h3>
              <FormattedMessage id={categoriesInfo && categoriesInfo.id ? 'Edit category' : 'New category'} />
            </h3>
            <div className="close_modal" onClick={onCloseModal}>
              <Close />
            </div>
          </div>
        </div>
        <div className="new_categories_form_wrapper">
          <Form className="basic_info_form">
            <div className="categories_input">
              <Form.Item>
                <p className="title_deals">
                  <FormattedMessage id="Category name" />
                </p>
                <Input
                  placeholder={intl.formatMessage({ id: 'Category name' })}
                  value={categoriesName}
                  onChange={handleCategoriesNameChange}
                  maxLength={200}
                />
              </Form.Item>
            </div>
            <Form.Item>
              <div className="side_btns_cat">
                <div className="btn_wrapper">
                  {categoriesInfo && categoriesInfo.id && categoriesInfo.name !== categoriesName ? (
                    <p
                      className="cancel_btn_categories"
                      onClick={() => {
                        setCategoriesName(categoriesInfo.name);
                        // setIsActive(categoriesInfo.isActive);
                      }}
                    >
                      <FormattedMessage id="Cancel" />
                    </p>
                  ) : (
                    <></>
                  )}
                  <Button
                    className="save_sar_btn"
                    onClick={() => {
                      handleSaveButtonClick();
                      onCloseModal();
                    }}
                    disabled={!categoriesName || (categoriesInfo ? categoriesInfo?.name === categoriesName : false)}
                  >
                    <FormattedMessage id={categoriesInfo && categoriesInfo.id ? 'Save changes' : 'Save'} />
                    <ArrowForward />
                  </Button>
                  <Loading visible={isLoading} />
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
      </>
    </ModalDlg>
  );
};

CategoriesViewModal.defaultProps = {};

export default CategoriesViewModal;

const buildQueryString = ({
  search = '',
  page = 1,
  size = 10,
  count = true,
  sort_by_name = '',
  sort_by_id = 'desc',
}) => {
  const params = [`search=${encodeURIComponent(search)}`, `page=${page}`, `size=${size}`, `count=${count}`];

  if (sort_by_id) {
    params.push(`sort[id]=${sort_by_id}`);
  }

  if (sort_by_name) {
    params.push(`sort[name]=${sort_by_name}`);
  }

  return params.join('&');
};
