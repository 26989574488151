import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import OtsAgentList from 'containers/Dashboard/OtsAgents/OtsAgentList';
import OtsAgentEdit from 'containers/Dashboard/OtsAgents/OtsAgentEdit';
import OtsMonitor from 'containers/Dashboard/OtsAgents/OtsMonitor';

class OtsAgents extends PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path="/dashboard/ots" component={OtsAgentList} />
        <Route exact path="/dashboard/ots/monitor" component={OtsMonitor} />
        <Route exact path={['/dashboard/ots/new', '/dashboard/ots/:id']} component={OtsAgentEdit} />
      </Switch>
    );
  }
}

export default OtsAgents;
