import React, { useState } from 'react';
import { Button, DatePicker, Input, Select } from 'antd';
import { ArrowDropDown, DateRange } from '@material-ui/icons';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { compose } from 'react-recompose';
import toast from '../../../components/Basic/Toast';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import {
  useLazyExportPointReportQuery,
  useLazyExportActivityAccessReportQuery,
  useLazyExportActivityRegisterReportQuery,
  useLazyExportActivityOrderReportQuery,
} from 'core/report/ReportService';
import { setPointReportData, setUsersReportData } from 'core/report/ReportSlice';

const { Option } = Select;

const AnalyticalUsers = ({ intl }) => {
  const dispatch = useAppDispatch();

  const { pointReport, usersReport } = useAppSelector(state => state.reportReducer);

  const [exportActivityAccessReport] = useLazyExportActivityAccessReportQuery();
  const [exportActivityRegisterReport] = useLazyExportActivityRegisterReportQuery();
  const [exportActivityOrderReport] = useLazyExportActivityOrderReportQuery();
  const [exportPointReporter] = useLazyExportPointReportQuery();

  const typeState = [
    { value: 'exportActivityRegisterReport', label: intl.formatMessage({ id: 'Registration' }) },
    { value: 'exportActivityAccessReport', label: intl.formatMessage({ id: 'Access' }) },
    { value: 'exportActivityOrderReport', label: intl.formatMessage({ id: 'Buy' }) },
    { value: 'exportPointReporter', label: intl.formatMessage({ id: 'Redemption of points' }) },
  ];

  const exportType = {
    exportActivityRegisterReport: exportActivityRegisterReport,
    exportActivityAccessReport: exportActivityAccessReport,
    exportActivityOrderReport: exportActivityOrderReport,
    exportPointReporter: exportPointReporter,
  };

  const [reportData, setReportDate] = useState({
    exportType: typeState[0].value,
    dateStart: undefined,
    dateEnd: undefined,
  });

  const handleChangeStartDate = (date, dateString) => {
    setReportDate({ ...reportData, dateStart: dateString });
  };

  const [isCreate, setIsCreate] = useState(false);

  const handleChangeEndDate = (date, dateString) => {
    setReportDate({ ...reportData, dateEnd: dateString });
  };

  const handleExport = async () => {
    if (reportData.dateStart && reportData.dateEnd) {
      try {
        const res = await exportType[reportData.exportType]({
          fromDate: reportData.dateStart,
          toDate: reportData.dateEnd,
        }).unwrap();

        if (reportData.exportType === 'exportPointReporter') {
          dispatch(setPointReportData({ pointReport: res }));
        } else {
          dispatch(setUsersReportData({ usersReport: res }));
        }
        setIsCreate(true);
      } catch (e) {
        console.log(e);
      }
    } else {
      toast.error({ title: intl.formatMessage({ id: 'Fields date is empty' }) });
    }
  };

  const handleClipboardFilename = () => {
    navigator.clipboard.writeText(
      reportData.exportType === 'exportPointReporter' ? pointReport.filename : usersReport.filename,
    );
    toast.success({ title: intl.formatMessage({ id: 'File name has been copied' }) });
  };

  return (
    <div className={'analytical_layout_content_box_inside'}>
      <div className={'analytical_layout_content_box_inside_top'}>
        <span>
          <FormattedMessage id={'Activity report and user registration'} />
        </span>
        <p>
          <FormattedMessage id={'Exports a CSV file the history of users'} />
        </p>
      </div>
      <div className={'analytical_layout_content_box_inside_bottom'}>
        <div>
          <FormattedMessage id={'Type of activity'} />
          <Select
            className="sort_by"
            suffixIcon={<ArrowDropDown />}
            defaultValue={typeState[0].value}
            onChange={e => setReportDate({ ...reportData, exportType: e })}
            dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
          >
            {typeState.map(item => (
              <Option value={item.value} key={item.label}>
                {item.label}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <FormattedMessage id="Start date" />
          <DatePicker
            className="start_date"
            placeholder={intl.formatMessage({ id: 'Start date' })}
            onChange={handleChangeStartDate}
            suffixIcon={<DateRange />}
          />
        </div>
        <div>
          <FormattedMessage id="Finish date" />
          <DatePicker
            className="start_date"
            placeholder={intl.formatMessage({ id: 'Finish date' })}
            onChange={handleChangeEndDate}
            suffixIcon={<DateRange />}
          />
        </div>
        <Button className="export_scv_button" onClick={handleExport}>
          <FormattedMessage id={'Export to CSV'} />
        </Button>
        {isCreate ? (
          <div className={'analytical_alert_success_create'}>
            <span>
              <FormattedMessage id="Please wait when file will be format and go to Download page. Save file name :" />
              <button onClick={handleClipboardFilename}>
                {reportData.exportType === 'exportPointReporter' ? pointReport.filename : usersReport.filename}
              </button>
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

AnalyticalUsers.propsTypes = {
  intl: typeof useIntl,
};

export default compose(injectIntl)(AnalyticalUsers);
