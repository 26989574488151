import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import GoogleMapReact from 'google-map-react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Button, Checkbox, Switch } from 'antd';
import Marker from 'components/Basic/Marker';
import LocationSearchInput from 'components/Basic/LocationSearchInput';
import toast from 'components/Basic/Toast';
import { getLocations } from 'utilities/common';
import polygon from 'recharts/lib/shape/Polygon';
import SearchIcon from '@material-ui/icons/Search';

let allShapes = [];
let areas = [];
let selectedShape;
let mapRef;
let mapsRef;
let drawingManager;
let otherZones = [];

class PolygonMap extends PureComponent {
  constructor(props) {
    super(props);
    this.circle = null;

    allShapes = [];
    areas = [];

    this.state = {
      center: {
        lat: getLocations().lat,
        lng: getLocations().lng,
      },
      zoom: 11,
      isLoadedShape: false,
      isVisisbleSearch: false,
      isExistRegion: true,
      restaurantList: [],
      isShowOtherZone: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.deliveryInfo.area !== this.props.deliveryInfo.area && !this.state.isLoadedShape) {
      this.initArea();
    }
  }

  initArea = () => {
    const { deliveryInfo } = this.props;
    if (deliveryInfo.area?.areas && Array.isArray(deliveryInfo.area?.areas) && mapsRef) {
      const shapes = [];
      deliveryInfo.area.areas.forEach(polygon => {
        const coords = [];
        polygon.forEach(p => {
          coords.push({ lat: p.latitude || p.lat, lng: p.longitude || p.lng });
        });
        shapes.push(coords);
      });
      if (shapes.length !== 0) {
        this.setState({ isExistRegion: true, center: shapes[0][0] });
      } else {
        this.setState({ isExistRegion: false });
      }
      shapes.forEach(shape => {
        const polygonsObj = new mapsRef.Polygon({
          paths: shape,
          strokeColor: '#D0021B',
          strokeWeight: 2,
          fillColor: '#D0021B',
          fillOpacity: 0.07,
        });
        polygonsObj.setMap(mapRef);
        drawingManager.setDrawingMode(null);
        const newShape = polygonsObj;
        newShape.setOptions({
          clickable: true,
        });
        mapsRef.event.addListener(newShape, 'click', e => {
          if ((e.domEvent.ctrlKey || e.domEvent.metaKey) && newShape.getPath().getArray().length > 3) {
            newShape.setPath(
              newShape
                .getPath()
                .getArray()
                .filter(item => {
                  return item === e.latLng ? undefined : item;
                }),
            );
          }
          this.setSelection(newShape);
        });
        allShapes.push(newShape);
        this.getAreaPositions(allShapes);
        mapsRef.event.addListener(newShape, 'mouseup', () => {
          this.updateCurrentShape(newShape);
        });
      });
      this.setState({ isLoadedShape: true });
    }
    this.drawOtherRegions();
  };

  drawOtherRegions = () => {
    const { deliveryInfo } = this.props;
    const { isExistRegion, isShowOtherZone } = this.state;
    let coords = [];
    if (deliveryInfo.neighbours && mapsRef) {
      for (let i = 0; i < deliveryInfo.neighbours.length; i++) {
        if (
          deliveryInfo.neighbours[i].area &&
          deliveryInfo.neighbours[i].area.areas &&
          Array.isArray(deliveryInfo.neighbours[i].area.areas)
        ) {
          const shapes = [];
          deliveryInfo.neighbours[i].area.areas.forEach(polygon => {
            coords = [];
            polygon.forEach(p => {
              coords.push({
                lat: p.latitude || p.lat,
                lng: p.longitude || p.lng,
              });
            });
            shapes.push(coords);
          });

          shapes.forEach(shape => {
            const polygonsObj = new mapsRef.Polygon({
              paths: shape,
              strokeColor: '#000000',
              strokeWeight: 2,
              fillColor: '#000000',
              fillOpacity: 0.07,
            });
            polygonsObj.setMap(mapRef);
          });
        }
      }
      if (coords.length !== 0 && !isExistRegion) {
        this.setState({ center: coords[0] });
      }
    }
  };
  drawZoneOtherRestaurant({ checked: isShowOtherZone }) {
    const { deliveryInfo, deliveryAreas } = this.props;
    const { isExistRegion } = this.state;
    if (isShowOtherZone) {
      const otherDeliveryAreas = deliveryAreas.filter(item => {
        return item.id !== deliveryInfo.id && item.restaurant !== deliveryInfo.restaurant.id ? item : undefined;
      });
      if (otherDeliveryAreas) {
        for (let i = 0; i < otherDeliveryAreas.length; i++) {
          if (
            otherDeliveryAreas[i].area &&
            otherDeliveryAreas[i].area.areas &&
            Array.isArray(otherDeliveryAreas[i].area.areas)
          ) {
            const shapes = [];
            otherDeliveryAreas[i].area.areas.forEach(polygon => {
              const coords = [];
              polygon.forEach(p => {
                coords.push({
                  lat: p.latitude || p.lat,
                  lng: p.longitude || p.lng,
                });
              });
              shapes.push(coords);
            });
            shapes.forEach(shape => {
              const polygonsObj = new mapsRef.Polygon({
                paths: shape,
                strokeColor: otherDeliveryAreas[i].type === 'I' ? '#000000' : '#D0021B',
                strokeWeight: 2,
                fillColor: otherDeliveryAreas[i].type === 'I' ? '#000000' : '#D0021B',
                fillOpacity: 0.07,
              });
              polygonsObj.setMap(mapRef);
              otherZones.push(polygonsObj);
            });
          }
        }
        this.setState({ isLoadedShape: true });
      }
    } else {
      otherZones.map(item => item.setMap(null));
      otherZones = [];
    }
  }
  clearSelection = () => {
    if (selectedShape) {
      selectedShape.setEditable(false);
      selectedShape = null;
    }
  };

  deleteAllShape = () => {
    for (let i = 0; i < allShapes.length; i += 1) {
      allShapes[i].setMap(null);
    }
    allShapes = [];
  };

  setSelection = shape => {
    this.clearSelection();
    selectedShape = shape;
    shape.setEditable(true);
  };

  getShapeCoords = shape => {
    const path = shape.getPath();
    const coords = [];
    if (path) {
      for (let i = 0; i < path.length; i += 1) {
        coords.push({
          lat: path.getAt(i).lat(),
          lng: path.getAt(i).lng(),
        });
      }
    }
    return coords;
  };

  deleteSelectedShape = () => {
    if (!selectedShape) {
      toast.error({
        title: this.props.intl.formatMessage({
          id: 'There are no area selected',
        }),
      });
      return;
    }
    const index = allShapes.indexOf(selectedShape);
    allShapes.splice(index, 1);
    selectedShape.setMap(null);
    this.getAreaPositions(allShapes);
    this.props.onUpdateArea(areas);
  };

  loadJsonData = () => {
    this.state.fileElement.click();
  };

  updateCurrentShape = shape => {
    const index = allShapes.indexOf(shape);
    if (index !== -1) {
      allShapes[index] = shape;
      this.getAreaPositions(allShapes);
      this.props.onUpdateArea(areas);
    }
  };

  handleGoogleAPILoaded = ({ map, maps }) => {
    drawingManager = new maps.drawing.DrawingManager({
      drawingMode: maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: maps.ControlPosition.TOP_CENTER,
        drawingModes: [maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        editable: true,
        strokeColor: '#D0021B',
        strokeWeight: 2,
        fillColor: '#D0021B',
        fillOpacity: 0.07,
        suppressUndo: true,
      },
    });
    mapRef = map;
    mapsRef = maps;
    drawingManager.setMap(map);

    maps.event.addListener(drawingManager, 'overlaycomplete', event => {
      drawingManager.setDrawingMode(null);
      const newShape = event.overlay;
      newShape.setOptions({
        clickable: true,
      });
      newShape.type = event.type;
      maps.event.addListener(newShape, 'click', e => {
        if ((e.domEvent.ctrlKey || e.domEvent.metaKey) && newShape.getPath().getArray().length > 3) {
          newShape.setPath(
            newShape
              .getPath()
              .getArray()
              .filter(item => {
                return item === e.latLng ? undefined : item;
              }),
          );
        }
        this.setSelection(newShape);
      });
      allShapes.push(newShape);
      this.setSelection(newShape);
      this.getAreaPositions(allShapes);
      this.props.onUpdateArea(areas);
      maps.event.addListener(event.overlay, 'mouseup', () => {
        const updatedShape = event.overlay;
        this.updateCurrentShape(updatedShape);
      });
    });
    this.initArea();
  };

  getAreaPositions = shapes => {
    areas = [];
    shapes.forEach(shape => {
      const coords = this.getShapeCoords(shape);
      areas.push(coords);
    });
  };

  handleFileChange = event => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (() => {
        return e => {
          try {
            areas = [];
            this.deleteAllShape();
            const json = JSON.parse(e.target.result);
            const polygonArr = [];
            for (let i = 0; i < json.features[0].geometry.coordinates[0].length; i += 1) {
              polygonArr.push({
                lng: json.features[0].geometry.coordinates[0][i][0],
                lat: json.features[0].geometry.coordinates[0][i][1],
              });
            }
            areas.push(polygonArr);
            this.props.onUpdateArea(areas);
            this.initArea();
          } catch (ex) {
            toast.error({
              title: this.props.intl.formatMessage({
                id: 'The file type is not in json format',
              }),
            });
          }
        };
      })(file);
      reader.readAsText(file);
    }
  };

  showSearchBox = () => {
    const { isVisisbleSearch } = this.state;
    this.setState({ isVisisbleSearch: !isVisisbleSearch });
  };

  handleGeoInfo = (address, latLng) => {
    this.setState({
      center: {
        lat: latLng.lat,
        lng: latLng.lng,
      },
    });
  };

  isMacOS = () => {
    return window.navigator.userAgent.indexOf('Mac') !== -1;
  };

  render() {
    const { center, isVisisbleSearch } = this.state;
    const { restaurantInfo } = this.props;
    return (
      <div className="polygon_map_layout">
        <div className={'polygon_map_layout_upper'}>
          <div>
            <button className="load_btn" type="button" onClick={this.loadJsonData}>
              <FormattedMessage id="Load JSON file" />
            </button>
            <button className="delete_btn" type="button" onClick={this.deleteSelectedShape}>
              <FormattedMessage id="Delete selected area" />
            </button>
            <input
              type="file"
              className="file_input"
              accept=".json, .geojson"
              ref={input => this.setState({ fileElement: input })}
              onChange={this.handleFileChange}
            />
          </div>
          <div>
            <Checkbox className="show_other_zones" onChange={change => this.drawZoneOtherRestaurant(change.target)}>
              Show delivery zone other restaurants
            </Checkbox>
            <span className="delete_polygon">
              {`Delete polygon ctrl\u00A0+\u00A0left click${
                this.isMacOS() ? '\nor\u00A0⌘\u00A0+\u00A0left click' : ''
              }`}
            </span>
          </div>
        </div>

        <div className="google_map_wrapper">
          {isVisisbleSearch && <LocationSearchInput onGetGeoInfo={this.handleGeoInfo} />}
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAP_API,
              libraries: ['drawing'].join(','),
            }}
            yesIWantToUseGoogleMapApiInternals
            center={center}
            defaultZoom={20}
            zoom={this.state.zoom}
            onGoogleApiLoaded={this.handleGoogleAPILoaded}
            draggable
          >
            <Marker lat={restaurantInfo.lat || getLocations().lat} lng={restaurantInfo.long || getLocations().lng} />
          </GoogleMapReact>
          <Button className="search_btn" onClick={this.showSearchBox}>
            <SearchIcon />
          </Button>
        </div>
      </div>
    );
  }
}

export default compose(injectIntl)(PolygonMap);
