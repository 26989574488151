import { string } from 'prop-types';
import { api } from '..';
import { OverviewData, SalesData, TopSalesData, AmbassadorLocationsData } from './types';

export const overviewApi = api.enhanceEndpoints({ addTagTypes: ['Overview'] }).injectEndpoints({
  endpoints: build => ({
    getOverview: build.query<OverviewData, any>({
      query: body => ({
        url: `v0/tracking/overview`,
        method: 'GET',
      }),
    }),
    getSales: build.query<SalesData, { from: string; to: string }>({
      query: ({ from, to }) => ({
        url: `v0/tracking/sales/${from}/${to}`,
        method: 'GET',
      }),
    }),
    getTops: build.query<TopSalesData, { from: string; to: string; limit: number }>({
      query: ({ from, to, limit }) => ({
        url: `v0/tracking/top/${from}/${to}/${limit}`,
        method: 'GET',
      }),
    }),
    getAmbassadorLocation: build.query<
      AmbassadorLocationsData,
      { offset: number; limit: number; or: boolean; filter: string; sort: string }
    >({
      query: ({ offset, limit, or, filter, sort }) => ({
        url: `v0/tracking/ambassadorLocations?${offset !== undefined ? `offset=${offset || 0}&` : ''}${
          limit ? `&limit=${limit || 5}` : ''
        }${or ? `&or=${or}` : ''}${filter || ''}${sort ? `&sort=${sort}` : '&sort=createdAt desc'}`,
        method: 'GET',
      }),
    }),
    getAgentsOverview: build.query<any, any>({
      query: () => ({
        url: `v0/tracking/overview/agents`,
        method: 'GET',
      }),
    }),
    getAmbassadorsOverview: build.query<
      any,
      { sector: string; restaurant: string; ambassadorType: string; ambassadorState: string }
    >({
      query: ({ sector, restaurant, ambassadorType, ambassadorState }) => ({
        url: `v0/tracking/overview/ambassadors?${sector ? `sector=${sector}` : ''}${
          restaurant ? `&restaurant=${restaurant}` : ''
        }${ambassadorType ? `&ambassadorType=${ambassadorType}` : ''}${
          ambassadorState ? `&ambassadorState=${ambassadorState}` : ''
        }`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyGetOverviewQuery,
  useLazyGetSalesQuery,
  useLazyGetTopsQuery,
  useLazyGetAgentsOverviewQuery,
  useLazyGetAmbassadorsOverviewQuery,
} = overviewApi;
