import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Input, Button, Select } from 'antd';
import { Refresh, Search, ArrowDropDown } from '@material-ui/icons';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyGetRestaurantListQuery } from 'core/restaurant/RestaurantService';
import { setRestaurantList } from 'core/restaurant/RestaurantSlice';

const { Option } = Select;

function DistributionFilter({
  intl,
  searchText,
  onStateFilter,
  onRestaurantFilter,
  onSort,
  onSearch,
  onKeyDownSearch,
  onApplyFilter,
}) {
  const sectorStates = [
    { value: 'H', label: intl.formatMessage({ id: 'High demand' }) },
    { value: 'M', label: intl.formatMessage({ id: 'Average demand' }) },
    { value: 'L', label: intl.formatMessage({ id: 'Low demand' }) },
  ];
  const sectorSorts = [
    {
      value: 'demandOrders desc',
      label: intl.formatMessage({ id: 'high to less demand' }),
    },
    {
      value: 'demandOrders asc',
      label: intl.formatMessage({ id: 'less to high demand' }),
    },
    {
      value: 'nowOrders desc',
      label: intl.formatMessage({ id: 'high to less orders amount' }),
    },
    {
      value: 'nowOrders asc',
      label: intl.formatMessage({ id: 'less to high orders amount' }),
    },
  ];

  const dispatch = useAppDispatch();

  const { list: restaurantList } = useAppSelector(state => state.restaurantReducer);

  const [getRestaurantList] = useLazyGetRestaurantListQuery();

  useEffect(async () => {
    try {
      const res = await getRestaurantList({}).unwrap();
      dispatch(setRestaurantList({ list: res }));
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleStateFilter = values => {
    onStateFilter(values);
  };

  const handleRestaurantFilter = values => {
    onRestaurantFilter(values);
  };

  const handleSort = value => {
    onSort(value);
  };

  const handleSearch = e => {
    onSearch(e);
  };

  return (
    <div className="distribution_filter_layout">
      <Select
        className="active_states"
        suffixIcon={<ArrowDropDown />}
        mode="multiple"
        placeholder="estados activos"
        onChange={handleStateFilter}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {sectorStates.map(item => (
          <Option value={item.value} key={item.label}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Select
        className="all_restaurants"
        suffixIcon={<ArrowDropDown />}
        mode="multiple"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={v => handleRestaurantFilter(v)}
        placeholder="Todos los restaurantes"
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {restaurantList.rows &&
          restaurantList.rows.map(s => (
            <Option value={s.id} key={s.id}>
              {s.description || ''}
            </Option>
          ))}
      </Select>
      <Select
        className="sort_by"
        suffixIcon={<ArrowDropDown />}
        placeholder="Ordernar por"
        onChange={handleSort}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {sectorSorts.map(item => (
          <Option value={item.value} key={item.label}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Button className="apply_filters_btn" onClick={onApplyFilter}>
        <FormattedMessage id="Apply filters" />
        <Refresh />
      </Button>
      <Input
        suffix={<Search />}
        placeholder="Buscar sector"
        value={searchText}
        onChange={e => handleSearch(e)}
        onKeyDown={e => onKeyDownSearch(e)}
      />
    </div>
  );
}

DistributionFilter.defaultProps = {
  restaurantList: {},
};

export default compose(injectIntl)(DistributionFilter);
