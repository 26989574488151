import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Input, Button, Select } from 'antd';
import { Refresh, ArrowDropDown, Search } from '@material-ui/icons';

const { Option } = Select;

function PromotionFilter({
  intl,
  searchText,
  onStateFilter,
  onTypeFilter,
  onSort,
  onSearch,
  onKeyDownSearch,
  onApplyFilter,
}) {
  const promotionStates = [
    { value: 'A', label: intl.formatMessage({ id: 'Active' }) },
    { value: 'I', label: intl.formatMessage({ id: 'Inactive' }) },
  ];
  const promotionTypes = [
    { value: 'ALL', label: intl.formatMessage({ id: 'All types' }) },
    { value: 'P', label: intl.formatMessage({ id: 'Production' }) },
    { value: 'U', label: intl.formatMessage({ id: 'URL' }) },
    { value: 'O', label: intl.formatMessage({ id: 'Offers' }) },
  ];
  const promotionSorts = [
    { value: 'state desc', label: intl.formatMessage({ id: 'By state' }) },
    { value: 'type desc', label: intl.formatMessage({ id: 'By type' }) },
    {
      value: 'createdAt desc',
      label: intl.formatMessage({ id: 'By creation date' }),
    },
  ];

  const handleStateFilter = values => {
    onStateFilter(values);
  };

  const handleTypeFilter = value => {
    onTypeFilter(value);
  };

  const handleSort = value => {
    onSort(value);
  };

  const handleSearch = e => {
    onSearch(e);
  };

  return (
    <div className="promotion_filter_layout">
      <Select
        className="active_states"
        suffixIcon={<ArrowDropDown />}
        mode="multiple"
        placeholder="estados activos"
        onChange={handleStateFilter}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {promotionStates.map(item => (
          <Option value={item.value} key={item.label}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Select
        className="sort_by"
        defaultValue="ALL"
        suffixIcon={<ArrowDropDown />}
        placeholder="Todos los tipos"
        onChange={handleTypeFilter}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {promotionTypes.map(item => (
          <Option value={item.value} key={item.label}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Select
        className="sort_by"
        suffixIcon={<ArrowDropDown />}
        placeholder="Ordernar por"
        onChange={handleSort}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {promotionSorts.map(item => (
          <Option value={item.value} key={item.label}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Button className="apply_filters_btn" onClick={onApplyFilter}>
        <FormattedMessage id="Apply filters" />
        <Refresh />
      </Button>
      <Input
        suffix={<Search />}
        placeholder="Buscar promoción"
        value={searchText}
        onChange={e => handleSearch(e)}
        onKeyDown={e => onKeyDownSearch(e)}
      />
    </div>
  );
}

export default compose(injectIntl)(PromotionFilter);
