import React from 'react';
import PropTypes from 'prop-types';
import { Button, Progress } from 'antd';
import ModalDlg from 'components/Basic/ModalDlg';
import { ArrowForward } from '@material-ui/icons';

function SyncModal({ title, description, buttonText, isOpenModal, onCloseModal, percent, isSync, isSyncError }) {
  return (
    <ModalDlg
      className={isSyncError ? 'ambassador_sync_modal error_modal' : 'ambassador_sync_modal'}
      visible={isOpenModal}
      width={434}
      handleCancel={onCloseModal}
      isCenter
    >
      <div className="modal_header">
        <div>
          <h3>{title}</h3>
          <p className="description">{description}</p>
        </div>
      </div>
      <div className="modal_footer">
        {!isSync && (
          <Button onClick={onCloseModal}>
            <span>{buttonText}</span>
            <ArrowForward />
          </Button>
        )}
        {isSync && !isSyncError && <Progress percent={percent} strokeColor="#000000" />}
      </div>
    </ModalDlg>
  );
}

SyncModal.defaultProps = {
  isOpenModal: false,
  title: '',
  description: '',
  buttonText: '',
  isSync: false,
  isSyncError: false,
  percent: 0,
};

export default SyncModal;
