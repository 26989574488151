import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ArrowForward, ArrowBack } from '@material-ui/icons';
import SegmentsTable from 'components/Dashboard/Segments/SegmentsTable';
import Loading from 'components/Basic/Loading';
import SegmentsViewModal from 'components/Dashboard/Segments/SegmentsViewModal';
import SegmentsFilter from 'components/Dashboard/Segments/SegmentsFilter';
import { NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyGetSegmentsListQuery } from 'core/segments/SegmentsService';
import { setSegmentsListData } from 'core/segments/SegmentsSlice';

const Segments: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isShowDetailModal, setShowDetailModal] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [current, setCurrent] = useState(1);
  const [sortName, setSortName] = useState('');
  const [sortId, setSortId] = useState('desc');
  const [pageSize, setPageSize] = useState(10);

  const dispatch = useAppDispatch();
  const [getSegmentsList] = useLazyGetSegmentsListQuery();

  const { list } = useAppSelector(state => state.segmentsReducer);

  useEffect(() => {
    getInitialData();
    setSearchText('');
  }, []);

  useEffect(() => {
    setCurrent(1);
  }, [pageSize]);

  const getInitialData = async () => {
    setIsLoading(true);
    const queryString = buildQueryString({
      search: '',
      page: 1,
      size: pageSize,
      count: true,
      filter: 'omit',
      sort_by_id: sortId,
    });

    try {
      const response = await getSegmentsList({ queryString }).unwrap();
      dispatch(setSegmentsListData(response));
    } catch (error) {
      console.error('Error fetching segments list:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = async (page: number, size: number) => {
    setIsLoading(true);
    setPageSize(size);
    const queryString = buildQueryString({
      search: searchText,
      page: page,
      size: size,
      count: true,
      sort_by_id: sortId,
      sort_by_name: sortName,
    });

    try {
      const response = await getSegmentsList({ queryString }).unwrap();
      dispatch(setSegmentsListData(response));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleSearch = async (e: any) => {
    const searchTextValue = e.target.value.trimStart();
    setSearchText(searchTextValue);
    if (e.keyCode === 13) {
      setCurrent(1);
      const queryString = buildQueryString({
        search: e.target.value,
        page: 1,
        size: pageSize,
        count: true,
        sort_by_id: sortId,
        sort_by_name: sortName,
      });

      try {
        const response = await getSegmentsList({ queryString }).unwrap();
        dispatch(setSegmentsListData(response));
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleSort = (value: string) => {
    if (value[0] === 'I') {
      setSortId(value.substring(1));
      setSortName('');
    } else if (value[0] === 'N') {
      setSortName(value.substring(1));
      setSortId('');
    }
  };

  const onApplyFilter = async () => {
    setIsLoading(true);
    setCurrent(1);
    const queryString = buildQueryString({
      search: searchText,
      page: 1,
      size: pageSize,
      count: true,
      sort_by_id: sortId,
      sort_by_name: sortName,
    });

    try {
      const respose = await getSegmentsList({ queryString }).unwrap();
      dispatch(setSegmentsListData(respose));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <div className="configure_tab_header_segments">
      <div className="header_title">
        <NavLink to="/dashboard/deals/offers">
          <div className="back_btn">
            <ArrowBack />
          </div>
        </NavLink>
        <FormattedMessage id="Configure offer segments" />
      </div>
      <div className="btn_wrapper">
        <Button className="add_btn" onClick={() => setShowDetailModal(true)}>
          <FormattedMessage id="Add new segment" />
          <ArrowForward />
        </Button>
      </div>
      <SegmentsFilter
        searchText={searchText}
        handleSearch={handleSearch}
        onSort={handleSort}
        onApplyFilter={onApplyFilter}
      />
      <SegmentsTable
        segmentsData={list.segments || []}
        onChangePage={(current, size) => handleChangePage(current, size)}
        total={list.totalCount || 0}
        setCurrent={setCurrent}
        current={current}
        setPageSize={setPageSize}
        pageSize={pageSize}
      />
      <SegmentsViewModal
        isOpenModal={isShowDetailModal}
        onCloseModal={() => setShowDetailModal(false)}
        setPageSize={setPageSize}
        segmentsInfo={null}
      />
      {isLoading && <Loading visible={isLoading} />}
    </div>
  );
};

Segments.defaultProps = {
  list: {},
};

export default Segments;

const buildQueryString = ({
  search = '',
  page = 1,
  size = 10,
  count = true,
  sort_by_id = 'desc',
  sort_by_name = '',
  filter = '',
}) => {
  const params = [
    `search=${encodeURIComponent(search)}`,
    `page=${page}`,
    `size=${size}`,
    `count=${count}`,
    `filter=${filter}`,
  ];

  if (sort_by_id) {
    params.push(`sort[id]=${sort_by_id}`);
  }

  if (sort_by_name) {
    params.push(`sort[name]=${sort_by_name}`);
  }

  return params.join('&');
};
