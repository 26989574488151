import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Input, Button, Select } from 'antd';
import { Refresh, Search, ArrowDropDown } from '@material-ui/icons';

const { Option } = Select;

function OtsMonitorFilter({
  intl,
  searchText,
  onStateFilter,
  onAvailableFilter,
  onActionFilter,
  onMinOrderFilter,
  onMaxOrderFilter,
  onSearch,
  onKeyDownSearch,
  onApplyFilter,
}) {
  const orderCounts = [];
  for (let i = 0; i < 26; i += 1) {
    orderCounts.push({ value: i, label: i });
  }
  const agentStates = [
    { value: 'A', label: intl.formatMessage({ id: 'Active' }) },
    { value: 'I', label: intl.formatMessage({ id: 'Inactive' }) },
  ];
  const agentAvailable = [
    { value: 0, label: intl.formatMessage({ id: 'All availabilities' }) },
    { value: 1, label: intl.formatMessage({ id: 'Available Agents' }) },
    { value: 2, label: intl.formatMessage({ id: 'Unavailable Agents' }) },
  ];
  const otsActions = [
    { value: 0, label: intl.formatMessage({ id: 'All' }) },
    { value: 1, label: intl.formatMessage({ id: 'Taking Order Now' }) },
    { value: 2, label: intl.formatMessage({ id: 'Waiting for order' }) },
  ];
  const handleStateFilter = values => {
    onStateFilter(values);
  };
  const handleAvailalbeFilter = value => {
    onAvailableFilter(value);
  };
  const handleActionFilter = value => {
    onActionFilter(value);
  };
  const handleMinOrderFilter = value => {
    onMinOrderFilter(value);
  };
  const handleMaxOrderFilter = value => {
    onMaxOrderFilter(value);
  };
  const handleSearch = e => {
    onSearch(e);
  };

  return (
    <div className="ots_monitor_filter_layout">
      <div className="ots_monitor_filter_header">
        <h3>
          <FormattedMessage id="Monitoring of execution and operation at this time" />
        </h3>
        <Input
          suffix={<Search />}
          placeholder="Buscar agente"
          value={searchText}
          onChange={e => handleSearch(e)}
          onKeyDown={e => onKeyDownSearch(e)}
        />
      </div>
      <div className="ots_monitor_filter_content">
        <div className="state_filter">
          <p className="title">
            <FormattedMessage id="Agent status" />
          </p>
          <Select
            className="active_states"
            suffixIcon={<ArrowDropDown />}
            mode="multiple"
            placeholder="Estado del agente"
            onChange={handleStateFilter}
            dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
          >
            {agentStates.map(item => (
              <Option value={item.value} key={item.label}>
                {item.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className="availability_filter">
          <p className="title">
            <FormattedMessage id="Current availability" />
          </p>
          <Select
            className="availability_by"
            suffixIcon={<ArrowDropDown />}
            placeholder="Todas las disponibilidades"
            onChange={handleAvailalbeFilter}
            dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
          >
            {agentAvailable.map(item => (
              <Option value={item.value} key={item.label}>
                {item.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className="current_action_filter">
          <p className="title">
            <FormattedMessage id="Current action" />
          </p>
          <Select
            className="action_by"
            suffixIcon={<ArrowDropDown />}
            placeholder="Todos"
            onChange={handleActionFilter}
            dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
          >
            {otsActions.map(item => (
              <Option value={item.value} key={item.label}>
                {item.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className="orders_number_filter">
          <p className="title">
            <FormattedMessage id="Number of orders on the day" />
          </p>
          <div className="orders_number_between">
            <p className="between">
              <FormattedMessage id="Between" />
            </p>
            <Select
              className="order_min"
              suffixIcon={<ArrowDropDown />}
              onChange={handleMinOrderFilter}
              placeholder="0"
              dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
            >
              {orderCounts.map(item => (
                <Option value={item.value} key={item.label}>
                  {item.label}
                </Option>
              ))}
            </Select>
            <p className="to">Y</p>
            <Select
              className="order_max"
              suffixIcon={<ArrowDropDown />}
              onChange={handleMaxOrderFilter}
              placeholder="25"
              dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
            >
              {orderCounts.map(item => (
                <Option value={item.value} key={item.label}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <Button className="apply_filters_btn" onClick={onApplyFilter}>
          <FormattedMessage id="Apply filters" />
          <Refresh />
        </Button>
      </div>
    </div>
  );
}

export default compose(injectIntl)(OtsMonitorFilter);
