import React, { useState, useEffect } from 'react';
import { Img } from 'react-image';
import { Container, Draggable } from '@edorivai/react-smooth-dnd';
import { Button, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { ArrowForward } from '@material-ui/icons';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import CatalogueSyncModal from 'components/Dashboard/Catalogue/CatalogueSyncModal';
import CatalogueSetupModal from 'components/Dashboard/Catalogue/CatalogueSetupModal';
import SyncConfirmModal from 'components/Basic/SyncConfirmModal';
import catalogueImg from 'assets/img/catalogue.svg';
import toast from 'components/Basic/Toast';
import Loading from 'components/Basic/Loading';
import Icon from '@ant-design/icons';
import CloseIcon from '@material-ui/icons/Close';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import {
  useLazySyncedCatalogQuery,
  useLazyGetSuperCatalogsQuery,
  useLazyGetCatalogOrdersQuery,
  useUpdateCatalogOrdersMutation,
} from 'core/catalog/CatalogService';
import { setSuperCatalogsData } from 'core/catalog/CatalogSlice';

const CatalogueManage = () => {
  const intl = useIntl();

  const [state, setState] = useState({
    isShowSyncModal: false,
    isShowSetupModal: false,
    isConfirmModal: false,
    errMsg: '',
    isExistSyncResult: false,
    syncedData: {},
    catalogueInfo: null,
    scheme1: [],
    scheme2: [],
    scheme3: [],
    scheme4: [],
    isLoading: false,
  });
  const [isHolidaySchedule, setIsHolidaySchedule] = useState();

  const dispatch = useAppDispatch();

  const [getSuperCatalogs] = useLazyGetSuperCatalogsQuery();
  const [getCatalogOrders] = useLazyGetCatalogOrdersQuery();
  const [updateCatalogOrders] = useUpdateCatalogOrdersMutation();
  const [syncCatalog] = useLazySyncedCatalogQuery();

  const { superCataloges } = useAppSelector(state => state.catalogReducer);

  useEffect(() => {
    getInitialData();
    const now = new Date();
    setIsHolidaySchedule(
      (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_COUNTRY === 'gt') &&
        ((now.getMonth() === 11 && now.getDate() === 25) /* Merry Christmas */ ||
          (now.getMonth() === 0 && now.getDate() === 1)) /* Happy New Year */,
    );
  }, []);

  const getInitialData = async () => {
    setState(prev => ({ ...prev, isLoading: true }));
    try {
      const res = await getSuperCatalogs({}).unwrap();
      dispatch(setSuperCatalogsData({ superCataloges: res.superClasses.rows }));
      await getOrdersSchemes({ superCataloges: res.superClasses.rows });
    } catch (err) {
      console.log(err);
    }
  };

  const getOrdersSchemes = async ({ superCataloges }) => {
    try {
      const res = await getCatalogOrders({}).unwrap();
      const currentOrderSchemes = res.rows.filter(s => superCataloges.filter(c => c.id === s.scid)[0].state === 'A');

      setState(prev => ({ ...prev, orderSchemes: currentOrderSchemes }));

      const scheme1 = filterOrderSchemes(currentOrderSchemes, 1) || [];
      const scheme2 = filterOrderSchemes(currentOrderSchemes, 2) || [];
      const scheme3 = filterOrderSchemes(currentOrderSchemes, 3) || [];
      const scheme4 = filterOrderSchemes(currentOrderSchemes, 4) || [];

      setState(prev => ({
        ...prev,
        scheme1: scheme1.sort((a, b) => {
          return a.ordering - b.ordering;
        }),
        scheme2: scheme2.sort((a, b) => {
          return a.ordering - b.ordering;
        }),
        scheme3: scheme3.sort((a, b) => {
          return a.ordering - b.ordering;
        }),
        scheme4: scheme4.sort((a, b) => {
          return a.ordering - b.ordering;
        }),
        isLoading: false,
      }));
    } catch (err) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const updateOrderSchemes = async data => {
    setState(prev => ({ ...prev, isLoading: true }));
    try {
      await updateCatalogOrders({ orderSchemes: data }).unwrap();
      setState(prev => ({ ...prev, isLoading: false }));
      toast.success({
        title: intl.formatMessage({
          id: 'Your changing is saved successfully!',
        }),
      });
    } catch (err) {
      setState(prev => ({ ...prev, isLoading: false }));
      toast.error({
        title: intl.formatMessage({
          id: 'Your changing is not saved!',
        }),
      });
    }
  };

  const filterOrderSchemes = (orderSchemes, stepNum = 1) => {
    let filteredOrders;
    if (stepNum === 1) {
      filteredOrders = orderSchemes.filter(
        o => o.startTime === '06:00' && o.endTime === (isHolidaySchedule ? '11:59' : '10:59'),
      );
    } else if (stepNum === 2) {
      filteredOrders = orderSchemes.filter(
        o => o.startTime === (isHolidaySchedule ? '12:00' : '11:00') && o.endTime === '14:59',
      );
    } else if (stepNum === 3) {
      filteredOrders = orderSchemes.filter(o => o.startTime === '15:00' && o.endTime === '17:59');
    } else if (stepNum === 4) {
      filteredOrders = orderSchemes.filter(o => o.startTime === '18:00' && o.endTime === '22:00');
    }
    return filteredOrders;
  };

  const handleSyncCatalog = () => {
    setState(prev => ({ ...prev, isConfirmModal: true }));
  };

  const handleConfirmSync = async () => {
    setState(prev => ({
      ...prev,
      errMsg: '',
      isExistSyncResult: false,
      isConfirmModal: false,
      isShowSyncModal: true,
    }));
    try {
      const res = await syncCatalog({}).unwrap();
      setState(prev => ({ ...prev, isExistSyncResult: true, syncedData: res }));
    } catch (err) {
      setState(prev => ({ ...prev, errMsg: err.msg, isExistSyncResult: true }));
    }
  };

  const handleSetupCatalog = catalogueInfo => {
    setState(prev => ({ ...prev, isShowSetupModal: true, catalogueInfo: catalogueInfo }));
  };

  const applyDrag = (arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;
    const result = [...arr];
    let itemToAdd = payload;
    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }
    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    return result;
  };

  const handleDrop = (dropResult, stepNum) => {
    const { scheme1, scheme2, scheme3, scheme4 } = state;
    const { removedIndex, addedIndex, payload } = dropResult;
    if (removedIndex === null) {
      if (addedIndex !== null) {
        if (stepNum === 1) {
          if (scheme1.filter(s => s.scid === payload.id).length === 0) {
            setState(prev => ({
              ...prev,
              scheme1: [
                ...scheme1,
                {
                  scid: payload.id,
                  ordering: payload.ordering,
                  startTime: isHolidaySchedule ? '10:00' : '06:00',
                  endTime: isHolidaySchedule ? '11:59' : '10:59',
                },
              ],
            }));
          }
        } else if (stepNum === 2) {
          if (scheme2.filter(s => s.scid === payload.id).length === 0) {
            setState(prev => ({
              ...prev,
              scheme2: [
                ...scheme2,
                {
                  scid: payload.id,
                  ordering: payload.ordering,
                  startTime: isHolidaySchedule ? '12:00' : '11:00',
                  endTime: '14:59',
                },
              ],
            }));
          }
        } else if (stepNum === 3) {
          if (scheme3.filter(s => s.scid === payload.id).length === 0) {
            setState(prev => ({
              ...prev,
              scheme3: [
                ...scheme3,
                {
                  scid: payload.id,
                  ordering: payload.ordering,
                  startTime: '15:00',
                  endTime: '17:59',
                },
              ],
            }));
          }
        } else if (stepNum === 4) {
          if (scheme4.filter(s => s.scid === payload.id).length === 0) {
            setState(prev => ({
              ...prev,
              scheme4: [
                ...scheme4,
                {
                  scid: payload.id,
                  ordering: payload.ordering,
                  startTime: '18:00',
                  endTime: '22:00',
                },
              ],
            }));
          }
        }
      }
    } else if (stepNum === 1) {
      if (addedIndex !== null) {
        const temp = scheme1[removedIndex];
        scheme1.splice(removedIndex, 1);
        scheme1.splice(addedIndex, 0, temp);
        setState(prev => ({ ...prev, scheme1: [...scheme1] }));
      }
    } else if (stepNum === 2) {
      if (addedIndex !== null) {
        const temp = scheme2[removedIndex];
        scheme2.splice(removedIndex, 1);
        scheme2.splice(addedIndex, 0, temp);
        setState(prev => ({ ...prev, scheme2: [...scheme2] }));
      }
    } else if (stepNum === 3) {
      if (addedIndex !== null) {
        const temp = scheme3[removedIndex];
        scheme3.splice(removedIndex, 1);
        scheme3.splice(addedIndex, 0, temp);
        setState(prev => ({ ...prev, scheme3: [...scheme3] }));
      }
    } else if (stepNum === 4) {
      if (addedIndex !== null) {
        const temp = scheme4[removedIndex];
        scheme4.splice(removedIndex, 1);
        scheme4.splice(addedIndex, 0, temp);
        setState(prev => ({ ...prev, scheme4: [...scheme4] }));
      }
    }
  };

  const handleDelete = (index, stepNum) => {
    const { scheme1, scheme2, scheme3, scheme4 } = state;
    if (stepNum === 1) {
      setState(prev => ({ ...prev, scheme1: [...scheme1.slice(0, index), ...scheme1.slice(index + 1)] }));
    } else if (stepNum === 2) {
      setState(prev => ({ ...prev, scheme2: [...scheme2.slice(0, index), ...scheme2.slice(index + 1)] }));
    } else if (stepNum === 3) {
      setState(prev => ({ ...prev, scheme3: [...scheme3.slice(0, index), ...scheme3.slice(index + 1)] }));
    } else if (stepNum === 4) {
      setState(prev => ({ ...prev, scheme4: [...scheme4.slice(0, index), ...scheme4.slice(index + 1)] }));
    }
  };

  const handleSaveOrderSchemes = () => {
    const { scheme1, scheme2, scheme3, scheme4 } = state;
    for (let i = 0; i < scheme1.length; i += 1) {
      scheme1[i] = { ...scheme1[i], ordering: i };
    }
    for (let i = 0; i < scheme2.length; i += 1) {
      scheme2[i] = { ...scheme2[i], ordering: scheme1.length + i };
    }
    for (let i = 0; i < scheme3.length; i += 1) {
      scheme3[i] = { ...scheme3[i], ordering: scheme1.length + scheme2.length + i };
    }
    for (let i = 0; i < scheme4.length; i += 1) {
      scheme4[i] = { ...scheme4[i], ordering: scheme1.length + scheme2.length + scheme3.length + i };
    }
    updateOrderSchemes([...scheme1, ...scheme2, ...scheme3, ...scheme4]);
  };

  const getChannelName = id => {
    return intl.formatMessage({
      id: `${
        superCataloges.filter(s => s.id === id).length !== 0
          ? superCataloges.filter(s => s.id === id)[0].channel
          : 'APP'
      }`,
    });
  };

  const { scheme1, scheme2, scheme3, scheme4, isExistSyncResult, errMsg, syncedData } = state;
  return (
    <div className="catalogue_manage_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader type="catalogue_manage" onSaveOrderSchemes={handleSaveOrderSchemes} />
      <div className="catalogue_scheme_description">
        <h3>
          <FormattedMessage id="Category assignment scheme" />
        </h3>
        <p className="description">
          <FormattedMessage id="Category Management Description" />
        </p>
        <Button onClick={handleSyncCatalog}>
          <FormattedMessage id="Synchronize categories" />
          <ArrowForward />
        </Button>
      </div>
      <div className="catalogue_list">
        <Container
          groupName="1"
          behaviour="copy"
          getChildPayload={i => superCataloges.filter(s => s.state === 'A')[i]}
          onDrop={e =>
            setState(prev => ({
              ...prev,
              superCataloges: applyDrag(
                superCataloges.filter(s => s.state === 'A'),
                e,
              ),
            }))
          }
        >
          {superCataloges &&
            superCataloges
              .filter(s => s.state === 'A')
              .map(category => (
                <Draggable className="item" onClick={() => handleSetupCatalog(category)} key={category.id}>
                  <div className="catalogue_name">
                    {category.customizedImage && (
                      <Img src={`${process.env.REACT_APP_SERVER}/${category.customizedImage}`} alt="catalogue" />
                    )}
                    {category.customizedSelectedImage && (
                      <Img
                        src={`${process.env.REACT_APP_SERVER}/${category.customizedSelectedImage}`}
                        alt="catalogue"
                      />
                    )}
                    <p>{category.name}</p>
                  </div>
                  <div className="channel">
                    {intl.formatMessage({
                      id: `${category.channel}`,
                    })}
                  </div>
                </Draggable>
              ))}
        </Container>
      </div>
      <h3 className="catalogue_order_time_title">
        <FormattedMessage id="Hourly category ordering scheme" />
      </h3>
      <div className="catalogue_order_time_wrapper">
        <div className="card">
          <div className="card_header">
            <p className="description">
              <FormattedMessage id="From" /> {isHolidaySchedule ? '10:00 a 11:59' : '06:00 a 10:59'}{' '}
              <FormattedMessage id="hours" />
            </p>
          </div>
          <div className="card_list">
            <Container groupName="1" lockAxis="y" getChildPayload={i => scheme1[i]} onDrop={e => handleDrop(e, 1)}>
              {scheme1.map((item, index) => (
                <Draggable className="item" key={item.scid}>
                  <div className="item_wrapper">
                    <div className="name_wrapper">
                      <Tooltip
                        placement="top"
                        title={intl.formatMessage({
                          id: 'Drag to reorder',
                        })}
                      >
                        <Img src={catalogueImg} />
                      </Tooltip>
                      <p>
                        {superCataloges.filter(s => s.id === item.scid).length !== 0
                          ? superCataloges.filter(s => s.id === item.scid)[0].name
                          : ''}
                      </p>
                    </div>
                    <div className="channel_wrapper">
                      <div className="channel">{getChannelName(item.scid)}</div>
                      <div onClick={() => handleDelete(index, 1)}>
                        <CloseIcon />
                      </div>
                    </div>
                  </div>
                </Draggable>
              ))}
            </Container>
          </div>
        </div>
        <div className="card">
          <div className="card_header">
            <p className="description">
              <FormattedMessage id="From" /> {isHolidaySchedule ? '12:00 a 14:59' : '11:00 a 14:59'}{' '}
              <FormattedMessage id="hours" />
            </p>
          </div>
          <div className="card_list">
            <Container
              groupName="1"
              lockAxis="y"
              getChildPayload={i => state.scheme2[i]}
              onDrop={e => handleDrop(e, 2)}
            >
              {scheme2.map((item, index) => (
                <Draggable className="item" key={item.scid}>
                  <div className="item_wrapper">
                    <div className="name_wrapper">
                      <Tooltip
                        placement="top"
                        title={intl.formatMessage({
                          id: 'Drag to reorder',
                        })}
                      >
                        <Img src={catalogueImg} />
                      </Tooltip>
                      <p>
                        {superCataloges.filter(s => s.id === item.scid).length !== 0
                          ? superCataloges.filter(s => s.id === item.scid)[0].name
                          : ''}
                      </p>
                    </div>
                    <div className="channel_wrapper">
                      <div className="channel">{getChannelName(item.scid)}</div>
                      <div onClick={() => handleDelete(index, 2)}>
                        <CloseIcon />
                      </div>
                    </div>
                  </div>
                </Draggable>
              ))}
            </Container>
          </div>
        </div>
        <div className="card">
          <div className="card_header">
            <p className="description">
              <FormattedMessage id="From" /> 15:00 a 17:59 <FormattedMessage id="hours" />
            </p>
          </div>
          <div className="card_list">
            <Container
              groupName="1"
              lockAxis="y"
              getChildPayload={i => state.scheme3[i]}
              onDrop={e => handleDrop(e, 3)}
            >
              {scheme3.map((item, index) => (
                <Draggable className="item" key={item.scid}>
                  <div className="item_wrapper">
                    <div className="name_wrapper">
                      <Tooltip
                        placement="top"
                        title={intl.formatMessage({
                          id: 'Drag to reorder',
                        })}
                      >
                        <Img src={catalogueImg} />
                      </Tooltip>
                      <p>
                        {superCataloges.filter(s => s.id === item.scid).length !== 0
                          ? superCataloges.filter(s => s.id === item.scid)[0].name
                          : ''}
                      </p>
                    </div>
                    <div className="channel_wrapper">
                      <div className="channel">{getChannelName(item.scid)}</div>
                      <div onClick={() => handleDelete(index, 3)}>
                        <CloseIcon />
                      </div>
                    </div>
                  </div>
                </Draggable>
              ))}
            </Container>
          </div>
        </div>
        <div className="card">
          <div className="card_header">
            <p className="description">
              <FormattedMessage id="From" /> 18:00 a 22:00 <FormattedMessage id="hours" />
            </p>
          </div>
          <div className="card_list">
            <Container
              groupName="1"
              lockAxis="y"
              getChildPayload={i => state.scheme4[i]}
              onDrop={e => handleDrop(e, 4)}
            >
              {scheme4.map((item, index) => (
                <Draggable className="item" key={item.scid}>
                  <div className="item_wrapper">
                    <div className="name_wrapper">
                      <Tooltip
                        placement="top"
                        title={intl.formatMessage({
                          id: 'Drag to reorder',
                        })}
                      >
                        <Img src={catalogueImg} />
                      </Tooltip>
                      <p>
                        {superCataloges.filter(s => s.id === item.scid).length !== 0
                          ? superCataloges.filter(s => s.id === item.scid)[0].name
                          : ''}
                      </p>
                    </div>
                    <div className="channel_wrapper">
                      <div className="channel">{getChannelName(item.scid)}</div>
                      <div onClick={() => handleDelete(index, 4)}>
                        <CloseIcon />
                      </div>
                    </div>
                  </div>
                </Draggable>
              ))}
            </Container>
          </div>
        </div>
      </div>
      <SyncConfirmModal
        isOpenModal={state.isConfirmModal}
        onCancel={() => {
          setState(prev => ({ ...prev, isConfirmModal: false }));
        }}
        onOk={handleConfirmSync}
      >
        <p className="title">
          <FormattedMessage id="Do you want to synchronize the product catalog?" />
        </p>
        <p className="description">
          <FormattedMessage id="This action will import the latest available catalog settings as well as new products added to the system." />
        </p>
      </SyncConfirmModal>
      <CatalogueSyncModal
        isOpenModal={state.isShowSyncModal}
        title={
          errMsg ||
          `${
            isExistSyncResult
              ? intl.formatMessage({
                  id: 'Catalog Synchronization Successful',
                })
              : `${intl.formatMessage({ id: 'Syncing' })}...`
          }`
        }
        description={
          errMsg
            ? ''
            : `${
                isExistSyncResult
                  ? `${intl.formatMessage({
                      id: 'Total Synchronized Categories',
                    })}: ${syncedData.item ? syncedData.superClass.success : 0}.`
                  : ''
              }`
        }
        buttonText="Cerrar"
        onCloseModal={() => setState(prev => ({ ...prev, isShowSyncModal: false }))}
      />
      <CatalogueSetupModal
        isOpenModal={state.isShowSetupModal}
        catalogueInfo={state.catalogueInfo}
        title={intl.formatMessage({
          id: 'Category customization',
        })}
        buttonText={intl.formatMessage({ id: 'Save' })}
        onCloseModal={() => setState(prev => ({ ...prev, isShowSetupModal: false }))}
      />
    </div>
  );
};

export default CatalogueManage;
