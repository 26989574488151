import React from 'react';
import PropTypes from 'prop-types';

function StatusCard({ count, description }) {
  return (
    <div className="overview_status_card">
      <p>{count}</p>
      {description}
    </div>
  );
}

StatusCard.defaultProps = {
  count: 0,
  description: null,
};

export default StatusCard;
