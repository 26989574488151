import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import RestaurantList from 'containers/Dashboard/Restaurants/RestaurantList';
import RestaurantNew from 'containers/Dashboard/Restaurants/RestaurantNew';
import DeliveryZoneList from 'containers/Dashboard/Restaurants/DeliveryZoneList';
import DeliveryZoneNew from 'containers/Dashboard/Restaurants/DeliveryZoneNew';
import ConfigureRegion from 'containers/Dashboard/Restaurants/ConfigureRegion';
import DeliveryZones from 'containers/Dashboard/Restaurants/DeliveryZones';

class Restaurants extends PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path="/dashboard/restaurants" component={RestaurantList} />
        <Route exact path="/dashboard/restaurants/regions" component={ConfigureRegion} />
        <Route exact path="/dashboard/restaurants/deliveryzones" component={DeliveryZones} />
        <Route exact path={['/dashboard/restaurants/new', '/dashboard/restaurants/:id']} component={RestaurantNew} />
        <Route exact path="/dashboard/restaurants/:rid/deliveryzone" component={DeliveryZoneList} />
        <Route exact path="/dashboard/restaurants/:rid/deliveryzone/new" component={DeliveryZoneNew} />
        <Route exact path="/dashboard/restaurants/:rid/deliveryzone/:zid" component={DeliveryZoneNew} />
      </Switch>
    );
  }
}

export default Restaurants;
