import { api } from '..';
import { VersionData } from './types';

export const reportApi = api.enhanceEndpoints({ addTagTypes: ['Version'] }).injectEndpoints({
  endpoints: build => ({
    getVersion: build.query<VersionData, any>({
      query: () => ({
        url: `version`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetVersionQuery } = reportApi;
