import { api } from '..';
import {
  PricesData,
  ProductsData,
  SizesData,
  CatalogesData,
  SuperCatalogesData,
  PromoConfigurationType,
} from './types';

export const catalogApi = api.enhanceEndpoints({ addTagTypes: ['Catalog'] }).injectEndpoints({
  endpoints: build => ({
    getItems: build.query<ProductsData, { limit: number; offset: number; filter: string; or: boolean; sort: string }>({
      query: ({ offset, limit, or, filter, sort }) => ({
        url: `v0/catalog/items?details=false${offset !== undefined ? `&offset=${offset}` : ''}${
          limit !== undefined ? `&limit=${limit}` : ''
        }${or ? `&or=${or}` : ''}${filter || ''}${sort ? `&sort=${sort}` : '&sort=createdAt desc'}`,
        method: 'GET',
      }),
    }),
    getPrices: build.query<PricesData, any>({
      query: () => ({
        url: `v0/catalog/prices`,
        method: 'GET',
      }),
    }),
    getSizes: build.query<SizesData, any>({
      query: () => ({
        url: `v0/catalog/sizes`,
        method: 'GET',
      }),
    }),
    getCatalogs: build.query<CatalogesData, any>({
      query: () => ({
        url: `v0/catalog/classes`,
        method: 'GET',
      }),
    }),
    syncedCatalog: build.query<any, any>({
      query: () => ({
        url: `v0/catalog/sync`,
        method: 'GET',
      }),
    }),
    syncedStock: build.query<any, any>({
      query: () => ({
        url: `v0/catalog/syncStock`,
        method: 'GET',
      }),
    }),
    getSuperCatalogs: build.query<SuperCatalogesData, any>({
      query: () => ({
        url: `v0/catalog/superClasses`,
        method: 'GET',
      }),
    }),
    updateSuperCatalog: build.mutation<any, any>({
      query: body => ({
        url: `v0/catalog/superClasses/${body.id}`,
        method: 'PUT',
        body: body.formData,
      }),
    }),
    getCatalogOrders: build.query<any, any>({
      query: () => ({
        url: `v0/catalog/superClasses/orders`,
        method: 'GET',
      }),
    }),
    updateCatalogOrders: build.mutation<any, any>({
      query: body => ({
        url: `v0/catalog/superClasses/orders`,
        method: 'PUT',
        body: body.orderSchemes,
      }),
    }),
    getSingleItem: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `v0/catalog/items/${id}`,
        method: 'GET',
      }),
    }),
    getSinglePromoItem: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `/v1/catalog/extra-promos/preview/${id}`,
        method: 'GET',
      }),
    }),
    updateSinglePromoItem: build.mutation<any, { id: number; body: PromoConfigurationType }>({
      query: ({ id, body }) => ({
        url: `/v1/catalog/items/${id}`,
        method: 'PUT',
        body: body,
      }),
    }),
  }),
});

export const {
  useLazyGetItemsQuery,
  useLazyGetPricesQuery,
  useLazyGetSizesQuery,
  useLazyGetCatalogsQuery,
  useLazySyncedCatalogQuery,
  useLazySyncedStockQuery,
  useLazyGetSuperCatalogsQuery,
  useUpdateSuperCatalogMutation,
  useLazyGetCatalogOrdersQuery,
  useUpdateCatalogOrdersMutation,
  useLazyGetSingleItemQuery,
  useLazyGetSinglePromoItemQuery,
  useUpdateSinglePromoItemMutation,
} = catalogApi;
