import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import ModalDlg from 'components/Basic/ModalDlg';

const SaveAsDraftModal = ({ isLoading, title, subtitle, isOpenModal, isClosing, onCancel, onClose, onSave }) => {
  return (
    <ModalDlg className="save_as_draft_modal" visible={isOpenModal} width={574} handleCancel={onCancel} isCenter>
      <div className="header">
        <h3>{title}</h3>
        <p className="description">{subtitle}</p>
      </div>
      <div className="footer">
        <div className="btn_wrapper">
          <p onClick={onCancel}>
            <FormattedMessage id="Cancel" />
          </p>
          {isClosing && (
            <Button className="close_button" onClick={onClose}>
              <FormattedMessage id="Close without saving" />
            </Button>
          )}
          <Button className="save_button" disabled={isLoading} onClick={onSave}>
            <FormattedMessage id="Save as draft" />
          </Button>
        </div>
      </div>
    </ModalDlg>
  );
};

SaveAsDraftModal.defaultProps = {
  isOpenModal: false,
  title: '',
  subtitle: '',
};

export default SaveAsDraftModal;
