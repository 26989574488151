import { useState, FC, useEffect } from 'react';
import { Tooltip, Button, Pagination } from 'antd';
import { Edit, Delete } from '@material-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDeleteDraftMutation, useLazyGetDraftQuery } from 'core/offers/OffersService';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import DeleteConfirmModal from 'components/Basic/DeleteConfirmModal';
import toast from 'components/Basic/Toast';
import OffersViewModal from './OffersViewModal';
import { Draft } from 'core/offers/types';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { setOffersFullData, setDefaultFullData } from 'core/offers/OffersSlice';

type OffersDraftTableType = {
  defaultPage: number;
  defaultPageSize: number;
  pageSizeOptions: string[];
  page: number;
  pageSize: number;
  drafts: { drafts: []; totalCount: 0 };
  draftId: string;
  draftsRequest: (page: number, pageSize: number) => void;
  onChangePage: (page: number, pageSize: number) => void;
  onChangePageSize: (page: number, pageSize: number) => void;
};

const OffersDraftTable: FC<OffersDraftTableType> = ({
  defaultPage,
  defaultPageSize,
  pageSizeOptions,
  page,
  pageSize,
  drafts,
  draftId,
  draftsRequest,
  onChangePage,
  onChangePageSize,
}: OffersDraftTableType) => {
  const intl = useIntl();

  const [selectedId, setSeletedId] = useState<number | null>(null);
  const [isShowEditModal, setShowEditModal] = useState<boolean>(false);
  const [isShowDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { offersFullData } = useAppSelector(state => state.offersReducer);

  const dispatch = useAppDispatch();
  const [getDraft] = useLazyGetDraftQuery();
  const [deleteDraft] = useDeleteDraftMutation();

  useEffect(() => {
    if (draftId) {
      showDraftWithUrl(Number(draftId));
    }
  }, []);

  const onShowDeleteModal = (id: number) => {
    setSeletedId(id);
    setShowDeleteModal(true);
  };

  const onCloseDeleteModal = () => {
    setSeletedId(null);
    setShowDeleteModal(false);
  };

  const onShowEditModal = async (id: number) => {
    try {
      dispatch(setOffersFullData({ offersFullData: {} } as any));

      const response: Draft = await getDraft(id).unwrap();

      const offersFullData = { ...response, ...response.data, data: undefined };

      dispatch(setOffersFullData({ offersFullData }));
    } catch (error) {}
  };

  const onDeleteDraft = async () => {
    try {
      if (selectedId) {
        await deleteDraft(selectedId).unwrap();

        draftsRequest(page, pageSize);

        toast.success({ title: intl.formatMessage({ id: 'The draft is deleted successfully' }) });

        setShowDeleteModal(false);
      }
    } catch {
      toast.success({ title: intl.formatMessage({ id: 'The draft is deleted failure' }) });
    }
  };

  const renderDateTime = (dateTime: Date | null, type: 'date' | 'time') => {
    if (!dateTime) {
      return null;
    }

    switch (type) {
      case 'date':
        return dayjs(dateTime).utc().format('DD/MM/YYYY');
      case 'time':
        return dayjs(dateTime).utc().format('HH:mm');
      default:
        return dayjs(dateTime).utc().format('DD/MM/YYYY');
    }
  };

  const showDraftWithUrl = (id: number) => {
    onShowEditModal(id);
    setShowEditModal(true);
  };

  return (
    <div className="offer_draft_table">
      <div className="header">
        <div className="id">
          <FormattedMessage id="ID" />
        </div>
        <div className="name">
          <FormattedMessage id="Name" />
        </div>
        <div className="descriptions">
          <FormattedMessage id="Description" />
        </div>
        <div className="tab">
          <FormattedMessage id="Tab" />
        </div>
        <div className="category">
          <FormattedMessage id="Category" />
        </div>
        <div className="date">
          <FormattedMessage id="Date" />
        </div>
        <div className="hour">
          <FormattedMessage id="Hour" />
        </div>
        <div className="weight">
          <FormattedMessage id="Weight" />
        </div>
        <div className="actions">
          <FormattedMessage id="Actions" />
        </div>
      </div>
      <div>
        <div className="body">
          {Array.isArray(drafts.drafts) &&
            drafts.drafts?.map((item: any, index: number) => (
              <div className={`items type_state_${item?.data?.isActive}`} key={index}>
                <div className="id">
                  <p>{item?.id}</p>
                </div>
                <div className="name">
                  <p>{item?.data?.name}</p>
                </div>
                <div className="descriptions">
                  <p>{item?.data?.description}</p>
                </div>
                <Tooltip placement="top" title={item?.data?.tabs?.map((tab: any) => tab.tab.name).join(', ')}>
                  <div className="tab">
                    {item?.data?.tabs && (
                      <p>
                        {(() => {
                          const tabNames = item?.data?.tabs?.map((tab: any) => tab.tab.name);
                          const joinedNames = tabNames.join(', ');

                          if (joinedNames.length > 35) {
                            return joinedNames.substring(0, 35) + ' ...';
                          } else {
                            return joinedNames;
                          }
                        })()}
                      </p>
                    )}
                  </div>
                </Tooltip>
                <div className="category">
                  <p>{item?.data?.category?.name}</p>
                </div>
                <div className="date">
                  <p>{renderDateTime(item?.data?.schedule?.startDate, 'date')}</p>
                  <p>{renderDateTime(item?.data?.schedule?.endDate, 'date')}</p>
                </div>
                <div className="hour">
                  <p>{renderDateTime(item?.data?.schedule?.startTime, 'time')}</p>
                  <p>{renderDateTime(item?.data?.schedule?.endTime, 'time')}</p>
                </div>
                <div className="weight">
                  <p>{item?.data?.baseWeight}</p>
                </div>
                <div className="actions">
                  <NavLink to={`/dashboard/deals/offers-draft/${item.id}`} style={{ marginRight: '10px' }}>
                    <Tooltip placement="top" title={intl.formatMessage({ id: 'Edit offer' })}>
                      <Button
                        className="edit_btn"
                        onClick={() => {
                          showDraftWithUrl(item?.id);
                        }}
                      >
                        <Edit />
                      </Button>
                    </Tooltip>
                  </NavLink>
                  <NavLink to={`/dashboard/deals/offers-draft`}>
                    <Tooltip placement="top" title={intl.formatMessage({ id: 'Delete offer' })}>
                      <Button className="delete_btn" onClick={() => onShowDeleteModal(item?.id)}>
                        <Delete />
                      </Button>
                    </Tooltip>
                  </NavLink>
                </div>
              </div>
            ))}
        </div>
        <div className="footer">
          <Pagination
            showSizeChanger
            defaultCurrent={defaultPage}
            defaultPageSize={defaultPageSize}
            current={page}
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            total={drafts.totalCount}
            onChange={onChangePage}
            onShowSizeChange={onChangePageSize}
          />
          <div className="total">Resultados: {drafts.totalCount}</div>
        </div>
      </div>
      {offersFullData?.id && (
        <OffersViewModal
          isDraftPage={true}
          isShowModal={isShowEditModal}
          onShowModal={() => setShowEditModal(false)}
          offersInfo={offersFullData}
          current={page}
          pageSize={pageSize}
          draftRequest={() => draftsRequest(page, pageSize)}
          categories={null}
          offersRequest={() => {}}
        />
      )}
      <DeleteConfirmModal
        isOpenModal={isShowDeleteModal}
        title={intl.formatMessage({ id: 'Do you want to remove the draft' })}
        subtitle={intl.formatMessage({
          id: 'This action cannot be undone. Continue deleting the draft',
        })}
        onCancel={onCloseDeleteModal}
        onOk={onDeleteDraft}
      />
    </div>
  );
};

export default OffersDraftTable;
