export const constant = {
  FLAGS: ['GB', 'CN', 'JP', 'ES'],
};

export const segmentTypes = {
  newCustomer: 'new_customer',
  recurrentCustomer: 'recurrent_customer',
  everyone: 'everyone',
  personalized: 'personalized',
  welcomeOffer: 'welcome_offer',
};

export const tabsStringNames = [
  'Setup',
  'Redemptions',
  'ExtendedData',
  'PromotionalImage',
  'Details',
  'TabCategory',
  'Dates',
  'Filters',
  'Location',
  'Hidden',
];

export const authenticationErrorCodes = [10110, 10123, 10126, 10128, 10130, 10137, 10138, 10139, 10119, 10120];
export const authorizationErrorCodes = [10111, 10112, 10113, 10218, 10316, 10419, 10523];
export const validationErrorCodes = [
  10101, 10116, 10117, 10121, 10132, 10210, 10211, 10216, 10310, 10311, 10410, 10411, 10412, 10413, 10510, 10511, 10512,
  10513, 10514, 10515, 10525, 10610, 10611, 10612, 10613, 10614, 10615, 10616, 10710, 10711, 10810, 10910, 11210, 11810,
  11910, 12010, 12110, 12210, 12310, 12410, 12510, 12610, 12710, 12810, 12910, 13010, 13110, 13112, 13113, 13115, 13116,
  13118, 13210, 13211, 13212, 13213, 13226, 13227, 13228, 13250, 17010, 17110, 17210, 17310, 17410, 17510, 17610, 17710,
  17810, 17910, 18010, 18110, 18210, 18310, 18410, 18411, 18510, 18610, 18710, 18810, 18910, 19010, 19110, 19210, 19811,
  19812, 19901, 19911, 11010, 10201,
];
export const duplicationErrorCodes = [
  10101, 10301, 10401, 10501, 10215, 10314, 10417, 10519, 10520, 10521, 10713, 11013, 17714,
];
