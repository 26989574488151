import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { FormattedMessage, useIntl } from 'react-intl';
import GoogleMapReact from 'google-map-react';
import { Button, Select, Input } from 'antd';
import { Form } from '@ant-design/compatible';
import ModalDlg from 'components/Basic/ModalDlg';
import { ArrowForward, ArrowDropDown } from '@material-ui/icons';
import { getLocations } from 'utilities/common';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import {
  useLazyGetDepartmentsQuery,
  useLazyGetMunicipalitiesQuery,
  useLazyGetZonesQuery,
} from 'core/region/RegionService';
import { setDepartmentsData, setMunicipalitiesData, setZonesData } from 'core/region/RegionSlice';

const { Option } = Select;
const items = ['Item1', 'Item2', 'Item3'];

function DeliveryUpdateModal({ form, addressDetail, isOpenModal, onCloseModal }) {
  const [departmentId, setDepartmentId] = useState(null);
  const [municipalityId, setMunicipalityId] = useState(null);
  const [zoneId, setZoneId] = useState(null);

  const [center, setCenter] = useState({
    lat: getLocations().lat,
    lng: getLocations().lng,
  });

  const { departments, municipalities, zones } = useAppSelector(state => state.regionReducer);

  const dispatch = useAppDispatch();
  const [getDepartments] = useLazyGetDepartmentsQuery();
  const [getMunicipalities] = useLazyGetMunicipalitiesQuery();
  const [getZones] = useLazyGetZonesQuery();

  useEffect(async () => {
    try {
      const res = await getDepartments({}).unwrap();
      dispatch(setDepartmentsData({ departments: res.rows }));
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleGoogleAPILoaded = ({ map, maps }) => {
    // const marker = new maps.Marker({
    //   position: center,
    //   map,
    //   draggable: false
    // });
    setCenter({
      lat: addressDetail.lat || getLocations().lat,
      lng: addressDetail.long || getLocations().lng,
    });
  };

  const handleDepartment = async id => {
    setDepartmentId(id);
    try {
      const res = await getMunicipalities({ department: id }).unwrap();
      dispatch(setMunicipalitiesData({ municipalities: res.rows }));
    } catch (e) {
      console.log(e);
    }
  };

  const handleMunicipality = async id => {
    setMunicipalityId(id);
    try {
      const res = await getZones({
        department: departmentId,
        municipality: id,
      }).unwrap();
      dispatch(setZonesData({ zones: res.rows }));
    } catch (e) {
      console.log(e);
    }
  };

  const handleZone = id => {
    setZoneId(id);
  };

  const { getFieldDecorator } = form;

  return (
    <ModalDlg className="delivery_update_modal" visible={isOpenModal} width={951} handleCancel={onCloseModal} isCenter>
      <div className="modal_header">
        <div>
          <h3>
            <FormattedMessage id="User delivery address" />
          </h3>
          <p className="description">{addressDetail.address || ''}</p>
        </div>
      </div>
      <div className="modal_content">
        <div className="map_wrapper">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAP_API,
            }}
            defaultCenter={{
              lat: addressDetail.lat || getLocations().lat,
              lng: addressDetail.long || getLocations().lng,
            }}
            center={center}
            defaultZoom={20}
            zoom={11}
            onGoogleApiLoaded={handleGoogleAPILoaded}
            draggable
          />
        </div>
        <div className="address_setting_wrapper">
          <Form>
            <div className="address_direction">
              <p className="label">
                <FormattedMessage id="Textual direction and directions" />
              </p>
              <Input.TextArea className="address_detail" value={addressDetail.address || ''} />
              <Input />
            </div>
            <div className="custom_address">
              <div className="address">
                <p className="label">
                  <FormattedMessage id="country" />
                </p>
                <Form.Item>
                  {getFieldDecorator(
                    'country',
                    {},
                  )(
                    <Select
                      placeholder="Seleccionar país"
                      suffixIcon={<ArrowDropDown />}
                      dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                    >
                      {items.map(item => (
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </div>
              <div className="address">
                <p className="label">
                  <FormattedMessage id="Department / Province" />
                </p>
                <Form.Item>
                  {getFieldDecorator(
                    'department',
                    {},
                  )(
                    <Select
                      placeholder="Seleccionar departamento"
                      suffixIcon={<ArrowDropDown />}
                      value={departmentId}
                      onChange={handleDepartment}
                      dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                    >
                      {departments &&
                        departments.map(item => (
                          <Option value={item.id} key={item.description}>
                            {item.description}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
              </div>
              <div className="address">
                <p className="label">
                  <FormattedMessage id="City / Municipality" />
                </p>
                <Form.Item>
                  {getFieldDecorator(
                    'municipality',
                    {},
                  )(
                    <Select
                      placeholder="Seleccionar municipio"
                      suffixIcon={<ArrowDropDown />}
                      value={municipalityId}
                      onChange={handleMunicipality}
                      dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                    >
                      {municipalities &&
                        municipalities.map(item => (
                          <Option value={item.id} key={item.description}>
                            {item.description}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
              </div>
              <div className="address">
                <p className="label">
                  <FormattedMessage id="Zone or sector" />
                </p>
                <Form.Item>
                  {getFieldDecorator(
                    'zone',
                    {},
                  )(
                    <Select
                      placeholder="Seleccionar zona"
                      suffixIcon={<ArrowDropDown />}
                      value={zoneId}
                      onChange={handleZone}
                      dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                    >
                      {zones &&
                        zones.map(item => (
                          <Option value={item.id} key={item.description}>
                            {item.description}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
              </div>
              <div className="address restaurant">
                <p className="label">
                  <FormattedMessage id="Assigned restaurant" />
                </p>
                <Form.Item>
                  {getFieldDecorator(
                    'restaurant',
                    {},
                  )(
                    <Select
                      placeholder="Seleccionar restaurante"
                      suffixIcon={<ArrowDropDown />}
                      dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                    >
                      {items.map(item => (
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </div>
            </div>
            <div className="footer">
              <div className="aditional_detail">
                <p className="geo_position">
                  {addressDetail.lat || ''} / {addressDetail.long || ''}
                </p>
              </div>
              <div className="btn_wrapper">
                <Button className="close_btn" onClick={onCloseModal}>
                  <FormattedMessage id="To update" />
                  <ArrowForward />
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </ModalDlg>
  );
}

DeliveryUpdateModal.defaultProps = {
  addressDetail: {},
  form: {},
  isOpenModal: false,
};

export default compose(Form.create({ name: 'delivery_address_form' }))(DeliveryUpdateModal);
