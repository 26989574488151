import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Button, Select } from 'antd';
import { Edit, Add } from '@material-ui/icons';
import { ArrowDropDown, ArrowDownwardOutlined, ArrowUpwardOutlined } from '@material-ui/icons';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyGetSegmentsListQuery } from 'core/segments/SegmentsService';
import { setSegmentsListData } from 'core/segments/SegmentsSlice';
import SegmentsViewModal from '../Segments/SegmentsViewModal';

type Inner = {
  id: string;
  text: string;
};

type FiltersType = {
  selectedFilterStatus: number;
  setSelectedFilterStatus: (e: any) => void;
  lowerList: Array<Inner>;
  setLowerList: (e: any) => void;
  upperList: Array<Inner>;
  setUpperList: (e: any) => void;
  isValid: boolean;
};

const Filters: React.FC<FiltersType> = ({
  selectedFilterStatus,
  setSelectedFilterStatus,
  lowerList,
  setLowerList,
  upperList,
  setUpperList,
  isValid,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const [isShowDetailModal, setShowDetailModal] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [current, setCurrent] = useState(1);
  const [sortName, setSortName] = useState('');
  const [sortId, setSortId] = useState('desc');
  const [pageSize, setPageSize] = useState(10);
  const [segmentInfo, setSegmentInfo] = useState<any>();

  const dispatch = useAppDispatch();
  const [getSegmentsList] = useLazyGetSegmentsListQuery();

  const { list } = useAppSelector(state => state.segmentsReducer);

  useEffect(() => {
    getInitialData();
    setSearchText('');
  }, []);

  useEffect(() => {
    getInitialData();
  }, [isShowDetailModal, segmentInfo, list]);

  useEffect(() => {
    setCurrent(1);
  }, [pageSize]);

  const getInitialData = async () => {
    setIsLoading(true);
    const queryString = buildQueryString({
      search: '',
      page: 1,
      size: pageSize,
      count: true,
      filter: 'omit',
      sort_by_id: sortId,
      sort_by_name: '',
    });

    try {
      const res = await getSegmentsList({ queryString }).unwrap();
      dispatch(setSegmentsListData(res));
    } catch (error) {
      console.error('Error fetching segments list:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSegments = (value: number) => {
    setSelectedFilterStatus(value);
  };

  const [selectedItem, setSelectedItem] = useState<any>(null);

  const handleSelect = (item: any) => {
    setSelectedItem(item);
  };

  const handleAdd = () => {
    if (selectedItem && !lowerList.some((item: any) => item.id === selectedItem.id)) {
      setLowerList([...lowerList, selectedItem]);
      setUpperList(upperList.filter((item: any) => item.id !== selectedItem.id));
      setSelectedItem(null);
    }
  };

  const handleRemove = () => {
    if (selectedItem && !upperList.some((item: any) => item.id === selectedItem.id)) {
      setUpperList([...upperList, selectedItem]);
      setLowerList(lowerList.filter((item: any) => item.id !== selectedItem.id));
      setSelectedItem(null);
    }
  };

  const listStyleUpper = {
    height: '94px',
    overflowY: 'auto',
    border: '1px solid #CFCFCF',
    padding: '0px',
    margin: '8px 0',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  } as React.CSSProperties;

  const listStyleLower = {
    height: '72px',
    overflowY: 'auto',
    border: '1px solid #CFCFCF',
    padding: '0px',
    margin: '8px 0',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  } as React.CSSProperties;

  let segmentsArr = list.segments?.map(item => ({
    id: item.id,
    name: item.name,
  }));

  const checkField = (value: any, isArray: boolean) => {
    if (isValid) return;
    if (isArray) {
      return value.length === 0;
    }
    if (value && !isArray) {
      return false;
    } else {
      return true;
    }
  };

  const openModal = (id: number) => {
    if (id) {
      const item = list?.segments?.find(item => item.id === id);
      setSegmentInfo(item);
    }
    setShowDetailModal(true);
  };

  const closeModal = () => {
    segmentsArr = list.segments?.map(item => {
      if (item.id === segmentInfo) {
        const _item = {
          id: segmentInfo.id,
          name: segmentInfo.name,
        };
        return _item;
      } else {
        return item;
      }
    });
    setSelectedFilterStatus(segmentInfo?.id);
    setSegmentInfo(null);
  };

  return (
    <div className="new_filters_form_wrapper" id="" style={{ marginBottom: '20px' }}>
      <div className="basic_info_form">
        <div className="filters_input">
          <div style={{ display: 'flex', gap: '4px', alignItems: 'flex-end', paddingBottom: '20px' }}>
            <Form.Item style={{ marginBottom: 0 }}>
              <p className="title_deals" style={{ marginTop: 0 }}>
                <FormattedMessage id="Segment" />
              </p>
              <Select
                className={`select_modal ${checkField(selectedFilterStatus, false) && 'has-error'}`}
                suffixIcon={<ArrowDropDown />}
                onChange={handleSegments}
                value={selectedFilterStatus}
              >
                {segmentsArr &&
                  segmentsArr?.map(item => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <div className="upload-btn">
              <Button onClick={() => openModal(selectedFilterStatus)}>
                {selectedFilterStatus ? <Edit /> : <Add />}
                {selectedFilterStatus ? (
                  <span>
                    <FormattedMessage id="Edit" />
                  </span>
                ) : (
                  <span>
                    <FormattedMessage id="Create" />
                  </span>
                )}
              </Button>
            </div>
          </div>
        </div>
        {checkField(selectedFilterStatus, false) && (
          <div style={{ marginTop: -20, paddingBottom: 14 }}>
            <span className="error_msg">
              <FormattedMessage id="Field is required" />
            </span>
          </div>
        )}
        <div>
          <p className="title_deals">Etiqueta de contenido</p>
          <div style={listStyleUpper}>
            {upperList?.map((item: any) => (
              <div
                key={item.id}
                style={{
                  padding: '4px',
                  cursor: 'pointer',
                  fontSize: '12px',
                  fontWeight: '700',
                  lineHeight: 'normal',
                  backgroundColor: selectedItem && selectedItem.id === item.id ? '#EDF0F2' : 'transparent',
                }}
                onClick={() => handleSelect(item)}
              >
                <FormattedMessage id={item.text} />
              </div>
            ))}
          </div>
          <div className="content-tab-btn">
            <Button onClick={handleAdd} style={{ marginRight: '10px' }}>
              <span>
                <FormattedMessage id={'Add'} />
              </span>
              <ArrowDownwardOutlined />
            </Button>
            {lowerList?.length !== 0 && (
              <Button onClick={handleRemove}>
                <span>
                  <FormattedMessage id={'Remove'} />
                </span>
                <ArrowUpwardOutlined />
              </Button>
            )}
            {checkField(lowerList, true) && (
              <span style={{ marginLeft: 12 }} className="error_msg">
                <FormattedMessage id="Add some options" />
              </span>
            )}
          </div>
          <div style={listStyleLower}>
            {lowerList?.map((item: any) => (
              <div
                key={item.id}
                style={{
                  padding: '4px',
                  cursor: 'pointer',
                  fontSize: '12px',
                  fontWeight: '700',
                  lineHeight: 'normal',
                  backgroundColor: selectedItem && selectedItem.id === item.id ? '#EDF0F2' : 'transparent',
                }}
                onClick={() => handleSelect(item)}
              >
                <FormattedMessage id={item.text} />
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <SegmentTableModal isOpenModal={isShowDetailModal} onCloseModal={() => setShowDetailModal(false)} /> */}
      <SegmentsViewModal
        isOpenModal={isShowDetailModal}
        onCloseModal={() => {
          setShowDetailModal(false);
          closeModal();
        }}
        setPageSize={setPageSize}
        segmentsInfo={segmentInfo}
      />
    </div>
  );
};

export default Filters;

const buildQueryString = ({
  search = '',
  page = 1,
  size = 10,
  count = true,
  sort_by_id = 'desc',
  sort_by_name = '',
  filter = '',
}) => {
  const params = [`search=${encodeURIComponent(search)}`, `page=${page}`, `count=${count}`, `filter=${filter}`];

  if (sort_by_id) {
    params.push(`sort[id]=${sort_by_id}`);
  }

  if (sort_by_name) {
    params.push(`sort[name]=${sort_by_name}`);
  }

  return params.join('&');
};
