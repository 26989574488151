import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import RestaurantFilter from 'components/Dashboard/Restaurants/RestaurantFilter';
import RestaurantsTable from 'components/Dashboard/Restaurants/RestaurantsTable';
import Loading from 'components/Basic/Loading';
import { useLazyGetRestaurantListQuery } from 'core/restaurant/RestaurantService';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { setRestaurantList } from 'core/restaurant/RestaurantSlice';

const RestaurantList = ({ locationOffer = true, isModal, action }) => {
  const [state, setState] = useState({
    searchText: '',
    offset: 0,
    limit: 10,
    filterQuery: '',
    searchFilterQuery: '',
    sort: '',
    isLoading: false,
  });

  const dispatch = useAppDispatch();
  const [getRestaurantList] = useLazyGetRestaurantListQuery();

  const { list } = useAppSelector(state => state.restaurantReducer);

  const handleChangePage = async (offset, limit, filterQuery, sort) => {
    setState(prev => ({ ...prev, offset: offset, limit: limit, isLoading: true }));
    await getRestaurantList({
      offset,
      limit,
      or: false,
      filter: filterQuery || state.filterQuery + state.searchFilterQuery,
      sort: sort || state.sort,
      details: true,
    })
      .unwrap()
      .then(res => {
        dispatch(
          setRestaurantList({
            list: res,
          }),
        );
        setState(prev => ({ ...prev, isLoading: false }));
      })
      .catch(() => {
        setState(prev => ({ ...prev, isLoading: false }));
      });
  };

  const handleStateFilter = values => {
    if (values.length !== 0) {
      let filterQuery = '&filter=state in';
      values.map(v => {
        filterQuery += ` ${v}`;
        return filterQuery;
      });
      setState(prev => ({ ...prev, filterQuery: filterQuery }));
    } else {
      setState(prev => ({ ...prev, filterQuery: '' }));
    }
  };

  const handleSort = value => {
    setState(prev => ({ ...prev, sort: value }));
  };

  const handleSearch = e => {
    setState(prev => ({ ...prev, searchText: e.target.value }));
  };

  const handleKeyDownSearch = async e => {
    let searchFilterQuery = '';
    if (e.keyCode === 13) {
      if (state.searchText) {
        if (!isNaN(state.searchText, 10)) {
          searchFilterQuery += `&filter=or.rid eq ${state.searchText}`;
        }
        searchFilterQuery += `&filter=or.description iLike %25${state.searchText}%25&filter=or.address iLike %25${state.searchText}%25`;
      } else {
        searchFilterQuery = '';
      }
      setState(prev => ({ ...prev, searchFilterQuery: searchFilterQuery, isLoading: true }));
      await getRestaurantList({
        offset: state.offset,
        limit: state.limit,
        or: false,
        filter: state.filterQuery + searchFilterQuery,
        sort: `${state.sort}`,
        details: true,
      })
        .unwrap()
        .then(res => {
          dispatch(
            setRestaurantList({
              list: res,
            }),
          );
          setState(prev => ({ ...prev, isLoading: false }));
        })
        .catch(() => {
          setState(prev => ({ ...prev, isLoading: false }));
        });
    }
  };

  const handleApplyFilter = async () => {
    setState(prev => ({ ...prev, isLoading: true }));
    await getRestaurantList({
      offset: state.offset,
      limit: state.limit,
      or: false,
      filter: state.filterQuery + state.searchFilterQuery,
      sort: `${state.sort}`,
      details: true,
    })
      .unwrap()
      .then(res => {
        dispatch(
          setRestaurantList({
            list: res,
          }),
        );
        setState(prev => ({ ...prev, isLoading: false }));
      })
      .catch(() => {
        setState(prev => ({ ...prev, isLoading: false }));
      });
  };

  const handleEdit = id => {
    action(id);
  };

  return (
    <div className="restaurants_list_layout">
      <Loading visible={state.isLoading} />
      {locationOffer && <ContentHeader type="restaurant" />}
      <div className="restaurants_filter_wrapper">
        <RestaurantFilter
          searchText={state.searchText}
          onStateFilter={handleStateFilter}
          onSort={handleSort}
          onSearch={handleSearch}
          onKeyDownSearch={handleKeyDownSearch}
          onApplyFilter={handleApplyFilter}
        />
        <RestaurantsTable
          restaurantData={list.rows || []}
          onChangePage={handleChangePage}
          total={list.totalCount}
          isModal={isModal}
          action={handleEdit}
        />
      </div>
    </div>
  );
};

export default RestaurantList;
