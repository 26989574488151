import { api } from '..';
import { MyAddressData } from './types';

export const addressApi = api.enhanceEndpoints({ addTagTypes: ['Address'] }).injectEndpoints({
  endpoints: build => ({
    getMyAddress: build.query<MyAddressData, { id: number; pendingOrders: any }>({
      query: ({ id, pendingOrders }) => ({
        url: `v0/customers/${id}/addresses${pendingOrders ? `?pendingOrders=${pendingOrders}` : ''}`,
        method: 'GET',
      }),
    }),
    createMyAddress: build.mutation<any, any>({
      query: body => ({
        url: `v0/customers/addresses`,
        method: 'POST',
        body,
      }),
    }),
    updateMyAddress: build.mutation<any, any>({
      query: body => ({
        url: `v0/customers/addresses/${body.id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteMyAddress: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `v0/customers/addresses/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useLazyGetMyAddressQuery } = addressApi;
