import { api } from '..';
import { AgentData } from './types';

export const agentApi = api.enhanceEndpoints({ addTagTypes: ['Agent'] }).injectEndpoints({
  endpoints: build => ({
    getAgentList: build.query<AgentData, { offset: number; limit: number; or: boolean; filter: string; sort: string }>({
      query: ({ offset, limit, or, filter, sort }) => ({
        url: `v0/agents?details=true&offset=${offset || 0}&limit=${limit || 5}${or ? `&or=${or}` : ''}${filter || ''}${
          sort ? `&sort=${sort}` : '&sort=createdAt desc'
        }`,
        method: 'GET',
      }),
    }),
    getAgent: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `v0/agents/${id}?details=true`,
        method: 'GET',
      }),
    }),
    createAgent: build.mutation<any, any>({
      query: body => ({
        url: `v0/agents`,
        method: 'POST',
        body: body.formData,
      }),
    }),
    updateAgent: build.mutation<any, any>({
      query: body => ({
        url: `v0/agents/${body.id}`,
        method: 'PUT',
        body: body.formData,
      }),
    }),
    deleteAgent: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `v0/agents/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetAgentListQuery,
  useLazyGetAgentQuery,
  useCreateAgentMutation,
  useUpdateAgentMutation,
  useDeleteAgentMutation,
} = agentApi;
