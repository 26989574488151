import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import { api } from '.';
import loginReducer from './auth/LoginSlice';
import trackingReducer from './tracking/TrackingSlice';
import catalogReducer from './catalog/CatalogSlice';
import orderReducer from './order/OrderSlice';
import restaurantReducer from './restaurant/RestaurantSlice';
import regionReducer from './region/RegionSlice';
import customerReducer from './customer/CustomerSlice';
import addressReducer from './address/AddressSlice';
import agentReducer from './agent/AgentSlice';
import ambassadorReducer from './ambassador/AmbassadorSlice';
import operatorReducer from './operator/OperatorSlice';
import distributionReducer from './distribution/DistributionSlice';
import promotionReducer from './promotion/PromotionSlice';
import couponReducer from './coupon/CouponSlice';
import settingsReducer from './settings/SettingsSlice';
import reportReducer from './report/ReportSlice';
import versionReducer from './version/VersionSlice';
import campaignReducer from './campaign/CampaignSlice';
import offersReducer from './offers/OffersSlice';
import tabsReducer from './tabs/TabsSlice';
import categoriesReducer from './categories/CategoriesSlice';
import dealsReducer from './deals/DealsSlice';
import segmentsReducer from './segments/SegmentsSlice';
import rewardsReducer from './rewards/RewardsSlice';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'state_mcd',
  storage,
  blacklist: ['api'],
};

const rootReducer = combineReducers({
  loginReducer,
  trackingReducer,
  catalogReducer,
  orderReducer,
  restaurantReducer,
  regionReducer,
  customerReducer,
  addressReducer,
  agentReducer,
  ambassadorReducer,
  operatorReducer,
  distributionReducer,
  promotionReducer,
  couponReducer,
  settingsReducer,
  reportReducer,
  versionReducer,
  campaignReducer,
  offersReducer,
  tabsReducer,
  categoriesReducer,
  dealsReducer,
  rewardsReducer,
  segmentsReducer,
  [api.reducerPath]: api.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const setupStore = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }).concat(api.middleware),
});

export const initStore = () => setupStore;

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof initStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
export const persistor = persistStore(setupStore);
