import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, OrderData } from './types';

const initialState: initState = {
  list: {
    limit: 0,
    offset: 0,
    rows: [],
    totalCount: 0,
  },
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrdersData(state: initState, action: PayloadAction<{ list: OrderData }>) {
      state.list = action.payload.list;
    },
  },
});

export const { setOrdersData } = orderSlice.actions;

export default orderSlice.reducer;
