import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Checkbox, Radio } from 'antd';
import LocationTableModal from './LocationTableModal';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useGetRestaurantsForDealsQuery } from 'core/restaurant/RestaurantService';
import { setRestaurantList } from 'core/restaurant/RestaurantSlice';
import Loading from 'components/Basic/Loading';

type LocationType = {
  isLocation: string;
  setIsLocation: (e: string) => void;
  setSelectedRestaurant: (e: any) => void;
  selectedRestaurant: Array<Object>;
  isValid: boolean;
};

const Location: React.FC<LocationType> = ({
  isLocation,
  setIsLocation,
  setSelectedRestaurant,
  selectedRestaurant = [],
  isValid,
}) => {
  const [isShowCatDetailModal, setShowCatDetailModal] = useState(false);

  const { data, isLoading } = useGetRestaurantsForDealsQuery({}, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (isShowCatDetailModal) {
      setSelectedRestaurant([]);
    }
  }, [isShowCatDetailModal]);

  const restaurants = data?.restaurants?.map((item: any) => ({
    id: item.id,
    name: item.description,
    state: selectedRestaurant?.includes(item.id),
  }));

  const handleRadioChange = (value: string) => {
    setIsLocation(value);
    setSelectedRestaurant([]);
  };

  const handleCheckboxChange = (id: number, isChecked: boolean) => {
    if (isChecked) {
      setSelectedRestaurant([...selectedRestaurant, id]);
    } else {
      setSelectedRestaurant(selectedRestaurant.filter(restaurantId => restaurantId !== id));
    }
  };

  const checkField = (value: any) => {
    if (isValid) return;

    const hasTrueState = value?.some((day: any) => day.state);
    return !hasTrueState;
  };

  return (
    <>
      <div className="" id="campaign_form_wrapper">
        <div className="basic_info_form" style={{ marginBottom: '20px' }}>
          <div className="tabs_input">
            <Form.Item>
              <div>
                <p className="title_deals">
                  <FormattedMessage id="Location title" />
                </p>
                <Radio.Group onChange={e => handleRadioChange(e.target.value)} value={isLocation}>
                  <Radio value={'everywhere'}>
                    <FormattedMessage id="Radio button value first" />
                  </Radio>
                  <Radio value={'geofence'}>
                    <FormattedMessage id="Radio button value second" />
                  </Radio>
                </Radio.Group>
              </div>
            </Form.Item>
            {isLocation === 'geofence' && (
              <>
                <p className="title_deals" style={{ marginBottom: '2px' }}>
                  <FormattedMessage id="Restaurant" />
                </p>
                {checkField(restaurants) && <span className="error_msg">Elige algunos restaurantes</span>}
                <div className="checkbox-container">
                  {isLoading ? (
                    <div style={{ position: 'relative' }}>
                      <Loading visible={true} style={{ background: 'white', height: '200px' }} />
                    </div>
                  ) : (
                    <>
                      {restaurants &&
                        restaurants.map((restaurant, index) => (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: '10px',
                            }}
                          >
                            <Checkbox
                              indeterminate={restaurant.state}
                              checked={restaurant.state}
                              onChange={e => handleCheckboxChange(restaurant.id, e.target.checked)}
                            />
                            <p
                              className="location_checkbox_name"
                              style={{ marginLeft: '12px', marginBottom: '0', marginTop: '0' }}
                            >
                              {restaurant.name}
                              <span className="location_checkbox_id">{` ID ${restaurant.id}`}</span>
                            </p>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </>
            )}
            {/* <div className="upload-btn">
              <Button onClick={() => setShowCatDetailModal(true)}>
                <Edit />
                <span>
                  <FormattedMessage id="Edit" />
                </span>
              </Button>
            </div> */}
          </div>
        </div>
      </div>
      {/* <LocationTableModal isOpenModal={isShowCatDetailModal} onCloseModal={() => setShowCatDetailModal(false)} /> */}
    </>
  );
};

export default Location;
