import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import ModalDlg from 'components/Basic/ModalDlg';
import { useLazyGetSectorStatisticsQuery } from 'core/distribution/DistributionService';

function DistributionAnalyticsModal({ intl, data, isOpenModal, state, onCloseModal }) {
  const [statistics, setStatistics] = useState({});
  const STATES = {
    H: intl.formatMessage({ id: 'High demand' }),
    M: intl.formatMessage({ id: 'Average demand' }),
    L: intl.formatMessage({ id: 'Low demand' }),
  };

  const [getSectorStatistics] = useLazyGetSectorStatisticsQuery();

  useEffect(async () => {
    if (data.id) {
      try {
        const res = await getSectorStatistics({ id: data.id }).unwrap();
        setStatistics(res);
      } catch (e) {
        setStatistics({});
      }
    }
  }, [data]);

  return (
    <ModalDlg
      className="distribution_analytics_modal"
      visible={isOpenModal}
      width={670}
      handleCancel={onCloseModal}
      isCenter
    >
      <div className="modal_header">
        <div className={`flex align-center sector_status sector_${state}`}>{STATES[state]}</div>
        <h3>
          <FormattedMessage id="Real-time statistics of the sector" />: {data.name || ''}
        </h3>
        <p className="total_count">
          <FormattedMessage id="Total ambassadors in the sector now" />: <string>{statistics.ambassadors || 0}</string>
        </p>
      </div>
      <div className="modal_content">
        <div className="flex align-center row1">
          <div className="flex flex-column just-center production-card card">
            <p className="value">{statistics.inProduction || 0}</p>
            <p className="label">
              <FormattedMessage id="In production" />
            </p>
          </div>
          <div className="flex flex-column just-center queue-card card">
            <p className="value">{statistics.queueTime || 0} min.</p>
            <p className="label">
              <FormattedMessage id="Queue time" />
            </p>
          </div>
        </div>
        <div className="flex align-center row2">
          <div className="flex flex-column just-center slope-card card">
            <p className="value">{statistics.received || 0}</p>
            <p className="label">
              <FormattedMessage id="Slopes" />
            </p>
          </div>
          <div
            className={`flex flex-column just-center service-card card ${
              statistics.serviceTime < 30 ? 'green-card' : statistics.serviceTime <= 45 ? 'yellow-card' : 'red-card'
            }`}
          >
            <p className="value">{statistics.serviceTime || 0} min.</p>
            <p className="label">
              <FormattedMessage id="Service time" />
            </p>
          </div>
        </div>
      </div>
      <div className="modal_footer">
        <div className="flex align-center table-header">
          <div className="restaurant header">
            <FormattedMessage id="Restaurant" />
          </div>
          <div className="received header center">
            <FormattedMessage id="Received" />
          </div>
          <div className="on_route header center">
            <FormattedMessage id="On Route" />
          </div>
          <div className="delivered header center">
            <FormattedMessage id="Delivered" />
          </div>
          <div className="ambassadors_on_route header center">
            <FormattedMessage id="Ambassadors on route" />
          </div>
          <div className="solving header center">
            <FormattedMessage id="Solving" />
          </div>
        </div>
        <div className="table-content">
          {(statistics.statistics || []).map((s, index) => (
            <div className="flex align-center table-item" key={index}>
              <div className="restaurant">
                <strong>{s.description || ''}</strong>
              </div>
              <div className="received center">{s.received || 0}</div>
              <div className="on_route center">{s.onRoute || 0}</div>
              <div className="delivered center">{s.delivered || 0}</div>
              <div className="ambassadors_on_route center">{s.ambassadorsOnRoute || 0}</div>
              <div className="solving center">{s.solving || 0}</div>
            </div>
          ))}
        </div>
      </div>
    </ModalDlg>
  );
}

DistributionAnalyticsModal.defaultProps = {
  data: {},
  isOpenModal: false,
  state: '',
};

export default compose(injectIntl)(DistributionAnalyticsModal);
