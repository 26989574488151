import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, TabsListData } from './types';

const initialState: initState = {
  list: {
    tabs: [],
    totalCount: 0,
  },
};

const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setTabsListData(state: initState, action: PayloadAction<{ list: TabsListData }>) {
      state.list = action.payload.list;
    },
  },
});

export const { setTabsListData } = tabsSlice.actions;

export default tabsSlice.reducer;
