import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Select, Button, DatePicker } from 'antd';
import { DateRange, Refresh, ArrowDropDown } from '@material-ui/icons';
import { useLazyGetRestaurantListQuery } from 'core/restaurant/RestaurantService';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { setRestaurantList } from 'core/restaurant/RestaurantSlice';

const { Option } = Select;

function OrderFilter({
  intl,
  mode,
  onStartFilterChange,
  onEndFilterChange,
  onStateFilter,
  onSort,
  onActiveRestaurant,
  onApplyFilter,
}) {
  const dispatch = useAppDispatch();
  const [getRestaurantList] = useLazyGetRestaurantListQuery();

  const { admin } = useAppSelector(state => state.loginReducer);
  const { list: restaurantList } = useAppSelector(state => state.restaurantReducer);

  const orderStates = [
    { value: 'WP', label: intl.formatMessage({ id: 'WP' }) },
    { value: 'RV', label: intl.formatMessage({ id: 'RV' }) },
    { value: 'IP', label: intl.formatMessage({ id: 'IP' }) },
    { value: 'AS', label: intl.formatMessage({ id: 'AS' }) },
    { value: 'OW', label: intl.formatMessage({ id: 'OW' }) },
    { value: 'AD', label: intl.formatMessage({ id: 'AD' }) },
    { value: 'CL', label: intl.formatMessage({ id: 'CL' }) },
    { value: 'RS', label: intl.formatMessage({ id: 'RS' }) },
    { value: 'DD', label: intl.formatMessage({ id: 'DD' }) },
    { value: 'CD', label: intl.formatMessage({ id: 'CD' }) },
  ];

  const orderSorts = [
    { value: 'state desc nulls last', label: intl.formatMessage({ id: 'By state' }) },
    {
      value: 'order desc nulls last',
      label: intl.formatMessage({ id: 'By order number' }),
    },
    {
      value: 'totalQuantity desc nulls last',
      label: intl.formatMessage({ id: 'By quantity of items' }),
    },
    {
      value: 'dueTo desc nulls last',
      label: intl.formatMessage({ id: 'By date and time of delivery' }),
    },
    {
      value: 'purchaseAmount desc nulls last',
      label: intl.formatMessage({ id: 'By total' }),
    },
    {
      value: 'paymentType desc nulls last',
      label: intl.formatMessage({ id: 'By type of payment method' }),
    },
  ];

  useEffect(async () => {
    if (admin.restaurantAccess) {
      try {
        const res = await getRestaurantList({ filter: '&filter=state eq A' }).unwrap();
        dispatch(
          setRestaurantList({
            list: res,
          }),
        );
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  const onStartDateChange = (date, dateString) => {
    onStartFilterChange(dateString);
  };

  const onEndDateChange = (date, dateString) => {
    onEndFilterChange(dateString);
  };

  const handleStateFilter = values => {
    onStateFilter(values);
  };

  const handleSort = value => {
    onSort(value);
  };

  const handleRestaurantFilter = value => {
    onActiveRestaurant(value);
  };

  return (
    <div className="order_filter_layout">
      {mode === 'table' && (
        <Select
          className="active_states"
          suffixIcon={<ArrowDropDown />}
          mode="multiple"
          placeholder="estados activos"
          onChange={handleStateFilter}
          dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
        >
          {orderStates.map(item => (
            <Option value={item.value} key={item.label} style={{ fontFamily: 'Lato', color: '#242c40' }}>
              {item.label}
            </Option>
          ))}
        </Select>
      )}
      {mode === 'table' && admin.restaurantAccess && (
        <Select
          className="all_restaurants"
          suffixIcon={<ArrowDropDown />}
          mode="multiple"
          onChange={v => handleRestaurantFilter(v)}
          placeholder="Todos los restaurantes"
          dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
        >
          {restaurantList.rows &&
            restaurantList.rows.map(s => (
              <Option value={s.id} key={s.id} style={{ fontFamily: 'Lato', color: '#242c40' }}>
                {s.description || ''}
              </Option>
            ))}
        </Select>
      )}
      {mode === 'table' && (
        <DatePicker
          className="start_date"
          placeholder="Fecha de inicio"
          onChange={onStartDateChange}
          suffixIcon={<DateRange />}
        />
      )}
      {mode === 'table' && (
        <DatePicker
          className="end_date"
          placeholder="Fecha límite"
          onChange={onEndDateChange}
          suffixIcon={<DateRange />}
        />
      )}
      {(mode === 'table' || mode === 'modal') && (
        <Select
          className="sort_by"
          suffixIcon={<ArrowDropDown />}
          placeholder="Ordernar por"
          onChange={handleSort}
          dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
        >
          {orderSorts.map(item => (
            <Option value={item.value} key={item.label} style={{ fontFamily: 'Lato', color: '#242c40' }}>
              {item.label}
            </Option>
          ))}
        </Select>
      )}
      <Button className="apply_filters_btn" onClick={onApplyFilter}>
        <FormattedMessage id="Apply filters" />
        <Refresh />
      </Button>
    </div>
  );
}

OrderFilter.defaultProps = {
  mode: 'table',
};

export default compose(injectIntl)(OrderFilter);
