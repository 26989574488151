import { api } from '..';
import { SegmentsListData, CsvInfoType, CalculateInfoType } from './types';

export const segmentsApi = api.enhanceEndpoints({ addTagTypes: ['Segments'] }).injectEndpoints({
  endpoints: build => ({
    getSegmentsList: build.query<SegmentsListData, any>({
      query: ({ queryString }) => ({
        url: `v1/deals/segments/preview?${queryString}`,
        method: 'GET',
      }),
    }),
    createSegmnets: build.mutation<any, any>({
      query: body => ({
        url: `v1/deals/segments`,
        method: 'POST',
        body,
      }),
    }),
    updateSegments: build.mutation<any, any>({
      query: body => ({
        url: `v1/deals/segments/${body.id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteSegments: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `v1/deals/segments/${id}`,
        method: 'DELETE',
      }),
    }),
    csvSegments: build.mutation<CsvInfoType, any>({
      query: body => ({
        url: `v1/deals/segments/validate-customers`,
        method: 'POST',
        body,
      }),
    }),
    calculateSegments: build.mutation<CalculateInfoType, any>({
      query: body => ({
        url: `v1/deals/segments/calculate`,
        method: 'POST',
        body,
      }),
    }),
    downloadCustomers: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `v1/deals/segments/${id}/download-customers`,
        method: 'GET',
        responseHandler: async response => {
          const blob = new Blob([await response.blob()], { type: 'text/csv' });

          const url = document.createElement('a');
          url.href = window.URL.createObjectURL(blob);
          url.setAttribute('download', 'customers.csv');
          url.click();
        },
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useLazyGetSegmentsListQuery,
  useCreateSegmnetsMutation,
  useUpdateSegmentsMutation,
  useDeleteSegmentsMutation,
  useCsvSegmentsMutation,
  useCalculateSegmentsMutation,
  useLazyDownloadCustomersQuery,
} = segmentsApi;
