import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { compose } from 'react-recompose';
import { Img } from 'react-image';
import { Spin } from 'antd';
import Icon from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { FormattedMessage, useIntl } from 'react-intl';
import logoImg from 'assets/img/logo.svg';
import { getCountry } from 'utilities/common';
import { useAppSelector, useAppDispatch } from 'utilities/redux';
import { useLazyGetProfileQuery } from 'core/auth/LoginService';
import { setAdminData } from 'core/auth/LoginSlice';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const Global = ({ form }) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [profile] = useLazyGetProfileQuery();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const allParams = {};
    query.forEach((value, key) => {
      allParams[key] = value;
    });
    if (allParams?.access_token) {
      dispatch(
        setAdminData({
          admin: { ...{ accessToken: allParams?.access_token } },
        }),
      );
      login(allParams?.access_token);
    }
  }, [location]);

  const login = async token => {
    try {
      const res = await profile().unwrap();
      dispatch(
        setAdminData({
          admin: { ...res, accessToken: token },
        }),
      );
      history.push('/dashboard/overview');
    } catch (error) {
      history.push('/login');
      console.log(error);
    }
  };

  return (
    <div className="login_layout">
      <Spin indicator={antIcon} spinning={isLoading}>
        <div className="left_wrapper">
          <div className="logo-wrapper">
            <Img src={logoImg} alt="logo" />
          </div>
          <div className="login_wrapper">
            <p className="title">
              <FormattedMessage id="Administration system" />
            </p>
            <p className="description">
              {getCountry()} / <FormattedMessage id="Backend" />
            </p>
          </div>
          <div className="footer">
            <p className="copy_right">
              <FormattedMessage id="McDonald’s © {year}" values={{ year: new Date().getFullYear() }} />
            </p>
          </div>
        </div>
      </Spin>
    </div>
  );
};

Global.defaultProps = {
  form: {},
};

export default compose(Form.create({ name: 'login_form' }))(Global);
