import React, { PureComponent } from 'react';

class Notificationes extends PureComponent {
  render() {
    return (
      <div className="notificationes_layout">
        <embed src="https://eu1.dashboard.clevertap.com/R55-976-875Z/message-report.html" />
      </div>
    );
  }
}

export default Notificationes;
