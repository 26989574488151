import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { Button, Divider } from 'antd';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import ModalDlg from 'components/Basic/ModalDlg';
import boxImg from 'assets/img/box.svg';
import ConfirmCancelOrder from 'components/Basic/ConfirmCancelOrder';

function OrderStatusModal({ intl, orderDetail, isOpenModal, onCloseModal, onSendSAR, onCancelOrder }) {
  const [isConfirmModalOpen, setConfimModalOpen] = useState(false);

  const orderStates = {
    WP: intl.formatMessage({ id: 'WP' }),
    RV: intl.formatMessage({ id: 'RV' }),
    IP: intl.formatMessage({ id: 'IP' }),
    AS: intl.formatMessage({ id: 'AS' }),
    OW: intl.formatMessage({ id: 'OW' }),
    AD: intl.formatMessage({ id: 'AD' }),
    CL: intl.formatMessage({ id: 'CL' }),
    RS: intl.formatMessage({ id: 'RS' }),
    DD: intl.formatMessage({ id: 'DD' }),
    CD: intl.formatMessage({ id: 'CD' }),
    ERR: intl.formatMessage({ id: 'ERR' }),
  };
  if (!orderDetail) {
    return null;
  }

  const onCloseConfirmModal = () => {
    setConfimModalOpen(false);
  };

  const handleSendToSar = () => {
    onSendSAR(orderDetail.id);
  };

  const handleCancelOrder = () => {
    onCancelOrder(orderDetail.id);
    setConfimModalOpen(false);
  };

  return (
    <ModalDlg
      className="order_status_modal"
      visible={isOpenModal}
      width={750}
      top={60}
      handleCancel={() => onCloseModal()}
    >
      {orderDetail && (
        <>
          <div className="detail_content">
            <div className="detail_header">
              <p>
                <FormattedMessage id="Order" /> #{orderDetail.order || orderDetail.id}
              </p>
              <Button className={`${orderDetail.state}`}>{orderStates[orderDetail.state]}</Button>
            </div>
            <div className="buttons_container">
              <div className="send_sar_btn">
                <Button onClick={handleSendToSar} disabled={orderDetail.state !== 'RV' && orderDetail.state !== 'ERR'}>
                  <FormattedMessage id="Send to SAR" />
                  <img src={boxImg} alt="box" />
                </Button>
              </div>
              <div className="cancel_order_button">
                <Button onClick={() => setConfimModalOpen(true)} disabled={orderDetail.state !== 'RV'}>
                  <FormattedMessage id="Cancel order" />
                </Button>
              </div>
            </div>
            <div className="sar_json_list">
              {orderDetail && orderDetail.sarOrderDetail && orderDetail.sarOrderDetail.request && (
                <div className="sar_list_item">
                  <Divider orientation="left">
                    <FormattedMessage id="SAR System Details - Request" />
                  </Divider>
                  <div className="json-format">
                    <div className="scrollbar">{JSON.stringify(orderDetail.sarOrderDetail.request, undefined, 4)}</div>
                  </div>
                </div>
              )}
              {orderDetail.sarOrderDetail && orderDetail.sarOrderDetail.response && (
                <div className="sar_list_item">
                  <Divider orientation="left">
                    <FormattedMessage id="SAR System Details - Response" />
                  </Divider>
                  <div className="json-format">
                    <div className="scrollbar">{JSON.stringify(orderDetail.sarOrderDetail.response, undefined, 4)}</div>
                  </div>
                </div>
              )}
              {orderDetail.paymentDetail && orderDetail.paymentDetail.requestMessage && (
                <div className="sar_list_item">
                  <Divider orientation="left">
                    <FormattedMessage id="CC Payment Details - Request" />
                  </Divider>
                  <div className="json-format">
                    <div className="scrollbar">
                      {JSON.stringify(orderDetail.paymentDetail.requestMessage, undefined, 4)}
                    </div>
                  </div>
                </div>
              )}
              {orderDetail.paymentDetail && orderDetail.paymentDetail.result && (
                <div className="sar_list_item">
                  <Divider orientation="left">
                    <FormattedMessage id="CC Payment Details - Response" />
                  </Divider>
                  <div className="json-format">
                    <div className="scrollbar">{JSON.stringify(orderDetail.paymentDetail.result, undefined, 4)}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <ConfirmCancelOrder
            isOpenModal={isConfirmModalOpen}
            onCloseModal={onCloseConfirmModal}
            onConfirm={handleCancelOrder}
          />
        </>
      )}
    </ModalDlg>
  );
}

OrderStatusModal.defaultProps = {
  isOpenModal: false,
  orderDetail: null,
};

export default compose(injectIntl)(OrderStatusModal);
