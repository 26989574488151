import { api } from '..';
import { DepartmentsData, MunicipalitiesData, ZonesData } from './types';

export const regionApi = api.enhanceEndpoints({ addTagTypes: ['Region'] }).injectEndpoints({
  endpoints: build => ({
    getDepartments: build.query<
      DepartmentsData,
      { offset: number; limit: number; or: boolean; filter: string; sort: string }
    >({
      query: ({ offset, limit, or, filter, sort }) => ({
        url: `v0/region/departments?offset=${offset || 0}&limit=${limit || 5}${or ? `&or=${or}` : ''}${filter || ''}${
          sort ? `&sort=${sort}` : '&sort=createdAt desc'
        }`,
        method: 'GET',
      }),
    }),
    сreateDepartment: build.mutation<void, any>({
      query: body => ({
        url: `v0/region/departments`,
        method: 'POST',
        body,
      }),
    }),
    deleteDepartment: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `v0/region/departments/${id}`,
        method: 'DELETE',
      }),
    }),
    getMunicipalities: build.query<
      MunicipalitiesData,
      { offset: number; limit: number; or: boolean; filter: string; sort: string }
    >({
      query: ({ offset, limit, or, filter, sort }) => ({
        url: `v0/region/municipalities?offset=${offset || 0}&limit=${limit || 5}${or ? `&or=${or}` : ''}${
          filter || ''
        }${sort ? `&sort=${sort}` : '&sort=createdAt desc'}`,
        method: 'GET',
      }),
    }),
    сreateMunicipality: build.mutation<void, any>({
      query: body => ({
        url: `v0/region/municipalities`,
        method: 'POST',
        body,
      }),
    }),
    deleteMunicipality: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `v0/region/municipalities/${id}`,
        method: 'DELETE',
      }),
    }),
    getZones: build.query<ZonesData, { offset: number; limit: number; or: boolean; filter: string; sort: string }>({
      query: ({ offset, limit, or, filter, sort }) => ({
        url: `v0/region/zones?offset=${offset || 0}&limit=${limit || 5}${or ? `&or=${or}` : ''}${filter || ''}${
          sort ? `&sort=${sort}` : '&sort=createdAt desc'
        }`,
        method: 'GET',
      }),
    }),
    createZone: build.mutation<void, any>({
      query: body => ({
        url: `v0/region/zones`,
        method: 'POST',
        body,
      }),
    }),
    deleteZone: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `v0/region/zones/${id}`,
        method: 'DELETE',
      }),
    }),
    assignRestaurants: build.mutation<void, any>({
      query: body => ({
        url: `v0/region/zones/${body.id}/restaurants`,
        method: 'PUT',
        body: body.restaurants,
      }),
    }),
    getRestaurantsFromZone: build.query<ZonesData, { id: number }>({
      query: ({ id }) => ({
        url: `v0/region/zones/${id}/restaurants?details=true`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyGetDepartmentsQuery,
  useСreateDepartmentMutation,
  useDeleteDepartmentMutation,
  useLazyGetMunicipalitiesQuery,
  useСreateMunicipalityMutation,
  useDeleteMunicipalityMutation,
  useLazyGetZonesQuery,
  useCreateZoneMutation,
  useDeleteZoneMutation,
  useAssignRestaurantsMutation,
  useLazyGetRestaurantsFromZoneQuery,
} = regionApi;
