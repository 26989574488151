import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Button, Select } from 'antd';
import { Refresh, Search, ArrowDropDown } from '@material-ui/icons';

type CategoriesFilterType = {
  searchText: string;
  handleSearch: (e: any) => void;
  onSort: (e: string) => void;
  onApplyFilter: () => void;
};

const { Option } = Select;

const CategoriesFilter: React.FC<CategoriesFilterType> = ({ searchText, handleSearch, onSort, onApplyFilter }) => {
  const intl = useIntl();
  const agentSorts = [
    { value: 'Idesc', label: intl.formatMessage({ id: 'ID (desc)' }) },
    { value: 'Iasc', label: intl.formatMessage({ id: 'ID (asc)' }) },
    { value: 'Nasc', label: intl.formatMessage({ id: 'Category (A-Z)' }) },
    { value: 'Ndesc', label: intl.formatMessage({ id: 'Category (Z-A)' }) },
  ];

  const handleSort = (value: string) => {
    onSort(value);
  };

  const sortOptions = agentSorts.map(item => {
    let isDisabled = false;

    return (
      <Option
        value={item.value}
        key={item.label}
        disabled={isDisabled}
        style={{ fontFamily: 'Lato', color: '#242c40' }}
      >
        {item.label}
      </Option>
    );
  });

  return (
    <div className="categories_filter_layout" id="categories_filter_layout">
      <Select
        className="sort_by"
        /* value={option} */
        suffixIcon={<ArrowDropDown />}
        placeholder={intl.formatMessage({ id: 'Sort by' })}
        onChange={handleSort}
        id="categories_name_filter"
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {sortOptions}
      </Select>
      <Button className="apply_filters_btn" onClick={onApplyFilter} id="categories_apply_filters_btn">
        <FormattedMessage id="Apply filters" />
        <Refresh />
      </Button>
      <Input
        suffix={<Search />}
        placeholder={intl.formatMessage({ id: 'Search the category' })}
        value={searchText}
        maxLength={200}
        onChange={e => handleSearch(e)}
        onKeyDown={e => handleSearch(e)}
        id="categories_search_input"
      />
    </div>
  );
};

export default CategoriesFilter;
