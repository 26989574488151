import { api } from '..';
import { PromotionData } from './types';

export const promotionApi = api.enhanceEndpoints({ addTagTypes: ['Promotion'] }).injectEndpoints({
  endpoints: build => ({
    getPromotionList: build.query<
      PromotionData,
      { offset: number; limit: number; or: boolean; filter: string; sort: string }
    >({
      query: ({ offset, limit, or, filter, sort }) => ({
        url: `v0/advertising/promotions?offset=${offset || 0}&limit=${limit || 5}${or ? `&or=${or}` : ''}${
          filter || ''
        }${sort ? `&sort=${sort}` : '&sort=createdAt desc'}`,
        method: 'GET',
      }),
    }),
    getPromotion: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `v0/advertising/promotions/${id}`,
        method: 'GET',
      }),
    }),
    createPromotion: build.mutation<any, any>({
      query: body => ({
        url: `v0/advertising/promotions`,
        method: 'POST',
        body: body.formData,
      }),
    }),
    updatePromotion: build.mutation<any, any>({
      query: body => ({
        url: `v0/advertising/promotions/${body.id}`,
        method: 'PUT',
        body: body.formData,
      }),
    }),
    deletePromotion: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `v0/advertising/promotions/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetPromotionListQuery,
  useLazyGetPromotionQuery,
  useCreatePromotionMutation,
  useUpdatePromotionMutation,
  useDeletePromotionMutation,
} = promotionApi;
