import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import GlobalAreasMap from 'components/Basic/GlobalAreasMap';
import Loading from 'components/Basic/Loading';
import { getCountry } from 'utilities/common';
import { useLazyGetDeliveryAreasQuery, useLazyGetRestaurantListQuery } from 'core/restaurant/RestaurantService';

const DeliveryZones = () => {
  const [state, setState] = useState({
    restaurants: [],
    deliveryAreas: [],
    isLoading: false,
  });

  const [getDeliveryAreas] = useLazyGetDeliveryAreasQuery();
  const [getRestaurantList] = useLazyGetRestaurantListQuery();

  useEffect(async () => {
    setState(prev => ({ ...prev, isLoading: true }));
    try {
      const res = await getDeliveryAreas({}).unwrap();
      setState(prev => ({ ...prev, deliveryAreas: res }));
    } catch (err) {
      console.log(err);
      setState(prev => ({ ...prev, isLoading: false }));
    }
    try {
      const res = await getRestaurantList({}).unwrap();
      setState(prev => ({ ...prev, deliveryAreas: res.rows, isLoading: false }));
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div className="deliveryzones_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader type="deliveryzones" />
      <div className="description_wrapper">
        <div>
          <p className="title">
            <FormattedMessage id="Parameterized delivery areas" />
          </p>
          <p className="subtitle">
            <FormattedMessage id="delivery zones areas" />
          </p>
        </div>
        <div>
          <p>
            <FormattedMessage id="Geocoding country" />
          </p>
          <p>{getCountry()}</p>
        </div>
      </div>
      <div className="global_areas_map">
        <GlobalAreasMap deliveryAreas={state.deliveryAreas} restaurants={state.restaurants} />
      </div>
    </div>
  );
};

export default DeliveryZones;
