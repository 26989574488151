export const generateExtendedData = (state: any) => {
  const type: string = state.selectedTemplates;

  const defaultFields = {
    nameEn: state.promoNameEn,
    nameGt: state.promoName,
    shortDescriptionEn: state.enShortDesc,
    shortDescriptionGt: state.gtShortDesc,
    longDescriptionEn: state.enLongDesc,
    longDescriptionGt: state.gtLongDesc,
  };

  const productOne = {
    productCode: state.gtProductOne,
    quantity: state.gtQuantityOne,
    price: Number(state.gtPriceOne),
    discountLimit: Number(state.gtDiscountLimitOne),
  };

  const productTwo = {
    productCode: state.gtProductTwo,
    quantity: state.gtQuantityTwo,
    price: Number(state.gtPriceTwo),
    discountLimit: Number(state.gtDiscountLimitTwo),
  };

  const productThree = {
    productCode: state.gtProductThree,
    quantity: state.gtQuantityThree,
    price: Number(state.gtPriceThree),
    discountLimit: Number(state.gtDiscountLimitThree),
  };

  const productFour = {
    productCode: state.gtProductFour,
    quantity: state.gtQuantityFour,
    price: Number(state.gtPriceFour),
    discountLimit: Number(state.gtDiscountLimitFour),
  };

  switch (type) {
    case 'price_deal_two_gt':
      return {
        priceDealTwoGt: {
          ...defaultFields,
          productOne,
          productTwo,
        },
      };
    case 'price_deal_three_gt':
      return {
        priceDealThreeGt: {
          ...defaultFields,
          productOne,
          productTwo,
          productThree,
        },
      };
    case 'price_deal_four_gt':
      return {
        priceDealFourGt: {
          ...defaultFields,
          productOne,
          productTwo,
          productThree,
          productFour,
        },
      };
    case 'discount_off_product_gt':
      /* 
        discountAmount has default value = 0;             
        discountAmount: Number(state.gtDiscountAmount)
      */
      return {
        discountOffProductGt: {
          ...defaultFields,
          productCode: state.gtProductCode,
          price: Number(state.gtPriceOne),
        },
      };
    case 'discount_off_total_order_gt':
      return {
        discountOffTotalOrderGt: {
          ...defaultFields,
          discountAmount: Number(state.gtDiscountAmount),
        },
      };
    case 'buy_one_get_two_gt':
      return {
        buyOneGetTwoGt: {
          ...defaultFields,
          productCode: state.gtProductCode,
          quantity: state.gtReducedQuantity,
          productOne: {
            productCode: state.gtProductOne,
            price: Number(state.gtPriceOne),
            discountLimit: Number(state.gtDiscountLimitOne),
          },
          productTwo: {
            productCode: state.gtProductTwo,
            price: Number(state.gtPriceTwo),
            discountLimit: Number(state.gtDiscountLimitTwo),
          },
        },
      };
    default:
      return {};
  }
};
