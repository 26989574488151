import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import AmbassadorFilter from 'components/Dashboard/Ambassadors/AmbassadorFilter';
import AmbassadorTable from 'components/Dashboard/Ambassadors/AmbassadorTable';
import SyncModal from 'components/Dashboard/Ambassadors/SyncModal';
import SyncConfirmModal from 'components/Basic/SyncConfirmModal';
import Loading from 'components/Basic/Loading';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import {
  useLazyGetAmbassadorListQuery,
  useLazySyncedAmbassadorQuery,
  useLazySyncedScheduleAmbassadorQuery,
} from 'core/ambassador/AmbassadorService';
import {
  setAmbassadorData,
  setSyncedAmbassadorData,
  setSyncedScheduleAmbassadorData,
} from 'core/ambassador/AmbassadorSlice';

const AmbassadorList = () => {
  const timeInterval = useRef(null);
  const timeSync = useRef(null);
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [state, setState] = useState({
    isShowSyncModal: false,
    isConfirmModal: false,
    confirmTitle: '',
    confirmDescription: '',
    importantDescription: '',
    title: '',
    description: '',
    isSync: false,
    isSyncError: false,
    syncType: '',
    percent: 0,
    searchText: '',
    offset: 0,
    limit: 10,
    filterQuery: '',
    stateFilter: '',
    typeFilter: '',
    restaurantFilter: '',
    searchFilterQuery: '',
    sort: '',
    isLoading: false,
  });

  const [getAmbassadorList] = useLazyGetAmbassadorListQuery();
  const [syncAmbassador] = useLazySyncedAmbassadorQuery();
  const [syncScheduleAmbassador] = useLazySyncedScheduleAmbassadorQuery();

  const { list } = useAppSelector(state => state.ambassadorReducer);

  useEffect(() => {
    dispatch(setAmbassadorData({ list: [] }));
    return () => {
      clearInterval(timeInterval.current);
      clearInterval(timeSync.current);
    };
  }, []);

  const handleChangePage = async (offset, limit, filterQuery, sort) => {
    setState(prev => ({ ...prev, offset: offset, limit: limit, isLoading: true }));
    clearInterval(timeInterval.current);
    try {
      const res = await getAmbassadorList({
        offset,
        limit,
        or: false,
        filter: filterQuery || state.filterQuery + state.searchFilterQuery,
        sort: sort || state.sort,
      }).unwrap();
      dispatch(setAmbassadorData({ list: res }));
      setState(prev => ({ ...prev, isLoading: false }));
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
    timeInterval.current = setInterval(async () => {
      try {
        const res = await getAmbassadorList({
          offset,
          limit,
          or: false,
          filter: filterQuery || state.filterQuery + state.searchFilterQuery,
          sort: sort || state.sort,
        }).unwrap();
        dispatch(setAmbassadorData({ list: res }));
        setState(prev => ({ ...prev, isLoading: false }));
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    }, 10 * 1000);
  };

  const handleStateFilter = values => {
    if (values.length !== 0) {
      let filterQuery = '&filter=state in';
      values.map(v => {
        filterQuery += ` ${v}`;
        return filterQuery;
      });
      setState(prev => ({ ...prev, stateFilter: filterQuery }));
    } else {
      setState(prev => ({ ...prev, stateFilter: '' }));
    }
  };

  const handleTypeFilter = value => {
    if (value !== 'ALL') {
      setState(prev => ({ ...prev, typeFilter: `&filter=type eq ${value}` }));
    } else {
      setState(prev => ({ ...prev, typeFilter: '' }));
    }
  };

  const handleRestaurantFilter = values => {
    if (values.length !== 0) {
      let filterQuery = '&filter=restaurant in';
      values.map(v => {
        filterQuery += ` ${v}`;
        return filterQuery;
      });
      setState(prev => ({ ...prev, restaurantFilter: filterQuery }));
    } else {
      setState(prev => ({ ...prev, restaurantFilter: '' }));
    }
  };

  const handleSort = value => {
    setState(prev => ({ ...prev, sort: value }));
  };

  const handleSearch = e => {
    setState(prev => ({ ...prev, searchText: e.target.value }));
  };

  const handleKeyDownSearch = async e => {
    let searchFilterQuery = '';
    if (e.keyCode === 13) {
      const { offset, limit, filterQuery, sort, searchText } = state;
      if (searchText) {
        if (!isNaN(searchText, 10) && searchText < 100000000) {
          searchFilterQuery += `&filter=or.id eq ${searchText}`;
        }
        searchFilterQuery += `&filter=or.fullName iLike %25${searchText}%25&filter=or.eid iLike %25${searchText}%25&filter=or.phone iLike %25${searchText}%25&filter=or.email iLike %25${searchText}%25`;
      } else {
        searchFilterQuery = '';
      }
      setState(prev => ({ ...prev, searchFilterQuery: searchFilterQuery, isLoading: true }));
      clearInterval(timeInterval.current);
      try {
        const res = await getAmbassadorList({
          offset: 0,
          limit: 10,
          or: false,
          filter: filterQuery + searchFilterQuery,
          sort: sort,
        }).unwrap();
        dispatch(setAmbassadorData({ list: res }));
        setState(prev => ({ ...prev, isLoading: false }));
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
      }
      timeInterval.current = setInterval(async () => {
        try {
          const res = await getAmbassadorList({
            offset: 0,
            limit: 10,
            or: false,
            filter: filterQuery + searchFilterQuery,
            sort: sort,
          }).unwrap();
          dispatch(setAmbassadorData({ list: res }));
          setState(prev => ({ ...prev, isLoading: false }));
        } catch (e) {
          setState(prev => ({ ...prev, isLoading: false }));
        }
      }, 10 * 1000);
    }
  };

  const handleApplyFilter = async () => {
    const { stateFilter, typeFilter, restaurantFilter, sort, searchFilterQuery } = state;
    setState(prev => ({ ...prev, filterQuery: stateFilter + typeFilter + restaurantFilter, isLoading: true }));
    clearInterval(timeInterval.current);
    try {
      const res = await getAmbassadorList({
        offset: 0,
        limit: 10,
        or: false,
        filter: stateFilter + typeFilter + restaurantFilter + searchFilterQuery,
        sort: sort,
      }).unwrap();
      dispatch(setAmbassadorData({ list: res }));
      setState(prev => ({ ...prev, isLoading: false }));
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
    timeInterval.current = setInterval(async () => {
      try {
        const res = await getAmbassadorList({
          offset: 0,
          limit: 10,
          or: false,
          filter: stateFilter + typeFilter + restaurantFilter + searchFilterQuery,
          sort: sort,
        }).unwrap();
        dispatch(setAmbassadorData({ list: res }));
        setState(prev => ({ ...prev, isLoading: false }));
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    }, 10 * 1000);
  };

  const handleSync = type => {
    setState(prev => ({ ...prev, syncType: type, isConfirmModal: true }));
    if (type === 'ambassador') {
      setState(prev => ({
        ...prev,
        confirmTitle: intl.formatMessage({
          id: 'Do you want to synchronize the ambassadors?',
        }),
        confirmDescription: intl.formatMessage({
          id: 'This action will import the latest ambassador settings, as well as update the availability status of each one.',
        }),
        importantDescription: '',
      }));
    } else {
      setState(prev => ({
        ...prev,
        confirmTitle: intl.formatMessage({
          id: 'Do you want to synchronize to the schedules?',
        }),
        confirmDescription: intl.formatMessage({
          id: 'This action will import the latest ambassador schedule adjustments into the system, taking as a reference the Monday of the current week.',
        }),
        importantDescription: intl.formatMessage({
          id: 'Important: This will replace any manual adjustments that have been made this week.',
        }),
      }));
    }
  };

  const handleConfirmSync = () => {
    const { syncType } = state;
    setState(prev => ({ ...prev, isConfirmModal: false }));
    if (syncType === 'ambassador') {
      handleSyncAmbassador();
    } else {
      handleSyncSchedule();
    }
  };

  const handleSyncAmbassador = async () => {
    setState(prev => ({
      ...prev,
      isSync: true,
      isShowSyncModal: true,
      isSyncError: false,
      title: 'Syncing...',
      percent: 0,
      description: '',
    }));
    timeSync.current = await setInterval(() => {
      if (state.percent < 95) {
        setState(prev => ({ ...prev, percent: prev.percent + 1 }));
      }
    }, 500);
    try {
      const res = await syncAmbassador({}).unwrap();
      dispatch(setSyncedAmbassadorData({ synced: res }));
      setState(prev => ({
        ...prev,
        isSync: true,
        isShowSyncModal: true,
        isSyncError: false,
        percent: 100,
        title: 'Actualización de embajadores con éxito',
        description: `Total de embajadores sincronizados: ${
          parseInt(res.employee.success) + parseInt(res.floatingDriver.success)
        }.`,
      }));
      clearInterval(timeSync.current);
    } catch (e) {
      setState(prev => ({
        ...prev,
        isSync: true,
        isSyncError: true,
        title: 'Sync is failured!',
        description: '',
      }));
    }
  };

  const handleSyncSchedule = async () => {
    setState(prev => ({
      ...prev,
      isSync: true,
      isShowSyncModal: true,
      isSyncError: false,
      title: 'Syncing...',
      percent: 0,
      description: '',
    }));
    timeSync.current = setInterval(() => {
      if (state.percent < 95) {
        setState(prev => ({ ...prev, percent: prev.percent + 1 }));
      }
    }, 500);
    try {
      const res = await syncScheduleAmbassador({}).unwrap();
      dispatch(setSyncedScheduleAmbassadorData({ syncedSchedule: res }));
      setState(prev => ({
        ...prev,
        isSync: true,
        isShowSyncModal: true,
        isSyncError: false,
        percent: 100,
        title: 'Actualización de horarios con éxito',
        description: `Se han importado ${parseInt(res.success, 10)} configuraciones de horario.`,
      }));
      clearInterval(timeSync.current);
    } catch (e) {
      setState(prev => ({
        ...prev,
        isSync: true,
        isSyncError: true,
        title: 'Sync is failured!',
        description: '',
      }));
    }
  };

  const { confirmTitle, confirmDescription, importantDescription } = state;
  return (
    <div className="ambassador_list_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader
        type="ambassador_list"
        onSyncAmbassador={() => handleSync('ambassador')}
        onSyncSchedule={() => handleSync('schedule')}
      />
      <div className="ambassador_filter_wrapper">
        <AmbassadorFilter
          searchText={state.searchText}
          onStateFilter={handleStateFilter}
          onTypeFilter={handleTypeFilter}
          onRestaurantFilter={handleRestaurantFilter}
          onSort={handleSort}
          onSearch={handleSearch}
          onKeyDownSearch={handleKeyDownSearch}
          onApplyFilter={handleApplyFilter}
        />
        <AmbassadorTable ambassadorData={list.rows || []} onChangePage={handleChangePage} total={list.totalCount} />
      </div>
      <SyncModal
        isOpenModal={state.isShowSyncModal}
        title={state.title}
        description={state.description}
        buttonText="Cerrar"
        isSync={state.isSync}
        isSyncError={state.isSyncError}
        percent={state.percent}
        onCloseModal={() => setState(prev => ({ ...prev, isShowSyncModal: false }))}
      />
      <SyncConfirmModal
        isOpenModal={state.isConfirmModal}
        onCancel={() => setState(prev => ({ ...prev, isConfirmModal: false }))}
        onOk={handleConfirmSync}
      >
        <p className="title">{confirmTitle}</p>
        <p className="description">
          {confirmDescription} <b>{importantDescription}</b>
        </p>
      </SyncConfirmModal>
    </div>
  );
};

export default AmbassadorList;
