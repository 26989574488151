import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select } from 'antd';
import { ArrowDropDown } from '@material-ui/icons';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import ProfileStatistic from 'components/Dashboard/OtsAgents/ProfileStatistic';
import AmbassadorMap from 'components/Dashboard/Distribution/AmbassadorMap';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyGetAmbassadorsOverviewQuery } from 'core/tracking/TrackingService';
import { useLazyGetRestaurantListQuery } from 'core/restaurant/RestaurantService';
import { setRestaurantList } from 'core/restaurant/RestaurantSlice';
import { useLazyGetSectorListQuery } from 'core/distribution/DistributionService';
import { setSectorList } from 'core/distribution/DistributionSlice';

const { Option } = Select;

const DistributionMonitor = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const timeInterval = useRef();

  const [types, setTypes] = useState([
    {
      label: intl.formatMessage({ id: 'Ambassador' }),
      value: 'E',
    },
    {
      label: intl.formatMessage({ id: 'Floating Ambassador' }),
      value: 'F',
    },
  ]);

  const [states, setStates] = useState([
    {
      value: 'AV',
      label: 'Disponible',
      color: '#7ED321',
    },
    {
      value: 'NA',
      label: 'No disponible',
      color: '#BCBCBC',
    },
    {
      value: 'OR',
      label: 'En ruta',
      color: '#50E2C2',
    },
    {
      value: 'FP',
      label: 'Solucionar un problema',
      color: '#FF2527',
    },
    {
      value: 'ORT',
      label: 'En reposo',
      color: '#687992',
    },
    {
      value: 'OT',
      label: 'En otra tarea',
      color: '#5A9D8E',
    },
    {
      value: 'PV',
      label: 'Problemas con el vehículo',
      color: '#FFC200',
    },
  ]);

  const [state, setState] = useState({
    restaurants: [],
    ambassadorData: [],
    statisticInfo: [
      {
        quantity: 0,
        text: intl.formatMessage({ id: 'Active ambassadors' }),
      },
      {
        quantity: 0,
        text: intl.formatMessage({ id: 'Inactive ambassadors' }),
      },
      {
        quantity: 0,
        text: intl.formatMessage({ id: 'Active delivery men' }),
      },
      {
        quantity: 0,
        text: intl.formatMessage({ id: 'Inactive delivery men' }),
      },
      {
        quantity: 0,
        text: intl.formatMessage({ id: 'Orders delivered today' }),
      },
      {
        quantity: 0,
        text: intl.formatMessage({ id: 'Orders delivered now' }),
      },
      {
        quantity: 0,
        text: intl.formatMessage({ id: 'Orders out of time' }),
      },
    ],
    sid: 0,
    rid: 0,
    type: 0,
    status: 0,
  });

  const { list: restaurantList } = useAppSelector(state => state.restaurantReducer);
  const { sectorList } = useAppSelector(state => state.distributionReducer);

  const [getAmbassadorsOverview] = useLazyGetAmbassadorsOverviewQuery();
  const [getRestaurantList] = useLazyGetRestaurantListQuery();
  const [getSectorList] = useLazyGetSectorListQuery();

  useEffect(() => {
    getLoadData();
    getInitialData({});
    timeInterval.current = setInterval(() => {
      getInitialData({});
    }, 15 * 1000);
    return () => {
      clearInterval(timeInterval.current);
    };
  }, []);

  const getLoadData = async () => {
    try {
      const restaurantList = await getRestaurantList({}).unwrap();
      dispatch(setRestaurantList({ list: restaurantList }));
      const sectorList = await getSectorList({}).unwrap();
      dispatch(setSectorList({ sectorList: sectorList }));
    } catch (e) {
      console.log(e);
    }
  };

  const getInitialData = async ({ sid_value = '', rid_value = '', type_value = '', status_value = '' }) => {
    const { statisticInfo, sid, rid, type, status } = state;
    const ambassadorData = [];
    const newStatisticInfo = statisticInfo;
    try {
      const res = await getAmbassadorsOverview({
        sector: sid_value || sid || '',
        restaurant: rid_value || rid || '',
        ambassadorType: type_value || type || '',
        ambassadorState: status_value || status || '',
      }).unwrap();
      newStatisticInfo[0].quantity = res.onlineAmbassadors || 0;
      newStatisticInfo[1].quantity = res.offlineAmbassadors || 0;
      newStatisticInfo[2].quantity = res.onlineFloatingAmbassadors || 0;
      newStatisticInfo[3].quantity = res.offlineFloatingAmbassadors || 0;
      newStatisticInfo[4].quantity = res.deliveringOrders || 0;
      newStatisticInfo[5].quantity = res.todayOrders || 0;
      newStatisticInfo[6].quantity = res.outOfTimeOrders || 0;
      setState(prev => ({ ...prev, statisticInfo: [...newStatisticInfo] }));
      if (res.ambassadors) {
        for (let i = 0; i < res.ambassadors.length; i += 1) {
          if (res.ambassadors[i].locationLat && res.ambassadors[i].locationLong) {
            ambassadorData.push(res.ambassadors[i]);
          }
        }
        setState(prev => ({ ...prev, ambassadorData: [...ambassadorData] }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleFilterSelect = (type, key) => {};

  const handleSectorChange = value => {
    if (value) {
      const { restaurants } = sectorList.rows.filter(s => s.id === value)[0];
      setState(prev => ({ ...prev, restaurants: restaurants }));
    } else {
      setState(prev => ({ ...prev, restaurants: restaurantList.rows || [] }));
    }
    setState(prev => ({ ...prev, sid: value }));
    getInitialData({ sid_value: value });
  };

  const handleRestaurantChange = value => {
    setState(prev => ({ ...prev, rid: value }));
    getInitialData({ rid_value: value });
  };

  const handleAmbassadorType = value => {
    setState(prev => ({ ...prev, type: value }));
    getInitialData({ type_value: value });
  };

  const handleAmbassadorState = value => {
    setState(prev => ({ ...prev, status: value }));
    getInitialData({ status_value: value });
  };

  const { restaurants, ambassadorData, sid } = state;
  return (
    <div className="distribution_monitor_layout">
      <ContentHeader type="distribution_ambassador_monitor" />
      <div className="distribution_monitor_wrapper">
        <div className="restaurant_filter_wrapper">
          <div className="restaurant_filter">
            <p className="title">
              <FormattedMessage id="Sectors" />
            </p>
            <Select
              showSearch
              defaultValue={0}
              style={{ width: '100%' }}
              optionFilterProp="children"
              onChange={v => handleSectorChange(v)}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              placeHolder="Todos los sectores"
              suffixIcon={<ArrowDropDown />}
              dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
            >
              <Option value={0}>{intl.formatMessage({ id: 'All sectors' })}</Option>
              {sectorList.rows &&
                sectorList.rows.map(s => (
                  <Option value={s.id} key={s.id}>
                    {s.name || ''}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="restaurant_filter">
            <p className="title">
              <FormattedMessage id="Restaurants" />
            </p>
            <Select
              showSearch
              defaultValue={0}
              style={{ width: '100%' }}
              optionFilterProp="children"
              onChange={v => handleRestaurantChange(v)}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              placeHolder="Todos los restaurantes"
              suffixIcon={<ArrowDropDown />}
              dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
            >
              <Option value={0}>{intl.formatMessage({ id: 'All restaurants' })}</Option>
              {(sid ? restaurants : restaurantList.rows || []).map(s => (
                <Option value={s.id || s.rid} key={s.id || s.rid}>
                  {s.description || ''}
                </Option>
              ))}
            </Select>
          </div>
          <div className="type_filter_wrapper">
            <p className="title">
              <FormattedMessage id="Ambassador types" />
            </p>
            <Select
              defaultValue={0}
              style={{ width: '100%' }}
              onChange={v => handleAmbassadorType(v)}
              suffixIcon={<ArrowDropDown />}
              dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
            >
              <Option value={0}>{intl.formatMessage({ id: 'All types' })}</Option>
              {types.map(s => (
                <Option value={s.value} key={s.label}>
                  {s.label}
                </Option>
              ))}
            </Select>
          </div>
          <div className="status_filter_wrapper">
            <p className="title">
              <FormattedMessage id="Ambassador status" />
            </p>
            <Select
              className="status_filter"
              style={{ width: '100%' }}
              defaultValue={0}
              onChange={v => handleAmbassadorState(v)}
              suffixIcon={<ArrowDropDown />}
              dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
            >
              <Option value={0}>{intl.formatMessage({ id: 'All states' })}</Option>
              {states.map(s => (
                <Option className="flex align-center" value={s.value} key={s.value}>
                  <div
                    className="status-color"
                    style={{
                      width: 7,
                      height: 7,
                      minWidth: 7,
                      minHeight: 7,
                      borderRadius: '50%',
                      backgroundColor: s.color,
                      marginRight: 17,
                    }}
                  />{' '}
                  {s.label}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="ambassador_detail_wrapper">
          <ProfileStatistic statisticInfo={state.statisticInfo} />
          <h3 className="global_map_title">
            <FormattedMessage id="Global map" />
          </h3>
          <AmbassadorMap
            ambassadorData={ambassadorData}
            statisticInfo={state.statisticInfo}
            mapType="multi_ambassador"
          />
        </div>
      </div>
    </div>
  );
};

export default DistributionMonitor;
