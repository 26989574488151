import React, { useState } from 'react';
import { ArrowDropDown, DateRange } from '@material-ui/icons';
import { Button, DatePicker, Input, Select, TimePicker } from 'antd';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { compose } from 'react-recompose';
import PropTypes from 'prop-types';
import toast from '../../../components/Basic/Toast';
import { useLazyExportPromotionalProductReportQuery } from 'core/report/ReportService';

const { Option } = Select;

const AnalyticalPromo = ({ intl }) => {
  const [type, setType] = useState('All');
  const onDateTimeChange = (date, dateString, callback) => {
    if (dateString) {
      callback(dateString);
    } else {
      callback(null);
    }
  };

  const typeState = [
    { value: 'All', label: intl.formatMessage({ id: 'All types' }) },
    { value: 'WEB', label: 'WEB' },
    { value: 'Android', label: 'Android' },
    { value: 'iOS', label: 'IOS' },
    { value: 'OTS', label: 'OTS' },
  ];

  const [exportPromoReport] = useLazyExportPromotionalProductReportQuery();

  const [isCreate, setIsCreate] = useState(false);
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [timeStart, setTimeStart] = useState();
  const [timeEnd, setTimeEnd] = useState();
  const [report, setReport] = useState();
  const [promoID, setPromoID] = useState('');

  const handleInput = e => {
    const inputValue = e.target.value.replace(/\D/g, '');
    setPromoID(inputValue === '' ? '' : Number(inputValue));
  };

  const checkValidDates = () => {
    const _startDate = Number(dateStart.split('-').join(''));
    const _endDate = Number(dateEnd.split('-').join(''));
    const _startTime = Number(timeStart.split(':').join(''));
    const _endTime = Number(timeEnd.split(':').join(''));
    if (_startDate > _endDate || (_startDate === _endDate && _endTime <= _startTime)) {
      toast.error({ title: intl.formatMessage({ id: 'startEnd2' }) });
      return false;
    }

    if (promoID === 0) {
      toast.error({ title: intl.formatMessage({ id: 'Promotional ID must be greater than 0' }) });
      return false;
    }
    return true;
  };

  const handleExport = async () => {
    if (dateStart && dateEnd && timeStart && timeEnd) {
      const isValid = checkValidDates();
      if (isValid) {
        const params = {
          fromDate: `${dateStart} ${timeStart}`,
          toDate: `${dateEnd} ${timeEnd}`,
          ...(type !== 'All' && { channel: type }),
          ...(promoID && { promoProductId: Number(promoID) }),
        };
        try {
          const res = await exportPromoReport(params).unwrap();
          setReport(res);
          setIsCreate(true);
        } catch (e) {
          console.log(e);
          toast.error({ title: intl.formatMessage({ id: 'ERR' }) });
        }
      }
    } else {
      toast.error({ title: intl.formatMessage({ id: 'All fields are required' }) });
    }
  };

  const handleClipboardFilename = () => {
    navigator.clipboard.writeText(report.filename);
    toast.success({ title: intl.formatMessage({ id: 'File name has been copied' }) });
  };

  return (
    <div className={'analytical_layout_content_box_inside'}>
      <div className={'analytical_layout_content_box_inside_top'}>
        <span>
          <FormattedMessage id={'Promotional products report'} />
        </span>
        <p>
          <FormattedMessage id={'Export a CSV file the history of a promotionsl product'} />
        </p>
      </div>
      <div className={'analytical_layout_content_box_inside_bottom'}>
        <div>
          <FormattedMessage id="Start date" />
          <DatePicker
            className={'start_date'}
            placeholder="00/00/0000"
            onChange={(date, dateString) => onDateTimeChange(date, dateString, setDateStart)}
            suffixIcon={<DateRange />}
          />
        </div>
        <div>
          <FormattedMessage id="Start time" />
          <TimePicker
            className={`start_date`}
            format="HH:mm"
            placeholder="00:00"
            onChange={(date, dateString) => onDateTimeChange(date, dateString, setTimeStart)}
            minuteStep={10}
          />
        </div>
        <div>
          <FormattedMessage id="End date" />
          <DatePicker
            className={'start_date'}
            placeholder="00/00/0000"
            onChange={(date, dateString) => onDateTimeChange(date, dateString, setDateEnd)}
            suffixIcon={<DateRange />}
          />
        </div>
        <div>
          <FormattedMessage id="End time" />
          <TimePicker
            className={`start_date`}
            format="HH:mm"
            placeholder="00:00"
            onChange={(date, dateString) => onDateTimeChange(date, dateString, setTimeEnd)}
            minuteStep={10}
          />
        </div>
      </div>
      <div className={'analytical_layout_content_box_inside_bottom'} style={{ marginTop: '12px' }}>
        <div>
          <FormattedMessage id={'Promo ID'} />
          <Input className={'input_id'} placeholder="123456" value={promoID} onChange={e => handleInput(e)} />
        </div>
        <div>
          <FormattedMessage id={'Export type'} />
          <Select
            className="sort_by_custom"
            suffixIcon={<ArrowDropDown />}
            defaultValue={type}
            onChange={e => setType(e)}
            dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
          >
            {typeState.map(item => (
              <Option value={item.value} key={item.label}>
                {item.label}
              </Option>
            ))}
          </Select>
        </div>

        <Button className="export_scv_button" onClick={handleExport}>
          <FormattedMessage id={'Export to CSV'} />
        </Button>
        {isCreate ? (
          <div className={'analytical_alert_success_create'}>
            <span>
              <FormattedMessage id="Please wait when file will be format and go to Download page. Save file name :" />
              <button onClick={handleClipboardFilename}>{report.filename}</button>
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

AnalyticalPromo.propsTypes = {
  intl: typeof useIntl,
};

export default compose(injectIntl)(AnalyticalPromo);
