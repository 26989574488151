import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { Form, Input, Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'components/Basic/Toast';
import Loading from 'components/Basic/Loading';
import { ArrowForward } from '@material-ui/icons';
import { useLazyGetSarQuery, useUpdateSarMutation } from 'core/sar/SarService';

const SarConfigForm = ({ match, intl }) => {
  const [sarInfo, setSarInfo] = useState({
    apiUrl: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const [initialState, setInitialState] = useState();

  const [getSar] = useLazyGetSarQuery();
  const [updateSar] = useUpdateSarMutation();

  useEffect(() => {
    setIsLoading(true);
    const funcGetSar = async () => {
      try {
        const res = await getSar({}).unwrap();
        setInitialState(prevState => ({
          ...prevState,
          apiUrl: res.apiUrl,
        }));
        setSarInfo(res);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.log('e', e);
      }
    };
    funcGetSar();
  }, [getSar]);

  const handleChange = (field, value) => {
    setSarInfo({ ...sarInfo, [field]: value });
  };

  const onCancel = () => {
    setSarInfo(prevState => ({
      ...prevState.sarInfo,
      apiUrl: initialState.apiUrl,
    }));
  };

  const onSaveSar = async () => {
    setIsLoading(true);
    if (sarInfo.apiUrl) {
      try {
        await updateSar({ apiUrl: sarInfo.apiUrl }).unwrap();
        setIsLoading(false);
        setInitialState(prev => ({ ...prev, apiUrl: sarInfo.apiUrl }));
        toast.success({
          title: intl.formatMessage({
            id: 'SAR is updated successfully!',
          }),
        });
      } catch (e) {
        console.log('e', e);
        setIsLoading(false);
        toast.error({
          title: intl.formatMessage({
            id: 'Updating SAR is failure!',
          }),
        });
      }
    } else {
      setIsLoading(false);
      toast.error({
        title: intl.formatMessage({
          id: 'The field must not be empty!',
        }),
      });
    }
  };

  return (
    <div className="sar_form_layout">
      <Loading visible={isLoading} />
      <div className="sar_form_wrapper">
        <Form className="basic_info_form">
          <Form.Item>
            <p className="title">
              <FormattedMessage id="Api URL" />
            </p>
            <Input
              placeholder="https://"
              value={sarInfo.apiUrl || ''}
              onChange={e => handleChange('apiUrl', e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <div className="btn_wrapper">
              <Button className="save_sar_btn" onClick={onSaveSar}>
                <FormattedMessage id="Save" />
                <ArrowForward />
              </Button>
              <p className="cancel_btn" onClick={onCancel}>
                <FormattedMessage id="Cancel" />
              </p>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default compose(injectIntl, withRouter)(SarConfigForm);
