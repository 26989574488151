import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Input, Button, Select } from 'antd';
import { Refresh, Search, ArrowDropDown } from '@material-ui/icons';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyGetSectorListQuery } from 'core/distribution/DistributionService';
import { setSectorList } from 'core/distribution/DistributionSlice';

const { Option } = Select;

function TrafficMonitorFilter({ intl, searchText, onSectorFilter, onSort, onSearch, onKeyDownSearch, onApplyFilter }) {
  const restaurantSorts = [
    { value: 'state desc', label: intl.formatMessage({ id: 'By state' }) },
    { value: 'rid desc', label: intl.formatMessage({ id: 'By ID' }) },
    {
      value: 'addresses desc',
      label: intl.formatMessage({ id: 'By number of assigned addresses' }),
    },
    {
      value: 'processingOrders desc',
      label: intl.formatMessage({ id: 'By orders now' }),
    },
    {
      value: 'deliveryAreas desc',
      label: intl.formatMessage({ id: 'By total distribution areas' }),
    },
  ];

  const dispatch = useAppDispatch();

  const { sectorList } = useAppSelector(state => state.distributionReducer);

  const [getSectorList] = useLazyGetSectorListQuery();

  useEffect(async () => {
    try {
      const res = await getSectorList({}).unwrap();
      dispatch(setSectorList({ sectorList: res }));
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleSectorFilter = values => {
    onSectorFilter(values);
  };

  const handleSort = value => {
    onSort(value);
  };

  const handleSearch = e => {
    onSearch(e);
  };

  return (
    <div className="distribution_traffic_filter_layout">
      <Select
        className="all_sectors"
        suffixIcon={<ArrowDropDown />}
        mode="multiple"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={v => handleSectorFilter(v)}
        placeholder="Todos los sectores"
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {sectorList.rows &&
          sectorList.rows.map(s => (
            <Option value={s.id} key={s.id}>
              {s.name || ''}
            </Option>
          ))}
      </Select>
      <Select
        className="sort_by"
        suffixIcon={<ArrowDropDown />}
        placeholder="Ordernar por"
        onChange={handleSort}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {restaurantSorts.map(item => (
          <Option value={item.value} key={item.label}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Button className="apply_filters_btn" onClick={onApplyFilter}>
        <FormattedMessage id="Apply filters" />
        <Refresh />
      </Button>
      <Input
        suffix={<Search />}
        placeholder="Buscar restaurante"
        value={searchText}
        onChange={e => handleSearch(e)}
        onKeyDown={e => onKeyDownSearch(e)}
      />
    </div>
  );
}

TrafficMonitorFilter.defaultProps = {
  sectorList: {},
};

export default compose(injectIntl)(TrafficMonitorFilter);
