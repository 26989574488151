import React, { useRef, useState } from 'react';
import { Button, DatePicker, Input, Select } from 'antd';
import { ArrowDropDown, DateRange } from '@material-ui/icons';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { compose } from 'react-recompose';
import PropTypes from 'prop-types';
import toast from '../../../components/Basic/Toast';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyExportCybersourceReportQuery } from 'core/report/ReportService';
import { setCybersourceReportData } from 'core/report/ReportSlice';

const { Option } = Select;

const AnalyticalPaymentGateway = ({ intl }) => {
  const [type, setType] = useState('APP');

  const typeState = [
    { value: 'APP', label: 'APP' },
    { value: 'PWA', label: 'PWA' },
    { value: 'OTS', label: 'OTS' },
  ];

  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [isCreate, setIsCreate] = useState(false);

  const { cybersourceReport } = useAppSelector(state => state.reportReducer);

  const dispatch = useAppDispatch();
  const [exportCybersourceReporter] = useLazyExportCybersourceReportQuery();

  const handleChangeStartDate = (date, dateString) => {
    setDateStart(dateString);
  };

  const handleChangeEndDate = (date, dateString) => {
    setDateEnd(dateString);
  };

  const handleExport = async () => {
    if (dateStart && dateEnd) {
      try {
        const res = await exportCybersourceReporter({
          fromDate: dateStart,
          toDate: dateEnd,
          platform: type,
        }).unwrap();

        dispatch(setCybersourceReportData({ cybersourceReport: res }));
        setIsCreate(true);
      } catch (e) {
        console.log(e);
      }
    } else {
      toast.error({ title: intl.formatMessage({ id: 'Fields date is empty' }) });
    }
  };

  const handleClipboardFilename = () => {
    navigator.clipboard.writeText(cybersourceReport.filename);
    toast.success({ title: intl.formatMessage({ id: 'File name has been copied' }) });
  };

  return (
    <div className={'analytical_layout_content_box_inside'}>
      <div className={'analytical_layout_content_box_inside_top'}>
        <span>
          <FormattedMessage id={'Cybersources report'} />
        </span>
        <p>
          <FormattedMessage
            id={
              'Export a CSV file that contains the registration of all transactions in the selected period and the selected platforms'
            }
          />
        </p>
      </div>
      <div className={'analytical_layout_content_box_inside_bottom'}>
        <div>
          <FormattedMessage id="Start date" />
          <DatePicker
            className="start_date"
            placeholder={intl.formatMessage({ id: 'Start date' })}
            onChange={handleChangeStartDate}
            suffixIcon={<DateRange />}
          />
        </div>
        <div>
          <FormattedMessage id="Finish date" />
          <DatePicker
            className="start_date"
            placeholder={intl.formatMessage({ id: 'Finish date' })}
            onChange={handleChangeEndDate}
            suffixIcon={<DateRange />}
          />
        </div>
        <div>
          <FormattedMessage id={'Export type'} />
          <Select
            className="sort_by"
            defaultValue={'APP'}
            suffixIcon={<ArrowDropDown />}
            onChange={e => setType(e)}
            dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
          >
            {typeState.map(index => (
              <Option value={index.value} key={index.label}>
                {index.label}
              </Option>
            ))}
          </Select>
        </div>
        <Button className="export_scv_button" onClick={handleExport}>
          <FormattedMessage id={'Export to CSV'} />
        </Button>
        {isCreate ? (
          <div className={'analytical_alert_success_create'}>
            <span>
              <FormattedMessage id="Please wait when file will be format and go to Download page. Save file name :" />
              <button onClick={handleClipboardFilename}>{cybersourceReport.filename}</button>
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

AnalyticalPaymentGateway.propsTypes = {
  intl: typeof useIntl,
  cybersourceReport: PropTypes.object,
  exportCybersourceReporter: PropTypes.func.isRequired,
};

export default compose(injectIntl)(AnalyticalPaymentGateway);
