export const recursivelyTrimStrings = obj => {
  if (Array.isArray(obj)) {
    return obj.map(item => {
      if (typeof item === 'string') {
        return item.trim();
      } else if (typeof item === 'object' && item !== null) {
        return recursivelyTrimStrings(item);
      }
      return item;
    });
  }

  const newObj = {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === 'schedule') {
        newObj[key] = obj[key];
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        newObj[key] = recursivelyTrimStrings(obj[key]);
      } else if (typeof obj[key] === 'string') {
        newObj[key] = obj[key].trim();
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  return newObj;
};
