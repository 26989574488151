import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, CategoriesListData } from './types';

const initialState: initState = {
  list: {
    categories: [],
    totalCount: 0,
  },
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategoriesListData(state: initState, action: PayloadAction<{ list: CategoriesListData }>) {
      state.list = action.payload.list;
    },
  },
});

export const { setCategoriesListData } = categoriesSlice.actions;

export default categoriesSlice.reducer;
