import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

function ProfileStatistic({ statisticInfo }) {
  return (
    <div className="profile_statistic_layout">
      <h3>
        <FormattedMessage id="Today's statistical profile" />
      </h3>
      <div className="statistic_wrapper">
        {statisticInfo.map((item, index) => (
          <div className="card" key={index}>
            <p className="quantity">{item.quantity}</p>
            <p className="description">{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

ProfileStatistic.defaultProps = {
  statisticInfo: [],
};

export default ProfileStatistic;
