import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, DistributionList, SectorList } from './types';

const initialState: initState = {
  list: {
    limit: 0,
    offset: 0,
    rows: [
      {
        id: 0,
        name: '',
        state: '',
        restaurants: '',
        ambassadors: '',
        activeAmbassadors: '',
        deliveryTime: '',
        nowOrders: '',
        createdAt: '',
        updatedAt: '',
      },
    ],
    totalCount: 0,
  },
  sectorList: {
    limit: 0,
    offset: 0,
    rows: [
      {
        createdAt: '',
        id: 0,
        name: '',
        restaurants: [],
        updatedAt: '',
      },
    ],
    totalCount: 0,
  },
};

const distributionSlice = createSlice({
  name: 'distribution',
  initialState,
  reducers: {
    setDistributionList(state: initState, action: PayloadAction<{ list: DistributionList }>) {
      state.list = action.payload.list;
    },
    setSectorList(state: initState, action: PayloadAction<{ sectorList: SectorList }>) {
      state.sectorList = action.payload.sectorList;
    },
  },
});

export const { setDistributionList, setSectorList } = distributionSlice.actions;

export default distributionSlice.reducer;
