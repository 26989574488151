import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import DistributionFilter from 'components/Dashboard/Distribution/DistributionFilter';
import DistributionTable from 'components/Dashboard/Distribution/DistributionTable';
import Loading from 'components/Basic/Loading';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyGetDistributionListQuery } from 'core/distribution/DistributionService';
import { setDistributionList } from 'core/distribution/DistributionSlice';

const DistributionList = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const timeInterval = useRef();

  const [state, setState] = useState({
    searchText: '',
    offset: 0,
    limit: 10,
    filterQuery: '',
    stateFilter: '',
    restaurantFilter: '',
    searchFilterQuery: '',
    restaurants: null,
    sort: '',
    isLoading: false,
  });

  const { list } = useAppSelector(state => state.distributionReducer);

  const [getDistributionList] = useLazyGetDistributionListQuery();

  useEffect(() => {
    return () => {
      clearInterval(timeInterval.current);
    };
  }, []);

  const handleChangePage = async (offset, limit, filterQuery, sort) => {
    setState(prev => ({ ...prev, offset: offset, limit: limit, isLoading: true }));
    clearInterval(timeInterval.current);

    try {
      const res = await getDistributionList({
        offset,
        limit,
        or: false,
        filter: filterQuery || state.filterQuery + state.searchFilterQuery,
        restaurant: state.restaurants,
        sort: sort || state.sort,
      }).unwrap();
      dispatch(setDistributionList({ list: res }));
      setState(prev => ({ ...prev, isLoading: false }));
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
    }

    timeInterval.current = setInterval(async () => {
      try {
        const res = await getDistributionList({
          offset,
          limit,
          or: false,
          filter: filterQuery || state.filterQuery + state.searchFilterQuery,
          restaurant: state.restaurants,
          sort: sort || state.sort,
        }).unwrap();
        dispatch(setDistributionList({ list: res }));
        setState(prev => ({ ...prev, isLoading: false }));
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    }, 10 * 1000);
  };

  const handleStateFilter = values => {
    if (values.length !== 0 && values.length !== 3) {
      let filterQuery = '&filter=deliveryTime';

      if (values.includes('L')) {
        filterQuery = '&filter=deliveryTime lt 30';
      }

      if (values.includes('M')) {
        filterQuery = '&filter=deliveryTime between 30 45';
      }

      if (values.includes('H')) {
        filterQuery = '&filter=deliveryTime gt 45';
      }

      if (values.includes('L') && values.includes('M')) {
        filterQuery = '&filter=deliveryTime lte 45';
      }

      if (values.includes('L') && values.includes('H')) {
        filterQuery = '&filter=or.deliveryTime lt 30&filter=or.deliveryTime gte 45';
      }

      if (values.includes('M') && values.includes('H')) {
        filterQuery = '&filter=deliveryTime gte 30';
      }

      setState(prev => ({ ...prev, stateFilter: filterQuery }));
    } else {
      setState(prev => ({ ...prev, stateFilter: '' }));
    }
  };

  const handleRestaurantFilter = values => {
    if (values.length !== 0) {
      setState(prev => ({ ...prev, restaurants: values }));
    } else {
      setState(prev => ({ ...prev, restaurants: null }));
    }
  };

  const handleSort = value => {
    setState(prev => ({ ...prev, sort: value }));
  };

  const handleSearch = e => {
    setState(prev => ({ ...prev, searchText: e.target.value }));
  };

  const handleKeyDownSearch = async e => {
    let searchFilterQuery = '';

    if (e.keyCode === 13) {
      const { offset, limit, filterQuery, sort, searchText } = state;

      if (searchText) {
        if (!isNaN(searchText, 10) && searchText < 100000000) {
          searchFilterQuery += `&filter=or.id eq ${searchText}`;
        }
        searchFilterQuery += `&filter=or.name iLike %25${searchText}%25`;
      } else {
        searchFilterQuery = '';
      }

      setState(prev => ({ ...prev, searchFilterQuery: searchFilterQuery, isLoading: true }));
      clearInterval(timeInterval.current);

      try {
        const res = await getDistributionList({
          offset: 0,
          limit: 10,
          or: false,
          filter: filterQuery + searchFilterQuery,
          restaurant: state.restaurants,
          sort: sort,
        }).unwrap();
        dispatch(setDistributionList({ list: res }));
        setState(prev => ({ ...prev, isLoading: false }));
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
      }

      timeInterval.current = setInterval(async () => {
        try {
          const res = await getDistributionList({
            offset: 0,
            limit: 10,
            or: false,
            filter: filterQuery + searchFilterQuery,
            restaurant: state.restaurants,
            sort: sort,
          }).unwrap();
          dispatch(setDistributionList({ list: res }));
          setState(prev => ({ ...prev, isLoading: false }));
        } catch (e) {
          setState(prev => ({ ...prev, isLoading: false }));
        }
      }, 10 * 1000);
    }
  };

  const handleApplyFilter = async () => {
    const { stateFilter, restaurantFilter, sort, searchFilterQuery } = state;

    setState(prev => ({ ...prev, filterQuery: stateFilter + restaurantFilter, isLoading: true }));
    clearInterval(timeInterval.current);

    try {
      const res = await getDistributionList({
        offset: 0,
        limit: 10,
        or: false,
        filter: stateFilter + restaurantFilter + searchFilterQuery,
        restaurant: state.restaurants,
        sort: sort,
      }).unwrap();
      dispatch(setDistributionList({ list: res }));
      setState(prev => ({ ...prev, isLoading: false }));
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
    }

    timeInterval.current = setInterval(async () => {
      try {
        const res = await getDistributionList({
          offset: 0,
          limit: 10,
          or: false,
          filter: stateFilter + restaurantFilter + searchFilterQuery,
          restaurant: state.restaurants,
          sort: sort,
        }).unwrap();
        dispatch(setDistributionList({ list: res }));
        setState(prev => ({ ...prev, isLoading: false }));
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    }, 10 * 1000);
  };

  return (
    <div className="distribution_list_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader type="distribution_list" />
      <div className="distribution_filter_wrapper">
        <DistributionFilter
          searchText={state.searchText}
          onStateFilter={handleStateFilter}
          onRestaurantFilter={handleRestaurantFilter}
          onSort={handleSort}
          onSearch={handleSearch}
          onKeyDownSearch={handleKeyDownSearch}
          onApplyFilter={handleApplyFilter}
        />
        <DistributionTable distributionData={list.rows || []} onChangePage={handleChangePage} total={list.totalCount} />
      </div>
    </div>
  );
};

export default DistributionList;
