import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Input } from 'antd';
import ModalDlg from 'components/Basic/ModalDlg';
import { Search } from '@material-ui/icons';

const Status = {
  AV: {
    color: '#674FA6',
    label: 'Disponible',
  },
  PA: {
    color: '#E8A159',
    label: 'Preasignado',
  },
  NA: {
    color: '#A54F78',
    label: 'No disponible',
  },
  OR: {
    color: '#3A82C3',
    label: 'En ruta',
  },
  FP: {
    color: '#0C51C9',
    label: 'Solucionar un problema',
  },
  ORT: {
    color: '#FE1F20',
    label: 'En reposo',
  },
  OT: {
    color: '#202020',
    label: 'En otra tarea',
  },
  PV: {
    color: '#6BA752',
    label: 'Problemas con el vehículo',
  },
};

function AmbassadorListModal({ intl, visible, ambassadors, onCloseModal }) {
  const [searchText, setSearchText] = useState('');

  const handleSearch = e => {
    setSearchText(e.target.value);
  };

  return (
    <ModalDlg
      className="ambassador_list_modal"
      visible={visible}
      width={760}
      top={30}
      handleCancel={() => {
        onCloseModal();
      }}
    >
      <div className="header_wrapper">
        <p className="title">
          <FormattedMessage id="Assigned ambassadors" />
        </p>
        <div className="flex align-center just-end">
          <Input
            suffix={<Search />}
            placeholder="Encontrar embajadora específica"
            value={searchText}
            onChange={e => handleSearch(e)}
          />
        </div>
      </div>
      <div className="ambassador_list_wrapper">
        <div className="flex align-center table_header">
          <div className="code">
            <FormattedMessage id="Ambassador Code" />
          </div>
          <div className="name">
            <FormattedMessage id="Ambassador's name" />
          </div>
          <div className="state">
            <FormattedMessage id="Actual state" />
          </div>
          <div className="average_time">
            <FormattedMessage id="Delivery average" />
          </div>
          <div className="order_today">
            <FormattedMessage id="Orders delivered today" />
          </div>
        </div>
        <div className="table_content scrollbar">
          {ambassadors
            .filter(a => a.fullName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 || searchText === '')
            .map(a => (
              <div className="flex align-center table_item" key={a.eid}>
                <div className="code">{a.eid || ''}</div>
                <div className="name">{a.fullName || ''}</div>
                <div className="flex align-center state">
                  <div className="status_circle" style={{ backgroundColor: Status[a.detailedState].color }} />
                  {Status[a.detailedState].label}
                </div>
                <div className="average_time">{parseInt(a.deliveryMinutes, 10)} minutos</div>
                <div className="order_today">{a.deliveryCount}</div>
              </div>
            ))}
        </div>
      </div>
    </ModalDlg>
  );
}

AmbassadorListModal.defaultProps = {
  visible: false,
  ambassadors: [],
};

export default compose(injectIntl)(AmbassadorListModal);
