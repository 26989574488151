import React, { useState } from 'react';
import { Button, DatePicker, Select } from 'antd';
import { ArrowDropDown, DateRange } from '@material-ui/icons';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { compose } from 'react-recompose';
import toast from '../../../components/Basic/Toast';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyExportOrderReportQuery } from 'core/report/ReportService';
import { setOrderReportData } from 'core/report/ReportSlice';

const { Option } = Select;

const AnalyticalOrders = ({ intl }) => {
  const [reportData, setReportDate] = useState({
    dateStart: undefined,
    dateEnd: undefined,
    format: 'csv',
    state: 'RV',
  });

  const typeState = [
    { value: 'csv', label: intl.formatMessage({ id: 'Historic in CSV' }) },
    { value: 'json', label: intl.formatMessage({ id: 'Functional in Json' }) },
  ];

  const orderStates = [
    { value: 'RV', label: intl.formatMessage({ id: 'RV' }) },
    { value: 'IP', label: intl.formatMessage({ id: 'IP' }) },
    { value: 'AS', label: intl.formatMessage({ id: 'AS' }) },
    { value: 'OW', label: intl.formatMessage({ id: 'OW' }) },
    { value: 'AD', label: intl.formatMessage({ id: 'AD' }) },
    { value: 'CL', label: intl.formatMessage({ id: 'CL' }) },
    { value: 'RS', label: intl.formatMessage({ id: 'RS' }) },
    { value: 'DD', label: intl.formatMessage({ id: 'DD' }) },
    { value: 'CD', label: intl.formatMessage({ id: 'CD' }) },
  ];

  const [isCreate, setIsCreate] = useState(false);

  const { orderReport } = useAppSelector(state => state.reportReducer);

  const dispatch = useAppDispatch();
  const [exportOrderReporter] = useLazyExportOrderReportQuery();

  const handleChangeStartDate = (date, dateString) => {
    setReportDate({ ...reportData, dateStart: dateString });
  };

  const handleChangeEndDate = (date, dateString) => {
    setReportDate({ ...reportData, dateEnd: dateString });
  };

  const handleExport = async () => {
    if (reportData.dateStart && reportData.dateEnd) {
      try {
        const res = await exportOrderReporter({
          fromDate: reportData.dateStart,
          toDate: reportData.dateEnd,
          format: reportData.format,
          state: reportData.state,
        }).unwrap();

        dispatch(setOrderReportData({ orderReport: res }));
        setIsCreate(true);
      } catch (e) {
        console.log(e);
      }
    } else {
      toast.error({ title: intl.formatMessage({ id: 'Fields date is empty' }) });
    }
  };

  const handleClipboardFilename = () => {
    navigator.clipboard.writeText(orderReport.filename);
    toast.success({ title: intl.formatMessage({ id: 'File name has been copied' }) });
  };

  return (
    <div className={'analytical_layout_content_box_inside'}>
      <div className={'analytical_layout_content_box_inside_top'}>
        <span>
          <FormattedMessage id={'Order scheme'} />
        </span>
        <p>
          <FormattedMessage
            id={
              'Export a CSV or JSON file with the history of orders in lot, use the selected state based on the selected dates'
            }
          />
        </p>
      </div>
      <div className={'analytical_layout_content_box_inside_bottom request'}>
        <div className={'analytical_layout_content_box_request'}>
          <div>
            <FormattedMessage id={'Export type'} />
            <Select
              className="sort_by"
              suffixIcon={<ArrowDropDown />}
              defaultValue={reportData.format}
              onChange={e => setReportDate({ ...reportData, format: e })}
              dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
            >
              {typeState.map(item => (
                <Option value={item.value} key={item.label}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </div>
          {reportData.format === 'json' ? (
            <p>
              <FormattedMessage id={'option will generate a JSON file'} />
            </p>
          ) : (
            <></>
          )}
        </div>
        <div>
          <div>
            Estado
            <Select
              className="sort_by"
              suffixIcon={<ArrowDropDown />}
              defaultValue={reportData.state}
              onChange={e => setReportDate({ ...reportData, state: e })}
              dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
            >
              {orderStates.map(item => (
                <Option value={item.value} key={item.label}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <FormattedMessage id="Start date" />
            <DatePicker
              className="start_date"
              placeholder={intl.formatMessage({ id: 'Start date' })}
              onChange={handleChangeStartDate}
              suffixIcon={<DateRange />}
            />
          </div>
          <div>
            <FormattedMessage id="Finish date" />
            <DatePicker
              className="start_date"
              placeholder={intl.formatMessage({ id: 'Finish date' })}
              onChange={handleChangeEndDate}
              suffixIcon={<DateRange />}
            />
          </div>
          <div>
            <Button className="export_scv_button" onClick={handleExport}>
              <FormattedMessage id={'Export'} />
            </Button>
          </div>
          {isCreate ? (
            <div className={'analytical_alert_success_create'}>
              <span>
                <FormattedMessage id="Please wait when file will be format and go to Download page. Save file name :" />
                <button onClick={handleClipboardFilename}>{orderReport.filename}</button>
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

AnalyticalOrders.propsType = {
  intl: typeof useIntl,
};

export default compose(injectIntl)(AnalyticalOrders);
