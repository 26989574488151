import { ArrowDropDown, Edit } from '@material-ui/icons';
import { Button, Form, Select } from 'antd';
import { useGetSubtypesQuery } from 'core/rewards/RewarsService';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import SubtypeEditModal from './SubtypeEditModal';
import SubtypeModal from './SubtypeModal';

type SubtypeItemType = {
  id: number;
  name: string;
};

type SubtypeType = {
  isValid: boolean;
  selectedSubtype: number;
  setSelectedSubtype: (values: number) => void;
};

const Subtype: React.FC<SubtypeType> = ({ isValid, selectedSubtype, setSelectedSubtype }) => {
  const [isShowListModal, setShowListModal] = useState<boolean>(false);
  const [isModal, setModal] = useState<boolean>(false);
  const [tab, setTab] = useState<SubtypeItemType | null>(null);
  const { data: subtypes, refetch } = useGetSubtypesQuery({}, { refetchOnFocus: true });

  const checkField = (value: any) => {
    if (isValid) return;
    if (value) {
      const valid = value.length > 0 || value;
      return !valid;
    } else {
      return true;
    }
  };

  const tabArr = subtypes?.subtabs?.map((item: any) => {
    return {
      name: item.name,
      id: item.id,
    };
  });

  const onButtonClick = () => {
    setModal(false);
    setTab(null);
    setShowListModal(true);
  };

  return (
    <>
      <div className="" id="campaign_form_wrapper">
        <div className="basic_info_form">
          <div className="tabs_input">
            <div style={{ display: 'flex', gap: '4px' }}>
              <Form.Item>
                <p className="title_deals">
                  <FormattedMessage id="Subtype" />
                </p>
                <Select
                  className={`select_modal ${checkField(selectedSubtype) && 'has-error'}`}
                  suffixIcon={<ArrowDropDown />}
                  onChange={setSelectedSubtype}
                  id="campaign_status_filter"
                  value={selectedSubtype}
                  dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                  style={{ width: '250px' }}
                >
                  {tabArr?.map((item: any) => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <div className="upload-btn">
                <Button onClick={onButtonClick}>
                  <Edit />
                  <span>
                    <FormattedMessage id={selectedSubtype ? 'Edit' : 'Create'} />
                  </span>
                </Button>
              </div>
            </div>
            {checkField(selectedSubtype) && (
              <div style={{ marginTop: -20, paddingBottom: 14 }}>
                <span className="error_msg">
                  <FormattedMessage id="Field is required" />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <SubtypeModal isOpenModal={isShowListModal} onCloseModal={() => setShowListModal(false)} />
      <SubtypeEditModal
        isOpenModal={isModal}
        onCloseModal={() => setModal(false)}
        tabsInfo={tab}
        refetch={() => refetch()}
      />
    </>
  );
};

export default Subtype;

const buildQueryString = ({
  search = '',
  page = 1,
  size = 10,
  count = true,
  sort_by_name = '',
  sort_by_id = 'desc',
}) => {
  const params = [`search=${encodeURIComponent(search)}`, `page=${page}`, `count=${count}`];

  if (sort_by_id) {
    params.push(`sort[id]=${sort_by_id}`);
  }

  if (sort_by_name) {
    params.push(`sort[name]=${sort_by_name}`);
  }

  return params.join('&');
};
