import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import ModalDlg from 'components/Basic/ModalDlg';

function SyncConfirmModal({ children, isOpenModal, onCancel, onOk }) {
  return (
    <ModalDlg className="delete_confirm_modal" visible={isOpenModal} width={434} handleCancel={onCancel} isCenter>
      <div className="modal_header">{children}</div>
      <div className="modal_footer">
        <div className="btn_wrapper">
          <p onClick={onCancel}>
            <FormattedMessage id="Cancel" />
          </p>
          <Button className="sync_btn" onClick={onOk}>
            <FormattedMessage id="Sync up" />
          </Button>
        </div>
      </div>
    </ModalDlg>
  );
}

SyncConfirmModal.defaultProps = {
  isOpenModal: false,
};

export default SyncConfirmModal;
