import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input } from 'antd';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import PolygonMap from 'components/Basic/PolygonMap';
import DeliveryZoneBasicInfo from 'components/Dashboard/Restaurants/DeliveryZoneBasicInfo';
import DeliverySetting from 'components/Dashboard/Restaurants/DeliverySetting';
import DeleteConfirmModal from 'components/Basic/DeleteConfirmModal';
import DropMenu from 'components/Basic/DropMenu';
import toast from 'components/Basic/Toast';
import Loading from 'components/Basic/Loading';
import {
  useLazyGetDeliveryAreaQuery,
  useLazyGetDeliveryAreasQuery,
  useLazyGetRestaurantListQuery,
  useLazyGetRestaurantQuery,
  useCreateDeliveryAreaMutation,
  useUpdateDeliveryAreaMutation,
  useDeleteDeliveryAreaMutation,
} from 'core/restaurant/RestaurantService';

const kinds = [
  { key: 'I', value: 'Incluyente' },
  { key: 'E', value: 'Excluyente' },
];
const states = [
  { key: 'I', value: 'Zona inactiva' },
  { key: 'A', value: 'Zona activa' },
];

const DeliveryZoneNew = ({ match }) => {
  const { rid, zid: zoneId } = match.params;
  const intl = useIntl();

  const history = useHistory();

  const [state, setState] = useState({
    type: zoneId ? '' : 'Incluyente',
    actualState: zoneId ? '' : 'Zona activa',
    isSubmit: false,
    deliveryInfo: {
      restaurant: rid,
      name: '',
      type: 'I',
      state: 'A',
    },
    restaurantInfo: {},
    isRemoveModal: false,
    isLoading: false,
    deliveryAreas: [],
    restaurants: [],
  });

  const [getDeliveryArea] = useLazyGetDeliveryAreaQuery();
  const [getDeliveryAreas] = useLazyGetDeliveryAreasQuery();
  const [getRestaurantList] = useLazyGetRestaurantListQuery();
  const [getRestaurant] = useLazyGetRestaurantQuery();
  const [createDeliveryArea] = useCreateDeliveryAreaMutation();
  const [updateDeliveryArea] = useUpdateDeliveryAreaMutation();
  const [deleteDeliveryArea] = useDeleteDeliveryAreaMutation();

  useEffect(async () => {
    if (zoneId) {
      setState(prev => ({ ...prev, isLoading: true }));
      try {
        const res = await getDeliveryArea({ rid: rid, id: zoneId }).unwrap();
        console.log('res', res);
        setState(prev => ({ ...prev, deliveryInfo: res }));
        setState(prev => ({
          ...prev,
          type: res.type === 'I' ? 'Incluyente' : 'Excluyente',
          actualState: res.state === 'A' ? 'Zona activa' : 'Zona inactiva',
        }));
      } catch (error) {
        console.log(error);
      }
    }
    try {
      const res = await getDeliveryAreas({}).unwrap();
      setState(prev => ({ ...prev, deliveryAreas: res }));
    } catch (err) {
      console.log(err);
    }
    try {
      const res = await getRestaurantList({}).unwrap();
      setState(prev => ({ ...prev, restaurants: res.rows }));
    } catch (err) {
      console.log(err);
    }
    try {
      const res = await getRestaurant({ id: rid }).unwrap();
      setState(prev => ({ ...prev, restaurantInfo: res }));
    } catch (err) {
      console.log(err);
    }
    setState(prev => ({ ...prev, isLoading: false }));
  }, []);

  const handleFilter = (filterType, key) => {
    if (filterType === 'type') {
      handleChange('type', kinds[key].key);
      setState(prev => ({ ...prev, type: kinds[key].value }));
    } else if (filterType === 'state') {
      handleChange('state', states[key].key);
      setState(prev => ({ ...prev, actualState: states[key].value }));
    }
  };

  const handleChange = (field, value) => {
    if (field === 'lunchMinimumPurchase') {
      state.deliveryInfo.snackMinimumPurchase = value;
      state.deliveryInfo.dinnerMinimumPurchase = value;
    }
    setState(prev => ({ ...prev, deliveryInfo: { ...state.deliveryInfo, [field]: value } }));
  };

  const handleUpdateArea = areas => {
    setState(prev => ({ ...prev, deliveryInfo: { ...state.deliveryInfo, area: { areas } } }));
  };

  const handleCreateDeliveryZone = async () => {
    setState(prev => ({ ...prev, isSubmit: true, isLoading: true }));

    if (state.deliveryInfo.name && state.deliveryInfo.name.trim().length !== 0) {
      if (!zoneId) {
        try {
          await createDeliveryArea({ deliveryInfo: state.deliveryInfo }).unwrap();
          toast.success({
            title: intl.formatMessage({
              id: 'Delivery zone is created successfully!',
            }),
          });
          history.push(`/dashboard/restaurants/${state.deliveryInfo.restaurant}/deliveryzone`);
        } catch (err) {
          toast.error({
            title: intl.formatMessage({
              id: 'Creating delivery zone is failure!',
            }),
          });
        }
      } else {
        try {
          await updateDeliveryArea({
            id: zoneId,
            deliveryInfo: {
              ...state.deliveryInfo,
              restaurant: state.deliveryInfo.restaurant.id,
            },
          }).unwrap();
          toast.success({
            title: intl.formatMessage({
              id: 'Delivery zone is updated successfully!',
            }),
          });
        } catch (err) {
          toast.success({
            title: intl.formatMessage({
              id: 'Updating delivery zone is failure!',
            }),
          });
        }
      }
    }

    setState(prev => ({ ...prev, isLoading: false }));
  };

  const handleDeleteDeliveryZone = () => {
    setState(prev => ({ ...prev, isRemoveModal: true }));
  };

  const handleConfirmDelete = async () => {
    setState(prev => ({ ...prev, isLoading: true }));
    if (zoneId) {
      try {
        await deleteDeliveryArea({ rid: state.deliveryInfo.restaurant.id, id: zoneId }).unwrap();
        setState(prev => ({ ...prev, isLoading: false }));
        toast.success({
          title: intl.formatMessage({
            id: 'Delivery zone is deleted successfully!',
          }),
        });
        history.push(`/dashboard/restaurants/${state.deliveryInfo.restaurant.id}/deliveryzone`);
      } catch (err) {
        setState(prev => ({ ...prev, isLoading: false }));
        toast.error({
          title: intl.formatMessage({
            id: 'Deleting delivery zone is failure!',
          }),
        });
      }
      setState(prev => ({ ...prev, isRemoveModal: false }));
    }
  };
  return (
    <div className="deliveryzone_new_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader
        type="deliveryzone_new"
        rid={rid}
        zid={zoneId}
        onCreateDeliveryZone={handleCreateDeliveryZone}
        onDeleteDeliveryZone={handleDeleteDeliveryZone}
      />
      <div className="main_content">
        {!state.isLoading && (
          <>
            <div className="deliveryzone_info_wrapper">
              <h3>
                <FormattedMessage id="Basic information" />
              </h3>
              <div className="base_info_wrapper">
                <Form className="basic_info_form">
                  <Form.Item>
                    <p className="title">
                      <FormattedMessage id="Name" />
                    </p>
                    <p className="description">
                      <FormattedMessage id="It will be used for the deployment of the reference perimeter from the delivery area" />
                    </p>
                    <Input
                      placeholder="Ingresa el nombre de la zona de reparto"
                      value={state.deliveryInfo.name || ''}
                      onChange={e => handleChange('name', e.target.value)}
                    />
                    {(!state.deliveryInfo.name || state.deliveryInfo.name.trim().length === 0) && state.isSubmit && (
                      <p className="error_msg">
                        <FormattedMessage id="Name is required" />!
                      </p>
                    )}
                  </Form.Item>
                  <Form.Item>
                    <p className="title">
                      <FormattedMessage id="Kind" />
                    </p>
                    <p className="description">
                      <FormattedMessage id="Select a verification option" />
                    </p>
                    <div>
                      <DropMenu
                        items={kinds}
                        onMenu={key => handleFilter('type', key)}
                        placeHolder={state.type}
                        defaultValue={state.deliveryInfo.type || ''}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <p className="title">
                      <FormattedMessage id="Actual state" />
                    </p>
                    <p className="description">
                      <FormattedMessage id="Select a current status option" />
                    </p>
                    <div>
                      <DropMenu
                        items={states}
                        onMenu={key => handleFilter('state', key)}
                        placeHolder={state.actualState}
                        defaultValue={state.deliveryInfo.state || ''}
                      />
                    </div>
                  </Form.Item>
                  {state.type === 'Excluyente' && state.actualState === 'Zona inactiva' && (
                    <Form.Item>
                      <p className="title">
                        <FormattedMessage id="Excluding delivery zone message" />
                      </p>
                      <p className="description">
                        <FormattedMessage id="It will be used to notify users of the reason excluding cast" />
                      </p>
                      <Input
                        placeholder="Ingresa una descripción corta, eg. “Lo sentimos, todavía no repartimos en tu dirección de entrega que has indicado.”"
                        value={state.deliveryInfo.inactiveMessage || ''}
                        onChange={e => handleChange('inactiveMessage', e.target.value)}
                      />
                    </Form.Item>
                  )}
                  {state.type === 'Incluyente' && state.actualState === 'Zona inactiva' && (
                    <Form.Item>
                      <p className="title">
                        <FormattedMessage id="Inactivity message" />
                      </p>
                      <p className="description">
                        <FormattedMessage id="It will be used to notify users of the reason for inactivity" />
                      </p>
                      <Input
                        placeholder="Ingresa una descripción corta, eg. “Por fuertes lluvias e inundaciones, volveremos mañana”"
                        value={state.deliveryInfo.inactiveMessage || ''}
                        onChange={e => handleChange('inactiveMessage', e.target.value)}
                      />
                    </Form.Item>
                  )}
                </Form>
              </div>
            </div>
            {state.type === 'Incluyente' && (
              <div>
                <DeliveryZoneBasicInfo
                  deliveryInfo={state.deliveryInfo}
                  onChange={(field, value) => handleChange(field, value)}
                />
                <DeliverySetting
                  deliveryInfo={state.deliveryInfo}
                  onChange={(field, value) => handleChange(field, value)}
                />
              </div>
            )}
            {state.type === 'Excluyente' && (
              <div className="exclude_map_description">
                <h3>
                  <FormattedMessage id="Perimeter of the exclusive distribution area" />
                </h3>
                <p className="description">
                  <FormattedMessage id="Draw in detail the limits of the exclusion zone, in this zone orders will not be delivered." />
                </p>
              </div>
            )}
            {state.type === 'Incluyente' && (
              <div className="include_map_description">
                <h3>
                  <FormattedMessage id="Perimeter of the Inclusive distribution area" />
                </h3>
                <p className="description">
                  <FormattedMessage id="Draw in detail the limits of the inclusive zone, in this zone if orders will be delivered." />
                </p>
              </div>
            )}
          </>
        )}

        {!state.isLoading && (
          <PolygonMap
            onUpdateArea={handleUpdateArea}
            deliveryInfo={state.deliveryInfo}
            restaurantInfo={state.restaurantInfo}
            restaurants={state.restaurants}
            deliveryAreas={state.deliveryAreas.rows}
          />
        )}
      </div>
      <DeleteConfirmModal
        isOpenModal={state.isRemoveModal}
        title={intl.formatMessage({
          id: 'Do you really want to remove this delivery zone?',
        })}
        subtitle=""
        onCancel={() => setState({ isRemoveModal: false })}
        onOk={handleConfirmDelete}
      />
    </div>
  );
};

export default DeliveryZoneNew;
