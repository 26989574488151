import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import ModalDlg from 'components/Basic/ModalDlg';
import { ArrowForward } from '@material-ui/icons';
import GoogleMapReact from 'google-map-react';
import Marker from 'components/Basic/Marker';
import { getLocations } from 'utilities/common';

function AmbassadorMapModal({ data, isOpenModal, onCloseModal }) {
  const [center, setCenter] = useState({
    lat: getLocations().lat,
    lng: getLocations().lng,
  });

  const handleGoogleAPILoaded = () => {
    if (data) {
      setCenter({
        lat: data.locationLat || getLocations().lat,
        lng: data.locationLong || getLocations().lng,
      });
    }
  };

  return (
    <ModalDlg className="ambassador_map_modal" visible={isOpenModal} width={450} handleCancel={onCloseModal} isCenter>
      <div className="modal_content">
        <div className="ambassador_map_layout">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAP_API,
            }}
            defaultCenter={center}
            center={center}
            onGoogleApiLoaded={handleGoogleAPILoaded}
            defaultZoom={10}
            zoom={10}
          >
            {data && data.locationLat && (
              <Marker
                lat={center.lat}
                lng={center.lng}
                description={`${data.firstName || ''} ${data.lastName || ''}`}
              />
            )}
          </GoogleMapReact>
        </div>
      </div>
      <div className="modal_footer">
        <Button onClick={onCloseModal}>
          <FormattedMessage id="Close" />
          <ArrowForward />
        </Button>
      </div>
    </ModalDlg>
  );
}

AmbassadorMapModal.defaultProps = {
  isOpenModal: false,
  data: {},
};

export default AmbassadorMapModal;
