import React from 'react';
import PropTypes from 'prop-types';
import { toast as toastify } from 'react-toastify';

export function Toast({ title, type, ...props }) {
  return (
    <div {...props} className={`toast_container_wrapper ${type}`}>
      {title && <p className="title">{title}</p>}
    </div>
  );
}

Toast.defaultProps = {
  title: '',
  type: 'success',
};

function toast({ title, type = 'success', ...props }, options = {}) {
  return toastify(<Toast title={title} type={type} {...props} />, options);
}

toast.info = (content, options) => toast({ ...content, type: 'info' }, options);
toast.error = (content, options) => toast({ ...content, type: 'error' }, options);
toast.success = (content, options) => toast({ ...content, type: 'success' }, options);
toast.update = toastify.update;

export default toast;
