import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Form, Input, Button, Switch, message, Tooltip } from 'antd';
import ModalDlg from 'components/Basic/ModalDlg';
import { Close, Edit, Delete } from '@material-ui/icons';
import { ArrowForward } from '@material-ui/icons';
import toast from 'components/Basic/Toast';
import Loading from 'components/Basic/Loading';
import { useAppDispatch } from 'utilities/redux';
import { useLazyGetTabsListQuery, useCreateTabsMutation, useUpdateTabsMutation } from 'core/tabs/TabsService';
import { setTabsListData } from 'core/tabs/TabsSlice';
import SubtypeEditModal from './SubtypeEditModal';
import { useGetSubtypesQuery, useDeleteSubtabsMutation } from 'core/rewards/RewarsService';

type TabsInfo = {
  id: number;
  name: string;
};

type SybtypeModalType = {
  isOpenModal: boolean;
  onCloseModal: () => void;
};

const SubtypeModal: React.FC<SybtypeModalType> = ({ isOpenModal, onCloseModal }) => {
  const [tab, setTab] = useState<TabsInfo | null>(null);
  const [isModal, setModal] = useState<boolean>(false);
  const intl = useIntl();
  const { data: subtypes, isLoading: subtypesLoading, refetch } = useGetSubtypesQuery({}, { refetchOnFocus: true });
  const [deleteSubtypes, { isLoading: deleteLoading }] = useDeleteSubtabsMutation();

  const handleDelete = async (id: number) => {
    try {
      await deleteSubtypes({ id: id }).unwrap();
      refetch();
      toast.success({
        title: intl.formatMessage({
          id: 'Subtype is deleted successfully!',
        }),
      });
    } catch (error) {
      toast.error({
        title: intl.formatMessage({
          id: 'Subtype is deleted failure!',
        }),
      });
    }
  };

  return (
    <ModalDlg className="suptype_view_modal" visible={isOpenModal} width={580} handleCancel={onCloseModal} isCenter>
      <>
        <div className="modal_header">
          <div className="name_wrapper">
            <h3>
              <FormattedMessage id={'Reward subtypes'} />
            </h3>
            <div className="close_modal" onClick={onCloseModal}>
              <Close />
            </div>
          </div>
        </div>
        <div className="new_subtype_form_wrapper">
          <div className="tabs_input">
            <div className="add_wrapper">
              <Button
                className="add_btn"
                onClick={() => {
                  setTab(null);
                  setModal(true);
                }}
              >
                <FormattedMessage id="Add new subtype" />
                <ArrowForward />
              </Button>
            </div>
          </div>
          <div className="table_header">
            <div className="id">
              <FormattedMessage id="#" />
            </div>
            <div className="name">
              <FormattedMessage id="Subtype name" />
            </div>
            <div className="actions">
              <FormattedMessage id="Actions" />
            </div>
          </div>
          <div className="table">
            {subtypes?.subtabs?.map((item: any, ind: number) => {
              return (
                <>
                  <div key={ind} className="table_group">
                    <div className="id">
                      <p>{item.id}</p>
                    </div>
                    <div className="name">
                      <p>{item.name}</p>
                    </div>
                    <div className="actions">
                      <Tooltip placement="top" title={intl.formatMessage({ id: 'Edit subtype' })}>
                        <Button
                          className="edit_btn"
                          onClick={() => {
                            setTab(item);
                            setModal(true);
                          }}
                        >
                          <Edit />
                        </Button>
                      </Tooltip>
                      <Tooltip placement="top" title={intl.formatMessage({ id: 'Delete subtype' })}>
                        <Button
                          className="edit_btn"
                          onClick={() => {
                            handleDelete(item.id);
                          }}
                        >
                          <Delete />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        {subtypesLoading || (deleteLoading && <Loading visible={subtypesLoading || deleteLoading} />)}
        <SubtypeEditModal
          isOpenModal={isModal}
          onCloseModal={() => {
            setModal(false);
          }}
          refetch={() => refetch()}
          tabsInfo={tab}
        />
      </>
    </ModalDlg>
  );
};

export default SubtypeModal;
