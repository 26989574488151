import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';

const esLocale = require('moment/locale/es');

function DayAnalysis({ data }) {
  return (
    <div className="day_analysis_wrapper">
      <div className="latest_statistic_wrapper">
        <div className="average_delivery_time">
          <h5>
            <FormattedMessage id="Service scheme" />
          </h5>
          <p className="title">
            <FormattedMessage id="Showing data from the last hour in progress" />
          </p>
          <div className="card_item received_card">
            <p className="count_number">{data.info1hr ? parseInt(data.info1hr.avgDeliveryMinutes) : 0} minutos</p>
            <p className="card_description">
              <FormattedMessage id="Average delivery time since orders are received, service time" />
            </p>
          </div>
        </div>
        <div className="real_time_order">
          <h5>
            <FormattedMessage id="Real time orders" />
          </h5>
          <p className="title">
            <FormattedMessage id="Showing data from the last hour in progress" />
          </p>
          <div className="card_row">
            <div className="card_item delivered_card">
              <p className="count_number">{data.info1hr ? data.info1hr.pdLt10 : 0}</p>
              <p className="card_description">
                <FormattedMessage id="Produced in less than 5 minutes" />
              </p>
            </div>
            <div className="card_item solving_card">
              <p className="count_number">{data.info1hr ? data.info1hr.pdGt10 : 0}</p>
              <p className="card_description">
                <FormattedMessage id="Produced in more than 5 minutes" />
              </p>
            </div>
          </div>
          <div className="card_row">
            <div className="card_item production_card">
              <p className="count_number">{data.info1hr ? data.info1hr.ddLt30 : 0}</p>
              <p className="card_description">
                <FormattedMessage id="Delivered in less than 30 minutes" />
              </p>
            </div>
            <div className="card_item sloping_card">
              <p className="count_number">{data.info1hr ? data.info1hr.ddLt45 : 0}</p>
              <p className="card_description">
                <FormattedMessage id="Delivered between 30 and 45 minutes" />
              </p>
            </div>
            <div className="card_item ambassadors_card">
              <p className="count_number">{data.info1hr ? data.info1hr.ddGt45 : 0}</p>
              <p className="card_description">
                <FormattedMessage id="Delivered after 45 minutes" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="total_statistic_wrapper">
        <div className="real_time_order">
          <h5>
            Pedidos del{' '}
            {moment(new Date(), 'DD-MM-YYYY').tz('America/Guatemala').locale('es', esLocale).format('DD MMMM YYYY')}
          </h5>
          <p className="title">
            <FormattedMessage id="Showing data from the restaurant's opening time" />
          </p>
          <div className="card_row">
            <div className="card_item delivered_card">
              <p className="count_number">{data.infoToday ? data.infoToday.pdLt10 : 0}</p>
              <p className="card_description">
                <FormattedMessage id="Produced in less than 5 minutes" />
              </p>
            </div>
            <div className="card_item solving_card">
              <p className="count_number">{data.infoToday ? data.infoToday.pdGt10 : 0}</p>
              <p className="card_description">
                <FormattedMessage id="Produced in more than 5 minutes" />
              </p>
            </div>
          </div>
          <div className="card_row">
            <div className="card_item production_card">
              <p className="count_number">{data.infoToday ? data.infoToday.ddLt30 : 0}</p>
              <p className="card_description">
                <FormattedMessage id="Delivered in less than 30 minutes" />
              </p>
            </div>
            <div className="card_item sloping_card">
              <p className="count_number">{data.infoToday ? data.infoToday.ddLt45 : 0}</p>
              <p className="card_description">
                <FormattedMessage id="Delivered between 30 and 45 minutes" />
              </p>
            </div>
            <div className="card_item ambassadors_card">
              <p className="count_number">{data.infoToday ? data.infoToday.ddGt45 : 0}</p>
              <p className="card_description">
                <FormattedMessage id="Delivered after 45 minutes" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DayAnalysis.defaultProps = {
  data: {},
};

export default DayAnalysis;
