import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Select, Button, DatePicker } from 'antd';
import { DateRange, Refresh, ArrowDropDown } from '@material-ui/icons';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyGetRestaurantListQuery } from 'core/restaurant/RestaurantService';
import { setRestaurantList } from 'core/restaurant/RestaurantSlice';

const { Option } = Select;

function ReportFilter({
  intl,
  onStartFilterChange,
  onEndFilterChange,
  onAmbassadorStateFilter,
  onSector,
  onRestaurant,
  onApplyFilter,
}) {
  const orderStates = [
    { value: 'WP', label: intl.formatMessage({ id: 'WP' }) },
    { value: 'RV', label: intl.formatMessage({ id: 'RV' }) },
    { value: 'IP', label: intl.formatMessage({ id: 'IP' }) },
    { value: 'AS', label: intl.formatMessage({ id: 'AS' }) },
    { value: 'OW', label: intl.formatMessage({ id: 'OW' }) },
    { value: 'AD', label: intl.formatMessage({ id: 'AD' }) },
    { value: 'CL', label: intl.formatMessage({ id: 'CL' }) },
    { value: 'RS', label: intl.formatMessage({ id: 'RS' }) },
    { value: 'DD', label: intl.formatMessage({ id: 'DD' }) },
    { value: 'CD', label: intl.formatMessage({ id: 'CD' }) },
  ];

  const dispatch = useAppDispatch();

  const { list: restaurantList } = useAppSelector(state => state.restaurantReducer);

  const [getRestaurantList] = useLazyGetRestaurantListQuery();

  useEffect(async () => {
    try {
      const res = await getRestaurantList({ filter: '&filter=state eq A' }).unwrap();
      dispatch(setRestaurantList({ list: res }));
    } catch (e) {
      console.log(e);
    }
  }, []);

  const onStartDateChange = (date, dateString) => {
    onStartFilterChange(dateString);
  };

  const onEndDateChange = (date, dateString) => {
    onEndFilterChange(dateString);
  };

  const handleAmbassadorStateFilter = values => {
    onAmbassadorStateFilter(values);
  };

  const handleSectorFilter = value => {
    onSector(value);
  };

  const handleRestaurantFilter = value => {
    onRestaurant(value);
  };

  return (
    <div className="distribution_report_filter_layout">
      <Select
        className="ambassador_state"
        suffixIcon={<ArrowDropDown />}
        mode="multiple"
        placeholder="Productividad de embajadores"
        onChange={handleAmbassadorStateFilter}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {orderStates.map(item => (
          <Option value={item.value} key={item.label} style={{ fontFamily: 'Lato', color: '#242c40' }}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Select
        className="all_sectors"
        suffixIcon={<ArrowDropDown />}
        mode="multiple"
        onChange={v => handleSectorFilter(v)}
        placeholder="Todos los sectores"
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {restaurantList.rows &&
          restaurantList.rows.map(s => (
            <Option value={s.id} key={s.id} style={{ fontFamily: 'Lato', color: '#242c40' }}>
              {s.description || ''}
            </Option>
          ))}
      </Select>
      <Select
        className="all_restaurants"
        suffixIcon={<ArrowDropDown />}
        mode="multiple"
        onChange={v => handleRestaurantFilter(v)}
        placeholder="Todos los restaurantes en el sector"
        maxTagPlaceholder={5}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {restaurantList.rows &&
          restaurantList.rows.map(s => (
            <Option value={s.id} key={s.id} style={{ fontFamily: 'Lato', color: '#242c40' }}>
              {s.description || ''}
            </Option>
          ))}
      </Select>
      <DatePicker
        className="start_date"
        placeholder="Fecha de inicio"
        onChange={onStartDateChange}
        suffixIcon={<DateRange />}
      />
      <DatePicker
        className="end_date"
        placeholder="Fecha límite"
        onChange={onEndDateChange}
        suffixIcon={<DateRange />}
      />
      <Button className="apply_filters_btn" onClick={onApplyFilter}>
        <FormattedMessage id="Apply filters" />
        <Refresh />
      </Button>
    </div>
  );
}

ReportFilter.defaultProps = {
  restaurantList: {},
  onAmbassadorStateFilter: () => {},
  onSector: () => {},
  onRestaurant: () => {},
  onApplyFilter: () => {},
  onStartFilterChange: () => {},
  onEndFilterChange: () => {},
  getRestaurantList: () => {},
};

export default compose(injectIntl)(ReportFilter);
