import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';

type DetailsType = {
  setTitle: (e: string) => void;
  title: string;
  setDescription: (e: string) => void;
  description: string;
  setTerms: (e: string) => void;
  terms: string;
  isValid: boolean;
};

const Details: React.FC<DetailsType> = ({ setTitle, title, setDescription, description, setTerms, terms, isValid }) => {
  const intl = useIntl();
  const handleTitleChange = (e: any) => {
    setTitle(e.target.value.trimStart());
  };
  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value.trimStart());
  };
  const handleTermsChange = (e: any) => {
    setTerms(e.target.value.trimStart());
  };

  const checkField = (value: string) => {
    if (isValid) return;
    if (value) {
      const valid = value.length > 0;
      return !valid;
    } else {
      return true;
    }
  };

  return (
    <>
      <div className="" id="campaign_form_wrapper">
        <div className="basic_info_form">
          <div className="tabs_input">
            <Form.Item>
              <p className="title_deals">
                <FormattedMessage id="Title" />
              </p>
              <div style={{ marginBottom: '20px' }}>
                <TextArea
                  rows={4}
                  placeholder=""
                  value={title}
                  onChange={handleTitleChange}
                  maxLength={200}
                  style={{
                    resize: 'none',
                    backgroundColor: '#F4F5F7',
                    border: !checkField(title) ? 'none' : '',
                  }}
                  className={checkField(title) ? 'has-error_area' : ''}
                />
                {checkField(title) && (
                  <span className="error_msg">
                    <FormattedMessage id="Field is required" />
                  </span>
                )}
              </div>
              <p className="title_deals">
                <FormattedMessage id="Description" />
              </p>
              <div>
                <TextArea
                  rows={4}
                  placeholder=""
                  value={description}
                  onChange={handleDescriptionChange}
                  maxLength={500}
                  style={{
                    marginBottom: !checkField(description) ? '20px' : 0,
                    resize: 'none',
                    backgroundColor: '#F4F5F7',
                    border: !checkField(description) ? 'none' : '',
                  }}
                  className={checkField(description) ? 'has-error_area' : ''}
                />
                {checkField(description) && (
                  <span className="error_msg">
                    <FormattedMessage id="Field is required" />
                  </span>
                )}
              </div>
              <p className="title_deals">
                <FormattedMessage id="Terms" />
              </p>
              <div>
                <TextArea
                  rows={4}
                  placeholder=""
                  value={terms}
                  onChange={handleTermsChange}
                  maxLength={4000}
                  style={{
                    marginBottom: 0,
                    resize: 'none',
                    backgroundColor: '#F4F5F7',
                    border: !checkField(terms) ? 'none' : '',
                  }}
                  className={checkField(terms) ? 'has-error_area' : ''}
                />
                {checkField(terms) && (
                  <span className="error_msg">
                    <FormattedMessage id="Field is required" />
                  </span>
                )}
              </div>
            </Form.Item>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
