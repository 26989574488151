import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Input, Button, Select } from 'antd';
import { Refresh, ArrowDropDown, Search } from '@material-ui/icons';

const { Option } = Select;

function CatalogueFilter({
  intl,
  cataloges,
  searchText,
  onStateFilter,
  onChannelFilter,
  onCategoryFilter,
  onSort,
  onSearch,
  onKeyDownSearch,
  onApplyFilter,
}) {
  const catalogStates = [
    { value: 'A', label: intl.formatMessage({ id: 'Active' }) },
    { value: 'I', label: intl.formatMessage({ id: 'Inactive' }) },
  ];
  const saleChannels = [
    { value: 'ALL', label: 'Todos los canales' },
    { value: 'APP', label: 'APP' },
    { value: 'WEB', label: 'WEB' },
    { value: 'CC', label: 'OTS' },
  ];
  const catalogSorts = [
    { value: 'state desc', label: intl.formatMessage({ id: 'By state' }) },
    { value: 'id desc', label: intl.formatMessage({ id: 'By ID' }) },
    { value: 'productCode desc', label: intl.formatMessage({ id: 'By SKU' }) },
    { value: 'name desc', label: intl.formatMessage({ id: 'By name' }) },
    { value: 'price desc nulls last', label: intl.formatMessage({ id: 'By base price' }) },
    {
      value: 'updatedAt desc',
      label: intl.formatMessage({ id: 'By date of last import' }),
    },
  ];
  const handleStateFilter = values => {
    onStateFilter(values);
  };
  const handleCannelFilter = value => {
    onChannelFilter(value);
  };
  const handleCategoryFilter = value => {
    onCategoryFilter(value);
  };
  const handleSort = value => {
    onSort(value);
  };
  const handleSearch = e => {
    onSearch(e);
  };

  return (
    <div className="catalogue_filter_layout">
      <Select
        className="active_states"
        suffixIcon={<ArrowDropDown />}
        mode="multiple"
        placeholder="estados activos"
        onChange={handleStateFilter}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {catalogStates.map(item => (
          <Option value={item.value} key={item.label}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Select
        className="cannel_filter"
        defaultValue="ALL"
        suffixIcon={<ArrowDropDown />}
        placeholder="Canales"
        onChange={handleCannelFilter}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {saleChannels.map(item => (
          <Option value={item.value} key={item.label}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Select
        className="category_filter"
        defaultValue={0}
        suffixIcon={<ArrowDropDown />}
        placeholder="Todas las categorías"
        onChange={handleCategoryFilter}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        <Option value={0}>Todas las categorías</Option>
        {cataloges &&
          cataloges.map(item => (
            <Option value={item.id} key={item.id}>
              <p dangerouslySetInnerHTML={{ __html: item.name || '' }} style={{ marginBottom: 0 }} />
            </Option>
          ))}
      </Select>
      <Select
        className="sort_by"
        suffixIcon={<ArrowDropDown />}
        placeholder="Ordernar por"
        onChange={handleSort}
        dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
      >
        {catalogSorts.map(item => (
          <Option value={item.value} key={item.label}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Button className="apply_filters_btn" onClick={onApplyFilter}>
        <FormattedMessage id="Apply filters" />
        <Refresh />
      </Button>
      <Input
        suffix={<Search />}
        placeholder="Buscar producto"
        value={searchText}
        onChange={e => handleSearch(e)}
        onKeyDown={e => onKeyDownSearch(e)}
      />
    </div>
  );
}
export default compose(injectIntl)(CatalogueFilter);
