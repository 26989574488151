import { api } from '..';
import {
  OffersListData,
  OffersFullData,
  GetDraftsResponse,
  Draft,
  UpdateDraftDtoType,
  CreateDraftDtoType,
} from './types';

export const offersApi = api.enhanceEndpoints({ addTagTypes: ['Offers'] }).injectEndpoints({
  endpoints: build => ({
    getOffersList: build.query<OffersListData, any>({
      query: ({ queryString }) => ({
        url: `v1/deals/preview?${queryString}`,
        method: 'GET',
      }),
    }),
    createOffers: build.mutation<any, any>({
      query: body => ({
        url: `v1/deals`,
        method: 'POST',
        body: body.offerData,
      }),
    }),
    updateOffers: build.mutation<any, any>({
      query: body => ({
        url: `v1/deals/${body.id}`,
        method: 'PUT',
        body: body.offerData,
      }),
    }),
    deleteOffers: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `v1/deals/${id}`,
        method: 'DELETE',
      }),
    }),
    getFullOffers: build.query<OffersFullData, { id: number }>({
      query: ({ id }) => ({
        url: `v1/deals/${id}/preview`,
        method: 'GET',
      }),
    }),
    getDrafts: build.query<GetDraftsResponse, any>({
      query: params => ({
        url: `v1/deals/drafts?${params}`,
        method: 'GET',
      }),
    }),
    getDraft: build.query<Draft, number>({
      query: id => ({
        url: `v1/deals/drafts/${id}`,
        method: 'GET',
      }),
    }),
    createDraft: build.mutation<Draft, CreateDraftDtoType>({
      query: ({ body }) => ({
        url: `v1/deals/drafts`,
        method: 'POST',
        body,
      }),
    }),
    updateDraft: build.mutation<Draft, UpdateDraftDtoType>({
      query: ({ id, body }) => ({
        url: `v1/deals/drafts/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteDraft: build.mutation<void, number>({
      query: id => ({
        url: `v1/deals/drafts/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetOffersListQuery,
  useCreateOffersMutation,
  useUpdateOffersMutation,
  useDeleteOffersMutation,
  useLazyGetFullOffersQuery,
  useLazyGetDraftsQuery,
  useLazyGetDraftQuery,
  useCreateDraftMutation,
  useUpdateDraftMutation,
  useDeleteDraftMutation,
} = offersApi;
