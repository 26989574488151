import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import { injectIntl, useIntl } from 'react-intl';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import ReportFilter from 'components/Dashboard/Distribution/ReportFilter';
import Loading from 'components/Basic/Loading';
import toast from 'components/Basic/Toast';

const DistributionReport = () => {
  const intl = useIntl();
  const timeInterval = useRef();

  const [state, setState] = useState({
    stateFilter: '',
    restaurantFilter: '',
    startDate: '',
    endDate: '',
    isLoading: false,
  });

  useEffect(() => {
    return () => {
      clearInterval(timeInterval.current);
    };
  }, []);

  const handleAmbassadorStateFilter = values => {
    if (values.length !== 0) {
      let filterQuery = '&filter=state in';
      values.map(v => {
        filterQuery += ` ${v}`;
        return filterQuery;
      });
      setState(prev => ({ ...prev, stateFilter: filterQuery }));
    } else {
      setState(prev => ({ ...prev, stateFilter: '' }));
    }
  };

  const handleSectorFilter = values => {
    if (values.length !== 0) {
      let filterQuery = '&filter=restaurant in';
      values.map(v => {
        filterQuery += ` ${v}`;
        return filterQuery;
      });
      setState(prev => ({ ...prev, restaurantFilter: filterQuery }));
    } else {
      setState(prev => ({ ...prev, restaurantFilter: '' }));
    }
  };

  const handleRestaurantFilter = values => {
    if (values.length !== 0) {
      let filterQuery = '&filter=restaurant in';
      values.map(v => {
        filterQuery += ` ${v}`;
        return filterQuery;
      });
      setState(prev => ({ ...prev, restaurantFilter: filterQuery }));
    } else {
      setState(prev => ({ ...prev, restaurantFilter: '' }));
    }
  };

  const handleStartFilter = dateString => {
    setState(prev => ({ ...prev, startDate: dateString }));
  };

  const handleEndFilter = dateString => {
    setState(prev => ({ ...prev, endDate: dateString }));
  };

  const handleApplyFilter = () => {
    const { stateFilter, restaurantFilter, startDate, endDate } = state;
    let filterDate = '';
    if (startDate && endDate) {
      const start = moment(startDate).tz('America/Guatemala').startOf('day').format('YYYY-MM-DDTHH:mm:ss');
      const end = moment(endDate).tz('America/Guatemala').endOf('day').format('YYYY-MM-DDTHH:mm:ss');
      filterDate = `&filter=dueTo between ${start} ${end}`;
    }
    setState(prev => ({ ...prev, filterQuery: stateFilter + restaurantFilter + filterDate }));
  };

  return (
    <div className="distribution_report_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader type="distribution_report" />
      <div className="distribution_report_filter_wrapper">
        <ReportFilter
          onAmbassadorStateFilter={handleAmbassadorStateFilter}
          onSector={handleSectorFilter}
          onRestaurant={handleRestaurantFilter}
          onStartFilterChange={handleStartFilter}
          onEndFilterChange={handleEndFilter}
          onApplyFilter={handleApplyFilter}
        />
      </div>
    </div>
  );
};

export default DistributionReport;
