import OffersDraftFilter from 'components/Dashboard/Offers/OffersDraftFilter';
import OffersDraftTable from 'components/Dashboard/Offers/OffersDraftTable';
import { useLazyGetDraftsQuery } from 'core/offers/OffersService';
import { setDefaultFullData } from 'core/offers/OffersSlice';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import toast from 'components/Basic/Toast';
import { useLazyGetCampaignListQuery } from 'core/campaign/CampaignService';
import { useLazyGetCategoriesListQuery } from 'core/categories/CategoriesService';
import { useLazyGetTabsListQuery } from 'core/tabs/TabsService';
import Loading from 'components/Basic/Loading';
import { useParams } from 'react-router-dom';

const OffersDraft: FC = () => {
  const intl = useIntl();

  const defaultPage = 1;
  const defaultPageSize = 10;
  const pageSizeOptions = ['5', '10', '20', '30'];

  const sorts = [
    { value: 'Idesc', label: intl.formatMessage({ id: 'ID (desc)' }) },
    { value: 'Iasc', label: intl.formatMessage({ id: 'ID (asc)' }) },
    { value: 'Nasc', label: intl.formatMessage({ id: 'Name (A-Z)' }) },
    { value: 'Ndesc', label: intl.formatMessage({ id: 'Name (Z-A)' }) },
    { value: 'Wdesc', label: intl.formatMessage({ id: 'Weight (desc)' }) },
    { value: 'Wasc', label: intl.formatMessage({ id: 'Weight (asc)' }) },
    { value: 'Ddesc', label: intl.formatMessage({ id: 'Date (Newest first)' }) },
    { value: 'Dasc', label: intl.formatMessage({ id: 'Date (Oldest first)' }) },
  ];

  const [isLoading, setIsLoading] = useState(false);

  const [tabs, setTabs] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [campaigns, setCampaings] = useState<any>([]);

  const [page, setPage] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pageSortField, setPageSortField] = useState('id');
  const [pageSortType, setPageSortType] = useState('desc');
  const [searchText, setSearchText] = useState('');
  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [drafts, setDrafts] = useState<any>({ drafts: [], totalCount: 0 });
  const { draftId } = useParams<{ draftId: string }>();

  const dispatch = useAppDispatch();
  const [getDrafts] = useLazyGetDraftsQuery();
  const [getTabsList] = useLazyGetTabsListQuery();
  const [getCategoriesList] = useLazyGetCategoriesListQuery();
  const [getCampaignList] = useLazyGetCampaignListQuery();

  const generateQueryString = (page: number, size: number) => {
    const pageParam = page ? `page=${page}` : '';
    const pageSizeParam = size ? `size=${size}` : '';
    const sortParam = `sort[${pageSortField}]=${pageSortType}`;
    const searchParam = searchText.trim() !== '' ? `search=${searchText}` : '';
    const tabParam = selectedTab ? `filter[tab]=${selectedTab}` : '';
    const categoryParam = selectedCategory ? `filter[category]=${selectedCategory}` : '';
    const campaignParam = selectedCampaign ? `filter[campaign]=${selectedCampaign}` : '';
    const startDateParam = startDate !== '' ? `filter[startDate]=${startDate}` : '';
    const endDateParam = endDate !== '' ? `filter[endDate]=${endDate}` : '';

    const allPatams = [
      pageParam,
      pageSizeParam,
      sortParam,
      searchParam,
      tabParam,
      categoryParam,
      campaignParam,
      startDateParam,
      endDateParam,
    ];

    return allPatams.filter(s => s !== '').join('&');
  };

  const initRequest = async () => {
    try {
      setIsLoading(true);

      const params = generateQueryString(defaultPage, defaultPageSize);
      const draftsResponse = await getDrafts(params).unwrap();

      const tabsResponse = await getTabsList({ search: '' }).unwrap();
      const categoriesResponse = await getCategoriesList({ queryString: '' }).unwrap();
      const campaignResponse = await getCampaignList({ queryString: '' }).unwrap();

      setDrafts(draftsResponse);
      setTabs(tabsResponse.tabs);
      setCategories(categoriesResponse.categories);
      setCampaings(campaignResponse.campaigns);
    } catch (error: any) {
      toast.error({ title: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const draftsRequest = async (page: number, size: number) => {
    try {
      setIsLoading(true);

      const params = generateQueryString(page, size);
      const response = await getDrafts(params).unwrap();

      setDrafts(response);
    } catch (error: any) {
      toast.error({ title: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (page: number, size: number) => {
    setPage(page);
    setPageSize(size);
    draftsRequest(page, size);
  };

  const onChangePageSize = (page: number, size: number) => {
    setPage(page);
    setPageSize(size);
    draftsRequest(page, size);
  };

  const handleSearch = async (e: any) => {
    const searchTextValue = e.target.value;
    setSearchText(searchTextValue);

    if (e.keyCode === 13) {
      draftsRequest(page, pageSize);
    }
  };

  const handleSort = (value: any) => {
    setPageSortType(value.substring(1));

    switch (value[0]) {
      case 'I':
        setPageSortField('id');
        break;
      case 'N':
        setPageSortField('name');
        break;
      case 'W':
        setPageSortField('weight');
        break;
      case 'D':
        setPageSortField('date');
        break;
      default:
        setPageSortField('id');
        break;
    }
  };

  const onApplyFilter = () => {
    setPage(defaultPage);
    draftsRequest(defaultPage, pageSize);
  };

  useEffect(() => {
    dispatch(setDefaultFullData());
    initRequest();
  }, []);

  return (
    <div>
      <OffersDraftFilter
        sorts={sorts}
        selectedTab={selectedTab}
        selectedCategory={selectedCategory}
        selectedCampaign={selectedCampaign}
        searchText={searchText}
        tabs={tabs}
        categories={categories}
        campaigns={campaigns}
        handleSearch={handleSearch}
        onSort={handleSort}
        onApplyFilter={onApplyFilter}
        setSelectedTab={setSelectedTab}
        setSelectedCategory={setSelectedCategory}
        setSelectedCampaign={setSelectedCampaign}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
      <OffersDraftTable
        defaultPage={defaultPage}
        defaultPageSize={defaultPageSize}
        pageSizeOptions={pageSizeOptions}
        page={page}
        pageSize={pageSize}
        drafts={drafts}
        draftsRequest={draftsRequest}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        draftId={draftId}
      />
      {isLoading && <Loading visible={isLoading} />}
    </div>
  );
};

export default OffersDraft;
