import React from 'react';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import SarConfigForm from 'containers/Dashboard/SarConfig/index';

const SarConfig = () => (
  <div className="sar_config_layout">
    <ContentHeader type="sar_config" />
    <div className="sar_config_wrapper">
      <SarConfigForm />
    </div>
  </div>
);

export default SarConfig;
