import React from 'react';
import PropTypes from 'prop-types';
import { AreaChart, XAxis, YAxis, CartesianGrid, Area, Tooltip, ResponsiveContainer } from 'recharts';

class CustomizedXAxisTick extends React.PureComponent {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={18} fill="#687992" fontSize={14}>
          {payload.value}
        </text>
      </g>
    );
  }
}

class CustomizedYAxisTick extends React.PureComponent {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dx={-20} fill="#687992" fontSize={7}>
          {payload.value}
        </text>
      </g>
    );
  }
}

function SaleChart({ data }) {
  return (
    <div className="sale_chart">
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <XAxis dataKey="name" axisLine={false} tickLine={false} tick={<CustomizedXAxisTick />} />
          <YAxis axisLine={false} tickLine={false} type="number" tick={<CustomizedYAxisTick />} />
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={false} />
          <Tooltip />
          <Area
            dataKey="Última"
            stroke="#4FCAC0"
            strokeWidth={3}
            fillOpacity={0.26}
            fill="#4FCAC0"
            dot={{
              stroke: '#4FCAC0',
              strokeWidth: 1,
              fill: 'white',
              fillOpacity: 1,
            }}
            stackId="2"
          />
          <Area
            dataKey="Actual"
            stroke="#FFC200"
            fillOpacity={0.62}
            strokeWidth={3}
            fill="rgba(255, 194, 0, 0.3)"
            dot={{
              stroke: '#FFC200',
              strokeWidth: 1,
              fill: 'white',
              fillOpacity: 1,
            }}
            stackId="1"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

CustomizedXAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
};

CustomizedXAxisTick.defaultProps = {
  x: 0,
  y: 0,
  payload: {},
};
CustomizedYAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
};

CustomizedYAxisTick.defaultProps = {
  x: 0,
  y: 0,
  payload: {},
};

SaleChart.defaultProps = {
  data: [],
};

export default SaleChart;
