import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Pagination, Switch, Select } from 'antd';
import { ArrowDropDown } from '@material-ui/icons';
import toast from 'components/Basic/Toast';
import { useUpdateRestaurantMutation } from 'core/restaurant/RestaurantService';

const { Option } = Select;

const MaxOrderList = [
  { value: 1, label: '1 Pedidos' },
  { value: 2, label: '2 Pedidos' },
  { value: 3, label: '3 Pedidos' },
  { value: 4, label: '4 Pedidos' },
  { value: 5, label: '5 Pedidos' },
];

const MaxDistances = [
  { value: 0.1, label: '100 metros' },
  { value: 0.2, label: '200 metros' },
  { value: 0.3, label: '300 metros' },
  { value: 0.4, label: '400 metros' },
  { value: 0.5, label: '500 metros' },
  { value: 0.6, label: '600 metros' },
  { value: 0.7, label: '700 metros' },
  { value: 0.8, label: '800 metros' },
];

const ServiceTimes = new Array(18).fill().map((_, index) => ({ value: 5 * index, label: `${5 * index} minutos` }));

function TrafficMonitorTable({ intl, restaurantData, total, onChangePage }) {
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]);

  const [updateRestaurant] = useUpdateRestaurantMutation();

  useEffect(() => {
    onChangePage(0, 10);
  }, []);

  useEffect(() => {
    setData(restaurantData || []);
  }, [restaurantData]);

  const handleChangePage = (page, size) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage((page - 1) * size, size);
  };

  const handleShowSizeChange = (c, s) => {
    handleChangePage(1, s);
  };

  const handleUpdate = async (idx, field, v) => {
    const param = {};
    const newData = [...data];
    if (field === 'state') {
      param[field] = v ? 'A' : 'I';
      newData[idx] = { ...newData[idx], [field]: v ? 'A' : 'I' };
    } else {
      param[field] = v;
      newData[idx] = { ...newData[idx], [field]: v };
    }
    setData(newData);

    try {
      await updateRestaurant({
        id: newData[idx].id,
        restaurantInfo: param,
      }).unwrap();
      toast.success({
        title: intl.formatMessage({
          id: 'Restaurant is updated successfully!',
        }),
      });
    } catch (e) {
      toast.error({
        title: intl.formatMessage({ id: 'Updating restaurant is failure!' }),
      });
    }
  };

  return (
    <div className="traffic_monitor_table_layout">
      <div className="table_header">
        <div className="restaurant">
          <FormattedMessage id="Restaurant" />
        </div>
        <div className="service">
          <FormattedMessage id="Service" />
        </div>
        <div className="geofence">
          <FormattedMessage id="Bypass" />
        </div>
        <div className="grouping">
          <FormattedMessage id="Grouping" />
        </div>
        <div className="maximum_orders">
          <FormattedMessage id="Maximum orders" />
        </div>
        <div className="maximum_distance">
          <FormattedMessage id="Maximum distance for grouping" />
        </div>
        <div className="additional_service">
          <FormattedMessage id="Additional current service time" />
        </div>
      </div>
      <div className="table_content">
        {data &&
          data.map((item, index) => (
            <div className="table_item" key={index}>
              <div className="restaurant">
                <p className="title">{item.description || ''}</p>
              </div>
              <div className="service">
                <Switch size="small" checked={item.state === 'A'} onChange={e => handleUpdate(index, 'state', e)} />
              </div>
              <div className="geofence">
                <Switch
                  size="small"
                  checked={item.bypassEnabled}
                  onChange={e => handleUpdate(index, 'bypassEnabled', e)}
                />
              </div>
              <div className="grouping">
                <Switch
                  size="small"
                  checked={item.groupingEnabled}
                  onChange={e => handleUpdate(index, 'groupingEnabled', e)}
                />
              </div>
              <div className="maximum_orders">
                <Select
                  style={{ width: 106 }}
                  suffixIcon={<ArrowDropDown />}
                  value={item.maxGroupOrderCount}
                  onChange={v => handleUpdate(index, 'maxGroupOrderCount', v)}
                  dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                >
                  {MaxOrderList.map(s => (
                    <Option value={s.value}>{s.label}</Option>
                  ))}
                </Select>
              </div>
              <div className="maximum_distance">
                <Select
                  style={{ width: 140 }}
                  suffixIcon={<ArrowDropDown />}
                  value={item.maxDistanceForGroup}
                  onChange={v => handleUpdate(index, 'maxDistanceForGroup', v)}
                  dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                >
                  {MaxDistances.map(s => (
                    <Option value={s.value}>{s.label}</Option>
                  ))}
                </Select>
              </div>
              <div className="additional_service">
                <Select
                  style={{ width: 140 }}
                  suffixIcon={<ArrowDropDown />}
                  defaultValue={0}
                  value={item.deliveryTime2}
                  onChange={v => handleUpdate(index, 'deliveryTime2', v)}
                  dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                >
                  {ServiceTimes.map(s => (
                    <Option value={s.value}>{s.label}</Option>
                  ))}
                </Select>
              </div>
            </div>
          ))}
      </div>
      <div className="pagination_wrapper">
        <Pagination
          showSizeChanger
          defaultCurrent={1}
          defaultPageSize={10}
          current={current}
          pageSize={pageSize}
          pageSizeOptions={['5', '10', '20', '30']}
          total={total}
          onChange={handleChangePage}
          onShowSizeChange={handleShowSizeChange}
        />
      </div>
    </div>
  );
}

TrafficMonitorTable.defaultProps = {
  restaurantData: [],
  total: 0,
};

export default compose(injectIntl)(TrafficMonitorTable);
