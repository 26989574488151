import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import CouponFilter from 'components/Dashboard/Coupons/CouponFilter';
import CouponTable from 'components/Dashboard/Coupons/CouponTable';
import CouponNewModal from 'components/Dashboard/Coupons/CouponNewModal';
import toast from 'components/Basic/Toast';
import Loading from 'components/Basic/Loading';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import {
  useLazyGetCouponListQuery,
  useCreateCouponMutation,
  useUpdateCouponMutation,
  useDeleteCouponMutation,
} from 'core/coupon/CouponService';
import { setCouponData } from 'core/coupon/CouponSlice';
import { duplicationErrorCodes } from '../../../utilities/constants';

const CouponList = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [state, setState] = useState({
    searchText: '',
    offset: 0,
    limit: 10,
    filterQuery: '',
    searchFilterQuery: '',
    sort: '',
    isCreateCoupon: true,
    coupon: {},
    isLoading: false,
  });

  const { list } = useAppSelector(state => state.couponReducer);

  const [getCouponList] = useLazyGetCouponListQuery();
  const [createCoupon] = useCreateCouponMutation();
  const [updateCoupon] = useUpdateCouponMutation();
  const [deleteCoupon] = useDeleteCouponMutation();

  const handleChangePage = async (offset, limit, filterQuery, sort) => {
    setState(prev => ({ ...prev, offset: offset, limit: limit, isLoading: true }));

    try {
      const res = await getCouponList({
        offset,
        limit,
        or: false,
        filter: filterQuery || state.filterQuery + state.searchFilterQuery,
        sort: sort || state.sort,
      }).unwrap();
      dispatch(setCouponData({ list: res }));
      setState(prev => ({ ...prev, isLoading: false }));
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const handleStateFilter = values => {
    if (values.length !== 0) {
      let filterQuery = '&filter=state in';
      values.map(v => {
        filterQuery += ` ${v}`;
        return filterQuery;
      });
      setState(prev => ({ ...prev, filterQuery: filterQuery }));
    } else {
      setState(prev => ({ ...prev, filterQuery: '' }));
    }
  };

  const handleSort = value => {
    setState(prev => ({ ...prev, sort: value }));
  };

  const handleSearch = e => {
    setState(prev => ({ ...prev, searchText: e.target.value }));
  };

  const handleKeyDownSearch = async e => {
    let searchFilterQuery = '';
    if (e.keyCode === 13) {
      const { offset, limit, filterQuery, sort, searchText } = state;
      if (searchText) {
        if (!isNaN(searchText, 10) && searchText < 100000000) {
          searchFilterQuery += `&filter=or.id eq ${searchText}`;
        } else {
          searchFilterQuery += `&filter=or.code iLike %25${searchText}%25&filter=or.name iLike %25${searchText}%25`;
        }
      } else {
        searchFilterQuery = '';
      }
      setState(prev => ({ ...prev, searchFilterQuery: searchFilterQuery, isLoading: true }));
      try {
        const res = await getCouponList({
          offset: 0,
          limit: 10,
          or: false,
          filter: filterQuery + searchFilterQuery,
          sort: sort,
        }).unwrap();
        dispatch(setCouponData({ list: res }));
        setState(prev => ({ ...prev, isLoading: false }));
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
      }
    }
  };

  const handleApplyFilter = async () => {
    const { filterQuery, sort, searchFilterQuery } = state;
    setState(prev => ({ ...prev, isLoading: true }));
    try {
      const res = await getCouponList({
        offset: 0,
        limit: 10,
        or: false,
        filter: filterQuery + searchFilterQuery,
        sort: sort,
      }).unwrap();
      dispatch(setCouponData({ list: res }));
      setState(prev => ({ ...prev, isLoading: false }));
    } catch (e) {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const handleSubmitCoupon = async values => {
    const { isCreateCoupon, coupon } = state;
    setState(prev => ({ ...prev, isShowCouponModal: false, isLoading: true }));

    if (isCreateCoupon) {
      try {
        await createCoupon({ values }).unwrap();
        toast.success({
          title: intl.formatMessage({
            id: 'Coupon is created successfully',
          }),
        });
        handleApplyFilter();
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
        if (duplicationErrorCodes.includes(e.data.code)) {
          toast.error({
            title: intl.formatMessage({
              id: 'The coupon code is already in use',
            }),
          });
        } else {
          toast.error({
            title: intl.formatMessage({
              id: 'Coupon creation is failure',
            }),
          });
        }
      }
    } else {
      try {
        await updateCoupon({ id: coupon.id, ...values }).unwrap();
        toast.success({
          title: intl.formatMessage({
            id: 'Coupon is updated successfully',
          }),
        });
        handleApplyFilter();
      } catch (e) {
        setState(prev => ({ ...prev, isLoading: false }));
        if (err.msg.data.type === 'DuplicationError') {
          toast.error({
            title: intl.formatMessage({
              id: 'The coupon code is already in use',
            }),
          });
        } else {
          toast.error({
            title: intl.formatMessage({
              id: 'Updating coupon is failure',
            }),
          });
        }
      }
    }
  };

  const handleDeleteCoupon = async couponInfo => {
    try {
      await deleteCoupon({ id: couponInfo.id }).unwrap();
      toast.success({
        title: intl.formatMessage({
          id: 'Coupon is deleted successfully',
        }),
      });
      handleApplyFilter();
    } catch (e) {
      toast.error({
        title: intl.formatMessage({
          id: 'Deleting coupon is failure',
        }),
      });
    }
  };

  const handleEditCoupon = coupon => {
    setState(prev => ({
      ...prev,
      isShowCouponModal: true,
      coupon,
      isCreateCoupon: false,
    }));
  };

  const { coupon } = state;

  return (
    <div className="coupon_list_layout">
      <Loading visible={state.isLoading} />
      <ContentHeader
        type="coupon_list"
        onNewCoupon={() => setState(prev => ({ ...prev, isShowCouponModal: true, isCreateCoupon: true }))}
      />
      <div className="coupon_filter_wrapper">
        <CouponFilter
          searchText={state.searchText}
          onStateFilter={handleStateFilter}
          onSort={handleSort}
          onSearch={handleSearch}
          onKeyDownSearch={handleKeyDownSearch}
          onApplyFilter={handleApplyFilter}
        />
        <CouponTable
          couponData={list.rows || []}
          onDeleteCoupon={handleDeleteCoupon}
          onEditCoupon={handleEditCoupon}
          onChangePage={handleChangePage}
          total={list.totalCount}
        />
      </div>
      <CouponNewModal
        isOpenModal={state.isShowCouponModal}
        isCreateCoupon={state.isCreateCoupon}
        coupon={coupon}
        onSubmitCoupon={handleSubmitCoupon}
        onCloseModal={() => setState(prev => ({ ...prev, isShowCouponModal: false }))}
      />
    </div>
  );
};

CouponList.defaultProps = {
  list: {},
};

export default CouponList;
