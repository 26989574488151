import React from 'react';
import PropTypes from 'prop-types';

function Loading({ visible, ...props }) {
  if (visible) {
    return (
      <div className="loading-wrapper" {...props}>
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
          </svg>
        </div>
      </div>
    );
  }
  return null;
}

Loading.defaultProps = {
  visible: false,
};

export default Loading;
