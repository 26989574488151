import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initState, ProductsData, PricesData, SizesData, CatalogesData, SuperCatalogesData } from './types';

const initialState: initState = {
  cataloges: [
    {
      id: 0,
      name: '',
      channel: '',
      daypart: '',
      colorSource: '',
      superClassCode: 0,
      ordering: 0,
      description: '',
      image1: '',
      image2: '',
      state: '',
      createdAt: '',
      updatedAt: '',
    },
  ],
  prices: [
    {
      createdAt: '',
      id: 0,
      price: 0,
      product: 0,
      state: '',
      updatedAt: '',
    },
  ],
  products: {
    limit: 0,
    offset: 0,
    rows: [
      {
        id: 0,
        item: 0,
        name: '',
        channel: '',
        usaSpecial: '',
        isDessert: '',
        colorSource: '',
        productCode: 0,
        type: '',
        nutritionInfo: 0,
        classCode: 0,
        isCombo: '',
        isPromo: '',
        ordering: 0,
        description: '',
        description2: '',
        sku: 0,
        price: 0,
        image1: '',
        image2: '',
        image3: '',
        startTime: '',
        endTime: '',
        orders: 0,
        state: '',
        points: 0,
        createdAt: '',
        updatedAt: '',
      },
    ],
    totalCount: 0,
  },
  sizes: [
    {
      id: 0,
      name: '',
      size: 0,
      colorSource: '',
      productCode: 0,
      defaultValue: '',
      item: 0,
      validAvaiability: '',
      classCode: 0,
      ordering: 0,
      description: '',
      description2: '',
      image1: '',
      state: '',
      createdAt: '',
      updatedAt: '',
    },
  ],
  superCataloges: [
    {
      id: 0,
      name: '',
      channel: '',
      colorSource: '',
      ordering: 0,
      description: '',
      image1: '',
      image2: '',
      image3: '',
      state: '',
      customizedImage: '',
      customizedSelectedImage: '',
      orderOncePerCustomer: false,
      createdAt: '',
      updatedAt: '',
    },
  ],
  synced: {},
};

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setProductsData(state: initState, action: PayloadAction<{ products: ProductsData }>) {
      state.products = action.payload.products;
    },
    setPricesData(state: initState, action: PayloadAction<{ prices: PricesData }>) {
      state.prices = action.payload.prices;
    },
    setSizesData(state: initState, action: PayloadAction<{ sizes: SizesData }>) {
      state.sizes = action.payload.sizes;
    },
    setCatalogesData(state: initState, action: PayloadAction<{ cataloges: CatalogesData }>) {
      state.cataloges = action.payload.cataloges;
    },
    setSuperCatalogsData(state: initState, action: PayloadAction<{ superCataloges: SuperCatalogesData }>) {
      state.superCataloges = action.payload.superCataloges;
    },
  },
});

export const { setProductsData, setPricesData, setSizesData, setCatalogesData, setSuperCatalogsData } =
  catalogSlice.actions;

export default catalogSlice.reducer;
