import React, { useEffect, useState } from 'react';
import { CloudDownload } from '@material-ui/icons';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Button, Pagination, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { compose } from 'redux';

const AnalyticalDownloadTable = ({ intl, filesList, total, handlerDownloadFile }) => {
  const [files, setFiles] = useState(filesList);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    onChangePage(0, 10);
  }, [filesList]);
  const onChangePage = (startElement, endElement) => {
    setFiles(filesList.slice(startElement, startElement + endElement));
  };
  const handleChangePage = (page, size) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage((page - 1) * size, size);
  };

  const handleShowSizeChange = (c, s) => {
    handleChangePage(1, s);
  };

  return (
    <div className="report_table_layout">
      <div className="table_header">
        <div className="name">
          <FormattedMessage id="Name" />
        </div>
        <div className="actions">
          <FormattedMessage id="Actions" />
        </div>
      </div>
      <div className="table_content">
        {files &&
          files.map((item, index) => (
            <div className="table_item" key={index}>
              <div className="name">
                <p>{`${item}`}</p>
              </div>
              <div className="actions">
                <Tooltip placement="top" title={intl.formatMessage({ id: 'Download report' })}>
                  <Button
                    className="detail_btn"
                    name={item}
                    onClick={item => {
                      handlerDownloadFile(item.currentTarget.name);
                    }}
                  >
                    <CloudDownload />
                  </Button>
                </Tooltip>
              </div>
            </div>
          ))}
      </div>
      <div className="pagination_wrapper">
        <Pagination
          showSizeChanger
          defaultCurrent={1}
          defaultPageSize={10}
          current={current}
          pageSize={pageSize}
          pageSizeOptions={['5', '10', '20', '30']}
          total={total}
          onChange={handleChangePage}
          onShowSizeChange={handleShowSizeChange}
        />
        <div className="total">Resultados: {total}</div>
      </div>
    </div>
  );
};

AnalyticalDownloadTable.defaultProps = {
  filesList: [],
  total: 0,
};
export default compose(injectIntl)(AnalyticalDownloadTable);
