import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import moment from 'moment-timezone';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Select, Input, Button, DatePicker, Switch } from 'antd';
import { Form, Icon } from '@ant-design/compatible';
import ModalDlg from 'components/Basic/ModalDlg';
import { ArrowForward, ArrowDropDown } from '@material-ui/icons';
import toast from 'components/Basic/Toast';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyGetItemsQuery } from 'core/catalog/CatalogService';
import { setProductsData } from 'core/catalog/CatalogSlice';

const { Option } = Select;
dayjs.extend(utc);
dayjs.extend(timezone);

const CouponNewModal = ({ isCreateCoupon, coupon, intl, form, isOpenModal, onCloseModal, onSubmitCoupon }) => {
  const quantitys = [];
  for (let i = 1; i <= 10; i += 1) {
    quantitys.push({
      value: i,
      label: i,
    });
  }

  const rules = [];
  for (let i = 100; i <= 50000; i += 100) {
    rules.push({
      value: i,
      label: i,
    });
  }

  const userLimit = [];
  for (let i = 1; i <= 50; i += 1) {
    userLimit.push({
      value: i,
      label: i,
    });
  }

  const types = [
    // { value: 'PO', label: intl.formatMessage({ id: 'Percent discount to the order' }) },
    // { value: 'FO', label: intl.formatMessage({ id: 'Fixed discount to the order' }) },
    // { value: 'MO', label: intl.formatMessage({ id: 'Free product for minimum order' }) },
    {
      value: 'MP',
      label: intl.formatMessage({
        id: 'Free product for a minimum of the same products',
      }),
    },
    // { value: 'PP', label: intl.formatMessage({ id: 'Product percentage discount' }) },
    // { value: 'FP', label: intl.formatMessage({ id: 'Fixed discount to the product' }) },
    // { value: 'FS', label: intl.formatMessage({ id: 'Free shipping' }) },
    // { value: 'LU', label: intl.formatMessage({ id: 'Benefits by level of users' }) },
  ];

  const conditions = {
    CO: [
      {
        value: 'CO',
        label: intl.formatMessage({ id: 'Customer must enter Coupon Only' }),
      },
      {
        value: 'MV',
        label: intl.formatMessage({
          id: 'The customer must reach a Minimum Value in the cart',
        }),
      },
      {
        value: 'MQ',
        label: intl.formatMessage({
          id: 'Have a Minimum Quantity of the same product',
        }),
      },
    ],
    MP: [
      {
        value: 'CO',
        label: intl.formatMessage({ id: 'Customer must enter Coupon Only' }),
      },
      {
        value: 'SP',
        label: intl.formatMessage({
          id: 'The customer must have the Same Product within the order',
        }),
      },
    ],
  };

  const [couponInfo, setCouponInfo] = useState({
    code: '',
    name: '',
    type: 'MP',
    condition: conditions.MP[0].value,
    rewards: [
      {
        product: -1,
        quantity: 1,
      },
    ],
    totalCount: '',
    userLimit: '',
    description: '',
    startAt: null,
    endAt: null,
    state: 'A',
  });

  const { products } = useAppSelector(state => state.catalogReducer);

  const dispatch = useAppDispatch();
  const [getItems] = useLazyGetItemsQuery();

  useEffect(() => {
    const getProducts = async () => {
      try {
        const res = await getItems({ filter: '&filter=state eq A&filter=channel eq APP' }).unwrap();
        dispatch(setProductsData({ products: res }));
      } catch (err) {
        console.log(err);
      }
    };
    getProducts();
  }, []);

  useEffect(() => {
    if (isCreateCoupon) {
      form.resetFields();
      setCouponInfo({
        code: '',
        name: '',
        type: 'MP',
        condition: conditions.MP[0].value,
        rewards: [
          {
            product: -1,
            quantity: 1,
          },
        ],
        totalCount: '',
        userLimit: '',
        description: '',
        startAt: null,
        endAt: null,
        state: 'A',
      });
    } else {
      form.resetFields();
      setCouponInfo({ ...coupon });
    }
  }, [isOpenModal]);

  const { getFieldDecorator } = form;

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      const startDate = new Date(values.startAt);
      let endDate = new Date(values.endAt);

      const timeZone = new Date().getTimezoneOffset() / 60;
      endDate.setHours(23 - timeZone);
      endDate.setMinutes(59);
      endDate.setSeconds(59);

      const isRightDate = startDate.getTime() < endDate.getTime();

      if (!isRightDate) {
        toast.error({
          title: intl.formatMessage({
            id: 'The finish date must be later than the start date',
          }),
        });
      }

      const newStartDateAt = moment(values.startAt.toString()).tz('America/Guatemala');
      const newEndDateAt = moment(endDate.toString()).tz('America/Guatemala');

      if (!err && isRightDate) {
        const params = {
          code: values.code.toUpperCase(),
          name: values.name,
          type: values.type,
          condition: values.condition,
          totalCount: values.ruler,
          userLimit: values.limit,
          description: values.description,
          startAt: newStartDateAt.toString(),
          endAt: newEndDateAt.toString(),
          rewards: [],
          state: values.state ? 'A' : 'I',
        };

        for (let i = 0; i < couponInfo.rewards.length; i += 1) {
          params.rewards.push({
            product: values[`product${i}`],
            quantity: values[`quantity${i}`],
          });
        }
        onSubmitCoupon(params);
        onCloseModal();
      }
    });
  };

  const handletype = type => {
    form.setFieldsValue({
      type,
    });
    setCouponInfo({ ...couponInfo, type });
  };

  const handleProductChange = v => {
    console.log('v', v);
  };

  const handleAddOutCome = () => {
    setCouponInfo({
      ...couponInfo,
      rewards: [
        ...couponInfo.rewards,
        {
          product: -1,
          quantity: 1,
        },
      ],
    });
  };

  const handleRemoveOutCome = index => {
    setCouponInfo({
      ...couponInfo,
      rewards: [...couponInfo.rewards.filter((r, i) => i !== index)],
    });
  };

  const valueCondition = () => {
    if (isCreateCoupon) {
      return conditions[couponInfo.type][0].value;
    } else {
      const valueCondition = conditions['MP'].find(item => {
        return coupon.condition === item.value;
      });
      return valueCondition.value;
    }
  };

  return (
    <ModalDlg className="coupon_new_modal" visible={isOpenModal} width={576} handleCancel={onCloseModal} isCenter>
      <div className="modal_header">
        <h3>
          {isCreateCoupon ? <FormattedMessage id="Create a new coupon" /> : <FormattedMessage id="Edit coupon" />}
        </h3>
        <p className="description">
          <FormattedMessage id="Complete the coupon conditional logic form to create" />
        </p>
      </div>
      <Form onSubmit={handleSubmit}>
        <div className="modal_content">
          <Form.Item>
            <p className="title">
              <FormattedMessage id="Coupon name" />
            </p>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'Coupon name is required!',
                  }),
                },
              ],
              initialValue: couponInfo.name,
            })(<Input />)}
          </Form.Item>
          <Form.Item>
            <p className="title">
              <FormattedMessage id="Coupon Type" />
            </p>
            {getFieldDecorator('type', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'Action type is required!',
                  }),
                },
              ],
              initialValue: couponInfo.type || 'MP',
            })(
              <Select
                placeholder="Action type"
                suffixIcon={<ArrowDropDown />}
                onChange={handletype}
                dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
              >
                {types.map((item, ind) => (
                  <Select.Option value={item.value} key={ind}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          {couponInfo.type && couponInfo.type !== 'LU' && (
            <div>
              <p className="title">
                <FormattedMessage id="Condition" />
              </p>
              <div className="condition_wrapper">
                <Form.Item>
                  {getFieldDecorator('condition', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'Condition is required!',
                        }),
                      },
                    ],
                    initialValue: valueCondition(),
                  })(
                    <Select
                      placeholder="Seleccionar Condición"
                      suffixIcon={<ArrowDropDown />}
                      dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                    >
                      {conditions[couponInfo.type].map((item, ind) => (
                        <Select.Option value={item.value} key={ind}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
                {(couponInfo.type === 'FO' || couponInfo.type === 'PO') && (
                  <Form.Item className="order_amount">
                    {getFieldDecorator('orderAmount', {
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'Amount is required!',
                          }),
                        },
                      ],
                    })(
                      <Input
                        value={couponInfo.orderAmount}
                        onChange={e =>
                          setCouponInfo({
                            ...couponInfo,
                            orderAmount: e.target.value,
                          })
                        }
                        placeholder=""
                      />,
                    )}
                  </Form.Item>
                )}
              </div>
            </div>
          )}
          <p className="title">
            <FormattedMessage id="Outcome" />
          </p>
          {couponInfo.type &&
            couponInfo.type !== 'FS' &&
            couponInfo.rewards.map((r, index) => (
              <div className="outcome_container" key={index}>
                <div className="outcome_wrapper">
                  <Form.Item className="logic">
                    <p className="sub_label">
                      <FormattedMessage id="Logic" />
                    </p>
                    {couponInfo.type === 'LU' && <FormattedMessage id="Cantidad de descuento % del pedido" />}
                    {(couponInfo.type === 'PP' || couponInfo.type === 'FP') && <FormattedMessage id="Descuento fijo" />}
                    {couponInfo.type === 'FO' && <FormattedMessage id="Cantidad de descuento fijo del pedido" />}
                    {couponInfo.type === 'PO' && <FormattedMessage id="Cantidad de descuento % del pedido" />}
                    {couponInfo.type === 'MP' && <FormattedMessage id="Free Product" />}
                    {couponInfo.type === 'MO' && <FormattedMessage id="Regalar producto" />}
                  </Form.Item>
                  {(couponInfo.type === 'PP' || couponInfo.type === 'FP' || couponInfo.type === 'FO') && (
                    <Form.Item className="quantity">
                      <p className="sub_label">
                        {(couponInfo.type === 'PP' || couponInfo.type === 'FP') && (
                          <FormattedMessage id="Cantidad de porcentaje fijo" />
                        )}
                        {couponInfo.type === 'FO' && <FormattedMessage id="Cantidad" />}
                      </p>
                      {getFieldDecorator('quantity', {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({ id: 'Cantidad' }),
                          },
                        ],
                      })(<Input />)}
                    </Form.Item>
                  )}
                  {(couponInfo.type === 'LU' ||
                    couponInfo.type === 'PO' ||
                    couponInfo.type === 'MP' ||
                    couponInfo.type === 'MO') && (
                    <Form.Item className="quantity">
                      <p className="sub_label">
                        <FormattedMessage id="Quantity" />
                      </p>
                      {getFieldDecorator(`quantity${index}`, {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'Quantity is required!',
                            }),
                          },
                        ],
                        initialValue: r.quantity,
                      })(
                        <Select suffixIcon={<ArrowDropDown />} dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}>
                          {quantitys.map((item, ind) => (
                            <Select.Option value={item.value} key={ind}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>,
                      )}
                    </Form.Item>
                  )}
                  {(couponInfo.type === 'PP' ||
                    couponInfo.type === 'FP' ||
                    couponInfo.type === 'MP' ||
                    couponInfo.type === 'MO') && (
                    <Form.Item className="product">
                      <p className="sub_label">
                        <FormattedMessage id="Product" />
                      </p>
                      {getFieldDecorator(`product${index}`, {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'Product is required!',
                            }),
                          },
                        ],
                        initialValue: r.product !== -1 && products.length !== 0 ? parseInt(r.product) : '',
                      })(
                        <Select
                          showSearch
                          defaultValue={-1}
                          style={{ width: 200 }}
                          optionFilterProp="children"
                          onChange={v => handleProductChange(v)}
                          dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                          filterOption={(input, option) =>
                            option.props.children.props.dangerouslySetInnerHTML.__html
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          suffixIcon={<ArrowDropDown />}
                        >
                          {products.rows &&
                            products.rows.map((s, i) => (
                              <Select.Option value={s.id} key={i}>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: `${s.id || ''}. ${s.name || ''}`,
                                  }}
                                />
                              </Select.Option>
                            ))}
                        </Select>,
                      )}
                      {index === 0 && <Icon type="plus" onClick={handleAddOutCome} />}
                      {index !== 0 && <Icon type="minus" onClick={() => handleRemoveOutCome(index)} />}
                    </Form.Item>
                  )}
                </div>
              </div>
            ))}
          {couponInfo.type && (
            <div className="ruler_container">
              <p className="title">
                <FormattedMessage id="Rule" />
              </p>
              <div className="ruler_wrapper">
                <p>
                  <FormattedMessage id="Total Coupons" />
                </p>
                <Form.Item>
                  {getFieldDecorator('ruler', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'Ruler is required!',
                        }),
                      },
                    ],
                    initialValue: couponInfo.totalCount,
                  })(
                    <Select
                      placeholder="Seleccionar Regla"
                      suffixIcon={<ArrowDropDown />}
                      dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                    >
                      {rules.map((item, ind) => (
                        <Select.Option value={item.value} key={ind}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </div>
            </div>
          )}
          {couponInfo.type && (
            <div className="limit_container">
              <p className="title">
                <FormattedMessage id="Limit" />
              </p>
              <div className="user_limit_wrapper">
                <p>
                  <FormattedMessage id="Number of times the user can use the coupon" />
                </p>
                <Form.Item>
                  {getFieldDecorator('limit', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'Limit is required!',
                        }),
                      },
                    ],
                    initialValue: couponInfo.userLimit,
                  })(
                    <Select
                      placeholder="Limit user"
                      suffixIcon={<ArrowDropDown />}
                      dropdownStyle={{ paddingLeft: '0', paddingRight: '0' }}
                    >
                      {userLimit.map((item, ind) => (
                        <Select.Option value={item.value} key={ind}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </div>
            </div>
          )}
          {couponInfo.type && (
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Short description" />
              </p>
              {getFieldDecorator('description', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'Description is required!',
                    }),
                  },
                ],
                initialValue: couponInfo.description,
              })(<Input.TextArea />)}
            </Form.Item>
          )}
          {couponInfo.type && (
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Coupon code" />
              </p>
              {getFieldDecorator('code', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'Coupon code is required!',
                    }),
                  },
                ],
                initialValue: couponInfo.code,
              })(<Input className="upper_case_coupon" />)}
            </Form.Item>
          )}
          {couponInfo.type && (
            <div className="date_picker_wrapper">
              <Form.Item>
                <p className="title">
                  <FormattedMessage id="Start date" />
                </p>
                {getFieldDecorator('startAt', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'Start date is required!',
                      }),
                    },
                  ],
                  initialValue: couponInfo.startAt ? dayjs(couponInfo.startAt).tz('America/Guatemala') : '',
                })(<DatePicker />)}
              </Form.Item>
              <Form.Item>
                <p className="title">
                  <FormattedMessage id="Finish date" />
                </p>
                {getFieldDecorator('endAt', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'End date is required!',
                      }),
                    },
                  ],
                  initialValue: couponInfo.endAt ? dayjs(couponInfo.endAt).tz('America/Guatemala') : '',
                })(<DatePicker />)}
              </Form.Item>
            </div>
          )}
        </div>
        <div className="modal_footer">
          {couponInfo.type && (
            <div className="aditional_detail">
              <p>
                <FormattedMessage id="Active" />
              </p>
              <Form.Item>
                {getFieldDecorator('state', {
                  rules: [],
                  initialValue: couponInfo.state === 'A',
                })(
                  <Switch
                    checked={couponInfo.state === 'A'}
                    onChange={checked =>
                      setCouponInfo({
                        ...couponInfo,
                        state: checked ? 'A' : 'I',
                      })
                    }
                  />,
                )}
              </Form.Item>
            </div>
          )}
          <div className="btn_wrapper">
            <Button className="close_btn" htmlType="submit" disabled={!couponInfo.type}>
              {isCreateCoupon ? <FormattedMessage id="Create Coupon" /> : <FormattedMessage id="Edit Coupon" />}
              <ArrowForward />
            </Button>
          </div>
        </div>
      </Form>
    </ModalDlg>
  );
};

CouponNewModal.defaultProps = {
  coupon: {},
  isCreateCoupon: false,
  form: {},
  isOpenModal: false,
  products: {},
};

export default compose(injectIntl, Form.create({ name: 'coupon_form' }), withRouter)(CouponNewModal);
