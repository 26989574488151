import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';

function DeliveryAddresses({ intl, addressList, onSelectOrder }) {
  const deliveryTypes = {
    h: intl.formatMessage({ id: 'Home' }),
    o: intl.formatMessage({ id: 'Office' }),
    e: intl.formatMessage({ id: 'Other' }),
  };

  return (
    <div className="delivery_addresses_layout">
      <p className="header_title">
        <FormattedMessage id="Delivery addresses" />
      </p>
      <div className="table_content scrollbar">
        {addressList &&
          addressList.map((item, index) => (
            <div className="delivery_address" key={index} onClick={() => onSelectOrder(item)}>
              <p className="address1">{`${item.address}, ${item.addressHouseNumber || ''}` || ''}</p>
              <p className="address2">{item.description || ''}</p>
              <p className="product">{deliveryTypes[item.type ? item.type.toLowerCase() : 'e'] || ''}</p>
            </div>
          ))}
      </div>
    </div>
  );
}

DeliveryAddresses.defaultProps = {
  addressList: [],
};

export default compose(injectIntl)(DeliveryAddresses);
