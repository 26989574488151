import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Form, Input, Button, Switch, message } from 'antd';
import ModalDlg from 'components/Basic/ModalDlg';
import { Close, Edit, Delete } from '@material-ui/icons';
import { ArrowForward } from '@material-ui/icons';
import toast from 'components/Basic/Toast';
import Loading from 'components/Basic/Loading';
import { useAppDispatch } from 'utilities/redux';
import { useLazyGetTabsListQuery, useCreateTabsMutation, useUpdateTabsMutation } from 'core/tabs/TabsService';
import { setTabsListData } from 'core/tabs/TabsSlice';

type TabsInfo = {
  id: number;
  name: string;
  position: number;
  createdAt: string;
  updatedAt: string;
};

type TabsViewModalType = {
  isOpenModal: boolean;
  onCloseModal: () => void;
  tabsInfo: TabsInfo | null;
};

const TabsViewModal: React.FC<TabsViewModalType> = ({ isOpenModal, onCloseModal, tabsInfo }) => {
  const [tabsName, setTabsName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [getTabsList] = useLazyGetTabsListQuery();
  const [createTabs] = useCreateTabsMutation();
  const [updateTabs] = useUpdateTabsMutation();

  const handleTabsNameChange = (e: any) => {
    setTabsName(e.target.value.trimStart());
  };

  const handleSaveButtonClick = async () => {
    let messageId: string;
    const isUpdate = tabsInfo && tabsInfo.id;

    try {
      setIsLoading(true);

      if (isUpdate) {
        await updateTabs({ name: tabsName.trim(), id: tabsInfo.id }).unwrap();
      } else {
        await createTabs({ name: tabsName.trim() }).unwrap();
      }

      const tabs = await getTabsList({ search: '' }).unwrap();

      dispatch(setTabsListData({ list: tabs }));

      messageId = isUpdate ? 'Tabs is updated successfully!' : 'Tabs is created successfully!';

      toast.success({ title: intl.formatMessage({ id: messageId }) });
    } catch {
      messageId = isUpdate ? 'Updating tabs is failure!' : 'Creating tabs is failure!';

      toast.error({ title: intl.formatMessage({ id: messageId }) });
    } finally {
      setTabsName('');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (tabsInfo) {
      setTabsName(tabsInfo.name || '');
    } else {
      setTabsName('');
    }
  }, [isOpenModal]);

  return (
    <ModalDlg className="tabs_view_modal" visible={isOpenModal} width={430} handleCancel={onCloseModal} isCenter>
      <>
        <div className="modal_header">
          <div className="name_wrapper">
            <h3>
              <FormattedMessage id={tabsInfo && tabsInfo.id ? 'Edit tab' : 'New tab'} />
            </h3>
            <div className="close_modal" onClick={onCloseModal}>
              <Close />
            </div>
          </div>
        </div>
        <div className="new_tabs_form_wrapper">
          <Form className="basic_info_form">
            <div className="tabs_input">
              <Form.Item>
                <p className="title_deals">
                  <FormattedMessage id="tab name" />
                </p>
                <Input
                  placeholder={intl.formatMessage({ id: 'Tab name' })}
                  value={tabsName}
                  onChange={handleTabsNameChange}
                  maxLength={200}
                />
              </Form.Item>
            </div>
            <Form.Item>
              <div className="side_btns_cat">
                <div className="btn_wrapper">
                  {tabsInfo && tabsInfo.id && tabsInfo.name !== tabsName ? (
                    <p
                      className="cancel_btn_tabs"
                      onClick={() => {
                        setTabsName(tabsInfo.name);
                      }}
                    >
                      <FormattedMessage id="Cancel" />
                    </p>
                  ) : (
                    <></>
                  )}
                  <Button
                    className="save_sar_btn"
                    onClick={() => {
                      handleSaveButtonClick();
                      onCloseModal();
                    }}
                    disabled={!tabsName || (tabsInfo ? tabsInfo.name === tabsName : false)}
                  >
                    <FormattedMessage id={tabsInfo && tabsInfo.id ? 'Save changes' : 'Save'} />
                    <ArrowForward />
                  </Button>
                  <Loading visible={isLoading} />
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
      </>
    </ModalDlg>
  );
};

export default TabsViewModal;
