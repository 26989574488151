import React, { useState } from 'react';
import toast from '../../../components/Basic/Toast';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Button, DatePicker, Select } from 'antd';
import { ArrowDropDown, DateRange } from '@material-ui/icons';
import { compose } from 'react-recompose';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyExportCallsReportQuery } from 'core/report/ReportService';
import { setCallsReportData } from 'core/report/ReportSlice';

const AnalyticalCalls = ({ intl }) => {
  const [reportData, setReportDate] = useState({
    dateStart: undefined,
    dateEnd: undefined,
  });

  const { callsReport } = useAppSelector(state => state.reportReducer);

  const dispatch = useAppDispatch();
  const [exportCallsReport] = useLazyExportCallsReportQuery();

  const [isCreate, setIsCreate] = useState(false);
  const handleChangeStartDate = (date, dateString) => {
    setReportDate({ ...reportData, dateStart: dateString });
  };

  const handleChangeEndDate = (date, dateString) => {
    setReportDate({ ...reportData, dateEnd: dateString });
  };
  const handleExport = async () => {
    if (reportData.dateStart && reportData.dateEnd) {
      try {
        const res = await exportCallsReport({
          fromDate: reportData.dateStart,
          toDate: reportData.dateEnd,
        }).unwrap();

        dispatch(setCallsReportData({ callsReport: res }));
        setIsCreate(true);
      } catch (e) {
        console.log(e);
      }
    } else {
      toast.error({ title: intl.formatMessage({ id: 'Fields date is empty' }) });
    }
  };
  const handleClipboardFilename = () => {
    navigator.clipboard.writeText(callsReport.filename);
    toast.success({ title: intl.formatMessage({ id: 'File name has been copied' }) });
  };
  return (
    <div className={'analytical_layout_content_box_inside'}>
      <div className={'analytical_layout_content_box_inside_top'}>
        <span>
          <FormattedMessage id={'Customer calls report'} />
        </span>
        <p>
          <FormattedMessage id={'Export a CSV file that contains the calls of all customers in the selected period'} />
        </p>
      </div>
      <div className={'analytical_layout_content_box_inside_bottom request'}>
        <div>
          <div>
            <FormattedMessage id="Start date" />
            <DatePicker
              className="start_date"
              placeholder={intl.formatMessage({ id: 'Start date' })}
              onChange={handleChangeStartDate}
              suffixIcon={<DateRange />}
            />
          </div>
          <div>
            <FormattedMessage id="Finish date" />
            <DatePicker
              className="start_date"
              placeholder={intl.formatMessage({ id: 'Finish date' })}
              onChange={handleChangeEndDate}
              suffixIcon={<DateRange />}
            />
          </div>
          <div>
            <Button className="export_scv_button" onClick={handleExport}>
              <FormattedMessage id={'Export'} />
            </Button>
          </div>
          {isCreate ? (
            <div className={'analytical_alert_success_create'}>
              <span>
                <FormattedMessage id="Please wait when file will be format and go to Download page. Save file name :" />
                <button onClick={handleClipboardFilename}>{callsReport.filename}</button>
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

AnalyticalCalls.propsType = {
  intl: typeof useIntl,
};

export default compose(injectIntl)(AnalyticalCalls);
