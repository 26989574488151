import { Delete, Edit, FileCopy, RemoveRedEye } from '@material-ui/icons';
import { Button, Pagination, Tooltip } from 'antd';
import DeleteConfirmModal from 'components/Basic/DeleteConfirmModal';
import toast from 'components/Basic/Toast';
import OffersViewModal from 'components/Dashboard/Offers/OffersViewModal';
import { useCreateOffersMutation, useDeleteOffersMutation, useLazyGetFullOffersQuery } from 'core/offers/OffersService';
import { setDeleteOffer, setOffersFullData } from 'core/offers/OffersSlice';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import CodeViewModal from './CodeViewModal';
import { genetateDefaultDealState } from './defaultStates';
import { generateDealPayload } from './tools';

type OffersTableType = {
  total: number;
  current: number;
  offerId?: string;
  offersData: any | null;
  onChangePage: (current: number, size: number) => void;
  setCurrent: (e: number) => void;
};

const OffersTable: React.FC<OffersTableType> = ({
  total,
  current,
  offerId = '',
  offersData,
  setCurrent,
  onChangePage,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [pageSize, setPageSize] = useState<number>(10);
  const [_, setIsLoading] = useState<boolean>(false);

  const [offersInfo, setOffersInfo] = useState<any>(null);
  const [isRemoveModal, setIsRemoveModal] = useState<boolean>(false);
  const [isShowCodeModal, setShowCodeModal] = useState<boolean>(false);
  const [isShowDetailModal, setShowDetailModal] = useState<boolean>(false);

  const [deleteOffers] = useDeleteOffersMutation();
  const [getOfferInfo] = useLazyGetFullOffersQuery();
  const [createOffers] = useCreateOffersMutation();

  const { offersFullData } = useAppSelector(state => state.offersReducer);

  useEffect(() => {
    onChangePage(1, 10);
    if (offerId) {
      showOfferFromUrl(offerId);
    }
  }, []);

  const handleChangePage = (page: number, size: number) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage(page, size);
  };

  const handleShowSizeChange = (c: number, s: number) => {
    handleChangePage(c, s);
  };

  const handleDeleteButtonClick = async () => {
    setIsLoading(true);
    setIsRemoveModal(false);
    try {
      await deleteOffers({ id: offersInfo.id }).unwrap();
      dispatch(setDeleteOffer({ id: offersInfo.id }));

      toast.success({
        title: intl.formatMessage({
          id: 'Offer is deleted successfully!',
        }),
      });
    } catch (error) {
      toast.error({
        title: intl.formatMessage({
          id: 'Offer is deleted failure!',
        }),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInfoOffer = async (item: any) => {
    setIsLoading(true);
    try {
      dispatch(setOffersFullData({ offersFullData: null }));
      const res = await getOfferInfo({ id: item.id }).unwrap();
      dispatch(setOffersFullData({ offersFullData: res }));
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDuplicateOffer = async (item: any) => {
    setIsLoading(true);
    try {
      const res = await getOfferInfo({ id: item.id }).unwrap();
      const offer = genetateDefaultDealState(true, res);
      const newOfferState = { ...offer, ...{ isActive: false, name: `(Duplicado) ${offer.name}` } };
      const payload = generateDealPayload(newOfferState);
      await createOffers({ offerData: payload }).unwrap();
      handleChangePage(1, 10);
      toast.success({ title: intl.formatMessage({ id: 'Offer is created successfully' }) });
    } catch (error) {
      toast.error({ title: intl.formatMessage({ id: 'Creating offer is failure' }) });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const showOfferFromUrl = async (id: any) => {
    try {
      if (!id) return;

      const res = await getOfferInfo({ id: id }).unwrap();

      setOffersInfo(res);
      handleInfoOffer(res);
      setShowDetailModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="offers_table_layout">
      <div className="table_header">
        <div className="id">
          <FormattedMessage id="ID" />
        </div>
        <div className="name">
          <FormattedMessage id="Name" />
        </div>
        <div className="descriptions">
          <FormattedMessage id="Description" />
        </div>
        <div className="tab">
          <FormattedMessage id="Tab" />
        </div>
        <div className="category">
          <FormattedMessage id="Category" />
        </div>
        <div className="date">
          <FormattedMessage id="Date" />
        </div>
        <div className="hour">
          <FormattedMessage id="Hour" />
        </div>
        <div className="weight">
          <FormattedMessage id="Weight" />
        </div>
        <div className="actions">
          <FormattedMessage id="Actions" />
        </div>
      </div>
      <div>
        <div className="table_content">
          {Array.isArray(offersData) &&
            offersData.map((item, index) => (
              <div className={`table_item type_state_${item.isActive}`} key={index}>
                <div className="id">
                  <p>{item.id}</p>
                </div>
                <div className="name">
                  <p>{item.name}</p>
                </div>
                <div className="descriptions">
                  <p>{item.description}</p>
                </div>
                <Tooltip placement="top" title={item.tabs.map((tab: any) => tab.tab.name).join(', ')}>
                  <div className="tab">
                    {item?.tabs && (
                      <p>
                        {(() => {
                          const tabNames = item.tabs.map((tab: any) => tab.tab.name);
                          const joinedNames = tabNames.join(', ');

                          if (joinedNames.length > 35) {
                            return joinedNames.substring(0, 35) + ' ...';
                          } else {
                            return joinedNames;
                          }
                        })()}
                      </p>
                    )}
                  </div>
                </Tooltip>
                <div className="category">
                  <p>{item.category.name}</p>
                </div>
                <div className="date">
                  <p>{dayjs(item.schedule.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
                  <p>{dayjs(item.schedule.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
                </div>
                <div className="hour">
                  <p>{dayjs(item.schedule.startTime).utc().format('HH:mm')}</p>
                  <p>{dayjs(item.schedule.endTime).utc().format('HH:mm')}</p>
                </div>
                <div className="weight">
                  <p>{item.baseWeight}</p>
                </div>
                <div className="actions">
                  <Tooltip placement="top" title={intl.formatMessage({ id: 'View offer' })}>
                    <Button
                      className="edit_btn"
                      onClick={() => {
                        setShowCodeModal(true);
                        setOffersInfo(item);
                        handleInfoOffer(item);
                      }}
                    >
                      <RemoveRedEye />
                    </Button>
                  </Tooltip>
                  <NavLink to={`/dashboard/deals/offers/${item.id}`} style={{ marginRight: '10px' }}>
                    <Tooltip placement="top" title={intl.formatMessage({ id: 'Edit offer' })}>
                      <Button
                        className="edit_btn"
                        onClick={() => {
                          setOffersInfo(item);
                          setShowDetailModal(true);
                          handleInfoOffer(item);
                        }}
                      >
                        <Edit />
                      </Button>
                    </Tooltip>
                  </NavLink>

                  <Tooltip placement="top" title={intl.formatMessage({ id: 'Delete offer' })}>
                    <Button
                      className="delete_btn"
                      onClick={() => {
                        setOffersInfo(item);
                        setIsRemoveModal(true);
                      }}
                    >
                      <Delete />
                    </Button>
                  </Tooltip>
                  <Tooltip placement="top" title={intl.formatMessage({ id: 'Duplicate' })}>
                    <Button
                      className="delete_btn"
                      onClick={() => {
                        handleDuplicateOffer(item);
                      }}
                    >
                      <FileCopy />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            ))}
        </div>
        <div className="pagination_wrapper">
          <Pagination
            showSizeChanger
            defaultCurrent={1}
            defaultPageSize={10}
            current={current}
            pageSize={pageSize}
            pageSizeOptions={['5', '10', '20', '30']}
            total={total}
            onChange={handleChangePage}
            onShowSizeChange={handleShowSizeChange}
          />
          <div className="total">Resultados: {total}</div>
        </div>
      </div>
      {offersFullData?.id && (
        <OffersViewModal
          isDraftPage={false}
          isShowModal={isShowDetailModal}
          onShowModal={(value: boolean) => setShowDetailModal(value)}
          offersInfo={offersFullData}
          current={current}
          pageSize={pageSize}
          draftRequest={() => {}}
          categories={null}
          offersRequest={handleChangePage}
        />
      )}
      {offersFullData?.id && (
        <CodeViewModal
          isOpenModal={isShowCodeModal}
          onCloseModal={() => {
            setShowCodeModal(false);
          }}
          data={offersFullData}
          openOfferModal={() => setShowDetailModal(true)}
        />
      )}
      <DeleteConfirmModal
        isOpenModal={isRemoveModal}
        title={intl.formatMessage({ id: 'Do you want to remove the offer?' })}
        subtitle={intl.formatMessage({
          id: 'This action cannot be undone. Continue deleting the offer?',
        })}
        onCancel={() => setIsRemoveModal(false)}
        onOk={() => handleDeleteButtonClick()}
      />
    </div>
  );
};

export default OffersTable;
