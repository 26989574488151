import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import AmbassadorAnalysisTable from 'components/Dashboard/Distribution/RestaurantAnalysis/AmbassadorAnalysisTable';

const Status = {
  AV: {
    color: '#674FA6',
    label: 'Disponible',
  },
  PA: {
    color: '#E8A159',
    label: 'Preasignado',
  },
  NA: {
    color: '#A54F78',
    label: 'No disponible',
  },
  OR: {
    color: '#3A82C3',
    label: 'En ruta',
  },
  FP: {
    color: '#0C51C9',
    label: 'Solucionar un problema',
  },
  ORT: {
    color: '#FE1F20',
    label: 'En reposo',
  },
  OT: {
    color: '#202020',
    label: 'En otra tarea',
  },
  PV: {
    color: '#6BA752',
    label: 'Problemas con el vehículo',
  },
};

function AmbassadorAnalysis({ data }) {
  const renderCustomizedLabel = props => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const mx = cx + outerRadius * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <text style={{ fontSize: 12 }} x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={fill}>
        {`${Status[payload.detailedState].label}: ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className="ambassador_analysis_wrapper">
      <div className="ambassador_table_wrapper">
        <p className="title">
          <FormattedMessage id="Showing data from the last hour in progress" />
        </p>
        <AmbassadorAnalysisTable data={data.records || []} />
      </div>
      <div className="analysis_chart_wrapper">
        <div style={{ width: '100%', height: '100%' }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={data.statistics || []}
                cx="50%"
                cy="50%"
                outerRadius="40%"
                dataKey="count"
                nameKey="state"
                label={renderCustomizedLabel}
              >
                {(data.statistics || []).map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={Status[entry.detailedState].color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

AmbassadorAnalysis.defaultProps = {
  data: {},
};

export default AmbassadorAnalysis;
